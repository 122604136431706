import { createSelector } from '@ngrx/store';
import { Workspace, workspacesEntityAdapter } from './workspaces.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = workspacesEntityAdapter.getSelectors();

const workspaceEntities = (state: any) => state.workspaces.workspaces.entities;

export const workspaces = createSelector(workspaceEntities, (state) => Object.values(state) as Array<Workspace>);
export const workspacesLoaded = (state: any) => state.workspaces.workspacesLoaded;
export const currentWorkspace = (state: any) => state.workspaces.currentWorkspace;
export const currentWorkspaceLoading = (state: any) => state.workspaces.currentWorkspaceLoading;
export const currentWorkspaceFolder = (state: any) => state.workspaces.currentWorkspaceFolder;
export const workspaceFolderEntities = (state: any) => state.workspaces.workspaceFolderEntities;
export const currentWorkspaceFolderLoading = (state: any) => state.workspaces.currentWorkspaceFolderLoading;
export const currentWorkspaceFolderNextPageLoading = (state: any) =>
  state.workspaces.currentWorkspaceFolderNextPageLoading;
export const workspaceFolderStructure = (state: any) => state.workspaces.workspaceFolderStructure;
export const workspaceEntitiesFolderParameters = (state: any) => state.workspaces.workspaceEntitiesFolderParameters;
export const workspaceEntitiesFolderSummary = (state: any) => state.workspaces.workspaceEntitiesFolderSummary;
