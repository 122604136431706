import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { SubTypesHelper } from '@contrail/sdk';
import { TypePropertyFormFieldBaseComponent } from './type-property-form-field-base.component';
@Component({
  selector: 'app-type-property-form-field-type-reference',
  template: `
    <mat-form-field [appearance]="appearance">
      <mat-label>{{ propertyFormConfiguration.typeProperty.label }}</mat-label>
      <mat-select [formControl]="formControl">
        <mat-option
          [attr.data-test]="'property-form-option-' + option.value"
          *ngFor="let option of typeOptions"
          [value]="option"
        >
          {{ option.longDisplay }}
        </mat-option>
      </mat-select>

      <mat-error *ngIf="formControl.hasError('error')">{{ formControl.getError('error') }}</mat-error>
      <app-type-property-form-validation-error
        *ngIf="formControl.hasError('error') && formControl.disabled"
        [errorMessage]="formControl.getError('error')"
      >
      </app-type-property-form-validation-error>
    </mat-form-field>
  `,
  styles: [
    `
      :host {
        display: block;
        width: 100%;
      }
      .select {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 20px;
        cursor: pointer;
        min-width: 80px;
      }
      mat-form-field {
        width: 100%;
      }
    `,
  ],
})
export class TypePropertyFormFieldTypeReferenceComponent extends TypePropertyFormFieldBaseComponent implements OnInit {
  public display: string;
  public typeOptions: Array<any> = [];
  constructor() {
    super();
  }

  ngOnInit() {}
  ngOnDestroy() {
    this.onDestroy();
  }
  ngOnChanges() {
    this.init();
    this.handleInputChange();
  }
  handleInputChange() {}

  async initFormControl() {
    this.formControl = new UntypedFormControl({ disabled: this.isDisabled() });
    if (!this.entity) {
      return;
    }

    // Set value to correct property
    this.value = this.entity[`${this.propertyFormConfiguration.typeProperty.slug}Id`];

    this.typeOptions = await this.getTypeOptions(this.propertyFormConfiguration.typeProperty.referencedTypePath);
    if (this.value) {
      const selectedOption = this.typeOptions.find((o) => o.value === this.value);
      if (selectedOption) {
        this.formControl.setValue(selectedOption);
      }
    }

    this.formControl.valueChanges.subscribe((option) => {
      this.value = option.value;
      this.handleChange();
    });
  }

  async getTypeOptions(path): Promise<Array<any>> {
    const typesList = await SubTypesHelper.getTypeListFromPath(path);
    const options = typesList.map((type) => {
      return {
        display: type.label,
        longDisplay: type.pathLabel,
        value: type.id,
      };
    });
    return options;
  }
}
