import { createAction, props } from '@ngrx/store';

export enum LibrariesActionTypes {
  CLEAR_LIBRARIES = '[Libraries] Clear Libraries',

  LOAD_LIBRARIES = '[Libraries] Load Libraries',
  LOAD_LIBRARIES_SUCCESS = '[Libraries] Load Libraries Success',
  LOAD_LIBRARIES_FAILURE = '[Libraries] Load Libraries Failure',

  CREATE_LIBRARY_ENTITY = '[Libraries] Create Library Entity',
  CREATE_LIBRARY_ENTITY_SUCCESS = '[Libraries] Create Library Entity Success',
  CREATE_LIBRARY_ENTITY_FAILURE = '[Libraries] Create Library Entity Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const clearLibraries = createAction(LibrariesActionTypes.CLEAR_LIBRARIES);
export const loadLibraries = createAction(LibrariesActionTypes.LOAD_LIBRARIES);

export const loadLibrariesSuccess = createAction(
  LibrariesActionTypes.LOAD_LIBRARIES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadLibrariesFailure = createAction(LibrariesActionTypes.LOAD_LIBRARIES_FAILURE, props<{ error: any }>());

export const createLibraryEntity = createAction(LibrariesActionTypes.CREATE_LIBRARY_ENTITY, props<{ data: any }>());

export const createLibraryEntitySuccess = createAction(
  LibrariesActionTypes.CREATE_LIBRARY_ENTITY_SUCCESS,
  props<{ data: any }>(),
);

export const createLibraryEntityFailure = createAction(
  LibrariesActionTypes.CREATE_LIBRARY_ENTITY_FAILURE,
  props<{ error: any }>(),
);
