import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { workspacePrincipalsReducers } from './workspace-principals.reducers';
import { EffectsModule } from '@ngrx/effects';
import { WorkspacePrincipalsEffects } from './workspace-principals.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('workspacePrincipals', workspacePrincipalsReducers),
    EffectsModule.forFeature([WorkspacePrincipalsEffects]),
  ],
})
export class WorkspacePrincipalsStoreModule {}
