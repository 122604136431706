import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Plan } from './plans-store/plans.state';

@Injectable({
  providedIn: 'root',
})
export class PlansService {
  constructor() {}

  public async getPlans(workspaceId: string) {
    return new Entities().get({
      entityName: 'plan',
      criteria: { rootWorkspaceId: workspaceId, isArchived: false, isTrashed: false },
      relations: ['updatedBy', 'createdBy', 'targetAssortment', 'planSummary'],
    });
  }
  public async getPlanById(id: string) {
    return new Entities().get({ entityName: 'plan', id });
  }
  public async createPlan(plan: Plan) {
    return new Entities().create({ entityName: 'plan', object: plan });
  }
  public async deletePlan(plan: Plan) {
    await new Entities().delete({ entityName: 'plan', id: plan.id });
    return plan;
  }
  public async deletePlanById(id: string) {
    await new Entities().delete({ entityName: 'plan', id });
  }
  public async updatePlan(id: string, changes: Plan) {
    return new Entities().update({ entityName: 'plan', id, object: changes });
  }
  async copy(name, id): Promise<Plan> {
    return new Entities().create({ entityName: 'plan', object: { name }, id, relation: 'copy' });
  }
  public async asyncCopyPlan(name, id, newWorkspaceId = null) {
    return await new Entities().create({
      entityName: 'plan',
      object: { name, planId: id, newWorkspaceId },
      relation: 'copy',
      suffix: 'async',
    });
  }
}
