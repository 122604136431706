import {
  collectionElementInitialState,
  CollectionElementsState,
} from './collection-elements/collection-elements.state';
import {
  CollectionViewDefinition,
  collectionViewsInitialState,
  CollectionViewsState,
  localCollectionViewsInitialState,
} from './collection-views/collection-views.state';
import { ViewDefinition } from '@contrail/client-views';
import { Type } from '@contrail/types';
import { FilterDefinition } from 'src/app/common/types/filters/filter-definition';
import {
  collectionStatusMessagesInitialState,
  CollectionStatusMessagesState,
} from './collection-status-messages/collection-status-messages.state';
import { SortDefinition } from 'src/app/common/components/sort/sort-definition';

export enum EditorMode {
  EDIT = 'EDIT',
  COMMENT = 'COMMENT',
  VIEW = 'VIEW',
  SHARED_EDIT = 'SHARED_EDIT',
  SHARED_VIEW = 'SHARED_VIEW',
  SHARED_COMMENT = 'SHARED_COMMENT',
}
export interface State {
  editorMode: EditorMode;
  collectionElements: CollectionElementsState;
  collectionElementsLoaded: boolean;
  collectionStatusMessages: CollectionStatusMessagesState;
  currentViewDefinition: CollectionViewDefinition; // current view being displayed
  viewDefinitions: CollectionViewsState; // all available views
  localViewDefinitions: CollectionViewsState;
  hoveredEntityId: string; // which entity is currently hovered, used for highlighing rows
  selectedEntityIds: Array<string>; // array of entityIds that are selected.
  selectedProperty: any; // the current 'property' (entityId, propertySlug) selected by the local user
  remoteSelectedProperties: Map<string, any>; // holds a collection of 'properties' that are selected by remote clients. Used for highlighting cells
  selectedElementLocation: DOMRect; // holds the location of the 'focused' selected element, such as a grid cell.
  typeDefinitions: { [key: string]: Type };
  scrollHorizontalPercentage: number; // % of the horizontal scroll distance from the virtual scroll bar
  scrollVerticalPercentage: number; // % of the vertical scroll distance from the virtual scroll bar
  gridViewRowHeight: number;
  frozenColumns: number;
  gridTotalWidth: number;
  gridTotalHeight: number;
  gridViewColumnWidth: number; // single column width drives the scroll speed/column movement per scroll
  sorts: Array<SortDefinition>;
  filterDefinition: FilterDefinition;
  hideEmptyGroups: boolean;

  selectorActive: boolean;
  selectorKeyActive: boolean;
  dragActiveRow: any; // TODO:
  copiedRows: Array<string>;
  anchorRowSelectorId: string;
  sidePanelWidth: number;
  statusMessageElement: any;
  focusedItemFamily: any;
  spreadValueInfo: any;
  contextMenuActive: boolean;
}
export const initialState: State = {
  editorMode: EditorMode.EDIT,
  collectionElements: collectionElementInitialState,
  collectionElementsLoaded: false,
  collectionStatusMessages: collectionStatusMessagesInitialState,
  currentViewDefinition: null,
  viewDefinitions: collectionViewsInitialState,
  localViewDefinitions: localCollectionViewsInitialState,
  hoveredEntityId: null,
  selectedEntityIds: [],
  selectedProperty: null,
  remoteSelectedProperties: new Map(),
  selectedElementLocation: null,
  typeDefinitions: null,
  scrollHorizontalPercentage: 0,
  scrollVerticalPercentage: 0,
  gridViewRowHeight: 24,
  frozenColumns: 2,
  gridTotalWidth: window.innerWidth,
  gridTotalHeight: 0,
  gridViewColumnWidth: 24,
  sorts: [],
  filterDefinition: null,
  hideEmptyGroups: false,
  selectorActive: false,
  selectorKeyActive: false,
  dragActiveRow: null,
  copiedRows: [],
  anchorRowSelectorId: null,
  sidePanelWidth: 1,
  statusMessageElement: null,
  focusedItemFamily: null,
  spreadValueInfo: null,
  contextMenuActive: false,
};
