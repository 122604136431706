import * as _ from 'lodash';
import { AggregateHelper, AggregatePropertyInfo } from '@contrail/aggregates';
import { PropertyType, PropertyValueFormatter, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { ViewPropertyAggregateFunction, ViewPropertyConfiguration, ViewSortDefinition } from '@contrail/client-views';
import { NUMERIC_AGGREGATE_VALUE_PROPERTY_TYPES } from './pivot-grid.constants';
import { DEFAULT_COLUMN_WIDTH_PX } from './header-row/pivot-header-column-resize-helper';

export const AGGREGATE_FUNCTION_MAP = {
  SUM: 'total',
  MAX: 'max',
  MIN: 'min',
  AVG: 'average',
  COUNT: 'count',
};

export interface AggregateRowEntity {
  id: string;
  propertySlug: string;
  children?: Array<AggregateRowEntity>; // nested group properties
  aggregates?: {
    [columnGroupKey: string]: {
      [aggregateValueKey: string]: AggregateValue;
    };
  };
  [key: string]: any;
}

export interface AggregateValue {
  count: number;
  total: number;
  average: number;
  min: number;
  max: number;
}

export interface AggregateColumnEntity {
  propertySlug?: string;
  values: GroupingPropertyValue[];
  child?: AggregateColumnEntity;
  columnType: AggregateColumnType;
}

enum AggregateColumnType {
  COLUMN = 'COLUMN',
  VALUE = 'VALUE',
}

export interface GroupingPropertyValue {
  label: string;
  value?: any;
  width?: number;
  aggregateFunction?: ViewPropertyAggregateFunction;
}

interface ComputeAggregateContext {
  data: any[];
  rowGroupProperties: AggregatePropertyInfo[];
  columnGroupProperties: AggregatePropertyInfo[];
  aggregateProperties: AggregatePropertyInfo[];
}

export class PivotAggregateHelper {
  public static EMPTY_VALUE = '**empty**';

  private static formatter = new PropertyValueFormatter();

  public static computeAggregates(context: ComputeAggregateContext): AggregateRowEntity {
    const rootAggregateRow: AggregateRowEntity = { id: '0', propertySlug: 'root' };

    PivotAggregateHelper.constructAndAssignNestedAggregateRows(rootAggregateRow, context, -1);

    return rootAggregateRow;
  }

  private static constructAndAssignNestedAggregateRows(
    aggregateRowEntity: AggregateRowEntity,
    context: ComputeAggregateContext,
    level: number,
    parentGroupingValues: { [key: string]: any } = {},
  ) {
    const { data, rowGroupProperties, columnGroupProperties, aggregateProperties } = context;

    level++;

    if (rowGroupProperties.length === 0) {
      PivotAggregateHelper.addAggregateData({
        parentAggregate: aggregateRowEntity,
        level,
        parentGroupingValues,
        context: {
          data: data,
          aggregateProperties,
          rowGroupProperties,
          columnGroupProperties,
        },
      });
      return;
    }

    const rowGroupingProperty = rowGroupProperties.splice(0, 1)[0];
    const rowOptionValues = PivotAggregateHelper.buildArrayOfExistingPropertyValues(
      data,
      rowGroupingProperty.typeProperty,
    );

    for (const optionValue of rowOptionValues) {
      const isEmptyValue = !optionValue && typeof optionValue !== 'boolean';
      if (isEmptyValue) {
        continue;
      }

      const entitiesWithMatchingValue = PivotAggregateHelper.filterDataByPropertyValue(
        data,
        rowGroupingProperty,
        optionValue,
      );

      if (entitiesWithMatchingValue?.length > 0) {
        PivotAggregateHelper.addAggregateData({
          parentAggregate: aggregateRowEntity,
          value: optionValue,
          rowGroupingProperty,
          level,
          parentGroupingValues,
          context: {
            data: entitiesWithMatchingValue,
            aggregateProperties,
            rowGroupProperties,
            columnGroupProperties,
          },
        });
      }
    }

    const entitiesWithEmptyValue = PivotAggregateHelper.filterDataByEmptyPropertyValue(data, rowGroupingProperty);

    PivotAggregateHelper.addAggregateData({
      parentAggregate: aggregateRowEntity,
      value: PivotAggregateHelper.EMPTY_VALUE,
      rowGroupingProperty,
      level,
      context: {
        data: entitiesWithEmptyValue,
        aggregateProperties,
        rowGroupProperties,
        columnGroupProperties,
      },
    });
  }

  private static buildArrayOfExistingPropertyValues(data: any[], typeProperty: TypeProperty): any[] {
    const propertyType = typeProperty.propertyType;
    const propertySlug = typeProperty.slug;

    if (propertyType === PropertyType.MultiSelect) {
      const validMultiSelectValues = data.map((entity) => {
        const cellValue = ObjectUtil.cloneDeep(entity[propertySlug]);
        if (cellValue && !Array.isArray(cellValue)) {
          return [cellValue];
        }
        return cellValue?.sort();
      });

      const setOfValues = new Set(validMultiSelectValues.map((value) => (value ? JSON.stringify(value) : null)));
      return Array.from(setOfValues).map((value) => (value ? JSON.parse(value) : value));
    }

    if (propertyType === PropertyType.ObjectReference) {
      return [...new Map(data.map((entity) => entity[propertySlug]).map((obj) => [obj?.id, obj])).values()];
    }

    const optionSetValues = typeProperty.options?.map((option) => option.value);
    if (optionSetValues) {
      return optionSetValues;
    }

    const validValues = data.map((entity) => {
      const value = entity[propertySlug];

      if (propertyType === PropertyType.Boolean) {
        return Boolean(value);
      }

      return value;
    });

    return Array.from(new Set(validValues));
  }

  private static filterDataByPropertyValue(data: any[], aggregateProperty: AggregatePropertyInfo, expectedValue: any) {
    return data.filter((entity) => {
      return PivotAggregateHelper.doesEntityHaveMatchingValue(entity, aggregateProperty, expectedValue);
    });
  }

  private static filterDataByPropertyGroupingValues(
    data: any[],
    aggregateProperties: AggregatePropertyInfo[],
    propertyGroupingValues: { [propertySlug: string]: any } = {},
  ) {
    const aggregatePropertiesBySlug = _.keyBy(aggregateProperties, 'typeProperty.slug');

    return data.filter((entity) => {
      return Object.keys(propertyGroupingValues).every((propertySlug) => {
        const property = aggregatePropertiesBySlug[propertySlug];
        const expectedValue = propertyGroupingValues[propertySlug];
        return PivotAggregateHelper.doesEntityHaveMatchingValue(entity, property, expectedValue);
      });
    });
  }

  private static doesEntityHaveMatchingValue(
    entity: any,
    aggregateProperty: AggregatePropertyInfo,
    expectedValue: any,
  ) {
    const entityValue = PivotAggregateHelper.getValueFromEntityByProperty(entity, aggregateProperty);
    const propertyType = aggregateProperty.typeProperty.propertyType;

    if (propertyType === PropertyType.Boolean) {
      return Boolean(expectedValue) === Boolean(entityValue);
    }

    if (Array.isArray(expectedValue)) {
      return expectedValue.sort().toString() === entityValue?.sort().toString(); // change array to string to compare?
    }

    if (propertyType === PropertyType.ObjectReference) {
      return expectedValue?.id === entityValue?.id;
    }

    return expectedValue === entityValue;
  }

  private static filterDataByEmptyPropertyValue(data: any[], aggregateProperty: AggregatePropertyInfo) {
    if (aggregateProperty.typeProperty.propertyType === PropertyType.Boolean) {
      return [];
    }

    return data.filter((entity) => {
      const entityValue = PivotAggregateHelper.getValueFromEntityByProperty(entity, aggregateProperty);
      return (
        entityValue === null ||
        entityValue === undefined ||
        (typeof entityValue === 'string' && entityValue.trim() === '')
      );
    });
  }

  private static getValueFromEntityByProperty(entity: any, aggregateProperty: AggregatePropertyInfo): any {
    const groupingIndexKey = `${aggregateProperty.indexPrefix || ''}${aggregateProperty.typeProperty.slug}`;
    const entityValue = ObjectUtil.cloneDeep(ObjectUtil.getByPath(entity, groupingIndexKey));
    const propertyType = aggregateProperty.typeProperty.propertyType;

    if (propertyType === PropertyType.MultiSelect) {
      if (entityValue && !Array.isArray(entityValue)) {
        return [entityValue];
      }
    }

    return entityValue;
  }

  private static addAggregateData({
    parentAggregate,
    value,
    rowGroupingProperty,
    level,
    parentGroupingValues,
    context,
  }: {
    parentAggregate: AggregateRowEntity;
    value?: any;
    rowGroupingProperty?: AggregatePropertyInfo;
    level: number;
    parentGroupingValues?: { [key: string]: any };
    context: ComputeAggregateContext;
  }): void {
    const { data, rowGroupProperties } = context;
    if (!data.length) {
      return;
    }

    const id = `${parentAggregate.id}-${value?.id || value}`;
    const newAggregate: AggregateRowEntity = {
      id,
      propertySlug: rowGroupingProperty?.typeProperty?.slug,
      level,
    };

    // Assign values to the new aggregate
    if (rowGroupingProperty) {
      newAggregate[rowGroupingProperty.typeProperty.slug] = value;
      if (parentGroupingValues) {
        parentGroupingValues[rowGroupingProperty.typeProperty.slug] = value;
      }
    }

    Object.assign(newAggregate, parentGroupingValues);
    parentGroupingValues = ObjectUtil.cloneDeep(parentGroupingValues || {});

    PivotAggregateHelper.computeAndAssignColumnAggregates(newAggregate, context);

    parentAggregate.children = parentAggregate.children || [];
    parentAggregate.children.push(newAggregate);

    if (rowGroupProperties.length > 0) {
      PivotAggregateHelper.constructAndAssignNestedAggregateRows(
        newAggregate,
        {
          ...context,
          rowGroupProperties: ObjectUtil.cloneDeep(rowGroupProperties),
        },
        level,
        parentGroupingValues,
      );
    }
  }

  public static computeAndAssignColumnAggregates(
    aggregateRowEntity: AggregateRowEntity,
    context: ComputeAggregateContext,
    propertyGroupingValues: { [propertySlug: string]: any } = {},
    level: number = 0,
  ): void {
    const { data, columnGroupProperties, aggregateProperties } = context;

    if (level === columnGroupProperties.length) {
      const filteredEntities = PivotAggregateHelper.filterDataByPropertyGroupingValues(
        data,
        columnGroupProperties,
        propertyGroupingValues,
      );

      const columnKey = PivotAggregateHelper.buildAggregateColumnKey(propertyGroupingValues);
      PivotAggregateHelper.assignAggregatesToRow(aggregateRowEntity, filteredEntities, aggregateProperties, columnKey);
      return;
    }

    const columnGroupProperty = columnGroupProperties[level];
    const columnOptionValues = PivotAggregateHelper.buildArrayOfExistingPropertyValues(
      data,
      columnGroupProperty.typeProperty,
    );

    for (const columnOptionValue of columnOptionValues) {
      const mergedPropertyGroupingValues = {
        ...propertyGroupingValues,
        [columnGroupProperty.typeProperty.slug]: columnOptionValue,
      };

      this.computeAndAssignColumnAggregates(aggregateRowEntity, context, mergedPropertyGroupingValues, level + 1);
    }
  }

  private static assignAggregatesToRow(
    aggregateRowEntity: AggregateRowEntity,
    entities: any[],
    aggregateProperties: AggregatePropertyInfo[],
    columnKey: string,
  ): void {
    const countAggregate = { count: entities?.length || 0 };

    aggregateProperties.forEach((property) => {
      const propertyAggregate = AggregateHelper.computeAggregatesForNumeric(
        entities,
        property.indexPrefix,
        property.typeProperty.slug,
      );

      const allAggregates = Object.assign({}, countAggregate, propertyAggregate);

      aggregateRowEntity.aggregates = aggregateRowEntity.aggregates || {};
      aggregateRowEntity.aggregates[columnKey] = aggregateRowEntity.aggregates[columnKey] || {};
      aggregateRowEntity.aggregates[columnKey][property.typeProperty.slug] = allAggregates;
    });

    aggregateRowEntity.count = entities.length;
  }

  private static buildAggregateColumnKey(propertyGroupingValues: { [propertySlug: string]: any }): string {
    if (!Object.keys(propertyGroupingValues).length) {
      return '*';
    }

    return Object.entries(propertyGroupingValues)
      .map(([propertySlug, value]) => {
        const formattedValue = PivotAggregateHelper.formatValueAsString(value);
        return `${propertySlug}-${formattedValue}`;
      })
      .join('_');
  }

  public static getDefaultAggregateFunctionForPropertyType(propertyType: PropertyType): ViewPropertyAggregateFunction {
    if (NUMERIC_AGGREGATE_VALUE_PROPERTY_TYPES.includes(propertyType)) {
      return ViewPropertyAggregateFunction.SUM;
    }

    return ViewPropertyAggregateFunction.COUNT;
  }

  public static buildAggregateColumns(
    data: any[],
    columnGroupingProperties: ViewPropertyConfiguration[],
    valueProperties: ViewPropertyConfiguration[],
    sorts?: ViewSortDefinition[],
  ): AggregateColumnEntity {
    if (!columnGroupingProperties.length) {
      const values = valueProperties.map((property) => ({
        label: property.propertyDefinition.label,
        width: property.width || DEFAULT_COLUMN_WIDTH_PX,
        aggregateFunction: property.aggregateFunction,
      }));

      return {
        values,
        columnType: AggregateColumnType.VALUE,
      };
    }

    const [currentProperty, ...remainingProperties] = columnGroupingProperties;
    const values = PivotAggregateHelper.buildArrayOfExistingPropertyValues(data, currentProperty.propertyDefinition);

    const columnGroupingValues: GroupingPropertyValue[] = values
      .map((value) => {
        return {
          label: PivotAggregateHelper.formatValueAsLabel(value, currentProperty.propertyDefinition),
          value,
        };
      })
      .filter((value, index, allGroupingValues) => {
        if (value.label === PivotAggregateHelper.EMPTY_VALUE) {
          const isFirstEmptyLabelInList =
            index === allGroupingValues.findIndex((grouping) => value.label === grouping.label);

          return isFirstEmptyLabelInList;
        }

        return true;
      })
      .sort((a, b) => {
        if (a.label === PivotAggregateHelper.EMPTY_VALUE) return 1;
        if (b.label === PivotAggregateHelper.EMPTY_VALUE) return -1;

        const shouldSortAscending = sorts?.some(
          (sort) => sort.propertySlug === currentProperty.slug && sort.direction === 'DESC',
        );

        if (shouldSortAscending) {
          return a.label.localeCompare(b.label) * -1;
        }

        return a.label.localeCompare(b.label);
      });

    const child = this.buildAggregateColumns(data, remainingProperties, valueProperties, sorts);

    return {
      propertySlug: currentProperty.slug,
      values: columnGroupingValues,
      child,
      columnType: AggregateColumnType.COLUMN,
    };
  }

  public static buildColumnGroupingKeys(baseAggregateColumn: AggregateColumnEntity) {
    const columnGroupings = this.buildListOfColumnGroupingPropertyValueObjects(baseAggregateColumn);
    return columnGroupings.map((columnGrouping) => {
      return this.buildAggregateColumnKey(columnGrouping);
    });
  }

  public static buildListOfColumnGroupingPropertyValueObjects(
    aggregateColumn: AggregateColumnEntity,
  ): { [propertySlug: string]: any }[] {
    const isValueColumn = Boolean(
      !aggregateColumn || !aggregateColumn.child || aggregateColumn.columnType === AggregateColumnType.VALUE,
    );

    if (isValueColumn) {
      return [];
    }

    const propertySlug = aggregateColumn.propertySlug;
    const values = aggregateColumn.values;

    const isLastGroupingColumn = Boolean(
      aggregateColumn.columnType === AggregateColumnType.COLUMN &&
        aggregateColumn.child?.columnType === AggregateColumnType.VALUE,
    );

    if (isLastGroupingColumn) {
      return values.map((value) => ({ [propertySlug]: value.value }));
    }

    const childGroupings = this.buildListOfColumnGroupingPropertyValueObjects(aggregateColumn.child);

    const groupedPropertyValueMapObjects: { [propertySlug: string]: any }[] = [];

    values.forEach((value) => {
      childGroupings.forEach((childGrouping) => {
        groupedPropertyValueMapObjects.push({
          [propertySlug]: value.value,
          ...childGrouping,
        });
      });
    });

    return groupedPropertyValueMapObjects;
  }

  private static formatValueAsLabel(value: any, property: TypeProperty): string {
    if (PivotAggregateHelper.isEmptyValue(value)) {
      return PivotAggregateHelper.EMPTY_VALUE;
    }

    if (property.propertyType === PropertyType.SingleSelect && value) {
      const option = property.options.find((option) => option.value === value);
      return option.display;
    }

    if (property.propertyType === PropertyType.MultiSelect && Array.isArray(value) && value.length) {
      const options = property.options.filter((option) => value.includes(option.value));
      return options.map((o) => o.display).join(',');
    }

    const formattedValue = PivotAggregateHelper.formatter.formatValueForProperty(value, property);
    return PivotAggregateHelper.formatValueAsString(formattedValue);
  }

  private static formatValueAsString(value: any): string {
    if (Array.isArray(value)) {
      const formattedArrayValues = value.map((arrayValue) => PivotAggregateHelper.formatValueAsString(arrayValue));
      return formattedArrayValues.join(', ');
    }

    if (typeof value === 'object') {
      return value?.name || value?.label || '';
    }

    if (PivotAggregateHelper.isEmptyValue(value)) {
      return PivotAggregateHelper.EMPTY_VALUE;
    }

    return `${value}`;
  }

  private static isEmptyValue(value: any): boolean {
    return value === undefined || value === null || (typeof value === 'string' && value.trim() === '');
  }
}
