import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-explorer-card',
  templateUrl: './explorer-card.component.html',
  styleUrls: ['./explorer-card.component.scss'],
})
export class ExplorerCardComponent implements OnInit {
  @Input() cardData: any;
  @Input() rootWorkspaceType: string;
  @Input() multiSelect: boolean = true;
  @Input() recents: boolean = false;
  @Output() openMenu = new EventEmitter();
  @Output() checkClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  showMenu(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.openMenu.emit({ evt });
  }

  get rgb() {
    return `rgb(${this.cardData?.entity.red}, ${this.cardData?.entity.green}, ${this.cardData?.entity.blue})`;
  }

  quickChangePrevent(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }
  selectRow(evt) {
    // evt.preventDefault();
    // evt.stopPropagation();
  }

  onCheckClick(mouseEvt: MouseEvent) {
    mouseEvt.preventDefault();
    let key = 'NONE';
    if (mouseEvt.ctrlKey || mouseEvt.metaKey) {
      key = 'CTRL';
    } else if (mouseEvt.shiftKey) {
      key = 'SHIFT';
    }
    this.checkClick.emit({ mouseEvt, key });
  }
}
