<app-modal title="Create New Color" [dialogRef]="dialogRef">
  <div class="flex justify-between mb-4">
    <div class="flex flex-col w-1/2 my-auto" [formGroup]="colorForm">
      <mat-form-field class="w-full mt-4" appearance="fill">
        <mat-label>Color Name</mat-label>
        <input matInput formControlName="name" />
      </mat-form-field>

      <div class="flex justify-between" formGroupName="rgb">
        <mat-form-field style="width: 28%" appearance="outline" class="!mb-auto">
          <mat-label>Red</mat-label>
          <input type="number" matInput formControlName="red" min="0" max="255" step="1" pattern="\d*" />
        </mat-form-field>
        <mat-form-field style="width: 28%" appearance="outline" class="!mb-auto">
          <mat-label>Green</mat-label>
          <input type="number" matInput formControlName="green" min="0" max="255" step="1" pattern="\d*" />
        </mat-form-field>
        <mat-form-field style="width: 28%" appearance="outline" class="!mb-auto">
          <mat-label>Blue</mat-label>
          <input type="number" matInput formControlName="blue" min="0" max="255" step="1" pattern="\d*" />
        </mat-form-field>
      </div>

      <mat-form-field class="w-1/2 mx-auto !mb-auto pointer-events-none" appearance="outline">
        <mat-label>Hex</mat-label>
        <input matInput formControlName="hex" />
      </mat-form-field>
    </div>

    <div>
      <span
        [(colorPicker)]="color"
        [style.background]="color"
        [cpWidth]="'300px'"
        [cpSaveClickOutside]="true"
        [cpAlphaChannel]="'disabled'"
        [cpOutputFormat]="'hex'"
        [cpToggle]="true"
        [cpDialogDisplay]="'inline'"
        (colorPickerChange)="colorChange($event)"
        autocomplete="off"
      >
      </span>
    </div>
  </div>

  <div class="actions">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="save()">Ok</button>
  </div>
</app-modal>
