import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Showcase } from './showcases-store/showcases.state';

@Injectable({
  providedIn: 'root',
})
export class ShowcasesService {
  constructor() {}

  public async getShowcases(workspaceId: string) {
    return new Entities().get({
      entityName: 'showcase',
      criteria: { workspaceId, isArchived: false, isTrashed: false },
      relations: ['updatedBy', 'createdBy', 'previewFile', 'sourceAssortment'],
    });
  }
  public async getShowcaseById(id: string) {
    return new Entities().get({ entityName: 'showcase', id });
  }
  public async createShowcase(showcase: Showcase) {
    return new Entities().create({ entityName: 'showcase', object: showcase });
  }
  public async deleteShowcase(showcase: Showcase) {
    await new Entities().delete({ entityName: 'showcase', id: showcase.id });
    return showcase;
  }
  public async deleteShowcaseById(id: string) {
    await new Entities().delete({ entityName: 'showcase', id });
  }
  public async updateShowcase(id: string, changes: Showcase) {
    return new Entities().update({ entityName: 'showcase', id, object: changes });
  }
  public async copyShowcase(newname, showcaseid, newWorkspaceId = null) {
    return await new Entities().create({
      entityName: 'showcase',
      object: { name: newname, newWorkspaceId: newWorkspaceId },
      id: showcaseid,
      relation: 'copy',
    });
  }
  public async asyncCopyShowcase(name, id, newWorkspaceId = null) {
    return await new Entities().create({
      entityName: 'showcase',
      object: { name, showcaseId: id, newWorkspaceId },
      relation: 'copy',
      suffix: 'async',
    });
  }
}
