import { createAction, props } from '@ngrx/store';
import {
  WorkspaceEntitiesFolderSummary as WorkspaceEntitiesFolderSummary,
  WorkspaceFolderResultsNextPageData,
} from '../workspaces.service';
import { Workspace, WorkspaceEntitiesFolderParameters, WorkspaceEntity, WorkspaceFolder } from './workspaces.state';

export enum WorkspacesActionTypes {
  LOAD_WORKSPACES = '[Workspaces] Load Workspaces',
  LOAD_WORKSPACES_SUCCESS = '[Workspaces] Load Workspaces Success',
  LOAD_WORKSPACES_FAILURE = '[Workspaces] Load Workspaces Failure',

  CLEAR_WORKSPACES = '[Workspaces] Clear Workspaces',

  CREATE_WORKSPACE = '[Workspaces] Create Workspace',
  CREATE_WORKSPACE_SUCCESS = '[Workspaces] Create Workspace Success',
  CREATE_WORKSPACE_FAILURE = '[Workspaces] Create Workspace Failure',

  DELETE_WORKSPACE = '[Workspaces] Delete Workspace',
  DELETE_WORKSPACE_SUCCESS = '[Workspaces] Delete Workspace Success',
  DELETE_WORKSPACE_FAILURE = '[Workspaces] Delete Workspace Failure',

  UPDATE_WORKSPACE = '[Workspaces] Update Workspace',
  UPDATE_WORKSPACE_SUCCESS = '[Workspaces] Update Workspace Success',
  UPDATE_WORKSPACE_FAILURE = '[Workspaces] Update Workspace Failure',

  LOAD_CURRENT_WORKSPACE = '[Workspaces] Load Current Workspace',
  LOAD_CURRENT_WORKSPACE_SUCCESS = '[Workspaces] Load Current Workspace Success',
  LOAD_CURRENT_WORKSPACE_FAILURE = '[Workspaces] Load Current Workspace Failure',
  SET_CURRENT_WORKSPACE_LOADING = '[Workspaces] Set Current Workspace (Root) Loading',

  SET_CURRENT_WORKSPACE_FOLDER = '[Workspaces] Set Current Workspace Folder',
  SET_CURRENT_WORKSPACE_FOLDER_LOADING = '[Workspaces] Set Current Workspace Folder Loading',
  SET_CURRENT_WORKSPACE_NEXT_PAGE_LOADING = '[Workspaces] Set Current Workspace Folder Next Page Loading',
  LOAD_WORKSPACE_FOLDER_ENTITIES = '[Workspaces] Load Workspace Folder Entities',
  LOAD_WORKSPACE_FOLDER_ENTITIES_SUCCESS = '[Workspaces] Load Workspace Folder Entities Success',
  LOAD_WORKSPACE_FOLDER_ENTITIES_FAILURE = '[Workspaces] Load Workspace Folder Entities Failure',

  SET_WORKSPACE_ENTITIES_FOLDER_PARAMETERS = '[Workspaces] Set Workspace Folder Entities Param',
  SET_WORKSPACE_ENTITIES_FOLDER_SUMMARY = '[Workspaces] Set Workspace Folder Entities Page Key',

  CREATE_WORKSPACE_FOLDER = '[Workspaces] Create Workspace Folder',
  CREATE_WORKSPACE_FOLDER_SUCCESS = '[Workspaces] Create Workspace Success Folder',
  CREATE_WORKSPACE_FOLDER_FAILURE = '[Workspaces] Create Workspace Failure Folder',

  DELETE_WORKSPACE_FOLDER = '[Workspaces] Delete Workspace Folder',
  DELETE_WORKSPACE_FOLDER_SUCCESS = '[Workspaces] Delete Workspace Success Folder',
  DELETE_WORKSPACE_FOLDER_FAILURE = '[Workspaces] Delete Workspace Failure Folder',

  MOVE_FOLDER_ENTITIES = '[Workspaces] Move Folder Entities Folder',
  MOVE_FOLDER_ENTITIES_SUCCESS = '[Workspaces] Move Folder Entities Success Folder',
  MOVE_FOLDER_ENTITIES_FAILURE = '[Workspaces] Move Folder Entities Failure Folder',

  REMOVE_FOLDER_ENTITIES = '[Workspaces] Remove Folder Entities Folder',
  LOAD_NEXT_FOLDER_PAGE = '[Workspaces] Load Next Folder Page',
  LOAD_NEXT_FOLDER_PAGE_SUCCESS = '[Workspaces] Load Next Folder Page Success',
  SET_WORKSPACE_FOLDER_STRUCTURE = '[Workspaces] Set Workspace Folder Structure',

  ADD_ENTITY_TO_CURRENT_FOLDER = '[Workspaces] Add Entity',
  ADD_ENTITY_TO_CURRENT_FOLDER_SUCCESS = '[Workspaces] Add Entity Success',
  ADD_ENTITY_TO_CURRENT_FOLDER_FAILURE = '[Workspaces] Add Entity Failure',

  DELETE_ENTITIES_FROM_CURRENT_FOLDER = '[Workspaces] Delete Entity',
  DELETE_ENTITIES_FROM_CURRENT_FOLDER_SUCCESS = '[Workspaces] Delete Entity Success',
  DELETE_ENTITIES_FROM_CURRENT_FOLDER_FAILURE = '[Workspaces] Delete Entity Failure',

  WORKSPACE_API_ERROR = '[Workspaces] API Error',
}

////////////////////////////////////////////////////////////////////////////////

export const workspaceAPIError = createAction(WorkspacesActionTypes.WORKSPACE_API_ERROR, props<{ error: any }>());
export const clearWorkspaces = createAction(WorkspacesActionTypes.CLEAR_WORKSPACES);
export const loadWorkspaces = createAction(WorkspacesActionTypes.LOAD_WORKSPACES);

export const loadWorkspacesSuccess = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadWorkspacesFailure = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACES_FAILURE,
  props<{ error: any }>(),
);

export const createWorkspace = createAction(WorkspacesActionTypes.CREATE_WORKSPACE, props<{ workspace: Workspace }>());

export const createWorkspaceSuccess = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const createWorkspaceFailure = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const updateWorkspace = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE,
  props<{ id: string; changes: Workspace }>(),
);

export const updateWorkspaceSuccess = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE_SUCCESS,
  props<{ id: string; changes: Workspace }>(),
);

export const updateWorkspaceFailure = createAction(
  WorkspacesActionTypes.UPDATE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const deleteWorkspace = createAction(WorkspacesActionTypes.DELETE_WORKSPACE, props<{ workspace: Workspace }>());

export const deleteWorkspaceSuccess = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const deleteWorkspaceFailure = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentWorkspace = createAction(WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE, props<{ id: string }>());

export const loadCurrentWorkspaceSuccess = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const loadCurrentWorkspaceFailure = createAction(
  WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_FAILURE,
  props<{ error: any }>(),
);

export const setCurrentWorkspaceLoading = createAction(
  WorkspacesActionTypes.SET_CURRENT_WORKSPACE_LOADING,
  props<{ loading: boolean }>(),
);

export const setCurrentWorkspaceFolder = createAction(
  WorkspacesActionTypes.SET_CURRENT_WORKSPACE_FOLDER,
  props<{ workspace: Workspace }>(),
);

export const setCurrentWorkspaceFolderLoading = createAction(
  WorkspacesActionTypes.SET_CURRENT_WORKSPACE_FOLDER_LOADING,
  props<{ loading: boolean }>(),
);

export const setCurrentWorkspaceFolderNextPageLoading = createAction(
  WorkspacesActionTypes.SET_CURRENT_WORKSPACE_NEXT_PAGE_LOADING,
  props<{ loading: boolean }>(),
);

export const loadWorkspaceFolderEntities = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES,
  props<{ workspaceId: string }>(),
);

export const loadWorkspaceFolderEntitiesSuccess = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES_SUCCESS,
  props<{ workspaceEntities: Array<WorkspaceEntity> }>(),
);

export const loadWorkspaceFolderEntitiesFailure = createAction(
  WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES_FAILURE,
  props<{ error: any }>(),
);

export const createWorkspaceFolder = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FOLDER,
  props<{ workspace: Workspace }>(),
);

export const createWorkspaceFolderSuccess = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FOLDER_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const createWorkspaceFolderFailure = createAction(
  WorkspacesActionTypes.CREATE_WORKSPACE_FOLDER_FAILURE,
  props<{ error: any }>(),
);

export const deleteWorkspaceFolder = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FOLDER,
  props<{ workspaceId: string }>(),
);

export const deleteWorkspaceFolderSuccess = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FOLDER_SUCCESS,
  props<{ workspace: Workspace }>(),
);

export const deleteWorkspaceFolderFailure = createAction(
  WorkspacesActionTypes.DELETE_WORKSPACE_FOLDER_FAILURE,
  props<{ error: any }>(),
);

export const moveFolderEntities = createAction(
  WorkspacesActionTypes.MOVE_FOLDER_ENTITIES,
  props<{ entityReferences: Array<string>; targetWorkspaceId: string }>(),
);

export const moveFolderEntitiesSuccess = createAction(
  WorkspacesActionTypes.MOVE_FOLDER_ENTITIES_SUCCESS,
  props<{ workspaceEntityIds: Array<string> }>(),
);

export const moveFolderEntitiesFailure = createAction(
  WorkspacesActionTypes.MOVE_FOLDER_ENTITIES_FAILURE,
  props<{ error: any }>(),
);

export const setWorkspaceFolderStructure = createAction(
  WorkspacesActionTypes.SET_WORKSPACE_FOLDER_STRUCTURE,
  props<{ workspaceFolderStructure: Array<WorkspaceFolder> }>(),
);

/** Given a list of entity references, will remove
 * from the local store (not the API). Useful post delete
 * of a document, etc.
 */
export const removeFolderEntities = createAction(
  WorkspacesActionTypes.REMOVE_FOLDER_ENTITIES,
  props<{ entityReferences: Array<any> }>(),
);

export const setWorkspaceEntitiesFolderParameters = createAction(
  WorkspacesActionTypes.SET_WORKSPACE_ENTITIES_FOLDER_PARAMETERS,
  props<{ parameters: WorkspaceEntitiesFolderParameters }>(),
);

export const setWorkspaceEntitiesFolderSummary = createAction(
  WorkspacesActionTypes.SET_WORKSPACE_ENTITIES_FOLDER_SUMMARY,
  props<{ workspaceEntitiesFolderSummary: WorkspaceEntitiesFolderSummary }>(),
);

export const loadNextFolderPage = createAction(WorkspacesActionTypes.LOAD_NEXT_FOLDER_PAGE);
export const loadNextFolderPageSuccess = createAction(
  WorkspacesActionTypes.LOAD_NEXT_FOLDER_PAGE_SUCCESS,
  props<{ nextPageData: WorkspaceFolderResultsNextPageData }>(),
);

export const addEntity = createAction(WorkspacesActionTypes.ADD_ENTITY_TO_CURRENT_FOLDER, props<{ entity: any }>());

export const addEntitySuccess = createAction(
  WorkspacesActionTypes.ADD_ENTITY_TO_CURRENT_FOLDER_SUCCESS,
  props<{ entity: any }>(),
);

export const addEntityFailure = createAction(
  WorkspacesActionTypes.ADD_ENTITY_TO_CURRENT_FOLDER_FAILURE,
  props<{ error: any }>(),
);

export const deleteEntity = createAction(
  WorkspacesActionTypes.DELETE_ENTITIES_FROM_CURRENT_FOLDER,
  props<{ entities: any }>(),
);

export const deleteEntitySuccess = createAction(
  WorkspacesActionTypes.DELETE_ENTITIES_FROM_CURRENT_FOLDER_SUCCESS,
  props<{ entities: any }>(),
);

export const deleteEntityFailure = createAction(
  WorkspacesActionTypes.DELETE_ENTITIES_FROM_CURRENT_FOLDER_FAILURE,
  props<{ error: any }>(),
);
