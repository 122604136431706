import { State } from './libraries.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as LibrariesActions from './libraries.actions';
import { librariesEntityAdapter } from './libraries.state';
import { librariesInitialState } from './libraries.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    libraries: librariesEntityAdapter.setAll(data, state.libraries),
    librariesLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    libraries: librariesEntityAdapter.removeAll(state.libraries),
    librariesLoaded: false,
  };
};

export const librariesReducers = createReducer(
  librariesInitialState,
  on(LibrariesActions.loadLibrariesSuccess, setData),
  on(LibrariesActions.clearLibraries, clearData),
);

export function reducer(state: State | undefined, action: Action): any {
  return librariesReducers(state, action);
}
