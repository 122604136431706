import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { WorkspacesActions } from '../../workspaces/workspaces-store';
import { RootStoreState } from '../../../root-store';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss'],
})
export class CreateFolderComponent implements OnInit {
  policyOwner = false;
  public nameFormControl = new UntypedFormControl('', Validators.required);

  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CreateFolderComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }

  save() {
    this.store.dispatch(
      WorkspacesActions.createWorkspaceFolder({
        workspace: { name: this.nameFormControl.value, policyOwner: this.policyOwner },
      }),
    );
    // TODO: @RORY | SIDE NAV FOLDER REFRESH CHECKING---
    // setTimeout(() => {
    //   this.store.dispatch(WorkspacesActions.loadCurrentWorkspace({id: 'Z5Fii3xeWfSuwq9y'}));
    // }, 2000);
    // this.workspaceFolderStructureService.subscribe();
    this.dialogRef.close();
  }
  isValid() {
    return this.nameFormControl.valid;
  }
}
