import { Component, OnDestroy, OnInit } from '@angular/core';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { WorkspaceEntitiesHelperService } from 'src/app/workspaces/workspace-entities-helper.service';

@Component({
  selector: 'app-folder-breadcrumb-nav',
  templateUrl: './folder-breadcrumb-nav.component.html',
  styleUrls: ['./folder-breadcrumb-nav.component.scss'],
})
export class FolderBreadcrumbNavComponent implements OnInit, OnDestroy {
  public currentFolder: Workspace;
  private subscriptions = [];

  tempArr = [];
  tempLength = 0;

  constructor(
    public store: Store<RootStoreState.State>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
  ) {
    this.subscribe();
  }

  ngOnInit(): void {}

  subscribe() {
    this.subscriptions.push(
      this.store.select(WorkspacesSelectors.currentWorkspaceFolder).subscribe((ws) => {
        this.currentFolder = ws;
        this.setTempArr();
      }),
    );
  }

  unsubscribe() {
    this.subscriptions.map((sub) => sub.unsubscribe());
  }

  ngOnDestroy() {
    this.unsubscribe();
  }
  goToEntity(entity) {
    this.workspaceEntitiesHelper.goToEntity(entity);
  }
  goToRootFolder() {
    this.workspaceEntitiesHelper.goToRootFolder();
  }
  goToFolder(folder) {
    this.workspaceEntitiesHelper.goToFolder(folder);
  }

  setTempArr() {
    let tempWS = this.currentFolder;
    let length = 0;
    this.tempArr = [];
    const arr = [...Array(20).keys()]; //TODO: Brian | Currently array max length 20
    for (let idx = 0; idx < arr.length; idx++) {
      if (tempWS?.parent) {
        this.tempArr.push(tempWS);
        tempWS = tempWS.parent;
      } else {
        length = idx;
        break;
      }
    }
    this.tempLength = length;
    this.tempArr.reverse();
  }
}
