import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesSelectors, WorkspacesActions } from '../workspaces-store';
import { Workspace } from '../workspaces-store/workspaces.state';

@Component({
  selector: 'app-workspace-selector',
  templateUrl: './workspace-selector.component.html',
  styleUrls: ['./workspace-selector.component.scss'],
})
export class WorkspaceSelectorComponent implements OnInit {
  // TODO: WE DON'T USE IN HUB | DELETE

  @Output() selectWorkspace = new EventEmitter();
  public workspaces$: Observable<Array<Workspace>>;
  public selectedWorkspace$: Observable<Workspace>;
  constructor(private store: Store<RootStoreState.State>) {
    this.workspaces$ = this.store.select(WorkspacesSelectors.workspaces).pipe(
      map((workspaces) => {
        return workspaces.filter((w) => !w.isArchived);
      }),
    );
    this.selectedWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);
  }

  ngOnInit(): void {}

  public handleSelectWorkspace(workspace: Workspace) {
    this.selectWorkspace.emit(workspace);
  }
}
