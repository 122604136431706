<div class="flex p-3">
  <div class="flex" *ngIf="!currentFolder?.parent">
    {{ currentFolder?.rootWorkspaceType === 'PROJECT' ? 'Documents' : 'Explorer' }}
  </div>

  <ng-container *ngFor="let tempWS of tempArr; index as idx">
    <div class="flex" *ngIf="tempWS?.parent" (click)="goToFolder(tempWS.parent)">
      <span class="text-gray-700 cursor-pointer hover:underline hover:text-primary hover:font-medium">
        <ng-container *ngIf="tempWS?.parent?.id !== tempWS?.parent?.rootWorkspaceId; else root">
          {{ tempWS.parent.name }}
        </ng-container>
        <ng-template #root>
          {{ tempWS.parent.workspaceType === 'PROJECT' ? 'Documents' : 'Explorer' }}
        </ng-template>
      </span>
      <span class="mx-2">/</span>
    </div>

    <div class="text-gray-800 font-medium" *ngIf="idx + 1 === tempLength">
      {{ tempWS.name }}
    </div>
  </ng-container>
</div>
