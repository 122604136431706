import { State } from './boards.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as BoardsActions from './boards.actions';
import { boardsEntityAdapter } from './boards.state';
import { boardsInitialState } from './boards.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    boards: boardsEntityAdapter.setAll(data, state.boards),
    boardsLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    boards: boardsEntityAdapter.removeAll(state.boards),
    boardsLoaded: false,
  };
};
const add = (state: State, { board }) => {
  return {
    ...state,
    boards: boardsEntityAdapter.addOne(board, state.boards),
  };
};
const update = (state: State, { id, changes }) => {
  let currentBoard = state.currentBoard;
  if (state.currentBoard?.id === id) {
    currentBoard = { ...state.currentBoard, ...changes };
  }
  return {
    ...state,
    boards: boardsEntityAdapter.updateOne({ id, changes }, state.boards),
    currentBoard,
  };
};
const remove = (state: State, { board }) => {
  return {
    ...state,
    boards: boardsEntityAdapter.removeOne(board.id, state.boards),
  };
};
const setCurrentBoard = (state: State, { board }) => {
  return {
    ...state,
    currentBoard: board,
  };
};

export const boardsReducers = createReducer(
  boardsInitialState,
  on(BoardsActions.loadBoardsSuccess, setData),
  on(BoardsActions.clearBoards, clearData),
  on(BoardsActions.createBoardSuccess, add),
  on(BoardsActions.copyBoardSuccess, add),
  // on(BoardsActions.deleteBoardSuccess, remove),
  on(BoardsActions.updateBoardSuccess, update),
  on(BoardsActions.loadCurrentBoardSuccess, setCurrentBoard),
);

export function reducer(state: State | undefined, action: Action): any {
  return boardsReducers(state, action);
}
