import { createAction, props } from '@ngrx/store';
import { Showroom } from './showrooms.state';

export enum ShowroomsActionTypes {
  LOAD_SHOWROOMS = '[Showrooms] Load Showrooms',
  LOAD_SHOWROOMS_SUCCESS = '[Showrooms] Load Showrooms Success',
  LOAD_SHOWROOMS_FAILURE = '[Showrooms] Load Showrooms Failure',

  CLEAR_SHOWROOMS = '[Showrooms] Clear Showrooms',

  CREATE_SHOWROOM = '[Showrooms] Create Showroom',
  CREATE_SHOWROOM_SUCCESS = '[Showrooms] Create Showroom Success',
  CREATE_SHOWROOM_FAILURE = '[Showrooms] Create Showroom Failure',

  COPY_SHOWROOM = '[Showrooms] Copy Showroom',
  COPY_SHOWROOM_SUCCESS = '[Showrooms] Copy Showroom Success',
  COPY_SHOWROOM_FAILURE = '[Showrooms] Copy Showroom Failure',

  DELETE_SHOWROOM = '[Showrooms] Delete Showroom',
  DELETE_SHOWROOM_SUCCESS = '[Showrooms] Delete Showroom Success',
  DELETE_SHOWROOM_FAILURE = '[Showrooms] Delete Showroom Failure',

  UPDATE_SHOWROOM = '[Showrooms] Update Showroom',
  UPDATE_SHOWROOM_SUCCESS = '[Showrooms] Update Showroom Success',
  UPDATE_SHOWROOM_FAILURE = '[Showrooms] Update Showroom Failure',

  LOAD_CURRENT_SHOWROOM = '[Showrooms] Load Current Showroom',
  LOAD_CURRENT_SHOWROOM_SUCCESS = '[Showrooms] Load Current Showroom Success',
  LOAD_CURRENT_SHOWROOM_FAILURE = '[Showrooms] Load Current Showroom Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const clearShowrooms = createAction(ShowroomsActionTypes.CLEAR_SHOWROOMS);
export const loadShowrooms = createAction(ShowroomsActionTypes.LOAD_SHOWROOMS);

export const loadShowroomsSuccess = createAction(
  ShowroomsActionTypes.LOAD_SHOWROOMS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadShowroomsFailure = createAction(ShowroomsActionTypes.LOAD_SHOWROOMS_FAILURE, props<{ error: any }>());

export const createShowroom = createAction(ShowroomsActionTypes.CREATE_SHOWROOM, props<{ showroom: Showroom }>());

export const createShowroomSuccess = createAction(
  ShowroomsActionTypes.CREATE_SHOWROOM_SUCCESS,
  props<{ showroom: Showroom }>(),
);

export const createShowroomFailure = createAction(
  ShowroomsActionTypes.CREATE_SHOWROOM_FAILURE,
  props<{ error: any }>(),
);

export const copyShowroom = createAction(
  ShowroomsActionTypes.COPY_SHOWROOM,
  props<{ name: string; sourceId: string }>(),
);

export const copyShowroomSuccess = createAction(
  ShowroomsActionTypes.COPY_SHOWROOM_SUCCESS,
  props<{ showroom: Showroom }>(),
);

export const copyShowroomFailure = createAction(ShowroomsActionTypes.COPY_SHOWROOM_FAILURE, props<{ error: any }>());

export const updateShowroom = createAction(
  ShowroomsActionTypes.UPDATE_SHOWROOM,
  props<{ id: string; changes: Showroom }>(),
);

export const updateShowroomSuccess = createAction(
  ShowroomsActionTypes.UPDATE_SHOWROOM_SUCCESS,
  props<{ id: string; changes: Showroom }>(),
);

export const updateShowroomFailure = createAction(
  ShowroomsActionTypes.UPDATE_SHOWROOM_FAILURE,
  props<{ error: any }>(),
);

export const deleteShowroom = createAction(ShowroomsActionTypes.DELETE_SHOWROOM, props<{ showroomId: string }>());

export const deleteShowroomSuccess = createAction(
  ShowroomsActionTypes.DELETE_SHOWROOM_SUCCESS,
  props<{ showroomId: string }>(),
);

export const deleteShowroomFailure = createAction(
  ShowroomsActionTypes.DELETE_SHOWROOM_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentShowroom = createAction(ShowroomsActionTypes.LOAD_CURRENT_SHOWROOM, props<{ id: string }>());

export const loadCurrentShowroomSuccess = createAction(
  ShowroomsActionTypes.LOAD_CURRENT_SHOWROOM_SUCCESS,
  props<{ showroom: Showroom }>(),
);

export const loadCurrentShowroomFailure = createAction(
  ShowroomsActionTypes.LOAD_CURRENT_SHOWROOM_FAILURE,
  props<{ error: any }>(),
);
