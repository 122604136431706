import { Injectable } from '@angular/core';
import { EntityReference } from '@contrail/sdk';
import { ObjectUtil } from '@contrail/util';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { skip, take, tap } from 'rxjs/operators';
import { WorkspacesActions, WorkspacesSelectors } from '../workspaces-store';
import { WorkspaceEntity, WorkspaceFolder } from '../workspaces-store/workspaces.state';
import { WorkspacesService } from '../workspaces.service';
import { firstValueFrom } from 'rxjs';

//TODO: EXPAND FOLDER LIMITATION
@Injectable()
export class WorkspaceFolderStructureService {
  constructor(
    private store: Store<RootStoreState.State>,
    private workspaceService: WorkspacesService,
  ) {}

  async syncPossibleFolderChange(workspaceEntities: Array<WorkspaceEntity>) {
    if (!workspaceEntities?.length) {
      return;
    }
    const workspaceId = workspaceEntities[0].workspaceId;
    let currentFolderStructure;
    const wss = await firstValueFrom(this.store.select(WorkspacesSelectors.workspaceFolderStructure));
    currentFolderStructure = wss;
    const changedFolder = this.getFolderForWorkspaceIdFromStructure(currentFolderStructure, workspaceId);
    if (!changedFolder || !changedFolder.expanded) {
      return;
    }

    // DIFF WORKSPACES IN FOLDER VS CURRENT ENTITIES LIST.
    const childWorkspaceIds = changedFolder.children.map((fold) => fold.workspace.id);
    const newFolders = workspaceEntities.filter((we) => {
      const ref = new EntityReference(we.entityReference);
      return ref.entityType.indexOf('workspace') > -1 && !childWorkspaceIds.includes(ref.id);
    });
    console.log('new folders: ', newFolders);
  }

  public async getWorkspaceFolders(workspace) {
    const workspaces = await this.workspaceService.getWorkspaceFolders(workspace);
    return workspaces
      .sort((ws1, ws2) => {
        return ws1.name < ws2.name ? -1 : 1;
      })
      .map((ws) => {
        return { name: ws.name, workspace: { ...ws, parent: workspace } };
      });
  }

  public async loadWorkspaceFolders(workspace: any) {
    const children = await this.getWorkspaceFolders(workspace);
    const name = workspace?.rootWorkspaceType === 'PROJECT' ? 'Documents' : 'Explorer';

    const folders = [
      {
        name,
        workspace,
        children,
        expanded: true,
      },
    ];
    this.store.dispatch(WorkspacesActions.setWorkspaceFolderStructure({ workspaceFolderStructure: folders }));
  }

  private getFolderForWorkspaceIdFromStructure(structure, workspaceId): WorkspaceFolder {
    for (const f of structure) {
      if (f?.workspace?.id === workspaceId) {
        return f;
      }
    }
    for (const f of structure) {
      if (f?.children) {
        const childFolder = this.getFolderForWorkspaceIdFromStructure(f.children, workspaceId);
        if (f) {
          return childFolder;
        }
      }
    }
  }

  public async expandFolder(folder: WorkspaceFolder) {
    const currentStructure = await firstValueFrom(this.store.select(WorkspacesSelectors.workspaceFolderStructure));
    const structureClone = ObjectUtil.cloneDeep(currentStructure);
    const structureFolder = this.getFolderForWorkspaceIdFromStructure(structureClone, folder.workspace.id);

    if (!folder?.children && folder.workspace) {
      structureFolder.children = await this.getWorkspaceFolders(folder.workspace);
    }
    structureFolder.expanded = !structureFolder?.expanded;
    this.store.dispatch(WorkspacesActions.setWorkspaceFolderStructure({ workspaceFolderStructure: structureClone }));
  }
}
