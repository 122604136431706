import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserOrg } from './user-org';

interface UserOrgsState extends EntityState<UserOrg> {}

export interface State {
  userOrgs: UserOrgsState;
  currentUserOrg: UserOrg;
}
export const userOrgsEntityAdapter: EntityAdapter<UserOrg> = createEntityAdapter<UserOrg>({});

export const userOrgsInitialState = {
  userOrgs: userOrgsEntityAdapter.getInitialState({}),
  currentUserOrg: null,
};
