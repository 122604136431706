<!-- folder.name = `undefined` (no access) -->

<div class="ml-1">
  <div
    *ngIf="folder?.name"
    class="flex items-center folder cursor-pointer h-7"
    [ngClass]="{ selected: selectedWorkspace.id === folder.workspace.id }"
    (dragenter)="handleDragEnter($event)"
    (dragleave)="handleDragLeave($event)"
    (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event)"
  >
    <span class="material-icons flex-center w-5 h-5 text-xl" (click)="toggleExpanded()">
      {{ folder.expanded ? 'arrow_drop_down' : 'arrow_right' }}
    </span>
    <span
      class="material-icons"
      [ngClass]="!folder?.workspace?.parent ? 'text-black60' : 'text-blue-300 text-xl'"
      [class.scale-90]="!folder?.workspace?.parent"
      (click)="handleSelect()"
    >
      {{ folder?.workspace?.parent ? 'folder' : folder?.name === 'Documents' ? 'folder' : 'explore' }}
    </span>
    <span
      (click)="handleSelect()"
      class="folder-name w-available line-ellipsis"
      matTooltipPosition="right"
      [matTooltip]="folder?.name"
      [matTooltipDisabled]="folder?.name.length < 13"
    >
      {{ folder.name }}
    </span>
  </div>
  <div *ngIf="folder.expanded && folder?.name" class="ml-2">
    <app-workspace-folder-navigation-folder
      *ngFor="let folder of folder.children"
      [folder]="folder"
      [selectedWorkspace]="selectedWorkspace"
      (folderExpanded)="handleSubFolderExpanded($event)"
      (folderClicked)="handleSubFolderClicked($event)"
    >
    </app-workspace-folder-navigation-folder>
  </div>
</div>
