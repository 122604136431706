import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { SortObjects } from '../../components/sort/sort-objects';
import { BehaviorSubject, combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { ItemData } from '../../item-data/item-data';
import { FilterDefinition } from '../../types/filters/filter-definition';
import { SortDefinition } from '../../components/sort/sort-definition';
import { Types } from '@contrail/sdk';
import { PropertyType, Type } from '@contrail/types';

export interface ChooserFilterConfig {
  searchTerm?: string;
  filterDefintion?: FilterDefinition;
  auth?: any;
  baseCriteria?: any;
}

export abstract class ChooserDataSource {
  protected filteredData$: Observable<Array<any>>;
  private dataSubscription: Subscription;
  private resultsSubject: Subject<Array<any>> = new BehaviorSubject([]);
  public results$: Observable<Array<any>> = this.resultsSubject.asObservable();

  protected assortmentSubject: Subject<Array<ItemData>> = new BehaviorSubject([]);
  public assortmentItemData$ = this.assortmentSubject.asObservable();
  protected searchableProperties = [];

  protected loadingSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public loading$: Observable<boolean> = this.loadingSubject.asObservable();

  public resultsCount$: Subject<number> = new BehaviorSubject(null);
  public id = Math.random();

  constructor(
    protected entityType: string,
    protected filterConfigSubject: Observable<ChooserFilterConfig>,
    protected sortConfigSubject: Observable<SortDefinition[]>,
    protected existingIdsSubject: Observable<any>,
    protected showAllSubject: Observable<any>,
    protected dateFilterSubject: Observable<FilterDefinition>,
  ) {
    this.initSearchableProperties();
  }

  private async initSearchableProperties() {
    const baseType = await new Types().getType({ root: this.entityType, path: this.entityType });

    this.searchableProperties = [...baseType.typeProperties]
      .filter((p) => {
        return [PropertyType.String, PropertyType.Text, PropertyType.SingleSelect, PropertyType.MultiSelect].includes(
          p.propertyType,
        );
      })
      .map((p) => p.slug);
  }

  public filterLocalData({ searchTerm }, data, additionalProperties = []) {
    const mappedNames = additionalProperties.map((p) => 'properties.' + p);
    const searchOptions = ['properties.name', 'properties.optionName', ...mappedNames];

    let filtered;
    filtered = data.filter((entity) => {
      return (
        !searchTerm?.length ||
        searchOptions.some((key) => new RegExp(searchTerm, 'gi').test(ObjectUtil.getByPath(entity, key.trim())))
      );
    });
    return Object.assign([], filtered);
  }

  protected abstract initFilteredDataObservable();

  /** Initalizes the final $data observable, which additionally filters based on existing ids in the context. */
  public initResultsObservable() {
    this.dataSubscription = combineLatest([this.filteredData$, this.existingIdsSubject, this.showAllSubject])
      .pipe(
        tap(async ([results, existingIds, showAll]) => {
          let data = results;
          if (!data) {
            console.log('WARN: data null in building existing filtered obsservable');
            return [];
          }
          // FILTER OUT EXISTING
          if (!showAll) {
            data = data.filter((obj) => !existingIds.has(obj.id)); // THIS COULD BE EXPENSIVE...
          }
          this.resultsSubject.next(data);
        }),
      )
      .subscribe();
  }
  public cleanUp() {
    console.log('ChooserDataSource: cleanUp');
    this.dataSubscription.unsubscribe();
  }

  public sortData(data, sorts) {
    // Sort properties and get the sorted list
    const sortedProperties = SortObjects.sort(
      data.map((obj) => obj.properties),
      sorts,
    );

    // Create a lookup map for item IDs and their indices directly from sortedProperties
    const idToIndexMap = new Map(sortedProperties.map((property, index) => [property.itemId, index]));

    // Sort data using the lookup map for indices
    data.sort((a, b) => {
      return (idToIndexMap.get(a.id) || 0) - (idToIndexMap.get(b.id) || 0);
    });
  }

  public assignResults(data) {
    this.resultsSubject.next(data);
  }
}
