import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { AssortmentHistoryService } from '@common/assortment-history/assortment-history.service';

@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private assortmentHistoryService: AssortmentHistoryService) {}

  public async getLatestAssortmentSummary(assortmentId: string) {
    const history = await this.assortmentHistoryService.getAssortmentHistoryItems(assortmentId, ['createdBy']);
    if (!history) {
      return null;
    }
    const sorted = history as Array<any>;
    sorted.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1));
    if (sorted.length) {
      const historyItem = sorted[0];
      console.log('historyItem: ', historyItem);
      return historyItem?.summary;
    }
  }
  public async getAssortmentHistory(assortmentId: string) {
    const history = await this.assortmentHistoryService.getAssortmentHistoryItems(assortmentId, ['createdBy']);
    if (!history) {
      return null;
    }
    const sorted = history as Array<any>;
    sorted.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1));
    return sorted;
  }
}
