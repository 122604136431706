import { Injectable } from '@angular/core';
import { ObjectUtil } from '@contrail/util';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of as observableOf, of } from 'rxjs';
import { catchError, delay, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { CollectionManagerActions } from '..';
import { FilterDefinition } from '@common/types/filters/filter-definition';
import { FilterObjects } from '@contrail/filters';
import { CollectionManagerService } from '../../collection-manager.service';
import { RootStoreState } from '@rootstore';
import { CollectionType } from './collection-elements.actions';

@Injectable()
export class CollectionElementEffects {
  constructor(
    private actions$: Actions,
    private collectionManagerService: CollectionManagerService,
    private store: Store<RootStoreState.State>,
  ) {}

  loadCollectionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CollectionManagerActions.CollectionElementsActionTypes.LOAD_COLLECTION_DATA),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const { id, collectionType } = action;
        const isAssortment = collectionType === CollectionType.ASSORTMENT;
        if (!isAssortment) {
          console.error('Cannot load collection data of non assortment entity');
          return;
        }

        this.store.dispatch(CollectionManagerActions.setCollectionElementsLoaded({ loaded: false }));

        const collectionData$ = isAssortment
          ? this.collectionManagerService.getCollectionDataByAssortmentId(id)
          : of([]);

        return collectionData$.pipe(
          tap(() => this.store.dispatch(CollectionManagerActions.setCollectionElementsLoaded({ loaded: true }))),
          map((data) => {
            this.store.dispatch(CollectionManagerActions.setScrollVerticalPercentage({ percentage: 0 }));
            return CollectionManagerActions.loadCollectionDataSuccess({ data });
          }),
          catchError((error) => observableOf(CollectionManagerActions.loadCollectionDataFailure({ error }))),
        );
      }),
    ),
  );

  filterCollectionData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        CollectionManagerActions.CollectionElementsActionTypes.LOAD_COLLECTION_DATA_SUCCESS,
        CollectionManagerActions.CollectionManagerActionTypes.SET_FILTER_DEFINITION_SUCCESS,
      ),
      delay(1),
      withLatestFrom(this.store),
      map(([action, store]: [any, RootStoreState.State]) => {
        const filterDef: FilterDefinition = store.collectionManager.filterDefinition;
        let rawData =
          ObjectUtil.cloneDeep(action.data) || Object.values(store.collectionManager.collectionElements.entities);

        if (!filterDef?.filterCriteria?.propertyCriteria || filterDef.filterCriteria.propertyCriteria.length === 0) {
          return CollectionManagerActions.setFilteredEntityIds({
            filteredEntityIds: null,
          });
        }

        const filteredEntityIds = new Set(FilterObjects.filter(rawData, filterDef.filterCriteria).map((ph) => ph.id));
        return CollectionManagerActions.setFilteredEntityIds({
          filteredEntityIds,
        });
      }),
    ),
  );
}
