import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ListComponent } from './list.component';
import { ListItemCellComponent } from './list-item-cell/list-item-cell.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PipesModule } from '@common/pipes/pipes.module';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  declarations: [ListComponent, ListItemCellComponent],
  imports: [CommonModule, MatIconModule, MatCheckboxModule, MatTooltipModule, PipesModule, DirectivesModule],
  exports: [ListComponent],
})
export class ListModule {}
