import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { ShowcasesActions } from '.';
import { ShowcasesService } from '../showcases.service';
import { Showcase } from './showcases.state';
import { RoutingService } from '@common/routing/routing.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { DownloadService } from '@common/exports/download/download.service';
import { WorkspaceEntitiesHelperService } from 'src/app/workspaces/workspace-entities-helper.service';

@Injectable()
export class ShowcasesEffects {
  constructor(
    private actions$: Actions,
    private showcaseService: ShowcasesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private routingService: RoutingService,
    private downloadService: DownloadService,
    private workspaceEntitiesHelperService: WorkspaceEntitiesHelperService,
  ) {}

  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          // this.store.dispatch(ShowcasesActions.clearShowcases());
          // this.store.dispatch(ShowcasesActions.loadShowcases());
        }),
      ),
    { dispatch: false },
  );

  loadShowcases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.LOAD_SHOWCASES),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showcaseService.getShowcases(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => ShowcasesActions.loadShowcasesSuccess({ data })),
          catchError((error) => observableOf(ShowcasesActions.loadShowcasesFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentShowcases$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowcasesActions.ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE),
      switchMap((action: any) => {
        return from(this.showcaseService.getShowcaseById(action.id)).pipe(
          map((data) => ShowcasesActions.loadCurrentShowcaseSuccess({ showcase: data })),
          catchError((error) => observableOf(ShowcasesActions.loadCurrentShowcaseFailure({ error }))),
        );
      }),
    ),
  );
  createShowcase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.CREATE_SHOWCASE),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating showcase.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          const showcase: Showcase = { ...action.showcase };
          showcase.workspaceId = store.workspaces.currentWorkspaceFolder.id;
          return from(this.showcaseService.createShowcase(showcase)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showcase Created.', '', { duration: 2000 });
              // Could be we only want this in hub....
              this.routingService.openApp('showcase-manager', `showcase-details/${data.id}`);
              this.store.dispatch(WorkspacesActions.addEntity({ entity: data }));
              return ShowcasesActions.createShowcaseSuccess({ showcase: data });
            }),
            catchError((error) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowcasesActions.createShowcaseFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  copyShowcase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.COPY_SHOWCASE),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Copying showcase.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.showcaseService.copyShowcase(action.name, action.sourceId, action?.newWorkspaceId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showcase Copied.', '', { duration: 2000 });
              this.routingService.openApp('showcase-manager', `showcase-details/${data.id}`);
              this.store.dispatch(
                WorkspacesActions.loadWorkspaceFolderEntities({
                  workspaceId: store.workspaces.currentWorkspaceFolder.id,
                }),
              );
              return ShowcasesActions.copyShowcaseSuccess({ showcase: data });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowcasesActions.copyShowcaseFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  asyncCopyShowcase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.ASYNC_COPY_SHOWCASE),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Copying showcase.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.showcaseService.asyncCopyShowcase(action.name, action.sourceId, action.newWorkspaceId)).pipe(
            map((data) => {
              if (!data?.jobId) {
                throw new Error();
              }
              this.downloadService.initDownloadPolling(data.jobId, `/showcases/copy/${data.jobId}`);
              const combletedJobSubscription = this.downloadService.completedJobsObservable$.subscribe(
                async (completedJob) => {
                  if (completedJob?.jobId === data.jobId && completedJob?.path) {
                    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
                    this.store.dispatch(
                      ShowcasesActions.asyncCopyShowcaseSuccess({
                        path: completedJob.path as string,
                        jobId: completedJob.jobId,
                        navigateToFolder: action.navigateToFolder,
                      }),
                    );
                    combletedJobSubscription.unsubscribe();
                  }
                },
              );
              return data;
            }),
            catchError((error) => {
              this.snackBar.open(error, 'An error occurred while attempting to copy this document. Please try again.', {
                duration: 2000,
              });
              return observableOf(ShowcasesActions.copyShowcaseFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  asyncCopyShowcaseSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.ASYNC_COPY_SHOWCASE_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          this.snackBar.open('Showcase Copied.', '', { duration: 2000 });
          const response = await fetch(action.path);
          const showcase = await response.json();
          this.routingService.openApp('showcase-manager', `showcase-details/${showcase.id}`);
          this.store.dispatch(
            WorkspacesActions.loadWorkspaceFolderEntities({
              workspaceId: store.workspaces.currentWorkspaceFolder.id,
            }),
          );
          this.store.dispatch(ShowcasesActions.copyShowcaseSuccess({ showcase }));
          if (action.navigateToFolder) {
            if (action.navigateToFolder?.across) {
              this.routingService.go('/workspaces/workspace/' + action.navigateToFolder.rootId + '/explorer');
              if (action.navigateToFolder?.folder?.entity) {
                setTimeout(() => this.workspaceEntitiesHelperService.goToEntity(action.navigateToFolder.folder), 1500);
              }
            } else {
              action.navigateToFolder?.folder?.entity
                ? this.workspaceEntitiesHelperService.goToEntity(action.navigateToFolder.folder)
                : this.workspaceEntitiesHelperService.goToRootFolder();
            }
          }
        }),
      ),
    { dispatch: false },
  );
  deleteShowcase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.DELETE_SHOWCASE),
        withLatestFrom(this.store),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Deleting showcase.' }));
        }),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.showcaseService.deleteShowcaseById(action.showcaseId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showcase Deleted.', '', { duration: 2000 });
              this.store.dispatch(
                WorkspacesActions.removeFolderEntities({ entityReferences: [`showcase:${action.showcaseId}`] }),
              );
              return ShowcasesActions.deleteShowcaseSuccess({ showcaseId: action.showcaseId });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowcasesActions.deleteShowcaseFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  updateShowcase$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowcasesActions.ShowcasesActionTypes.UPDATE_SHOWCASE),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Updating showcase.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.showcaseService.updateShowcase(action.id, action.changes)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showcase Updated.', '', { duration: 2000 });
              if (store.workspaces.currentWorkspaceFolder?.id) {
                this.store.dispatch(
                  WorkspacesActions.loadWorkspaceFolderEntities({
                    workspaceId: store.workspaces.currentWorkspaceFolder.id,
                  }),
                );
              }

              this.store.dispatch(ShowcasesActions.updateShowcaseSuccess({ id: data.id, changes: data }));
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowcasesActions.updateShowcaseFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
