import { Component, OnInit, ViewChild, AfterViewInit, SimpleChanges, Input } from '@angular/core';
import { SearchBarComponent } from '@common/components/search-bar/search-bar.component';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { combineLatest, from, Observable } from 'rxjs';
import { startWith, filter, map, tap } from 'rxjs/operators';
import { Entities } from '@contrail/sdk';
import { LibrariesSelectors } from 'src/app/libraries/libraries-store';

@Component({
  selector: 'app-workspace-navigator',
  templateUrl: './workspace-navigator.component.html',
  styleUrls: ['./workspace-navigator.component.scss'],
})
export class WorkspaceNavigatorComponent implements AfterViewInit {
  @Input() rootWorkspaceType: string; // 'Projects' | 'Libraries'
  public more = false;
  public slice = 4;
  @ViewChild(SearchBarComponent) searchBar: SearchBarComponent;
  public workspaces$: Observable<Array<Workspace>>;
  public loading$: Observable<boolean>;
  public data$: Observable<Array<any>>;

  constructor(private store: Store<RootStoreState.State>) {}

  ngAfterViewInit() {}

  ngOnChanges(): void {
    if (this.rootWorkspaceType === 'Projects') {
      this.loading$ = this.store.select(WorkspacesSelectors.workspacesLoaded);
      this.data$ = this.store.select(WorkspacesSelectors.workspaces);
    } else if (this.rootWorkspaceType === 'Libraries') {
      this.loading$ = this.store.select(LibrariesSelectors.librariesLoaded);
      this.data$ = this.store.select(LibrariesSelectors.libraries);
    }
    this.setWorkspaces();
  }

  setWorkspaces() {
    setTimeout(() => {
      this.workspaces$ = combineLatest([
        this.searchBar.valueChange.pipe(startWith('')),
        this.loading$,
        this.data$,
      ]).pipe(
        filter(([searchTerm, loaded, workspaces]) => loaded),
        map(([searchTerm, loaded, workspaces]) => {
          const keys = 'name';
          if (this.rootWorkspaceType === 'Libraries') {
            workspaces = workspaces.map((ws) => {
              return {
                ...ws,
                icon: `assets/images/${ws?.name}.svg`,
                name: ws.name.charAt(0).toUpperCase() + ws.name.slice(1),
              };
            });
          }

          return (
            workspaces
              // .sort((a, b) => (new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()))
              .filter((ws) =>
                keys
                  .split(',')
                  .some(
                    (key) =>
                      ws.hasOwnProperty(key) && new RegExp(searchTerm, 'gi').test(ws[key]) && ws.isArchived === false,
                  ),
              )
          );
        }),
      );
    }, 10);
  }
}
