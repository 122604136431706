<button class="btn-36 hover:scale-110" mat-button [matMenuTriggerFor]="beforeMenu" data-test="app-switch-button">
  <span class="material-icons-outlined opacity-60"> dashboard_customize </span>
</button>
<mat-menu #beforeMenu="matMenu" xPosition="before">
  <div class="apps-wrap w-[15rem] h-36 flex flex-wrap items-center mx-4">
    <div
      *ngFor="let app of apps"
      (click)="gotoApp(app.url, app.name)"
      class="cursor-pointer flex flex-col items-center w-[5rem] py-1 px-2 rounded-md hover:bg-blue-50"
    >
      <img [src]="'assets/images/' + app.icon" class="w-9 h-9 object-contain pt-1" />
      <p class="text-xs p-1">{{ app.name }}</p>
    </div>
  </div>
</mat-menu>
