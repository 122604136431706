import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { Store } from '@ngrx/store';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '../../common/workspaces/workspaces-store';

@Component({
  selector: 'app-create-workspace',
  templateUrl: './create-workspace.component.html',
  styleUrls: ['./create-workspace.component.scss'],
})
export class CreateWorkspaceComponent implements OnInit {
  public nameFormControl = new UntypedFormControl('', Validators.required);
  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CreateWorkspaceComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}

  cancel() {
    this.dialogRef.close();
  }
  save() {
    this.store.dispatch(WorkspacesActions.createWorkspace({ workspace: { name: this.nameFormControl.value } }));
    this.dialogRef.close();
  }
  isValid() {
    return this.nameFormControl.valid;
  }
}
