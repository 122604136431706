import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthActions } from 'src/app/common/auth/auth-store';
import { RootStoreState } from 'src/app/root-store';
import { LibrariesActions } from '.';
import { LibrariesService } from './libraries.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';

@Injectable()
export class LibrariesEffects {
  constructor(
    private actions$: Actions,
    private librariesService: LibrariesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  authContextChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AuthActionTypes.SET_AUTH_CONTEXT),
        tap((action: any) => {
          this.store.dispatch(LibrariesActions.clearLibraries());
          this.store.dispatch(LibrariesActions.loadLibraries());
        }),
      ),
    { dispatch: false },
  );

  loadLibraries$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LibrariesActions.LibrariesActionTypes.LOAD_LIBRARIES),
      switchMap((action: any) => {
        return from(this.librariesService.getLibraries()).pipe(
          map((data) => LibrariesActions.loadLibrariesSuccess({ data })),
          catchError((error) => observableOf(LibrariesActions.loadLibrariesFailure({ error }))),
        );
      }),
    ),
  );

  createLibraryEntity$ = createEffect(
    // add it to current folder store manually.
    () =>
      this.actions$.pipe(
        ofType(LibrariesActions.LibrariesActionTypes.CREATE_LIBRARY_ENTITY),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          this.store.dispatch(
            LoadingIndicatorActions.setLoading({ loading: true, message: `Creating ${action.entityName}.` }),
          );
          return from(
            this.librariesService.createLibraryEntity({ entityName: action.entityName, object: action.object }),
          ).pipe(
            map((data) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(`${action.entityName} created.`, '', { duration: 2000 });

              this.store.dispatch(WorkspacesActions.addEntity({ entity: data }));
              return LibrariesActions.createLibraryEntitySuccess({ data });
            }),
            catchError((error) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(LibrariesActions.createLibraryEntityFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
