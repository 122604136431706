import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebSocketMessageDispatcher } from './web-socket-mesage-dispatcher';

@NgModule({
  declarations: [],
  imports: [CommonModule],
})
export class WebSocketModule {
  constructor(private dispatcher: WebSocketMessageDispatcher) {}
}
