<div [id]="'column_header_' + columnId" class="bg-gray-100 w-full">
  <div class="header-cell" #headerCell>
    <span class="label">
      {{ label }}
    </span>
  </div>

  <app-pivot-grouping-column-headers
    *ngIf="childColumns"
    [columnGroupingValues]="childColumns"
    [level]="level + 1"
    [showGoals]="showGoals"
  >
  </app-pivot-grouping-column-headers>

  <div *ngIf="showGoals && !childColumns" class="flex box-border border-gray-300 border-t-[1px]">
    <div class="flex justify-center label w-2/4">Current</div>
    <div class="flex justify-center label sub-header-cell-right w-2/4">Goal</div>
  </div>
</div>
