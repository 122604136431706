import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesSelectors, WorkspacesActions } from '../workspaces-store';
import { Workspace, WorkspaceFolder } from '../workspaces-store/workspaces.state';
import { WorkspaceFolderStructureService } from './workspace-folder-structure.service';

@Component({
  selector: 'app-workspace-folder-navigation',
  templateUrl: './workspace-folder-navigation.component.html',
  styleUrls: ['./workspace-folder-navigation.component.scss'],
  providers: [WorkspaceFolderStructureService],
})
export class WorkspaceFolderNavigationComponent implements OnInit, AfterViewInit {
  private destroy$ = new Subject();

  @Output() selectWorkspace = new EventEmitter();
  @Input() visible: boolean = true;

  public currentWorkspace$: Observable<Workspace>;
  public currentWorkspaceFolder$: Observable<Workspace>;
  public workspaceFolderStructure$: Observable<Array<WorkspaceFolder>>;
  public workspaceFolderEntities$: Observable<Array<WorkspaceFolder>>;

  constructor(
    private store: Store<RootStoreState.State>,
    private workspaceFolderStructureService: WorkspaceFolderStructureService,
  ) {
    this.workspaceFolderStructure$ = this.store.select(WorkspacesSelectors.workspaceFolderStructure);
    this.currentWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);
    this.currentWorkspaceFolder$ = this.store.select(WorkspacesSelectors.currentWorkspaceFolder);
    this.workspaceFolderEntities$ = this.store.select(WorkspacesSelectors.workspaceFolderEntities);
  }

  ngOnInit() {
    this.currentWorkspace$.pipe(takeUntil(this.destroy$)).subscribe((ws) => {
      if (ws) {
        this.workspaceFolderStructureService.loadWorkspaceFolders(ws);
      }
    });
    this.workspaceFolderEntities$.pipe(takeUntil(this.destroy$)).subscribe((wfe) => {
      if (wfe?.length) {
        this.workspaceFolderStructureService.syncPossibleFolderChange(wfe);
      }
    });
  }

  ngAfterViewInit() {}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public handleSelectWorkspace(workspace: Workspace) {
    this.selectWorkspace.emit(workspace);
  }

  async handleFolderClicked(folder) {
    console.log('handleFolderClicked: ', folder);
    this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace: folder.workspace }));
  }

  async handleFolderExpanded(folder) {
    await this.workspaceFolderStructureService.expandFolder(folder);
  }
}
