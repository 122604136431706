import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { PropertyValueFormatter } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { ListColumnDefinition } from '../list-column-definition';
import { ListItemData } from '../list-item-data';

@Component({
  selector: 'app-list-item-cell',
  templateUrl: './list-item-cell.component.html',
  styleUrls: ['./list-item-cell.component.scss'],
})
export class ListItemCellComponent implements OnChanges {
  private propertyValueFormatter = new PropertyValueFormatter();
  @Input() listItemData: ListItemData | any;
  @Input() columnDefinition: ListColumnDefinition;
  @Output() actionClick = new EventEmitter();
  @Output() checkClick = new EventEmitter();
  public value;
  public display;

  constructor() {}

  ngOnChanges(): void {
    if (!this.columnDefinition.propertyType || this.columnDefinition.propertyType === 'color') {
      return;
    }

    // Value
    if (this.columnDefinition.index) {
      if (this.columnDefinition.index.includes('userName')) {
        const first = this.columnDefinition.index.replace('userName', 'firstName');
        const last = this.columnDefinition.index.replace('userName', 'lastName');
        const firstName = ObjectUtil.getByPath(this.listItemData, first);
        const lastName = ObjectUtil.getByPath(this.listItemData, last);
        const email = this.columnDefinition.index.replace('userName', 'email');
        this.value =
          firstName && lastName ? firstName + ' ' + lastName : ObjectUtil.getByPath(this.listItemData, email);
      } else {
        this.value = ObjectUtil.getByPath(this.listItemData, this.columnDefinition.index);
      }
    } else if (this.columnDefinition.constantValue) {
      this.value = this.columnDefinition.constantValue;
    }

    // Display
    if (this.columnDefinition.propertyType) {
      this.display = this.propertyValueFormatter.formatValue(
        this.value,
        this.columnDefinition.propertyType,
        {
          format: this.columnDefinition.numberFormat?.format,
          precision: this.columnDefinition.numberFormat?.decimalPlaces,
        },
        {
          includeTime: this.columnDefinition?.includeTime,
        },
      );
    } else {
      this.display = this.value;
    }
  }

  rowAction(evt, icon) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.columnDefinition?.actionIcon) {
      this.actionClick.emit({ evt, icon });
    }
  }

  quickChangePrevent(evt) {
    evt.preventDefault();
    evt.stopPropagation();
  }

  selectRow(evt) {
    // evt.preventDefault();
    // evt.stopPropagation();
  }

  trPrevent(mouseEvt: MouseEvent) {
    mouseEvt.preventDefault();
    let key = 'NONE';
    if (mouseEvt.ctrlKey || mouseEvt.metaKey) {
      key = 'CTRL';
    } else if (mouseEvt.shiftKey) {
      key = 'SHIFT';
    }
    this.checkClick.emit({ mouseEvt, key });
  }
}
