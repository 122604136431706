import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { WorkspacesService } from '@common/workspaces/workspaces.service';
import { ObjectUtil } from '@contrail/util';
import { ListColumnDefinition } from './list-column-definition';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent implements OnChanges {
  constructor(private workspaceService: WorkspacesService) {}

  @Input() columnDefinitions: Array<ListColumnDefinition>;
  @Input() sortBy: any = null;
  @Input() listData: any[];
  @Input() draggable: boolean;
  @Input() multiSelect = true;
  @Output() doubleClickRow = new EventEmitter();
  @Output() clickRow = new EventEmitter();
  @Output() contextMenu = new EventEmitter();
  @Output() dropListItem = new EventEmitter();
  @Output() cellIconClick = new EventEmitter();
  @Output() clickHeaderColumn = new EventEmitter();
  private dragEnterCounter = {};

  selectedRowNum = 0;
  tempListData = [];
  draggingSelected = false;
  draggingRowIdx = null;

  ngOnChanges(): void {
    const prevSelected = this.tempListData.filter((t) => t?.selected).map((i) => i.id) || [];
    if (prevSelected?.length) {
      this.listData = this.listData.map((i) => {
        const selected = prevSelected.includes(i.id) ? true : false;
        return { ...i, selected };
      });
    }
    this.tempListData = [];
    this.selectedRowNum = this.listData.filter((i) => i?.selected).length;
  }

  sort(column) {
    this.clickHeaderColumn.emit(column);
  }

  handleDoubleClickRow(listItemData) {
    this.doubleClickRow.emit(listItemData);
  }

  checkBoxClick(evt, listItemData, idx) {
    evt?.mouseEvt.stopPropagation();
    const key: 'CTRL' | 'SHIFT' | 'NONE' = evt?.key;
    // No checkbox if multiSelect = false
    if (!this.multiSelect) return;

    switch (key) {
      case 'NONE':
      case 'CTRL':
        // set `selectedOne` when we select only 1 row
        this.ctrlSelect(listItemData);
        break;
      case 'SHIFT':
        this.shiftSelect(listItemData, idx);
        break;
      default:
        break;
    }
  }

  handleClickRow(mouseEvt: MouseEvent, listItemData, idx) {
    setTimeout(() => {
      // settimeout to set delay in case of double click
      // multiSelect ---------
      let key = 'NONE';
      if (mouseEvt.ctrlKey || mouseEvt.metaKey) {
        key = 'CTRL';
      } else if (mouseEvt.shiftKey) {
        key = 'SHIFT';
      }

      if (!this.multiSelect) {
        key = 'NONE';
      }

      switch (key) {
        case 'NONE':
          this.listData.forEach((i) => (i.selected = false));
          this.selectedRowNum = 1;
          listItemData.selected = true;
          this.clickRow.emit({ selectedNum: 1, selectedOne: listItemData });
          break;
        case 'CTRL':
          this.ctrlSelect(listItemData);
          break;
        case 'SHIFT':
          this.shiftSelect(listItemData, idx);
          break;

        default:
          break;
      }
    }, 200);
  }

  shiftSelect(listItemData, idx) {
    if (this.selectedRowNum < 1) {
      // JUST CLICK - NO SHIFT EFFECT
      this.selectedRowNum = 1;
      listItemData.selected = true;
      this.clickRow.emit({ selectedNum: this.selectedRowNum, selectedOne: listItemData });
      return;
    } else if (this.selectedRowNum === 1) {
      // IF ONLY ONE SELECTED
      let firstIndex = this.listData.findIndex((e) => e?.selected);
      if (idx > firstIndex) {
        for (let i = firstIndex; i < idx; i++) {
          this.listData[i + 1].selected = true;
        }
        this.selectedRowNum = idx - firstIndex + 1;
        this.clickRow.emit({ selectedNum: this.selectedRowNum, selectedOne: null });
      } else {
        for (let i = idx; i < firstIndex; i++) {
          this.listData[i].selected = true;
        }
        this.selectedRowNum = firstIndex - idx + 1;
        const selectedOne = this.selectedRowNum === 1 ? listItemData : null;
        this.clickRow.emit({ selectedNum: this.selectedRowNum, selectedOne });
      }
    } else {
      // MORE THAN 2 ROWS SELECTED - FIRST/LASTINDEX UPDATE
      let firstIndex = this.listData.findIndex((e) => e?.selected);
      let lastIndex = 0;
      // lastIndex = this.listData.findLastIndex((e) => e?.selected); // TODO: @RORY  `findLastIndex is supported in lib:es2023`
      const len = this.listData?.length - 1;
      for (let index = len; index > -1; index--) {
        if (this.listData[index]?.selected) {
          lastIndex = index;
          break;
        }
      }
      if (idx < firstIndex) {
        for (let k = firstIndex; k < lastIndex; k++) {
          this.listData[k + 1].selected = false;
        }
        lastIndex = firstIndex;
        firstIndex = idx;
      } else if (idx > lastIndex) {
        lastIndex = idx;
      } else if (idx > firstIndex && idx < lastIndex) {
        for (let k = idx; k < lastIndex; k++) {
          this.listData[k + 1].selected = false;
        }
        lastIndex = idx;
      } else {
        // first/lastIndex click with SHIFT KEY - DO NOTHING
        return;
      }
      for (let j = firstIndex; j < lastIndex + 1; j++) {
        this.listData[j].selected = true;
      }
      this.selectedRowNum = lastIndex - firstIndex + 1;
      this.clickRow.emit({ selectedNum: this.selectedRowNum, selectedOne: null });
    }
  }

  ctrlSelect(listItemData) {
    let selectedOne = null;
    listItemData?.selected ? this.selectedRowNum-- : this.selectedRowNum++;
    listItemData.selected = !listItemData?.selected;
    if (this.selectedRowNum === 1) {
      selectedOne = this.listData.find((i) => i.selected);
    }
    this.clickRow.emit({ selectedNum: this.selectedRowNum, selectedOne });
  }

  /** MORE ACTIONS ------------------------------------ */
  handleContextMenu(mouseEvent: MouseEvent, listItem) {
    if (this.selectedRowNum > 1 && listItem?.selected) {
      // Selected (> 1) rows and right click on selected row.
      const multiRows = this.listData.filter((r) => r?.selected);
      this.contextMenu.emit({ mouseEvent, listItem, multiRows });
    } else if (this.selectedRowNum === 0) {
      // No Selected Row.
      listItem.selected = true;
      this.selectedRowNum = 1;
      this.contextMenu.emit({ mouseEvent, listItem });
      this.clickRow.emit({ selectedNum: 1, selectedOne: listItem });
    } else {
      // We have selected rows but right click on unselected row.
      this.listData.forEach((i) => (i.selected = false));
      listItem.selected = true;
      this.selectedRowNum = 1;
      this.contextMenu.emit({ mouseEvent, listItem });
      this.clickRow.emit({ selectedNum: 1, selectedOne: listItem });
    }
  }
  handleCellIconClick(icon, itemData) {
    // TODO: We might need to consider other action icons. Currently only use in Trash (restore & delete_forever).
    if (this.selectedRowNum > 0 && itemData?.selected) {
      this.selectedRowNum--;
    }
    this.cellIconClick.emit({ icon, itemData });
  }
  cellActionClick(event, itemData) {
    if (event.icon === 'more_horiz') {
      this.contextMenu.emit({ mouseEvent: event.evt, listItem: itemData });
    } else this.handleCellIconClick(event.icon, itemData);
  }
  /** ------------------------------------ MORE ACTIONS */

  /** DRAG & DROP ------------------------------------ */
  handleDragStart($event, listItem, idx) {
    this.draggingRowIdx = idx;
    if (listItem?.selected) {
      this.draggingSelected = true;
      const setData = this.selectedRowNum > 1 ? this.listData.filter((i) => i?.selected) : [listItem];
      $event.dataTransfer.setData('text/plain', JSON.stringify(setData));
    } else {
      this.draggingSelected = false;
      // --------------   you are dragging unselected row   --------------
      this.tempListData = ObjectUtil.cloneDeep(this.listData);
      if (this.selectedRowNum > 0) {
        // there are some selected rows.
        this.listData.forEach((i) => (i.selected = false));
      }
      listItem.selected = true;
      $event.target.classList.add('dragging');
      $event.dataTransfer.setData('text/plain', JSON.stringify([listItem]));
    }

    const elem = document.createElement('div');
    elem.id = 'drag-ghost';

    const label = listItem?.name;
    let icon;
    if (listItem?.icon) {
      icon = `<img class="mr-3 w-6 h-6 object-contain" src='${listItem?.icon}'>`;
    } else if (listItem?.matIcon) {
      icon = `<span class="material-icons mr-3">${listItem?.matIcon}</span>`;
    }
    const ghostShadow =
      this.draggingSelected && this.selectedRowNum > 1 ? `<div class="second-ghost ghost-shadow"></div>` : ``;

    elem.innerHTML = `
      <div class="drag-ghost-wrap">
        <div class="first-ghost ghost-shadow p-3 font-bold text-base">
          ${icon}
          <div class="line-ellipsis">${label}</div>
        </div>
        ${ghostShadow}
      </div>
    `;
    elem.style.position = 'absolute';
    elem.style.top = '-1000px';
    document.body.appendChild(elem);
    $event.dataTransfer.setDragImage(elem, 0, 0);
    // console.log('drag =====', elem.innerHTML); //TODO: @RORY - folder icon bug  (only asset list view dragging)
  }

  handleDragEnd($event, listItem) {
    this.draggingRowIdx = null;
    if (this.draggingSelected) {
      // user was dragging selected rows
      this.draggingSelected = false;
      this.workspaceService.handleDropSuccess.next(false);
    } else {
      // user dragged unselected item.
      setTimeout(() => {
        if (this.tempListData.length && !this.workspaceService.handleDropSuccess.getValue()) {
          // no actual move, just dragged & dropped
          this.listData = [...this.tempListData];
          this.tempListData = [];
        }
        this.workspaceService.handleDropSuccess.next(false);
        $event.target.classList.remove('dragging');
      }, 50);
    }

    const ghost = document.getElementById('drag-ghost');
    if (ghost.parentNode) {
      ghost.parentNode.removeChild(ghost);
    }
  }

  handleDragEnter($event, targetItemData: any) {
    const id = targetItemData?.entity?.id;
    this.dragEnterCounter[id] = this.dragEnterCounter[id] || 0;
    this.dragEnterCounter[id]++;

    const rowEl = this.getParentRowFromEvent($event);
    if (rowEl) {
      rowEl.classList.add('dragOver');
    }
  }

  handleDragOver($event) {
    $event.preventDefault();
  }

  handleDragLeave($event, targetItemData) {
    const id = targetItemData?.entity?.id;
    const rowEl = this.getParentRowFromEvent($event);
    this.dragEnterCounter[id] = this.dragEnterCounter[id] || 0;
    this.dragEnterCounter[id]--;

    if (rowEl && this.dragEnterCounter[id] === 0) {
      rowEl.classList.remove('dragOver');
    }
  }

  handleDrop($event, targetItemData: any) {
    $event.preventDefault();
    const rowEl = this.getParentRowFromEvent($event);
    if (targetItemData.isDropTarget && rowEl) {
      const droppedItemData = JSON.parse($event.dataTransfer.getData('text/plain'));
      this.dropListItem.emit({ droppedItemData, targetItemData });
    }
    if (rowEl) {
      rowEl.classList.remove('dragOver');
    }
    this.dragEnterCounter[targetItemData.entity.id] = 0;
  }

  getParentRowFromEvent($event) {
    for (const el of $event.composedPath()) {
      if (el?.tagName === 'TR' && el?.className.includes('droppable') && !el?.className.includes('selected')) {
        return el;
      }
    }
  }
  /** ------------------------------------ DRAG & DROP */
}
