import { AfterViewInit, Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { ViewPropertyConfiguration } from '@contrail/client-views';
import { PivotGoals } from '../pivot-goals';
import { ObjectUtil } from '@contrail/util';
import { PropertyType } from '@contrail/types';
import { AggregateRowEntity } from '../pivot-aggregate-helper';

interface DataRow {
  data: AggregateRowEntity;
  rowIndex: number;
}

@Component({
  selector: 'app-pivot-data-rows',
  templateUrl: './pivot-data-rows.component.html',
  styleUrls: ['./pivot-data-rows.component.scss'],
})
export class PivotDataRowsComponent implements AfterViewInit, OnChanges {
  @Input() allData: Array<AggregateRowEntity>;
  @Input() data: Array<AggregateRowEntity>;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() rowGroupingProperties: Array<ViewPropertyConfiguration>;
  @Input() columnGroupingKeys?: string[];
  @Input() side: string;
  @Input() sideWidth = 600;
  @Input() rowsToRender = 0;
  @Input() rowContainerHeight = 0;
  @Input() rowStartIndex = 0;
  @Input() hoveredRowId = '';
  @Input() goals: Array<PivotGoals>;
  @Input() showGoals: boolean = false;
  @Output() hoverRow = new EventEmitter();
  @Output() rowAction = new EventEmitter();
  @Output() onContextMenu = new EventEmitter();

  public visibleDataRows: Array<DataRow>;
  constructor() {}

  public ngAfterViewInit(): void {
    this.deriveVisibleRows();
    this.setWidth();
  }

  ngOnChanges() {
    this.deriveVisibleRows();
    this.sideWidth = this.sideWidth || 100000;
    this.setWidth();
  }

  deriveVisibleRows() {
    if (!this.data) {
      return;
    }
    const startIndex = this.rowStartIndex;
    const endIndex = startIndex + this.rowsToRender;
    const visibleData = this.data.slice(startIndex, endIndex);
    let index = startIndex;

    // This for virutal scrolling, not row expansion
    const visibleRows = [];
    visibleData.forEach((data) => {
      visibleRows.push({
        rowIndex: index++,
        data,
        realRowNumber: this.allData.findIndex((rowData) => rowData.id === data.id),
      });
    });
    this.visibleDataRows = [...visibleRows];
  }
  rowTrackByFn(index, row: any) {
    return row.data.id;
  }

  setWidth() {
    this.sideWidth = this.properties.reduce((a, b) => a + (b.width || 150), 0);
  }

  onRowAction(event) {
    this.rowAction.emit(event);
  }

  onHoverRow(event) {
    this.hoverRow.emit(event);
  }

  handleContextMenu(event) {
    this.onContextMenu.emit(event);
  }
}
