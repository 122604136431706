import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from '@common/auth/auth.service';
import { RoutingService } from '@common/routing/routing.service';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';

@Component({
  selector: 'app-workspace-item',
  templateUrl: './workspace-item.component.html',
  styleUrls: ['./workspace-item.component.scss'],
})
export class WorkspaceItemComponent implements OnInit {
  public authContext;
  @Input() workspace: Workspace;

  constructor(
    public routingService: RoutingService,
    private authService: AuthService,
  ) {
    this.authService.authContext.subscribe((authContext) => {
      this.authContext = authContext;
    });
  }

  ngOnInit(): void {}

  gotoWorkspace(workspaceId: string, route?: string) {
    route = route ? route : '';
    this.routingService.go('/workspaces/workspace/' + workspaceId + '/' + route);
  }
}
