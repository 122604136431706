import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ViewDefinition } from '@contrail/client-views';

export interface CollectionViewDefinition extends ViewDefinition {
  isLocalView?: boolean;
}

export interface CollectionViewsState extends EntityState<ViewDefinition> {}
export const collectionViewsEntityAdapter: EntityAdapter<ViewDefinition> = createEntityAdapter<ViewDefinition>({});
export const collectionViewsInitialState = collectionViewsEntityAdapter.getInitialState({});

export const localCollectionViewsEntityAdapter: EntityAdapter<CollectionViewDefinition> =
  createEntityAdapter<CollectionViewDefinition>({});
export const localCollectionViewsInitialState = localCollectionViewsEntityAdapter.getInitialState({});
