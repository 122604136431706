import { createAction, props } from '@ngrx/store';
import { Showcase } from './showcases.state';

export enum ShowcasesActionTypes {
  LOAD_SHOWCASES = '[Showcases] Load Showcases',
  LOAD_SHOWCASES_SUCCESS = '[Showcases] Load Showcases Success',
  LOAD_SHOWCASES_FAILURE = '[Showcases] Load Showcases Failure',

  CLEAR_SHOWCASES = '[Showcases] Clear Showcases',

  CREATE_SHOWCASE = '[Showcases] Create Showcase',
  CREATE_SHOWCASE_SUCCESS = '[Showcases] Create Showcase Success',
  CREATE_SHOWCASE_FAILURE = '[Showcases] Create Showcase Failure',

  COPY_SHOWCASE = '[Showcases] Copy Showcase',
  COPY_SHOWCASE_SUCCESS = '[Showcases] Copy Showcase Success',
  COPY_SHOWCASE_FAILURE = '[Showcases] Copy Showcase Failure',

  ASYNC_COPY_SHOWCASE = '[Showcases] Async Copy Showcase',
  ASYNC_COPY_SHOWCASE_SUCCESS = '[Showcases] Async Copy Showcase Success',

  DELETE_SHOWCASE = '[Showcases] Delete Showcase',
  DELETE_SHOWCASE_SUCCESS = '[Showcases] Delete Showcase Success',
  DELETE_SHOWCASE_FAILURE = '[Showcases] Delete Showcase Failure',

  UPDATE_SHOWCASE = '[Showcases] Update Showcase',
  UPDATE_SHOWCASE_SUCCESS = '[Showcases] Update Showcase Success',
  UPDATE_SHOWCASE_FAILURE = '[Showcases] Update Showcase Failure',

  LOAD_CURRENT_SHOWCASE = '[Showcases] Load Current Showcase',
  LOAD_CURRENT_SHOWCASE_SUCCESS = '[Showcases] Load Current Showcase Success',
  LOAD_CURRENT_SHOWCASE_FAILURE = '[Showcases] Load Current Showcase Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const clearShowcases = createAction(ShowcasesActionTypes.CLEAR_SHOWCASES);

export const loadShowcases = createAction(ShowcasesActionTypes.LOAD_SHOWCASES);

export const loadShowcasesSuccess = createAction(
  ShowcasesActionTypes.LOAD_SHOWCASES_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadShowcasesFailure = createAction(ShowcasesActionTypes.LOAD_SHOWCASES_FAILURE, props<{ error: any }>());

export const createShowcase = createAction(ShowcasesActionTypes.CREATE_SHOWCASE, props<{ showcase: Showcase }>());

export const createShowcaseSuccess = createAction(
  ShowcasesActionTypes.CREATE_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const createShowcaseFailure = createAction(
  ShowcasesActionTypes.CREATE_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const copyShowcase = createAction(
  ShowcasesActionTypes.COPY_SHOWCASE,
  props<{ name: string; sourceId: string; newWorkspaceId?: string }>(),
);

export const asyncCopyShowcase = createAction(
  ShowcasesActionTypes.ASYNC_COPY_SHOWCASE,
  props<{ name: string; sourceId: string; newWorkspaceId?; navigateToFolder?: { id?; folder?; across?; rootId? } }>(),
);

export const asyncCopyShowcaseSuccess = createAction(
  ShowcasesActionTypes.ASYNC_COPY_SHOWCASE_SUCCESS,
  props<{ jobId: string; path: string; navigateToFolder?: { id?; folder?; across?; rootId? } }>(),
);

export const copyShowcaseSuccess = createAction(
  ShowcasesActionTypes.COPY_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const copyShowcaseFailure = createAction(ShowcasesActionTypes.COPY_SHOWCASE_FAILURE, props<{ error: any }>());

export const updateShowcase = createAction(
  ShowcasesActionTypes.UPDATE_SHOWCASE,
  props<{ id: string; changes: Showcase }>(),
);

export const updateShowcaseSuccess = createAction(
  ShowcasesActionTypes.UPDATE_SHOWCASE_SUCCESS,
  props<{ id: string; changes: Showcase }>(),
);

export const updateShowcaseFailure = createAction(
  ShowcasesActionTypes.UPDATE_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const deleteShowcase = createAction(ShowcasesActionTypes.DELETE_SHOWCASE, props<{ showcaseId: string }>());

export const deleteShowcaseSuccess = createAction(
  ShowcasesActionTypes.DELETE_SHOWCASE_SUCCESS,
  props<{ showcaseId: string }>(),
);

export const deleteShowcaseFailure = createAction(
  ShowcasesActionTypes.DELETE_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentShowcase = createAction(ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE, props<{ id: string }>());

export const loadCurrentShowcaseSuccess = createAction(
  ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE_SUCCESS,
  props<{ showcase: Showcase }>(),
);

export const loadCurrentShowcaseFailure = createAction(
  ShowcasesActionTypes.LOAD_CURRENT_SHOWCASE_FAILURE,
  props<{ error: any }>(),
);
