import { createSelector } from '@ngrx/store';
import { WorkspacePrincipal } from '@common/workspaces/workspace-principal';
import { folderPrincipalsEntityAdapter } from './folder-principals.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = folderPrincipalsEntityAdapter.getSelectors();

const folderPrincipalEntities = (state: any) => state.folderPrincipals.folderPrincipals.entities;
export const folderPrincipals = createSelector(
  folderPrincipalEntities,
  (state) => Object.values(state) as Array<WorkspacePrincipal>,
);
export const currentFolderPrincipal = (state: any) => state.folderPrincipals.currentFolderPrincipal;
