import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Board } from './boards-store/boards.state';

@Injectable({
  providedIn: 'root',
})
export class BoardsService {
  constructor() {}

  public async getBoards(workspaceId: string) {
    return new Entities().get({
      entityName: 'board',
      criteria: { workspaceId, isTrashed: false },
      relations: ['updatedBy', 'createdBy', 'previewFile'],
    });
  }
  public async getBoardById(id: string) {
    return new Entities().get({ entityName: 'board', id });
  }
  public async createBoard(board: Board) {
    return new Entities().create({ entityName: 'board', object: board });
  }
  public async deleteBoard(board: Board) {
    await new Entities().delete({ entityName: 'board', id: board.id });
    return board;
  }
  public async deleteBoardById(id: string) {
    await new Entities().delete({ entityName: 'board', id });
  }
  public async updateBoard(id: string, changes: Board) {
    return new Entities().update({ entityName: 'board', id, object: changes });
  }
  async copy(name, id): Promise<Board> {
    return new Entities().create({ entityName: 'board', object: { name }, id, relation: 'copy' });
  }
  public async asyncCopyBoard(name, id, newWorkspaceId = null) {
    return await new Entities().create({
      entityName: 'board',
      object: { name, boardId: id, newWorkspaceId },
      relation: 'copy',
      suffix: 'async',
    });
  }
}
