import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { Showroom } from './showrooms-store/showrooms.state';

@Injectable({
  providedIn: 'root',
})
export class ShowroomsService {
  constructor() {}

  public async getShowrooms(workspaceId: string) {
    return new Entities().get({
      entityName: 'showroom',
      criteria: { workspaceId, isTrashed: false },
      relations: ['updatedBy', 'createdBy'],
    });
  }
  public async getShowroomById(id: string) {
    return new Entities().get({ entityName: 'showroom', id });
  }
  public async createShowroom(showroom: Showroom) {
    return new Entities().create({ entityName: 'showroom', object: showroom });
  }
  public async deleteShowroom(showroom: Showroom) {
    await new Entities().delete({ entityName: 'showroom', id: showroom.id });
    return showroom;
  }
  public async deleteShowroomById(id: string) {
    await new Entities().delete({ entityName: 'showroom', id });
  }
  public async updateShowroom(id: string, changes: Showroom) {
    return new Entities().update({ entityName: 'showroom', id, object: changes });
  }
  async copy(name, id): Promise<Showroom> {
    return new Entities().create({ entityName: 'showroom', object: { name }, id, relation: 'copy' });
  }
}
