import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppNotificationsService } from '@common/app-notifications/app-notifications.service';
import { AuthService } from '@common/auth/auth.service';
import { FeatureFlagsSelectors } from '@common/feature-flags';
import { BetaFeature } from '@common/feature-flags/feature-flag';
import { RoutingService } from '@common/routing/routing.service';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { WorkspaceEntitiesHelperService } from 'src/app/workspaces/workspace-entities-helper.service';

@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
})
export class SideNavComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$ = new Subject();
  public authContext;
  public recents = false;
  public listPage = true;
  public workspacesExpanded = false;
  // public newObjectMenuOptions: Array<any> = [];
  // public newProjectMenuOptions = [];

  public rootLoading$: Observable<boolean>;
  public selectedWorkspace$: Observable<Workspace>;
  public selectedWorkspace: Workspace;
  public selectedWorkspaceExpanded = false;
  public rootWorkspaceType: string;

  private dragEnterCounter = 0;
  public showBetaFeatures$: Observable<boolean>;

  constructor(
    private element: ElementRef,
    private router: Router,
    private store: Store<RootStoreState.State>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
    private routingService: RoutingService,
    private authService: AuthService,
    private notificationService: AppNotificationsService,
  ) {
    this.rootLoading$ = this.store.select(WorkspacesSelectors.currentWorkspaceLoading);
    this.selectedWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);

    this.authService.authContext.pipe(takeUntil(this.destroy$)).subscribe((authContext) => {
      this.authContext = authContext;
      // if (this.authContext?.currentOrg?.role === 'ADMIN') {
      //   this.newProjectMenuOptions.push({ name: 'Project', type: 'workspace', icon: 'assets/images/workspace_icon.svg' });
      // }
    });

    this.router.events.pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd)).subscribe((event) => {
      if (event?.urlAfterRedirects?.includes('/workspaces/list')) {
        this.rootWorkspaceType = 'Projects';
        this.workspacesExpanded = true;
        this.listPage = true;
        this.recents = false;
      } else if (event?.urlAfterRedirects?.includes('/libraries/list')) {
        this.rootWorkspaceType = 'Libraries';
        this.workspacesExpanded = true;
        this.listPage = true;
        this.recents = false;
      } else if (event?.urlAfterRedirects?.includes('/workspaces/recents')) {
        this.recents = true;
      } else {
        this.recents = false;
        this.workspacesExpanded = false;
        this.listPage = false;
      }
    });

    this.showBetaFeatures$ = this.store.select(FeatureFlagsSelectors.featureFlags).pipe(
      map((featureFlags) => {
        console.log('feature flags:', featureFlags);
        return featureFlags.map((x) => x.featureName).includes(BetaFeature.ASSET_LIBRARY);
      }),
    );
  }

  ngOnInit(): void {
    this.selectedWorkspace$.pipe(takeUntil(this.destroy$)).subscribe((rws) => {
      this.selectedWorkspace = rws;
      this.selectedWorkspaceExpanded = true;
      if (rws?.workspaceType === 'PROJECT') {
        this.rootWorkspaceType = 'Projects';
        // this.newObjectMenuOptions = [];
        // this.store.select(AuthSelectors.selectAuthContext).pipe(
        //   take(1),
        //   tap(authContext => {
        //     if (authContext?.currentOrg?.role === 'ADMIN') {
        //       this.newObjectMenuOptions.push({ name: 'Project', type: 'workspace', icon: 'assets/images/workspace_icon.svg' })
        //     }
        //   })
        // ).subscribe();
        // this.newObjectMenuOptions.push(...ProjectNewMenuOptions);
      } else if (rws?.workspaceType === 'LIBRARY') {
        this.rootWorkspaceType = 'Libraries';
        // if (rws.name === 'color') { this.newObjectMenuOptions = ColorNewMenuOptions }
        // else if (rws.name === 'asset') { this.newObjectMenuOptions = AssetNewMenuOptions }
      }
    });
  }

  ngAfterViewInit(): void {}

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  toggleWorkspacesExpanded($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.workspacesExpanded = !this.workspacesExpanded;
  }

  toggleSelectedWorkspaceExpanded($event) {
    $event.preventDefault();
    $event.stopPropagation();
    this.selectedWorkspaceExpanded = !this.selectedWorkspaceExpanded;
  }

  go(path, params = {}) {
    this.routingService.go(path, params);
  }

  goHome() {
    this.routingService.go('/workspaces');
  }

  createNew(objectType: string) {
    this.workspaceEntitiesHelper.createNew(objectType);
  }

  handleDragEnter($event) {
    this.dragEnterCounter++;
    const trash = this.element.nativeElement.querySelector('.move-trash');
    if (trash) {
      trash.classList.add('move-trash_hover');
    }
  }
  handleDragLeave($event) {
    this.dragEnterCounter--;
    const trash = this.element.nativeElement.querySelector('.move-trash');
    if (trash && this.dragEnterCounter === 0) {
      trash.classList.remove('move-trash_hover');
    }
  }
  handleDragOver($event) {
    $event.preventDefault();
  }
  handleDrop($event) {
    $event.preventDefault();
    const droppedItemData = JSON.parse($event.dataTransfer.getData('text/plain'));
    this.workspaceEntitiesHelper.batchTrashOrRestore(droppedItemData, true);
    const trash = this.element.nativeElement.querySelector('.move-trash');
    if (trash) {
      trash.classList.remove('move-trash_hover');
    }
    this.dragEnterCounter = 0;
  }

  helpCenter() {
    window.open('https://intercom.help/vibesolutions/en/', '_blank');
  }
}
