import { createAction, props } from '@ngrx/store';
import { Assortment } from './assortments.state';

export enum AssortmentsActionTypes {
  LOAD_ASSORTMENTS = '[Assortments] Load Assortments',
  LOAD_ASSORTMENTS_SUCCESS = '[Assortments] Load Assortments Success',
  LOAD_ASSORTMENTS_FAILURE = '[Assortments] Load Assortments Failure',

  CREATE_ASSORTMENT = '[Assortments] Create Assortment',
  CREATE_ASSORTMENT_SUCCESS = '[Assortments] Create Assortment Success',
  CREATE_ASSORTMENT_FAILURE = '[Assortments] Create Assortment Failure',

  DELETE_ASSORTMENT = '[Assortments] Delete Assortment',
  DELETE_ASSORTMENT_SUCCESS = '[Assortments] Delete Assortment Success',
  DELETE_ASSORTMENT_FAILURE = '[Assortments] Delete Assortment Failure',

  UPDATE_ASSORTMENT = '[Assortments] Update Assortment',
  UPDATE_ASSORTMENT_SUCCESS = '[Assortments] Update Assortment Success',
  UPDATE_ASSORTMENT_FAILURE = '[Assortments] Update Assortment Failure',

  LOAD_CURRENT_ASSORTMENT = '[Assortments] Load Current Assortment',
  LOAD_CURRENT_ASSORTMENT_SUCCESS = '[Assortments] Load Current Assortment Success',
  LOAD_CURRENT_ASSORTMENT_FAILURE = '[Assortments] Load Current Assortment Failure',

  SET_CURRENT_ASSORTMENT = '[Assortments] Set Current Assortment',

  LOAD_SOURCE_ASSORTMENT = '[Assortments] Load Source Assortment',
  LOAD_SOURCE_ASSORTMENT_SUCCESS = '[Assortments] Load Source Assortment Success',
  LOAD_SOURCE_ASSORTMENT_FAILURE = '[Assortments] Load Source Assortment Failure',

  CLEAR_SOURCE_ASSORTMENT = '[Assortments] Clear Source Assortment',
  SET_ASSORTMENTS_LOADED = '[Assortments] Set Assortments Loaded',
}

////////////////////////////////////////////////////////////////////////////////
export const setAssortmentsLoaded = createAction(
  AssortmentsActionTypes.SET_ASSORTMENTS_LOADED,
  props<{ loaded: boolean }>(),
);

export const loadAssortments = createAction(AssortmentsActionTypes.LOAD_ASSORTMENTS);

export const loadAssortmentsSuccess = createAction(
  AssortmentsActionTypes.LOAD_ASSORTMENTS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadAssortmentsFailure = createAction(
  AssortmentsActionTypes.LOAD_ASSORTMENTS_FAILURE,
  props<{ error: any }>(),
);

export const loadSourceAssortment = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT,
  props<{ sourceAssortmentId: string }>(),
);

export const loadSourceAssortmentSuccess = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT_SUCCESS,
  props<{ sourceAssortment: Assortment }>(),
);

export const loadSourceAssortmentFailure = createAction(
  AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const createAssortment = createAction(
  AssortmentsActionTypes.CREATE_ASSORTMENT,
  props<{ assortment: Assortment }>(),
);

export const createAssortmentSuccess = createAction(
  AssortmentsActionTypes.CREATE_ASSORTMENT_SUCCESS,
  props<{ assortment: Assortment }>(),
);

export const createAssortmentFailure = createAction(
  AssortmentsActionTypes.CREATE_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const updateAssortment = createAction(
  AssortmentsActionTypes.UPDATE_ASSORTMENT,
  props<{ id: string; changes: Assortment }>(),
);

export const updateAssortmentSuccess = createAction(
  AssortmentsActionTypes.UPDATE_ASSORTMENT_SUCCESS,
  props<{ id: string; changes: Assortment }>(),
);

export const updateAssortmentFailure = createAction(
  AssortmentsActionTypes.UPDATE_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const deleteAssortment = createAction(
  AssortmentsActionTypes.DELETE_ASSORTMENT,
  props<{ assortment: Assortment }>(),
);

export const deleteAssortmentSuccess = createAction(
  AssortmentsActionTypes.DELETE_ASSORTMENT_SUCCESS,
  props<{ assortment: Assortment }>(),
);

export const deleteAssortmentFailure = createAction(
  AssortmentsActionTypes.DELETE_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);

export const setCurrentAssortment = createAction(
  AssortmentsActionTypes.SET_CURRENT_ASSORTMENT,
  props<{ assortment: any }>(),
);

export const loadCurrentAssortment = createAction(
  AssortmentsActionTypes.LOAD_CURRENT_ASSORTMENT,
  props<{ id: string }>(),
);

export const loadCurrentAssortmentSuccess = createAction(
  AssortmentsActionTypes.LOAD_CURRENT_ASSORTMENT_SUCCESS,
  props<{ assortment: Assortment }>(),
);

export const loadCurrentAssortmentFailure = createAction(
  AssortmentsActionTypes.LOAD_CURRENT_ASSORTMENT_FAILURE,
  props<{ error: any }>(),
);
export const clearSourceAssortment = createAction(AssortmentsActionTypes.CLEAR_SOURCE_ASSORTMENT);
