import { CollectionDataEntity } from '@common/collection-manager/collection-manager.service';
import { ItemData } from '../item-data/item-data';
import { AssortmentItem } from './assortment-item';
import { ObjectUtil } from '@contrail/util';
export class AssortmentUtil {
  /** Takes a collection if item data and coverts each item into ItemData (without hiearchy) */
  public static convertAssortmentItemsToItemData(assortmentItems: Array<AssortmentItem>) {
    return assortmentItems.filter((ai) => !!ai.item).map((ai) => new ItemData(ai));
  }

  /** Takes a collection of assortment items and creates an ItemData hiearchy */
  public static convertAssortmentItemsFamilyItemData(assortmentItems: Array<AssortmentItem>): ItemData[] {
    const familyMap: Map<string, ItemData> = new Map();

    // MAP Families.
    for (const assortmentItem of assortmentItems) {
      const item = assortmentItem.item;
      if (!item) {
        continue;
      }

      const familyId = item.itemFamilyId;
      if (familyMap.get(familyId)) {
        continue;
      }
      const familyItem = { ...assortmentItem, item: { ...item, id: familyId } };
      const familyItemData = new ItemData(familyItem);
      const optionAssortentItems = this.getAssortmentItemOptionsForFamily(familyId, assortmentItems);
      familyItemData.options = this.convertAssortmentItemsToItemData(optionAssortentItems);
      familyMap.set(familyId, familyItemData);
    }

    return [...familyMap.values()];
  }
  private static getAssortmentItemOptionsForFamily(
    familyId: string,
    assortmentItems: Array<AssortmentItem>,
  ): Array<AssortmentItem> {
    return assortmentItems
      .filter((ai) => ai.item?.itemFamilyId === familyId)
      .filter((ai) => ai.item?.roles?.includes('option'));
  }
  public static convertItemData(itemData) {
    let entity;
    if (itemData.assortmentItem) {
      entity = {
        entityType: 'assortment-item',
        id: itemData.id,
        item: {
          ...itemData.item,
          thumbnail: itemData.thumbnail,
        },
        projectItem: {
          ...itemData.projectItem,
        },
        ...itemData.assortmentItem,
      };
    } else {
      entity = {
        entityType: 'item',
        id: itemData.id,
        ...itemData.item,
        thumbnail: itemData.thumbnail,
      };
    }
    return entity;
  }

  public static convertAssortmentItemsToCollectionDataEntities(
    assortmentItems: AssortmentItem[],
  ): CollectionDataEntity[] {
    return assortmentItems
      .filter((assortmentItem) => !!assortmentItem?.item)
      .map((assortmentItem) => this.convertAssortmentItemToCollectionDataEntity(assortmentItem));
  }

  public static convertAssortmentItemToCollectionDataEntity(assortmentItem: AssortmentItem): CollectionDataEntity {
    const assortmentItemData = ObjectUtil.cloneDeep(assortmentItem);
    const collectionDataEntity: CollectionDataEntity = {};

    const isOption = assortmentItemData.item?.roles?.includes('option');
    if (isOption) {
      collectionDataEntity.itemOptionId = assortmentItemData.item.id;
      collectionDataEntity.itemFamilyId = assortmentItemData.item.itemFamilyId;

      collectionDataEntity.itemOption = assortmentItemData.item;
      collectionDataEntity.itemFamily = assortmentItemData.item?.itemFamily;

      if (collectionDataEntity.itemOption) {
        collectionDataEntity.itemOption.projectItem = assortmentItemData.projectItem;
      }

      if (collectionDataEntity.itemFamily) {
        collectionDataEntity.itemFamily.projectItem = assortmentItemData.familyProjectItem;
      }
    }

    const isFamily = assortmentItemData.item?.roles?.includes('family');
    if (isFamily && !isOption) {
      collectionDataEntity.itemFamilyId = assortmentItemData.item.id;
      collectionDataEntity.itemFamily = assortmentItemData.item;

      if (collectionDataEntity.itemFamily) {
        collectionDataEntity.itemFamily.projectItem = assortmentItemData.projectItem;
      }
    }

    delete assortmentItemData.itemId;
    delete assortmentItemData.item;
    delete assortmentItemData.projectItem;
    delete assortmentItemData.familyProjectItem;

    return { ...collectionDataEntity, ...assortmentItemData };
  }
}
