<div class="header">
  <div class="title">Pivot Options</div>
  <div (click)="close.emit()" class="cursor-pointer flex-center">
    <mat-icon>close</mat-icon>
  </div>
</div>

<div class="accordion">
  <mat-expansion-panel data-test="row-grouping-config" class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Rows </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <app-pivot-view-property-selector
        *ngIf="allRowGroupingProperties?.length > 0"
        [properties]="allRowGroupingProperties"
        [currentProperties]="selectedRowGroupingProperties"
        (propertiesChangedEvent)="updateProperties($event, PIVOT_TYPE.ROW)"
        (propertiesSortEvent)="updateSortProperties($event)"
        [sortable]="true"
        [currentSorts]="viewDefinition.sorts"
        data-test="row-properties"
      >
      </app-pivot-view-property-selector>
    </div>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel data-test="column-grouping-config" class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Columns </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <app-pivot-view-property-selector
        *ngIf="allColumnGroupingProperties?.length > 0"
        [properties]="allColumnGroupingProperties"
        [currentProperties]="selectedColumnGroupingProperties"
        (propertiesChangedEvent)="updateProperties($event, PIVOT_TYPE.COLUMN)"
        (propertiesSortEvent)="updateSortProperties($event)"
        [sortable]="true"
        [currentSorts]="viewDefinition.sorts"
        [maxProperties]="2"
        data-test="column-properties"
      >
      </app-pivot-view-property-selector>
    </div>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel data-test="values-config" class="mat-elevation-z0" expanded>
    <mat-expansion-panel-header>
      <mat-panel-title class="text-sm font-normal"> Values </mat-panel-title>
    </mat-expansion-panel-header>
    <div>
      <app-pivot-view-property-selector
        *ngIf="allValueProperties?.length > 0"
        [properties]="allValueProperties"
        [currentProperties]="selectedValueProperties"
        (propertiesChangedEvent)="updateProperties($event, PIVOT_TYPE.VALUE)"
        (propertiesAggregateFunctionEvent)="updatePropertyAggregateFunction($event)"
        data-test="value-properties"
      >
      </app-pivot-view-property-selector>
    </div>
  </mat-expansion-panel>
</div>
