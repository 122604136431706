import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { showroomsReducers } from './showrooms.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ShowroomsEffects } from './showrooms.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('showrooms', showroomsReducers),
    EffectsModule.forFeature([ShowroomsEffects]),
  ],
})
export class ShowroomsStoreModule {}
