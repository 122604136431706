import { createAction, props } from '@ngrx/store';
import { UserOrg } from './user-org';

export enum UserOrgsActionTypes {
  LOAD_USER_ORGS = '[UserOrgs] Load UserOrgs',
  LOAD_USER_ORGS_SUCCESS = '[UserOrgs] Load UserOrgs Success',
  LOAD_USER_ORGS_FAILURE = '[UserOrgs] Load UserOrgs Failure',

  CREATE_USER_ORG = '[UserOrgs] Create UserOrg',
  CREATE_USER_ORG_SUCCESS = '[UserOrgs] Create UserOrg Success',
  CREATE_USER_ORG_FAILURE = '[UserOrgs] Create UserOrg Failure',

  DELETE_USER_ORG = '[UserOrgs] Delete UserOrg',
  DELETE_USER_ORG_SUCCESS = '[UserOrgs] Delete UserOrg Success',
  DELETE_USER_ORG_FAILURE = '[UserOrgs] Delete UserOrg Failure',

  DELETE_USER_ORGS = '[UserOrgs] Delete UserOrgs',
  DELETE_USER_ORGS_SUCCESS = '[UserOrgs] Delete UserOrgs Success',
  DELETE_USER_ORGS_FAILURE = '[UserOrgs] Delete UserOrgs Failure',

  UPDATE_USER_ORG = '[UserOrgs] Update UserOrg',
  UPDATE_USER_ORG_SUCCESS = '[UserOrgs] Update UserOrg Success',
  UPDATE_USER_ORG_FAILURE = '[UserOrgs] Update UserOrg Failure',

  LOAD_CURRENT_USER_ORG = '[UserOrgs] Load Current UserOrg',
  LOAD_CURRENT_USER_ORG_SUCCESS = '[UserOrgs] Load Current UserOrg Success',
  LOAD_CURRENT_USER_ORG_FAILURE = '[UserOrgs] Load Current UserOrg Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadUserOrgs = createAction(UserOrgsActionTypes.LOAD_USER_ORGS);

export const loadUserOrgsSuccess = createAction(
  UserOrgsActionTypes.LOAD_USER_ORGS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadUserOrgsFailure = createAction(UserOrgsActionTypes.LOAD_USER_ORGS_FAILURE, props<{ error: any }>());

export const createUserOrg = createAction(UserOrgsActionTypes.CREATE_USER_ORG, props<{ email: string }>());

export const createUserOrgSuccess = createAction(
  UserOrgsActionTypes.CREATE_USER_ORG_SUCCESS,
  props<{ userOrg: UserOrg }>(),
);

export const createUserOrgFailure = createAction(UserOrgsActionTypes.CREATE_USER_ORG_FAILURE, props<{ error: any }>());

export const updateUserOrg = createAction(
  UserOrgsActionTypes.UPDATE_USER_ORG,
  props<{ id: string; changes: UserOrg }>(),
);

export const updateUserOrgSuccess = createAction(
  UserOrgsActionTypes.UPDATE_USER_ORG_SUCCESS,
  props<{ id: string; changes: UserOrg }>(),
);

export const updateUserOrgFailure = createAction(UserOrgsActionTypes.UPDATE_USER_ORG_FAILURE, props<{ error: any }>());

export const deleteUserOrg = createAction(UserOrgsActionTypes.DELETE_USER_ORG, props<{ userOrg: UserOrg }>());

export const deleteUserOrgSuccess = createAction(
  UserOrgsActionTypes.DELETE_USER_ORG_SUCCESS,
  props<{ userOrg: UserOrg }>(),
);

export const deleteUserOrgFailure = createAction(UserOrgsActionTypes.DELETE_USER_ORG_FAILURE, props<{ error: any }>());

export const deleteUserOrgs = createAction(UserOrgsActionTypes.DELETE_USER_ORGS, props<{ ids: string[] }>());

export const deleteUserOrgsSuccess = createAction(
  UserOrgsActionTypes.DELETE_USER_ORGS_SUCCESS,
  props<{ ids: string[] }>(),
);

export const deleteUserOrgsFailure = createAction(
  UserOrgsActionTypes.DELETE_USER_ORGS_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentUserOrg = createAction(UserOrgsActionTypes.LOAD_CURRENT_USER_ORG, props<{ id: string }>());

export const loadCurrentUserOrgSuccess = createAction(
  UserOrgsActionTypes.LOAD_CURRENT_USER_ORG_SUCCESS,
  props<{ userOrg: UserOrg }>(),
);

export const loadCurrentUserOrgFailure = createAction(
  UserOrgsActionTypes.LOAD_CURRENT_USER_ORG_FAILURE,
  props<{ error: any }>(),
);
