<div cdkDropList class="flex flex-col space-y-[5px]" (cdkDropListDropped)="drop($event)">
  <ng-template ngFor let-property [ngForOf]="selectedProperties">
    <div
      class="list-value h-[26px] bg-gray-50 flex flex-row !box-border items-center justify-between text-xs rounded-[5px] px-[7px] py-1.5"
      cdkDrag
    >
      <div class="flex items-center w-full text-black/60">
        <mat-icon class="cursor-move mr-2.5" cdkDragHandle>drag_handle</mat-icon>
        <app-pivot-view-property
          class="flex w-full items-center"
          [property]="property"
          [sortable]="sortable"
          [sortMenu]="sortMenu"
          (propertyRemoved)="removeProperty($event)"
          (propertyAggregateFunctionChanged)="selectFunction($event)"
        ></app-pivot-view-property>
      </div>
    </div>
  </ng-template>
  <div class="h-[26px] bg-gray-50 flex flex-row items-center justify-between text-xs rounded-[5px] px-2">
    <form class="flex items-center w-full h-full add-property">
      <mat-icon
        data-test="add-property-button"
        class="text-black24 !w-6 !h-6 !text-2xl !leading-6 cursor-pointer"
        (click)="openPanel($event)"
        [attr.disabled]="isPropertyLimitReached"
        >add_box</mat-icon
      >
      <input
        #propertyInput
        type="text"
        [formControl]="control"
        placeholder="Add property"
        [matAutocomplete]="auto"
        [readonly]="isPropertyLimitReached"
        size="30"
      />
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addProperty($event.option.value)">
        <mat-option *ngFor="let property of filteredProperties | async" [value]="property">
          {{ property.label }}
        </mat-option>
      </mat-autocomplete>
    </form>
  </div>
</div>

<mat-menu class="column-header-menu" #sortMenu="matMenu" [hasBackdrop]="true">
  <ng-template matMenuContent let-property="property">
    <div>
      <button mat-menu-item (click)="sort(property, true)">
        <div class="flex">
          <div class="min-w-[22px]">
            <mat-icon *ngIf="isSortDirectionSelected(property, true)">check</mat-icon>
          </div>
          <span>Ascending</span>
        </div>
      </button>
      <button mat-menu-item (click)="sort(property)">
        <div class="flex">
          <div class="min-w-[22px]">
            <mat-icon *ngIf="isSortDirectionSelected(property)">check</mat-icon>
          </div>
          <span>Descending</span>
        </div>
      </button>
    </div>
  </ng-template>
</mat-menu>
