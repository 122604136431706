<div class="flex items-center h-9 relative non-selectable" [@focusInOut]="focus">
  <span class="material-icons absolute left-2 pointer-events-none text-black24">search</span>
  <input
    class="!w-full !h-full bg-neutral-50 rounded-3xl px-10"
    [ngClass]="{ 'border bg-white': focus === 'in' }"
    type="text"
    placeholder="Search anything"
    #searchInput
    [formControl]="globalSearch"
    [matAutocomplete]="auto"
    (blur)="onBlur()"
    (focus)="onFocus()"
  />
  <div class="flex-center absolute right-0 min-w-[40px] h-10 cursor-pointer z-50">
    <span
      *ngIf="globalSearch.value || focus === 'in'"
      (click)="clear()"
      class="material-icons text-black24 hover:text-black60"
    >
      close
    </span>
  </div>
</div>

<mat-autocomplete
  #auto="matAutocomplete"
  [class]="'global-search-overlay'"
  [panelWidth]="400"
  (optionSelected)="handleClick($event)"
>
  <ng-container *ngIf="globalSearch.value && !focusing">
    <mat-option *ngIf="loading" class="pointer-events-none opacity-70">
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </mat-option>

    <mat-option *ngIf="!loading && !(results$ | async)?.length" class="pointer-events-none opacity-70">
      <span class="no-result flex-center text-sm">There are no results matching your query</span>
    </mat-option>

    <ng-container *ngIf="!loading">
      <mat-option *ngFor="let entity of results$ | async" [value]="entity">
        <app-global-search-result-item [entity]="entity"></app-global-search-result-item>
      </mat-option>
      <mat-option *ngIf="moreLoading" class="pointer-events-none opacity-70">
        <div class="flex-center">
          <mat-spinner [diameter]="20"></mat-spinner> <span class="text-sm ml-3"> more loading </span>
        </div>
      </mat-option>
    </ng-container>
  </ng-container>
</mat-autocomplete>
