<app-modal [title]="copy ? 'Copy to folder' : 'Move to folder'" [dialogRef]="dialogRef">
  <div class="-mt-3" *ngIf="!loading">
    <ng-container *ngIf="folderEntities$ | async as folders">
      <ng-container *ngTemplateOutlet="breadcrumb; context: { folders }"></ng-container>

      <ng-container *ngIf="inAllWorkspaces">
        <div class="ws-list">
          <div
            class="workspace select-none px-1 py-2"
            *ngFor="let ws of workspaces$ | async; index as idx"
            (click)="selectWorkspace(ws, idx)"
            (dblclick)="openWorkspace(ws)"
            [class.workspace-selected]="selectedWorkspaceIdx === idx"
          >
            <img src="/assets/images/workspace_icon.svg" class="max-w-none mr-2" />
            <span>{{ ws.name }}</span>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!inAllWorkspaces">
        <div class="folder-list narrow-scroll" *ngIf="folders!.length">
          <div
            class="folder select-none p-1"
            *ngFor="let folder of folders"
            [class.folder-selected]="folder.selected"
            (click)="clickFolder(folder, folders)"
            (dblclick)="openFolder(folder)"
          >
            <img [src]="folder.icon" class="max-w-none mr-2" />
            <span [attr.data-test]="'select-folder-' + folder.name">{{ folder.name }}</span>
          </div>
        </div>
        <div class="folder-list flex-center py-5" *ngIf="!folders!.length">
          <h6>No Folders</h6>
        </div>
      </ng-container>
    </ng-container>
  </div>

  <div class="flex w-full justify-center items-center h-[333px]" *ngIf="loading">
    <app-mat-spinner [message]="'Loading ...'" [overlay]="false" [loading]="true"></app-mat-spinner>
  </div>

  <div class="actions">
    <button mat-flat-button (click)="cancel()">CANCEL</button>
    <button
      mat-flat-button
      color="primary"
      cdkFocusInitial
      (click)="save()"
      [disabled]="btnDisabled"
      [matTooltip]="tooltip"
      [matTooltipDisabled]="tooltipDisabled"
      matTooltipPosition="above"
    >
      {{ copy ? 'COPY' : 'MOVE' }}
    </button>
  </div>
</app-modal>

<ng-template #breadcrumb let-folders="folders">
  <div class="breadcrumb flex p-3 pl-0">
    <div *ngIf="copy">
      <span class="breadcrumb-nav" (click)="inAllWorkspaces = true">All projects</span>
      <span class="mx-2" *ngIf="!inAllWorkspaces">/</span>
    </div>

    <ng-container *ngIf="!inAllWorkspaces">
      <div
        *ngIf="!currentFolder?.parent"
        class="flex cursor-pointer"
        [class.text-primary]="!folderId"
        (click)="selectDocumentLevel(folders)"
      >
        {{ rootWorkspace.name }}
      </div>

      <ng-container *ngFor="let tempWS of tempArr; index as idx">
        <div class="flex" *ngIf="tempWS?.parent" (click)="goToFolder(tempWS.parent)">
          <span class="breadcrumb-nav">
            {{ tempWS.parent.workspaceType === 'PROJECT' ? rootWorkspace.name : tempWS.parent.name }}
          </span>
          <span class="mx-2">/</span>
        </div>

        <div class="text-gray-800 font-medium" *ngIf="idx + 1 === tempLength">
          {{ tempWS.name }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-template>
