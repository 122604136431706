import { State } from './workspace-principals.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as WorkspacePrincipalsActions from './workspace-principals.actions';
import { workspacePrincipalsEntityAdapter } from './workspace-principals.state';
import { workspacePrincipalsInitialState } from './workspace-principals.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    workspacePrincipals: workspacePrincipalsEntityAdapter.setAll(data, state.workspacePrincipals),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    workspacePrincipals: workspacePrincipalsEntityAdapter.removeAll(state.workspacePrincipals),
  };
};
const add = (state: State, { workspacePrincipal }) => {
  return {
    ...state,
    workspacePrincipals: workspacePrincipalsEntityAdapter.addOne(workspacePrincipal, state.workspacePrincipals),
  };
};
const batchAdd = (state: State, { data }) => {
  return {
    ...state,
    workspacePrincipals: workspacePrincipalsEntityAdapter.addMany(data, state.workspacePrincipals),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    workspacePrincipals: workspacePrincipalsEntityAdapter.updateOne({ id, changes }, state.workspacePrincipals),
  };
};
const remove = (state: State, { workspacePrincipal }) => {
  return {
    ...state,
    workspacePrincipals: workspacePrincipalsEntityAdapter.removeOne(workspacePrincipal.id, state.workspacePrincipals),
  };
};
const batchRemove = (state: State, { data }) => {
  const ids = data.map((i) => i.id);
  return {
    ...state,
    workspacePrincipals: workspacePrincipalsEntityAdapter.removeMany(ids, state.workspacePrincipals),
  };
};
const setCurrentWorkspacePrincipal = (state: State, { workspacePrincipal }) => {
  return {
    ...state,
    currentWorkspacePrincipal: workspacePrincipal,
  };
};

export const workspacePrincipalsReducers = createReducer(
  workspacePrincipalsInitialState,
  on(WorkspacePrincipalsActions.loadWorkspacePrincipalsSuccess, setData),
  on(WorkspacePrincipalsActions.createWorkspacePrincipalSuccess, add),
  on(WorkspacePrincipalsActions.deleteWorkspacePrincipalSuccess, remove),
  on(WorkspacePrincipalsActions.createWorkspacePrincipalsSuccess, batchAdd),
  on(WorkspacePrincipalsActions.deleteWorkspacePrincipalsSuccess, batchRemove),
  on(WorkspacePrincipalsActions.updateWorkspacePrincipalSuccess, update),
  on(WorkspacePrincipalsActions.loadCurrentWorkspacePrincipalSuccess, setCurrentWorkspacePrincipal),
);

export function reducer(state: State | undefined, action: Action): any {
  return workspacePrincipalsReducers(state, action);
}
