import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacePrincipalsService } from '@common/workspaces/workspace-principals.service';
import { FolderPrincipalsActions } from '.';

@Injectable()
export class FolderPrincipalsEffects {
  constructor(
    private actions$: Actions,
    private wpService: WorkspacePrincipalsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  loadFolderPrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderPrincipalsActions.FolderPrincipalsActionTypes.LOAD_FOLDER_PRINCIPALS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const folderId = store.workspaces.currentWorkspaceFolder.id;
        return from(this.wpService.getWorkspacePrincipals(folderId)).pipe(
          map((data) => FolderPrincipalsActions.loadFolderPrincipalsSuccess({ data })),
          catchError((error) => observableOf(FolderPrincipalsActions.loadFolderPrincipalsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentFolderPrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderPrincipalsActions.FolderPrincipalsActionTypes.LOAD_CURRENT_FOLDER_PRINCIPAL),
      switchMap((action: any) => {
        return from(this.wpService.getWorkspacePrincipalById(action.id)).pipe(
          map((data) => FolderPrincipalsActions.loadCurrentFolderPrincipalSuccess({ folderPrincipal: data })),
          catchError((error) => observableOf(FolderPrincipalsActions.loadCurrentFolderPrincipalFailure({ error }))),
        );
      }),
    ),
  );
  createFolderPrincipal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderPrincipalsActions.FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPAL),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const principalReference = action.principalReference;
        const role = action.role;
        const workspaceId = store.workspaces.currentWorkspaceFolder.id;
        return from(this.wpService.createWorkspacePrincipal({ principalReference, workspaceId, role })).pipe(
          map((data) => {
            this.snackBar.open('Collaborator added.', '', { duration: 2000 });
            // this.store.dispatch(FolderPrincipalsActions.setCurrentWorkspacePrincipal({ currentWorkspacePrincipal: data }));
            return FolderPrincipalsActions.createFolderPrincipalSuccess({ folderPrincipal: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FolderPrincipalsActions.createFolderPrincipalFailure({ error }));
          }),
        );
      }),
    ),
  );
  createFolderPrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderPrincipalsActions.FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPALS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const objects = action.objects;
        return from(this.wpService.createWorkspacePrincipals(objects)).pipe(
          map((data: any) => {
            this.snackBar.open('Collaborators added.', '', { duration: 2000 });
            return FolderPrincipalsActions.createFolderPrincipalsSuccess({ data: data?.createdWorkspacePrincipals });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FolderPrincipalsActions.createFolderPrincipalsFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteFolderPrincipal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderPrincipalsActions.FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPAL),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.wpService.deleteWorkspacePrincipal(action.folderPrincipal)).pipe(
          map((data) => {
            this.snackBar.open('Collaborator removed.', '', { duration: 2000 });
            return FolderPrincipalsActions.deleteFolderPrincipalSuccess({ folderPrincipal: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FolderPrincipalsActions.deleteFolderPrincipalFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteFolderPrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderPrincipalsActions.FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPALS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.wpService.deleteWorkspacePrincipals(action.ids)).pipe(
          map((data) => {
            this.snackBar.open('Collaborators removed.', '', { duration: 2000 });
            return FolderPrincipalsActions.deleteFolderPrincipalsSuccess({ data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FolderPrincipalsActions.deleteFolderPrincipalsFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateFolderPrincipal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(FolderPrincipalsActions.FolderPrincipalsActionTypes.UPDATE_FOLDER_PRINCIPAL),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.wpService.updateWorkspacePrincipal(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Collaborator updated.', '', { duration: 2000 });
            return FolderPrincipalsActions.updateFolderPrincipalSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(FolderPrincipalsActions.updateFolderPrincipalFailure({ error }));
          }),
        );
      }),
    ),
  );
}
