<app-modal title="Create New Project" [dialogRef]="dialogRef">
  <div class="explaination">
    <img class="icon max-w-none mr-4" src="assets/images/workspace_icon.svg" />
    <div class="content text-sm">
      A project is a repository of documents that allow a team to collaborate on a project. Projects are typically used
      by product, merchandising or sales teams to work on many types of assortments.
    </div>
  </div>
  <div>
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>Project Name</mat-label>
      <input matInput [formControl]="nameFormControl" />
    </mat-form-field>
  </div>
  <div class="actions">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="save()">Ok</button>
  </div>
</app-modal>
