import { createAction, props } from '@ngrx/store';
import { Board } from './boards.state';

export enum BoardsActionTypes {
  LOAD_BOARDS = '[Boards] Load Boards',
  LOAD_BOARDS_SUCCESS = '[Boards] Load Boards Success',
  LOAD_BOARDS_FAILURE = '[Boards] Load Boards Failure',

  CLEAR_BOARDS = '[Boards] Clear Boards',

  CREATE_BOARD = '[Boards] Create Board',
  CREATE_BOARD_SUCCESS = '[Boards] Create Board Success',
  CREATE_BOARD_FAILURE = '[Boards] Create Board Failure',

  COPY_BOARD = '[Boards] Copy Board',
  COPY_BOARD_SUCCESS = '[Boards] Copy Board Success',
  COPY_BOARD_FAILURE = '[Boards] Copy Board Failure',

  ASYNC_COPY_BOARD = '[Board] Async Copy Board',
  ASYNC_COPY_BOARD_SUCCESS = '[Board] Async Copy Board Success',

  DELETE_BOARD = '[Boards] Delete Board',
  DELETE_BOARD_SUCCESS = '[Boards] Delete Board Success',
  DELETE_BOARD_FAILURE = '[Boards] Delete Board Failure',

  UPDATE_BOARD = '[Boards] Update Board',
  UPDATE_BOARD_SUCCESS = '[Boards] Update Board Success',
  UPDATE_BOARD_FAILURE = '[Boards] Update Board Failure',

  LOAD_CURRENT_BOARD = '[Boards] Load Current Board',
  LOAD_CURRENT_BOARD_SUCCESS = '[Boards] Load Current Board Success',
  LOAD_CURRENT_BOARD_FAILURE = '[Boards] Load Current Board Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const clearBoards = createAction(BoardsActionTypes.CLEAR_BOARDS);
export const loadBoards = createAction(BoardsActionTypes.LOAD_BOARDS);

export const loadBoardsSuccess = createAction(BoardsActionTypes.LOAD_BOARDS_SUCCESS, props<{ data: Array<any> }>());

export const loadBoardsFailure = createAction(BoardsActionTypes.LOAD_BOARDS_FAILURE, props<{ error: any }>());

export const createBoard = createAction(BoardsActionTypes.CREATE_BOARD, props<{ board: Board }>());

export const createBoardSuccess = createAction(BoardsActionTypes.CREATE_BOARD_SUCCESS, props<{ board: Board }>());

export const copyBoard = createAction(BoardsActionTypes.COPY_BOARD, props<{ name: string; sourceId: string }>());

export const copyBoardSuccess = createAction(BoardsActionTypes.COPY_BOARD_SUCCESS, props<{ board: Board }>());

export const copyBoardFailure = createAction(BoardsActionTypes.COPY_BOARD_FAILURE, props<{ error: any }>());

export const asyncCopyBoard = createAction(
  BoardsActionTypes.ASYNC_COPY_BOARD,
  props<{ name: string; sourceId: string; newWorkspaceId?; navigateToFolder?: { id?; folder?; across?; rootId? } }>(),
);

export const asyncCopyBoardSuccess = createAction(
  BoardsActionTypes.ASYNC_COPY_BOARD_SUCCESS,
  props<{ jobId: string; path: string; navigateToFolder?: { id?; folder?; across?; rootId? } }>(),
);

export const createBoardFailure = createAction(BoardsActionTypes.CREATE_BOARD_FAILURE, props<{ error: any }>());

export const updateBoard = createAction(BoardsActionTypes.UPDATE_BOARD, props<{ id: string; changes: Board }>());

export const updateBoardSuccess = createAction(
  BoardsActionTypes.UPDATE_BOARD_SUCCESS,
  props<{ id: string; changes: Board }>(),
);

export const updateBoardFailure = createAction(BoardsActionTypes.UPDATE_BOARD_FAILURE, props<{ error: any }>());

export const deleteBoard = createAction(BoardsActionTypes.DELETE_BOARD, props<{ boardId: string }>());

export const deleteBoardSuccess = createAction(BoardsActionTypes.DELETE_BOARD_SUCCESS, props<{ boardId: string }>());

export const deleteBoardFailure = createAction(BoardsActionTypes.DELETE_BOARD_FAILURE, props<{ error: any }>());

export const loadCurrentBoard = createAction(BoardsActionTypes.LOAD_CURRENT_BOARD, props<{ id: string }>());

export const loadCurrentBoardSuccess = createAction(
  BoardsActionTypes.LOAD_CURRENT_BOARD_SUCCESS,
  props<{ board: Board }>(),
);

export const loadCurrentBoardFailure = createAction(
  BoardsActionTypes.LOAD_CURRENT_BOARD_FAILURE,
  props<{ error: any }>(),
);
