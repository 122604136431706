<div id="sidenav">
  <img class="logo" (click)="goHome()" src="assets/images/vibeiq_logo.jpg" />
  <!-- <ng-template *ngTemplateOutlet="newBtnTemp; context:{options: listPage? newProjectMenuOptions: newObjectMenuOptions}">
  </ng-template> -->

  <div class="ml-4 flex flex-col mt-[18px]">
    <div
      class="nav-item text-title-2"
      routerLinkActive="bg-blue-50 font-medium"
      data-test="side-panel-projects-open"
      [routerLink]="'/org/' + authContext?.currentOrg?.orgSlug + '/workspaces/list'"
    >
      <img src="assets/images/workspace_icon.svg" class="w-6 h-6 object-contain mr-6" /> Projects
    </div>
  </div>
  <div class="ml-4 flex flex-col" *ngIf="showBetaFeatures$ | async">
    <div
      class="nav-item text-title-2"
      routerLinkActive="bg-blue-50 font-medium"
      data-test="side-panel-libraries-open"
      [routerLink]="'/org/' + authContext?.currentOrg?.orgSlug + '/libraries/list'"
    >
      <img src="assets/images/library_icon_blue.svg" class="w-6 h-6 object-contain mr-6" /> Libraries
    </div>
  </div>
  <div class="ml-4 flex flex-col">
    <div
      class="nav-item text-title-2"
      routerLinkActive="bg-blue-50 font-medium"
      data-test="side-panel-recents-open"
      [routerLink]="'/org/' + authContext?.currentOrg?.orgSlug + '/workspaces/recents'"
    >
      <img src="assets/images/recents_icon.svg" class="w-6 h-6 object-contain mr-6" /> Recent
    </div>
  </div>
  <mat-divider class="!mt-2 pb-3"></mat-divider>

  <div class="wrapper narrow-scroll" *ngIf="!recents">
    <div class="flex flex-col ml-7 mr-3">
      <div class="cursor-pointer justify-between flex" (click)="toggleWorkspacesExpanded($event)">
        <span class="text-title-2">{{ rootWorkspaceType }}</span>
        <mat-icon>{{ workspacesExpanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
      </div>
      <app-workspace-navigator
        *ngIf="workspacesExpanded"
        [rootWorkspaceType]="rootWorkspaceType"
        class="mt-4"
      ></app-workspace-navigator>
    </div>
    <mat-divider class="!mt-3 pb-3"></mat-divider>

    <ng-container *ngIf="!(rootLoading$ | async) && !listPage">
      <div class="flex flex-col ml-7 mr-3">
        <div class="cursor-pointer flex items-center" (click)="toggleSelectedWorkspaceExpanded($event)">
          <ng-container *ngIf="selectedWorkspace?.workspaceType === 'LIBRARY'">
            <img src="/assets/images/{{ selectedWorkspace.name }}.svg" class="w-5 h-5 object-contain mr-2" />
            <span class="text-title-2 line-ellipsis">{{ selectedWorkspace?.name | titlecase }}</span>
          </ng-container>
          <ng-container *ngIf="selectedWorkspace?.workspaceType === 'PROJECT'">
            <span class="text-title-2 line-ellipsis">{{ selectedWorkspace?.name }}</span>
          </ng-container>
          <mat-icon class="!ml-auto">{{ selectedWorkspaceExpanded ? 'expand_more' : 'chevron_right' }}</mat-icon>
        </div>

        <div class="flex flex-col ml-1 mt-2" *ngIf="selectedWorkspaceExpanded">
          <div
            class="dashboard"
            *ngIf="selectedWorkspace?.workspaceType === 'PROJECT'"
            [routerLink]="
              '/org/' +
              authContext?.currentOrg?.orgSlug +
              '/workspaces/workspace/' +
              selectedWorkspace?.id +
              '/dashboard'
            "
            routerLinkActive="dashboard_active"
          >
            <img src="assets/images/dashboard_icon.svg" class="mr-2" />
            <span>Dashboard</span>
          </div>
          <app-workspace-folder-navigation
            class="-ml-7 folder"
            [routerLink]="
              '/org/' +
              authContext?.currentOrg?.orgSlug +
              '/workspaces/workspace/' +
              selectedWorkspace?.id +
              '/explorer'
            "
            routerLinkActive="folder_active"
          ></app-workspace-folder-navigation>
          <div
            class="move-trash"
            routerLinkActive="move-trash_active"
            [routerLink]="
              '/org/' + authContext?.currentOrg?.orgSlug + '/workspaces/workspace/' + selectedWorkspace?.id + '/trash'
            "
            (dragenter)="handleDragEnter($event)"
            (dragleave)="handleDragLeave($event)"
            (dragover)="handleDragOver($event)"
            (drop)="handleDrop($event)"
            data-test="trash-side-bar"
          >
            <span [draggable]="false" class="material-icons mr-2 text-black60"> delete </span>
            <span [draggable]="false">Trash</span>
          </div>
        </div>
      </div>
      <mat-divider class="!mt-3"></mat-divider>
    </ng-container>

    <div class="ml-4 flex flex-col" *ngIf="false">
      <div class="nav-item text-title-2" (click)="helpCenter()">
        <span class="material-icons text-black60 mr-6"> help_outline </span>
        Get Help
      </div>
    </div>
  </div>
</div>

<!--
<ng-template #newBtnTemp let-options="options">
  <button mat-raised-button color="primary" class="new-btn" [matMenuTriggerFor]="newMenu" data-test="new-button-workspace">
    <mat-icon class="mr-2">add</mat-icon> NEW
  </button>
  <mat-menu #newMenu="matMenu" class="add-new-menu">
    <button *ngFor="let menuOption of options" mat-menu-item (click)="createNew(menuOption.type)">
      <div class="flex items-center">
        <mat-icon class="!mr-0 !text-blue-300" *ngIf="menuOption.matIcon">{{ menuOption.matIcon }}</mat-icon>
        <img *ngIf="menuOption.icon" [src]="menuOption.icon" class="w-6 h-6 object-contain" alt="">
        <span class="text-title-2 ml-6" [attr.data-test]="'button-new-' + menuOption.name">{{ menuOption.name}}</span>
      </div>
    </button>
  </mat-menu>
</ng-template>
 -->
