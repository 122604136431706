export const environment = {
  production: false,
  apiBaseUrl: 'https://hub.dev.vibeiq.com/api-dev',
  hubAppHost: 'https://hub.dev.vibeiq.com',
  userPoolRegion: 'us-east-1',
  userPoolId: 'us-east-1_e2cA0nJWu',
  userPoolWebClientId: 'nnio2uhtq7qfk1tjmi7tgg63p',
  name: 'DEVELOPMENT',
  googleAnalyticsKey: 'UA-179147959-1',
  intercomAppId: 'e2uyp9md',
  domain: '.dev.vibeiq.com',
  websocketService: 'wss://qde1jn70k0.execute-api.us-east-1.amazonaws.com/dev/',
  appName: 'Hub',
  loginUrl: '', // only used by feature branches
  ngrxHistory: 2,
};
