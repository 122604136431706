import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class RoutingService {
  private authContext;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private authService: AuthService,
  ) {
    this.authService.authContext.subscribe((authContext) => {
      this.authContext = authContext;
    });
  }

  public switchOrg(orgSlug) {
    console.log('Routing Service: switchOrg: ', orgSlug);
    this.router.navigate([`/org/${orgSlug}`]);
  }
  public go(path, params = {}, state?) {
    this.router.navigate([`/org/${this.authContext?.currentOrg?.orgSlug}/${path}`], {
      queryParams: params,
      state: state,
    });
  }
  public getRouterLink(path) {
    return `/org/${this.authContext?.currentOrg?.orgSlug}/${path}`;
  }
  public isRoute(path) {
    return this.router.url.endsWith(path);
  }
  public openApp(app, path) {
    const newPath = `/org/${this.authContext?.currentOrg?.orgSlug}/${path}`;
    let domain;
    if (environment.production) {
      domain = `https://${app}.vibeiq.com`;
    } else if (environment.name === 'STAGING') {
      domain = `https://${app}-staging.vibeiq.com`;
    } else {
      domain = `https://${app}.dev.vibeiq.com`;
    }
    const url = domain + newPath;
    window.open(url, '_blank');
  }
}
