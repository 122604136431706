<app-modal title="Add project collaborators" [dialogRef]="dialogRef">
  <div class="modal-content overflow-y-hidden max-h-[65vh]">
    <div class="add-collaborator bg-gray-50 p-4 rounded-xl">
      <div class="flex">
        <mat-form-field class="user-select w-4/5 mr-4" appearance="fill" data-test="find-by-user-placeholder">
          <mat-label>Find by user or group name</mat-label>
          <mat-select [formControl]="addControl" multiple #addSelect (openedChange)="selectPanelOpen($event)">
            <mat-select-trigger>
              {{ addControl.value ? addControl.value[0]?.value : '' }}
              <span *ngIf="addControl.value?.length > 1">
                (+{{ addControl.value.length - 1 }} {{ addControl.value?.length === 2 ? 'other' : 'others' }})
              </span>
            </mat-select-trigger>
            <mat-form-field class="w-full -mb-4 mt-1 px-4" appearance="outlined">
              <input
                matInput
                type="text"
                (keydown)="$event.stopImmediatePropagation()"
                [(ngModel)]="searchKey"
                placeholder="Find by user or group name"
              />
              <button *ngIf="searchKey" matSuffix mat-icon-button aria-label="Clear" (click)="searchKey = ''">
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
            <ng-container *ngIf="addList | searchKey: searchKey : 'value' as list">
              <div class="selection-options">
                <div (click)="selectAll(list)" class="cursor-pointer hover:text-accent">Select all</div>
                <div class="mx-1">-</div>
                <div (click)="clearAll(list)" class="cursor-pointer hover:text-error-light">Clear</div>
              </div>
              <mat-option *ngFor="let item of list" [value]="item" data-test="list-of-users">
                <div class="flex items-center">
                  <div
                    class="w-8 h-8 rounded-2xl flex-center mr-4"
                    [class.bg-gray-500]="item.type === 'user:'"
                    [class.bg-blue-300]="item.type === 'group:'"
                  >
                    <span *ngIf="item?.type === 'user:'" class="material-icons text-white text-xl"> person </span>
                    <span *ngIf="item?.type === 'group:'" class="material-icons text-white text-xl"> group </span>
                  </div>
                  <span> {{ item?.value }} </span>
                </div>
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill">
          <mat-label>Role</mat-label>
          <mat-select [formControl]="newRoleControl" data-test="select-member-role">
            <mat-option
              *ngFor="let role of roles"
              [value]="role.value"
              [attr.data-test]="role.value === 'ADMIN' ? 'default-chooser-admin-role' : ''"
            >
              {{ role.text }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="flex-between-center">
        <div class="text-sm mr-16">
          Project collaborators can view, edit or create assortments and documents within this projects
          <a href="https://intercom.help/vibesolutions/en/" target="_blank" class="text-primary underline ml-2"
            >Learn More</a
          >
        </div>
        <button
          id="add-user-button"
          mat-raised-button
          color="primary"
          [disabled]="!addControl.value?.length"
          (click)="addPrincipal()"
        >
          Add
        </button>
      </div>
    </div>

    <mat-progress-bar mode="query" *ngIf="loading"></mat-progress-bar>

    <div class="principals-section narrow-scroll">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="name" matSortDirection="asc" class="w-full">
        <ng-container matColumnDef="select">
          <th mat-header-cell *matHeaderCellDef class="w-16">
            <mat-checkbox
              (change)="$event ? masterToggle() : null"
              [checked]="selection.hasValue() && isAllSelected()"
              [indeterminate]="selection.hasValue() && !isAllSelected()"
              [aria-label]="checkboxLabel()"
            >
            </mat-checkbox>
          </th>
          <td mat-cell *matCellDef="let row">
            <mat-checkbox
              (click)="$event.stopPropagation()"
              (change)="$event ? selection.toggle(row) : null"
              [checked]="selection.isSelected(row)"
              [aria-label]="checkboxLabel(row)"
            >
            </mat-checkbox>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex items-center">
              <app-avatar *ngIf="element?.type === 'User'" [user]="element?.user" class="mr-4"></app-avatar>
              <div
                *ngIf="element?.type === 'Group'"
                class="w-8 h-8 rounded-2xl flex-center mr-4"
                [ngStyle]="{ 'background-color': getBackgroundColor(element?.id) }"
              >
                <span class="material-icons text-white text-xl"> people_alt </span>
              </div>
              <span class="!w-36 line-ellipsis">
                {{ element?.user | userName }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
          <td mat-cell *matCellDef="let element">
            {{ element?.type }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email address</th>
          <td mat-cell *matCellDef="let element">
            <div class="flex items-center">
              <span *ngIf="element?.type === 'User'" class="!w-64 line-ellipsis">
                {{ element?.user?.email }}
              </span>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="role">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="min-w-[70px]">
            <mat-select
              [value]="element?.role"
              (click)="$event.preventDefault(); $event.stopPropagation()"
              (selectionChange)="handleRoleChange(element, $event)"
              class="role-select"
              panelClass="role-select"
              data-test="select-member-role"
            >
              <mat-option
                *ngFor="let role of roles"
                [value]="role.value"
                [attr.data-test]="role.value === 'ADMIN' ? 'admin-role-chooser' : ''"
              >
                {{ role.text }}
              </mat-option>
              <mat-option value="ORG_ADMIN" class="pointer-events-none !h-0">Org Admin</mat-option>
            </mat-select>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="relative">
            <div
              [class.opacity-0]="!selection.selected.length"
              *ngIf="this.selection?.selected?.length"
              (click)="removeMembers()"
              class="absolute w-24 h-full flex items-center top-0 right-0 cursor-pointer text-title-2 text-primary hover:text-error-light"
            >
              Remove Selected
            </div>
          </th>
          <td mat-cell *matCellDef="let element; index as idx" class="w-8">
            <mat-icon class="text-grey hover:text-error-light pl-5" (click)="removeFromWorkspace(element, idx, $event)"
              >close</mat-icon
            >
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: displayedColumns"
          (click)="selection.toggle(row)"
          class="cursor-pointer"
          [class.opacity-70]="row?.role === 'ORG_ADMIN'"
          [class.pointer-events-none]="row?.role === 'ORG_ADMIN'"
        ></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell" colspan="5">No collaborators</td>
        </tr>
      </table>
    </div>
  </div>
</app-modal>
