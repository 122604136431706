<app-modal [title]="title" [dialogRef]="dialogRef">
  <div class="explaination">
    <img *ngIf="currentPage === 0" class="icon min-w-[60px] mr-4" src="assets/images/showcase_icon.svg" />
    <div *ngIf="currentPage === 0" class="content text-sm">
      A showcase is an interactive digital product experience that lets you present and collaborate on an assortment in
      a visual and narrative manner
    </div>
    <div *ngIf="currentPage === 1" class="content text-sm">
      Connect your showcase to an assortment to receive change notifications and specify which items are available for
      selection in your document. You can change this later.
    </div>
  </div>

  <app-create-showcase-form
    (pageChanged)="onPageChanged($event)"
    (formSubmitted)="closeModal()"
    (formCancelled)="closeModal()"
  />
</app-modal>
