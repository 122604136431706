import { State } from './dashboard.state';
import { Action, createReducer, on } from '@ngrx/store';
import { initialState } from './dashboard.state';
import { DashboardsActions } from '.';

const setLoading = (state: State, { loading }) => {
  return {
    ...state,
    loading,
  };
};

const setCurrentAssortmentSummary = (state: State, { currentAssortmentSummary }) => {
  return {
    ...state,
    currentAssortmentSummary,
  };
};

const setCurrentAssortmentHistory = (state: State, { currentAssortmentHistory }) => {
  return {
    ...state,
    currentAssortmentHistory,
  };
};

const setCurrentAssortmentPlan = (state: State, { currentAssortmentPlan }) => {
  return {
    ...state,
    currentAssortmentPlan,
  };
};

export const dashboardReducer = createReducer(
  initialState,
  on(DashboardsActions.loadCurrentAssortmentHistorySuccess, setCurrentAssortmentHistory),
  on(DashboardsActions.setCurrentAssortmentSummary, setCurrentAssortmentSummary),
  on(DashboardsActions.setCurrentAssortmentPlan, setCurrentAssortmentPlan),
  on(DashboardsActions.setLoading, setLoading),
);

export function reducer(state: State | undefined, action: Action): any {
  return dashboardReducer(state, action);
}
