import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { AssortmentItem } from '../assortment-item';

export interface Assortment {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  isArchived?: boolean;
  name?: string;
  workspaceId?: string;
  assortmentItems?: AssortmentItem[];
  [key: string]: any;
}

interface AssortmentsState extends EntityState<Assortment> {}

export interface State {
  assortments: AssortmentsState;
  sourceAssortment: Assortment;
  currentAssortment: Assortment;
  assortmentsLoaded: boolean;
}
export const assortmentsEntityAdapter: EntityAdapter<Assortment> = createEntityAdapter<Assortment>({});

export const assortmentsInitialState = {
  assortments: assortmentsEntityAdapter.getInitialState({}),
  sourceAssortment: null,
  currentAssortment: null,
  assortmentsLoaded: false,
};
