import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkspacePrincipal } from '../workspace-principal';
import { WorkspaceEntitiesFolderSummary } from '../workspaces.service';
export enum WorkspaceType {
  project = 'PROJECT',
  folder = 'FOLDER',
  library = 'LIBRARY',
}
export interface Workspace {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;
  typeId?: string;
  orgId?: string;

  name?: string;
  workspaceType?: WorkspaceType;
  thumbnail?: string;
  description?: string;
  isArchived?: boolean;
  parentId?: string; // folders need a parent
  parentWorkspace?: any;
  projectId?: string; // projects need a project entity
  project?: any;

  rootWorkspaceId?: string;
  rootWorkspace?: any;
  rootWorkspaceType?: WorkspaceType;
  workspacePrincipals?: WorkspacePrincipal[];
  policyOwner?: boolean;
  parent?: any;

  icon?: any;
}

export interface WorkspaceEntity {
  workspaceId?: string;
  entityReference?: string;
  entity?: any;
  createdBy?: any;
  updatedBy?: any;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
  thumbnailUrl?: string;
  workspace?: any;
  id?: string;
  entityType?: string;
  // [key: string]: any;
}

export interface WorkspaceEntitiesFolderParameters {
  order?: any;
  search?: string;
  createdById?: string;
}

export interface WorkspaceFolder {
  name: string;
  children?: Array<WorkspaceFolder>;
  workspace: Workspace;
  expanded?: boolean;
}

interface WorkspacesState extends EntityState<Workspace> {}

export interface State {
  workspaces: WorkspacesState;
  workspacesLoaded: boolean;
  currentWorkspace: Workspace;
  currentWorkspaceLoading: boolean;
  currentWorkspaceFolder: Workspace;
  workspaceFolderEntities: Array<WorkspaceEntity>;
  currentWorkspaceFolderLoading: boolean;
  currentWorkspaceFolderNextPageLoading: boolean;
  workspaceFolderStructure: Array<WorkspaceFolder>;
  workspaceEntitiesFolderParameters: WorkspaceEntitiesFolderParameters;
  workspaceEntitiesFolderSummary: WorkspaceEntitiesFolderSummary;
}
export const workspacesEntityAdapter: EntityAdapter<Workspace> = createEntityAdapter<Workspace>({});

export const workspacesInitialState = {
  workspaces: workspacesEntityAdapter.getInitialState({}),
  workspacesLoaded: false,
  currentWorkspace: null,
  currentWorkspaceLoading: true,
  currentWorkspaceFolder: null,
  workspaceFolderEntities: [],
  currentWorkspaceFolderLoading: true,
  currentWorkspaceFolderNextPageLoading: false,
  workspaceFolderStructure: [],
  workspaceEntitiesFolderParameters: {},
  workspaceEntitiesFolderSummary: null,
};
