<div class="search-replace">
  <div class="form-field">
    <input
      data-test="app-search-replace-input"
      placeholder="Search in plan"
      matInput
      #inputField
      [formControl]="searchInput"
    />
    <button mat-button (click)="close()" data-test="app-search-replace-close-button">
      <mat-icon>clear</mat-icon>
    </button>
  </div>
  <div class="result-count" *ngIf="searchReplaceCells.length > 0">
    <div>
      <span data-test="app-search-replace-count-detail">
        {{ currentCellIndex + 1 }} of {{ searchReplaceCells.length }}
      </span>
      <button mat-button (click)="previousCell()" data-test="app-search-replace-previous-button">
        <mat-icon>expand_less</mat-icon>
      </button>
      <button mat-button (click)="nextCell()" data-test="app-search-replace-next-button">
        <mat-icon>expand_more</mat-icon>
      </button>
    </div>
  </div>
</div>
