import { State } from './showcases.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as ShowcasesActions from './showcases.actions';
import { showcasesEntityAdapter } from './showcases.state';
import { showcasesInitialState } from './showcases.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.setAll(data, state.showcases),
    showcasesLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.removeAll(state.showcases),
    showcasesLoaded: false,
  };
};
const add = (state: State, { showcase }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.addOne(showcase, state.showcases),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.updateOne({ id, changes }, state.showcases),
  };
};
const remove = (state: State, { showcase }) => {
  return {
    ...state,
    showcases: showcasesEntityAdapter.removeOne(showcase.id, state.showcases),
  };
};
const setCurrentShowcase = (state: State, { showcase }) => {
  return {
    ...state,
    currentShowcase: showcase,
  };
};

export const showcasesReducers = createReducer(
  showcasesInitialState,
  on(ShowcasesActions.loadShowcasesSuccess, setData),
  on(ShowcasesActions.clearShowcases, clearData),
  on(ShowcasesActions.createShowcaseSuccess, add),
  on(ShowcasesActions.copyShowcaseSuccess, add),
  //on(ShowcasesActions.deleteShowcaseSuccess, remove),
  on(ShowcasesActions.updateShowcaseSuccess, update),
  on(ShowcasesActions.loadCurrentShowcaseSuccess, setCurrentShowcase),
);

export function reducer(state: State | undefined, action: Action): any {
  return showcasesReducers(state, action);
}
