<app-modal title="Create New Showroom" [dialogRef]="dialogRef">
  <div>
    <div class="explaination text-xs mb-8 flex">
      <img class="icon max-w-none mr-4" src="assets/images/showroom_icon.svg" />
      <div class="content text-sm">
        <!-- //TODO: -->
        Showroom Content
      </div>
    </div>
    <div>
      <mat-form-field class="w-full" appearance="fill">
        <mat-label>Showroom Name</mat-label>
        <input matInput [formControl]="nameFormControl" />
      </mat-form-field>
    </div>

    <app-assortment-selector class="flex" (selectedAssortmentEvent)="setAssortment($event)"></app-assortment-selector>

    <div class="flex justify-end">
      <button mat-stroked-button (click)="cancel()">Cancel</button>
      <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="save()">Ok</button>
    </div>
  </div>
</app-modal>
