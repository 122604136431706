<app-modal title="Create New Folder" [dialogRef]="dialogRef">
  <div>
    <mat-form-field style="width: 100%" appearance="fill">
      <mat-label>Folder Name</mat-label>
      <input matInput [formControl]="nameFormControl" />
    </mat-form-field>
    <p class="-mt-4 mb-2 font-medium">FOLDER ACCESS</p>
    <mat-radio-group [(ngModel)]="policyOwner">
      <mat-radio-button class="mb-2" [value]="false">
        <span class="font-medium">Inherited.</span> Folder inherits access control from its parent folder or project.
      </mat-radio-button>
      <mat-radio-button class="mb-2" [value]="true">
        <span class="font-medium">Custom.</span> Define access control manually for this folder and its sub-folders.
      </mat-radio-button>
    </mat-radio-group>
  </div>
  <div class="actions">
    <button mat-flat-button (click)="cancel()">Cancel</button>
    <button mat-flat-button color="primary" [disabled]="!isValid()" (click)="save()">Ok</button>
  </div>
</app-modal>
