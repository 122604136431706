import { PropertyType } from '@contrail/types';

export enum FilterConditionType {
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  CONTAINS = 'contains',
  EQUALS = 'equals',
  LESS_THAN = 'less_than',
  GREATER_THAN = 'greater_than',
  GREATER_THAN_OR_EQUAL = 'greater_than_or_equal',
  LESS_THAN_OR_EQUAL = 'less_than_or_equal',
  NOT_EQUAL_TO = 'not_equal_to',
  IS_ANY_OF = 'is_any_of',
  IS_NONE_OF = 'is_none_of',
}
export interface FilterConditionTypeMeta {
  label: string;
  icon: string;
}

export const FilterConditionTypeMetaMap = new Map();
FilterConditionTypeMetaMap.set(FilterConditionType.EQUALS, { label: 'is', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.STARTS_WITH, { label: 'starts with', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.ENDS_WITH, { label: 'ends with', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.CONTAINS, { label: 'contains', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.LESS_THAN, { label: 'is less than', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.GREATER_THAN, { label: 'is greater than', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.GREATER_THAN_OR_EQUAL, {
  label: 'is greater or equal to',
  icon: '',
});
FilterConditionTypeMetaMap.set(FilterConditionType.LESS_THAN_OR_EQUAL, { label: 'is less than or equal to', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.NOT_EQUAL_TO, { label: 'is not', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.IS_ANY_OF, { label: 'is any of', icon: '' });
FilterConditionTypeMetaMap.set(FilterConditionType.IS_NONE_OF, { label: 'is none of', icon: '' });

const NumericFilterTypes = [
  FilterConditionType.EQUALS,
  FilterConditionType.NOT_EQUAL_TO,
  FilterConditionType.LESS_THAN,
  FilterConditionType.GREATER_THAN,
  FilterConditionType.GREATER_THAN_OR_EQUAL,
  FilterConditionType.LESS_THAN_OR_EQUAL,
];

const StringFilterTypes = [
  FilterConditionType.EQUALS,
  FilterConditionType.NOT_EQUAL_TO,
  FilterConditionType.CONTAINS,
  FilterConditionType.STARTS_WITH,
  FilterConditionType.ENDS_WITH,
];
const SingleSelectFilterTypes = [
  FilterConditionType.EQUALS,
  FilterConditionType.NOT_EQUAL_TO,
  FilterConditionType.IS_ANY_OF,
  FilterConditionType.IS_NONE_OF,
];
const MultiSelectFilterTypes = [FilterConditionType.IS_ANY_OF, FilterConditionType.IS_NONE_OF];
const BooleanFilterTypes = [FilterConditionType.EQUALS, FilterConditionType.NOT_EQUAL_TO];

const DateFilterTypes = [
  FilterConditionType.EQUALS,
  FilterConditionType.NOT_EQUAL_TO,
  FilterConditionType.GREATER_THAN,
  FilterConditionType.LESS_THAN,
  FilterConditionType.GREATER_THAN_OR_EQUAL,
  FilterConditionType.LESS_THAN_OR_EQUAL,
];

export const FilterConditionTypePropertyTypeMap = new Map();
FilterConditionTypePropertyTypeMap.set(PropertyType.Number, NumericFilterTypes);
FilterConditionTypePropertyTypeMap.set(PropertyType.Currency, NumericFilterTypes);
FilterConditionTypePropertyTypeMap.set(PropertyType.Formula, NumericFilterTypes);
FilterConditionTypePropertyTypeMap.set(PropertyType.Percent, NumericFilterTypes);

FilterConditionTypePropertyTypeMap.set(PropertyType.Text, StringFilterTypes);
FilterConditionTypePropertyTypeMap.set(PropertyType.String, StringFilterTypes);

FilterConditionTypePropertyTypeMap.set(PropertyType.SingleSelect, SingleSelectFilterTypes);
FilterConditionTypePropertyTypeMap.set(PropertyType.MultiSelect, MultiSelectFilterTypes);

FilterConditionTypePropertyTypeMap.set(PropertyType.Boolean, BooleanFilterTypes);

FilterConditionTypePropertyTypeMap.set(PropertyType.Date, DateFilterTypes);

export function getFilterConditionTypes(propertyType: PropertyType) {
  return FilterConditionTypePropertyTypeMap.get(propertyType);
}
