import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Plan {
  id?: string;
  name?: string;
  updatedOn?: string;
  createdOn?: string;
  coverPhoto?: string;
  isArchived?: boolean;
  planSummary?: {
    count: number;
    aggregates: any;
  };
  selected?: boolean;
  workspaceId?: string;
  targetAssortmentId?: string;
  sourceAssortments?: Array<any>;
  sourceAssortmentIds?: Array<string>;
  sourceAssortment?: any;
  isTrashed?: boolean;
}

interface PlansState extends EntityState<Plan> {}

export interface State {
  plans: PlansState;
  currentPlan: Plan;
  plansLoaded: boolean;
}
export const plansEntityAdapter: EntityAdapter<Plan> = createEntityAdapter<Plan>({});

export const plansInitialState = {
  plans: plansEntityAdapter.getInitialState({}),
  currentPlan: null,
  plansLoaded: false,
};
