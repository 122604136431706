import { ObjectUtil } from '@contrail/util';
import { createSelector } from '@ngrx/store';

export const loading = (state: any) => state.dashboard.loading;
export const currentAssortmentSummary = (state: any) => state.dashboard.currentAssortmentSummary;
export const currentAssortmentHistory = (state: any) => state.dashboard.currentAssortmentHistory;
export const currentAssortmentPlan = (state: any) => state.dashboard.currentAssortmentPlan;
export const currentAssortment = (state: any) => state.dashboard.currentAssortmentPlan; // TODO - this is not an assortment - remove?

export const latestAssortmentHistoryItem = createSelector(currentAssortmentHistory, (history) => {
  if (!history) {
    return;
  }
  const sorted = ObjectUtil.cloneDeep(history);
  sorted.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1));
  if (sorted.length) {
    return sorted[0];
  }
});
