import { PropertyType } from '@contrail/types';
import { SortDefinition, SortDirection } from './sort-definition';

export class SortObjects {
  public static sort(array: Array<any>, sorts: Array<SortDefinition>, rowOrder?: Array<string>) {
    let results: Array<any> = array;
    results = results.sort((obj1, obj2) => {
      let comp = 0;
      for (const sort of sorts) {
        comp = this.compareObjects(obj1, obj2, sort.propertySlug, sort.propertyType, sort.direction);
        if (comp !== 0) {
          return comp;
        }
      }
      // use the planRowOrder to sort last after other sorts are done.
      if (rowOrder) {
        return this.compareObjectIds(obj1, obj2, rowOrder);
      } else {
        return comp;
      }
    });
    return results;
  }

  public static compareObjectIds(obj1, obj2, rowOrder: Array<string>) {
    return rowOrder.indexOf(obj1.id) > rowOrder.indexOf(obj2.id) ? 1 : -1;
  }

  public static compareObjects(obj1, obj2, key, propertyType, direction: SortDirection = SortDirection.DESCENDING) {
    let valA = obj1[key] || '';
    let valB = obj2[key] || '';

    if (propertyType === PropertyType.ObjectReference || propertyType === PropertyType.ObjectReferenceList) {
      if (key === 'itemOption') {
        valA = valA?.optionName || valA?.name || '';
        valB = valB?.optionName || valB?.name || '';
      } else {
        valA = valA?.name || '';
        valB = valB?.name || '';
      }
    }

    let comp = 0;
    if (valA.localeCompare) {
      comp = valA.localeCompare(valB);
    } else {
      comp = valA - valB;
    }

    if (direction === SortDirection.DESCENDING) {
      comp = -1 * comp;
    }
    return comp;
  }
}
