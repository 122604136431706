import { Component, OnInit } from '@angular/core';
import { LeftSideBarService } from './left-sidebar.service';

@Component({
  selector: 'app-left-sidebar',
  templateUrl: './left-sidebar.component.html',
  styleUrls: ['./left-sidebar.component.scss'],
})
export class LeftSidebarComponent implements OnInit {
  expanded = true;
  constructor(private leftSideBarService: LeftSideBarService) {}

  ngOnInit(): void {}

  changeNavView() {
    this.expanded = !this.expanded;
    this.leftSideBarService.setSideBarOpen(this.expanded);
  }
}
