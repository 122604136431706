import { Component, OnInit } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthSelectors } from '@common/auth/auth-store';
import { CollectionManagerActions } from '@common/collection-manager/collection-manager-store';
import { FeatureFlagActions } from '@common/feature-flags';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { map } from 'rxjs';

@Component({
  selector: 'app-root',
  template: ` <router-outlet></router-outlet> `,
  styles: [
    `
      :host {
        display: block;
        height: 100%;
      }
    `,
  ],
})
export class AppComponent implements OnInit {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private store: Store<RootStoreState.State>,
  ) {
    this.store
      .select(AuthSelectors.selectAuthContext)
      .pipe(
        map((selectAuthContext) => {
          if (selectAuthContext?.currentOrg?.orgSlug) {
            this.store.dispatch(FeatureFlagActions.loadFeatureFlags());
            this.store.dispatch(CollectionManagerActions.loadTypeDefinitions());
          }
        }),
      )
      .subscribe();

    this.matIconRegistry.addSvgIcon(
      'workspace-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/workspace-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'showcase-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/showcase-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'plan-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/plan-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'board-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/board-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'showroom-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/showroom-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'trash-placeholder',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/trash-placeholder.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'slack',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/slack.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'msteams',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/msteams.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'assortment',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/assortment.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'pivot-view-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pivot_view_icon.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'pivot_active',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pivot_active.svg'),
    );
    this.matIconRegistry.addSvgIcon(
      'pivot_inactive',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/pivot_inactive.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'vertical_separator_icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/vertical_separator_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'more_actions',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/more_actions.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'sort',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/sort_icon.svg'),
    );

    this.matIconRegistry.addSvgIcon(
      'remove-icon',
      this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/images/remove_icon.svg'),
    );
  }

  public ngOnInit() {}
}
