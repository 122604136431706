import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChooserComponent } from './chooser.component';
import { ChooserEntityCardComponent } from './chooser-entity-card/chooser-entity-card.component';
import { VirtualScrollerModule } from '@iharbeck/ngx-virtual-scroller';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatIconModule } from '@angular/material/icon';
import { PipesModule } from '@common/pipes/pipes.module';
import { MatDividerModule } from '@angular/material/divider';
import { ComponentsModule } from '@common/components/components.module';
import { DateFilterModule } from '@common/components/date-filter/date-filter.module';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { FilterModule } from '@common/components/filter/filter.module';
import { SortModule } from '@common/components/sort/sort.module';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [ChooserComponent, ChooserEntityCardComponent],
  imports: [
    CommonModule,
    VirtualScrollerModule,
    MatCheckboxModule,
    MatIconModule,
    PipesModule,
    MatDividerModule,
    ComponentsModule,
    DateFilterModule,
    MatSlideToggleModule,
    FilterModule,
    SortModule,
    MatTooltipModule,
    MatButtonModule,
    MatProgressSpinnerModule,
  ],
  exports: [ChooserComponent],
})
export class ChooserModule {}
