export class FeatureFlag {
  id: string;

  createdOn?: Date;
  updatedOn?: Date;

  createdById?: string;
  updatedById?: string;

  createdBy?: any;
  updatedBy?: any;

  orgId?: string;

  featureName: string;
}

export enum BetaFeature {
  EXTENSIONS = 'EXTENSIONS',
  ASSET_LIBRARY = 'ASSET_LIBRARY',
  COLOR_LIBRARY = 'BOARD_COLORS',
  SHOWROOM = 'SHOWROOM',
  DEV_TABS = 'DEV_TABS',
  ITEM_BOARDS = 'ITEM_BOARDS',
  DOCUMENT_GENERATION = 'DOCUMENT_GENERATION',
  HUB_CREATE_ASSORTMENT = 'HUB_CREATE_ASSORTMENT',
  HUB_PIVOT_ASSORTMENT = 'HUB_PIVOT_ASSORTMENT',
  PROJECT_GOALS = 'PROJECT_GOALS',
}
