import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { BoardsActions } from '.';
import { BoardsService } from '../boards.service';
import { Board } from './boards.state';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { DownloadService } from '@common/exports/download/download.service';
import { WorkspaceEntitiesHelperService } from 'src/app/workspaces/workspace-entities-helper.service';

@Injectable()
export class BoardsEffects {
  constructor(
    private actions$: Actions,
    private boardService: BoardsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private webSocketService: WebSocketService,
    private routingService: RoutingService,
    private downloadService: DownloadService,
    private workspaceEntitiesHelperService: WorkspaceEntitiesHelperService,
  ) {}
  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          // this.store.dispatch(BoardsActions.clearBoards());
          // this.store.dispatch(BoardsActions.loadBoards());
        }),
      ),
    { dispatch: false },
  );
  loadBoards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.LOAD_BOARDS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.boardService.getBoards(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => BoardsActions.loadBoardsSuccess({ data })),
          catchError((error) => observableOf(BoardsActions.loadBoardsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentBoards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(BoardsActions.BoardsActionTypes.LOAD_CURRENT_BOARD),
      switchMap((action: any) => {
        return from(this.boardService.getBoardById(action.id)).pipe(
          map((data) => BoardsActions.loadCurrentBoardSuccess({ board: data })),
          catchError((error) => observableOf(BoardsActions.loadCurrentBoardFailure({ error }))),
        );
      }),
    ),
  );
  createBoard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.CREATE_BOARD),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating board.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          const board: Board = { ...action.board };
          board.workspaceId = store.workspaces.currentWorkspaceFolder.id;
          return from(this.boardService.createBoard(board)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Board Created.', '', { duration: 2000 });

              // Could be we only want this in hub....
              this.routingService.openApp('boards', `board/${data.id}`);
              this.store.dispatch(WorkspacesActions.addEntity({ entity: data }));
              return BoardsActions.createBoardSuccess({ board: data });
            }),
            catchError((error) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(BoardsActions.createBoardFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  deleteBoard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.DELETE_BOARD),
        withLatestFrom(this.store),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Deleting board.' }));
        }),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.boardService.deleteBoardById(action.boardId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Board Deleted.', '', { duration: 2000 });
              this.store.dispatch(
                WorkspacesActions.removeFolderEntities({ entityReferences: [`board:${action.boardId}`] }),
              );
              return BoardsActions.deleteBoardSuccess({ boardId: action.boardId });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(BoardsActions.deleteBoardFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  updateBoard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.UPDATE_BOARD),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Updating board.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.boardService.updateBoard(action.id, action.changes)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Board Updated.', '', { duration: 2000 });
              if (store.workspaces.currentWorkspaceFolder?.id) {
                this.store.dispatch(
                  WorkspacesActions.loadWorkspaceFolderEntities({
                    workspaceId: store.workspaces.currentWorkspaceFolder.id,
                  }),
                );
              }

              this.store.dispatch(BoardsActions.updateBoardSuccess({ id: data.id, changes: data }));
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(BoardsActions.updateBoardFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  copyBoard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.COPY_BOARD),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Copying board.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.boardService.copy(action.name, action.sourceId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Board Copied.', '', { duration: 2000 });
              this.routingService.openApp('boards', `board/${data.id}`);
              this.store.dispatch(
                WorkspacesActions.loadWorkspaceFolderEntities({
                  workspaceId: store.workspaces.currentWorkspaceFolder.id,
                }),
              );
              return BoardsActions.copyBoardSuccess({ board: data });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(BoardsActions.copyBoardFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  asyncCopyBoard$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.ASYNC_COPY_BOARD),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Copying board.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.boardService.asyncCopyBoard(action.name, action.sourceId, action.newWorkspaceId)).pipe(
            map((data) => {
              if (!data?.jobId) {
                throw new Error();
              }
              this.downloadService.initDownloadPolling(data.jobId, `/boards/copy/${data.jobId}`);
              const combletedJobSubscription = this.downloadService.completedJobsObservable$.subscribe(
                async (completedJob) => {
                  if (completedJob?.jobId === data.jobId && completedJob?.path) {
                    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
                    this.store.dispatch(
                      BoardsActions.asyncCopyBoardSuccess({
                        path: completedJob.path as string,
                        jobId: completedJob.jobId,
                        navigateToFolder: action.navigateToFolder,
                      }),
                    );
                    combletedJobSubscription.unsubscribe();
                  }
                },
              );

              return data;
            }),
            catchError((error) => {
              this.snackBar.open(error, 'An error occurred while attempting to copy this document. Please try again.', {
                duration: 2000,
              });
              return observableOf(BoardsActions.copyBoardFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  asyncCopyBoardSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(BoardsActions.BoardsActionTypes.ASYNC_COPY_BOARD_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          this.snackBar.open('Board Copied.', '', { duration: 2000 });
          const response = await fetch(action.path);
          const board = await response.json();
          this.routingService.openApp('boards', `board/${board.id}`);
          this.store.dispatch(
            WorkspacesActions.loadWorkspaceFolderEntities({
              workspaceId: store.workspaces.currentWorkspaceFolder.id,
            }),
          );
          this.store.dispatch(BoardsActions.copyBoardSuccess({ board }));
          if (action.navigateToFolder) {
            if (action.navigateToFolder?.across) {
              this.routingService.go('/workspaces/workspace/' + action.navigateToFolder.rootId + '/explorer');
              if (action.navigateToFolder?.folder?.entity) {
                setTimeout(() => this.workspaceEntitiesHelperService.goToEntity(action.navigateToFolder.folder), 1500);
              }
            } else {
              action.navigateToFolder?.folder?.entity
                ? this.workspaceEntitiesHelperService.goToEntity(action.navigateToFolder.folder)
                : this.workspaceEntitiesHelperService.goToRootFolder();
            }
          }
        }),
      ),
    { dispatch: false },
  );
}
