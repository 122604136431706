import { createSelector } from '@ngrx/store';
import { AssortmentUtil } from '../assortment-util';
import { Assortment, assortmentsEntityAdapter } from './assortments.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = assortmentsEntityAdapter.getSelectors();

const assortmentEntities = (state: any) => state.assortments.assortments.entities;
export const assortments = createSelector(assortmentEntities, (state) => Object.values(state) as Array<Assortment>);
export const sourceAssortment = (state: any) => state.assortments.sourceAssortment; // API RESPONSE FOR LOADED ASSORTMENT
export const assortmentsLoaded = (state: any) => state.assortments.assortmentsLoaded; // API RESPONSE FOR LOADED ASSORTMENT

export const sourceAssortmentItemData = createSelector(sourceAssortment, (sourceAssortmentObj) => {
  const assortmentItems = sourceAssortmentObj?.assortmentItems;
  if (assortmentItems) {
    return AssortmentUtil.convertAssortmentItemsToItemData(assortmentItems);
  }
  return null;
});
export const sourceAssortmentItemDataHiearchy = createSelector(sourceAssortment, (sourceAssortmentObj) => {
  const assortmentItems = sourceAssortmentObj?.assortmentItems;
  if (assortmentItems) {
    return AssortmentUtil.convertAssortmentItemsFamilyItemData(assortmentItems);
  }
  return null;
});
export const selectSourceItem = (itemId: string) =>
  createSelector(sourceAssortmentItemData, (itemDataCollection) => {
    if (itemDataCollection) {
      return itemDataCollection.find((item) => item.id === itemId);
    }
    return null;
  });
export const selectSourceFamily = (itemId: string) =>
  createSelector(sourceAssortmentItemDataHiearchy, (itemDataCollection) => {
    if (itemDataCollection) {
      return itemDataCollection.find((item) => item.id === itemId);
    }
    return null;
  });
export const currentAssortment = (state: any) => state.assortments.currentAssortment;
