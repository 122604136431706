import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacePrincipalsActions } from '.';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { WorkspacePrincipalsService } from '@common/workspaces/workspace-principals.service';
import { FolderPrincipalsActions } from '@common/folders/folder-principals-store';

@Injectable()
export class WorkspacePrincipalsEffects {
  constructor(
    private actions$: Actions,
    private workspacePrincipalService: WorkspacePrincipalsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}
  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          this.store.dispatch(WorkspacePrincipalsActions.loadWorkspacePrincipalsSuccess({ data: [] }));
        }),
      ),
    { dispatch: false },
  );
  loadWorkspacePrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacePrincipalsActions.WorkspacePrincipalsActionTypes.LOAD_WORKSPACE_PRINCIPALS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspacePrincipalService.getWorkspacePrincipals(store.workspaces?.currentWorkspace?.id)).pipe(
          map((data) => WorkspacePrincipalsActions.loadWorkspacePrincipalsSuccess({ data })),
          catchError((error) => observableOf(WorkspacePrincipalsActions.loadWorkspacePrincipalsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentWorkspacePrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacePrincipalsActions.WorkspacePrincipalsActionTypes.LOAD_CURRENT_WORKSPACE_PRINCIPAL),
      switchMap((action: any) => {
        return from(this.workspacePrincipalService.getWorkspacePrincipalById(action.id)).pipe(
          map((data) => WorkspacePrincipalsActions.loadCurrentWorkspacePrincipalSuccess({ workspacePrincipal: data })),
          catchError((error) =>
            observableOf(WorkspacePrincipalsActions.loadCurrentWorkspacePrincipalFailure({ error })),
          ),
        );
      }),
    ),
  );
  createWorkspacePrincipal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacePrincipalsActions.WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPAL),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const principalReference = action.principalReference;
        const role = action.role;
        const workspaceId = store.workspaces.currentWorkspaceFolder.id;
        return from(
          this.workspacePrincipalService.createWorkspacePrincipal({ principalReference, workspaceId, role }),
        ).pipe(
          map((data) => {
            this.snackBar.open('Collaborator added.', '', { duration: 2000 });
            // this.store.dispatch(WorkspacePrincipalsActions.setCurrentWorkspacePrincipal({ currentWorkspacePrincipal: data }));
            this.store.dispatch(FolderPrincipalsActions.loadFolderPrincipals());
            return WorkspacePrincipalsActions.createWorkspacePrincipalSuccess({ workspacePrincipal: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacePrincipalsActions.createWorkspacePrincipalFailure({ error }));
          }),
        );
      }),
    ),
  );
  createWorkspacePrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacePrincipalsActions.WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPALS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const objects = action.objects;
        return from(this.workspacePrincipalService.createWorkspacePrincipals(objects)).pipe(
          map((data: any) => {
            this.snackBar.open('Collaborators added.', '', { duration: 2000 });
            this.store.dispatch(FolderPrincipalsActions.loadFolderPrincipals());
            return WorkspacePrincipalsActions.createWorkspacePrincipalsSuccess({
              data: data?.createdWorkspacePrincipals,
            });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacePrincipalsActions.createWorkspacePrincipalsFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteWorkspacePrincipal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacePrincipalsActions.WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPAL),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspacePrincipalService.deleteWorkspacePrincipal(action.workspacePrincipal)).pipe(
          map((data) => {
            this.snackBar.open('Collaborator removed.', '', { duration: 2000 });
            this.store.dispatch(FolderPrincipalsActions.loadFolderPrincipals());
            return WorkspacePrincipalsActions.deleteWorkspacePrincipalSuccess({ workspacePrincipal: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacePrincipalsActions.deleteWorkspacePrincipalFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteWorkspacePrincipals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacePrincipalsActions.WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPALS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspacePrincipalService.deleteWorkspacePrincipals(action.ids)).pipe(
          map((data) => {
            this.snackBar.open('Collaborators removed.', '', { duration: 2000 });
            this.store.dispatch(FolderPrincipalsActions.loadFolderPrincipals());
            return WorkspacePrincipalsActions.deleteWorkspacePrincipalsSuccess({ data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacePrincipalsActions.deleteWorkspacePrincipalsFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateWorkspacePrincipal$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacePrincipalsActions.WorkspacePrincipalsActionTypes.UPDATE_WORKSPACE_PRINCIPAL),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspacePrincipalService.updateWorkspacePrincipal(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Collaborator updated.', '', { duration: 2000 });
            this.store.dispatch(FolderPrincipalsActions.loadFolderPrincipals());
            return WorkspacePrincipalsActions.updateWorkspacePrincipalSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacePrincipalsActions.updateWorkspacePrincipalFailure({ error }));
          }),
        );
      }),
    ),
  );
}
