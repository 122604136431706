import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LeftSideBarService {
  private sideBarOpenSubject: Subject<boolean> = new BehaviorSubject(true);
  public isSideBarOpen$: Observable<boolean> = this.sideBarOpenSubject.asObservable();
  constructor() {}

  public setSideBarOpen(isOpen: boolean) {
    this.sideBarOpenSubject.next(isOpen);
  }
}
