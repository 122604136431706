import { createAction, props } from '@ngrx/store';
import { Plan } from 'src/app/plans/plans-store/plans.state';

export enum DashboardActionTypes {
  SET_LOADING = '[Dashboard] Set loading',
  SET_CURRENT_ASSORTMENT_SUMMARY = '[Dashboard] Set Current Assortment Summary',
  LOAD_CURRENT_ASSORTMENT_HISTORY = '[Dashboard] Load Current Assortment History',
  LOAD_CURRENT_ASSORTMENT_HISTORY_SUCCESS = '[Dashboard] Load Current Assortment History - Success',
  LOAD_CURRENT_ASSORTMENT_HISTORY_FAILURE = '[Dashboard] Load Current Assortment History - Failure',

  SET_CURRENT_ASSORTMENT_PLAN = '[Dashboard] Set Current Assortment Plan',
}

////////////////////////////////////////////////////////////////////////////////

export const setLoading = createAction(DashboardActionTypes.SET_LOADING, props<{ loading: boolean }>());

export const setCurrentAssortmentSummary = createAction(
  DashboardActionTypes.SET_CURRENT_ASSORTMENT_SUMMARY,
  props<{ currentAssortmentSummary: any }>(),
);

export const setCurrentAssortmentPlan = createAction(
  DashboardActionTypes.SET_CURRENT_ASSORTMENT_PLAN,
  props<{ currentAssortmentPlan: Plan }>(),
);

export const loadCurrentAssortmentHistory = createAction(
  DashboardActionTypes.LOAD_CURRENT_ASSORTMENT_HISTORY,
  props<{ assortmentId: string }>(),
);

export const loadCurrentAssortmentHistorySuccess = createAction(
  DashboardActionTypes.LOAD_CURRENT_ASSORTMENT_HISTORY_SUCCESS,
  props<{ currentAssortmentHistory: any }>(),
);

export const loadCurrentAssortmentHistoryFailure = createAction(
  DashboardActionTypes.LOAD_CURRENT_ASSORTMENT_HISTORY_FAILURE,
  props<{ error: any }>(),
);
