import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { UntypedFormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-search-replace',
  templateUrl: './search-replace.component.html',
  styleUrls: ['./search-replace.component.scss'],
})
export class SearchReplaceComponent implements AfterViewInit {
  @ViewChild('inputField') inputField: ElementRef;
  @Input() searchReplaceCells: Array<any>;
  @Output() searchHandler = new EventEmitter<string>();
  @Output() setActiveCell = new EventEmitter<any>();
  @Output() closePanel = new EventEmitter<boolean>();

  searchInput = new UntypedFormControl();
  private sub: Subscription;
  public currentCellIndex = 0;

  constructor() {}

  ngAfterViewInit(): void {
    this.sub = this.searchInput.valueChanges
      .pipe(
        debounceTime(500),
        distinctUntilChanged(),
        tap((text: string) => {
          this.currentCellIndex = 0;
          this.searchHandler.emit(text);
        }),
      )
      .subscribe();
    this.inputField.nativeElement.focus();
  }

  close() {
    this.closePanel.emit();
    this.searchInput.setValue('');
    this.currentCellIndex = 0;
  }

  previousCell() {
    this.currentCellIndex === 0
      ? (this.currentCellIndex = this.searchReplaceCells.length - 1)
      : this.currentCellIndex--;
    this.setActiveCell.emit(this.searchReplaceCells[this.currentCellIndex]);
  }

  nextCell() {
    this.currentCellIndex === this.searchReplaceCells.length - 1
      ? (this.currentCellIndex = 0)
      : this.currentCellIndex++;
    this.setActiveCell.emit(this.searchReplaceCells[this.currentCellIndex]);
  }

  @HostListener('document:keydown.enter', ['$event'])
  handleEnterKey(event: KeyboardEvent = null) {
    this.nextCell();
    event.stopPropagation();
  }
}
