import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { Item } from '@contrail/entity-types/lib/item';
import { combineLatest, filter, Observable, switchMap, take } from 'rxjs';
import { AuthSelectors } from '@common/auth/auth-store';
import { CollectionDataUtil } from './collection-data-util';
import { CollectionManagerSelectors } from './collection-manager-store';
import { AssortmentUtil } from '@common/assortments/assortment-util';

export enum CollectionDataEntityType {
  PLAN_PLACEHOLDER = 'plan-placeholder',
  ASSORTMENT_ITEM = 'assortment-item',
}

export interface CollectionDataEntity {
  id?: string;
  itemFamilyId?: string;
  itemOptionId?: string;
  itemFamily?: Item;
  itemOption?: Item;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class CollectionManagerService {
  constructor(private store: Store<RootStoreState.State>) {}

  getCollectionDataByAssortmentId(assortmentId: string): Observable<CollectionDataEntity[]> {
    const assortment$ = this.getAssortmentWithAssortmentItems(assortmentId);

    const typeDefinitions$ = this.store.select(CollectionManagerSelectors.typeDefinitions).pipe(
      filter((types) => !!types),
      take(1),
    );

    const authContext$ = this.store.select(AuthSelectors.selectAuthContext).pipe(
      filter((authContext) => !!authContext),
      take(1),
    );

    return combineLatest([assortment$, typeDefinitions$, authContext$]).pipe(
      switchMap(async ([assortment, typeDefinitions, authContext]) => {
        const assortmentItems = assortment?.assortmentItems ?? [];
        const collectionData = AssortmentUtil.convertAssortmentItemsToCollectionDataEntities(assortmentItems);
        const hydratedCollectionData = await CollectionDataUtil.buildHydratedCollectionDataEntities(
          collectionData,
          CollectionDataEntityType.ASSORTMENT_ITEM,
          {
            typeMap: typeDefinitions,
            assortment,
            orgConfig: authContext?.currentOrg?.orgConfig,
          },
        );
        return hydratedCollectionData;
      }),
    );
  }

  private async getAssortmentWithAssortmentItems(assortmentId: string) {
    const assortment = await new Entities().get({
      entityName: 'assortment',
      id: assortmentId,
      relations: [
        'assortmentItems',
        'assortmentItems.item',
        'assortmentItems.item.itemFamily',
        'assortmentItems.projectItem',
      ],
    });

    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      return { ...assortment, assortmentItems };
    }

    return assortment;
  }
}
