import { Workspace, WorkspaceEntity } from './workspaces-store/workspaces.state';

export class WorkspaceEntityHelper {
  public static postProcessWorkspaceEntities(
    entities: Array<any>,
    workspace: Workspace = null,
  ): Array<WorkspaceEntity> {
    const processed = [];
    for (let entity of entities) {
      const workspaceEntity: any = {};
      let entityReference;
      entityReference = entity.entityType + ':' + entity.id;
      workspaceEntity.entityReference = entityReference;
      workspaceEntity.name = entity?.name;
      workspaceEntity.createdBy = entity?.createdBy;
      workspaceEntity.updatedBy = entity?.updatedBy;
      workspaceEntity.createdOn = entity?.createdOn;
      workspaceEntity.updatedOn = entity?.updatedOn;
      workspaceEntity.thumbnailUrl = this.getThumbnail(entity);
      workspaceEntity.workspace = workspace;
      workspaceEntity.entity = entity;
      workspaceEntity.id = entity.id;
      workspaceEntity.entityType = entity.entityType;
      processed.push(workspaceEntity);
    }
    return processed;
  }

  public static getThumbnail(entity: any) {
    let thumb = entity?.previewFile?.downloadUrl;
    return thumb;
  }
}
