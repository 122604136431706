import { Component, ElementRef, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';
import { RoutingService } from 'src/app/common/routing/routing.service';
import { Store } from '@ngrx/store';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { RootStoreState } from 'src/app/root-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { WorkspacePrincipalsComponent } from 'src/app/workspaces/workspace-principals/workspace-principals.component';
import { WorkspacePrincipal } from '@common/workspaces/workspace-principal';
import { ObjectUtil } from '@contrail/util';
import { distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import {
  WorkspacePrincipalsActions,
  WorkspacePrincipalsSelectors,
} from 'src/app/workspaces/workspace-principals/workspace-principals-store';
import { ColorUtil } from '@common/util/color-util';
import { UserOrgsSelectors } from '../../user-orgs-store';
import { NavigationEnd, Router } from '@angular/router';
import { AuthService } from '@common/auth/auth.service';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss'],
})
export class PageHeaderComponent implements OnInit, OnDestroy, OnChanges {
  private destroy$ = new Subject();
  onList = true;
  isProject = false; // PROJECT | LIBRARY
  isAdmin = false;
  editName = false;
  @ViewChild('editNameInput') editNameInput: ElementRef;
  name = '';
  form: UntypedFormGroup = new UntypedFormGroup({
    name: new UntypedFormControl(this.name, [Validators.required]),
  });

  currentWorkspace: Workspace = {};
  currentWorkspace$: Observable<Workspace>;
  public workspacePrincipals$: Observable<Array<WorkspacePrincipal>>;

  constructor(
    private store: Store<RootStoreState.State>,
    private router: Router,
    private authService: AuthService,
    private routingService: RoutingService,
    private matDialog: MatDialog,
  ) {
    this.router.events.pipe(filter((event: NavigationEnd) => event instanceof NavigationEnd)).subscribe((event) => {
      if (
        event?.urlAfterRedirects?.includes('/workspaces/list') ||
        event?.urlAfterRedirects?.includes('/libraries/list') ||
        event?.urlAfterRedirects?.includes('/workspaces/recents')
      ) {
        this.onList = true;
      } else {
        this.onList = false;
      }
    });
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log(`changes ${JSON.stringify(changes)}`);
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngOnInit() {
    this.isAdmin = this.authService.isAdmin();
    this.currentWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);
    this.currentWorkspace$.pipe(takeUntil(this.destroy$)).subscribe((workspace) => {
      this.isProject = workspace?.workspaceType === 'LIBRARY' ? false : true; // 'PROJECT'
      this.name = workspace?.name || '';
      if (workspace?.id && (!this.currentWorkspace?.id || this.currentWorkspace?.id !== workspace?.id)) {
        this.store.dispatch(WorkspacePrincipalsActions.loadWorkspacePrincipals());
      }
      this.currentWorkspace = workspace;
      this.form.get('name').setValue(this.name);
    });

    this.workspacePrincipals$ = combineLatest([
      this.store.select(UserOrgsSelectors.userOrgs), // dispatch userOrgs store in main.component.ts || firstValueFrom is null
      this.store.select(WorkspacePrincipalsSelectors.workspacePrincipals),
    ]).pipe(
      distinctUntilChanged(),
      map(([userOrgs, workspacePrincipals]) => {
        const principals = [];
        const admins = userOrgs.filter((u) => u.role === 'ADMIN');
        const adminIds = admins.map((a) => a.user.id);
        admins.forEach((admin) => {
          let newP: any = {};
          newP.principal = {
            name: admin?.userEmail,
            firstName: admin?.user?.firstName,
            lastName: admin?.user?.lastName,
            email: admin?.userEmail,
          };
          newP.principalType = 'User';
          principals.push(newP);
        });
        const wps = workspacePrincipals.filter((p) => !adminIds.includes(p?.principal?.id));
        wps.forEach((wp) => {
          const newPrinc: any = ObjectUtil.cloneDeep(wp);
          newPrinc.principal = {
            ...newPrinc?.principal,
            name: newPrinc.principal?.email || newPrinc.principal?.name,
          };
          newPrinc.principalType = newPrinc?.principalReference.indexOf('user') > -1 ? 'User' : 'Group';
          principals.push(newPrinc);
        });
        return principals;
      }),
    );
  }

  public goHome() {
    this.routingService.go('home');
  }

  share() {}

  startEditName() {
    if (this.editName) {
      return;
    }
    this.editName = true;
    setTimeout(() => {
      this.editNameInput.nativeElement.focus();
      this.form.get('name').setValue(this.name);
    }, 100);
  }

  endEditName() {
    this.editName = false;
    if (!this.form.valid) {
      return;
    }

    this.name = this.form.get('name').value;
    const changes: Workspace = { name: this.name };
    this.store.dispatch(WorkspacesActions.updateWorkspace({ id: this.currentWorkspace.id, changes }));
  }

  principalAdmin() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '800px';
    dialogConfig.data = {
      workspaceId: this.currentWorkspace.id,
    };
    const dialogRef = this.matDialog.open(WorkspacePrincipalsComponent, dialogConfig);
    dialogRef.afterClosed().subscribe((result) => {});
  }

  getBackgroundColor(id) {
    if (id) {
      return ColorUtil.stringToHslaColor(id);
    }
  }
}
