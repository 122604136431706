<div
  class="content-wrap flex flex-col"
  routerLinkActive="active"
  [routerLink]="'/org/' + authContext?.currentOrg?.orgSlug + '/workspaces/workspace/' + workspace?.id + '/'"
>
  <div (click)="gotoWorkspace(workspace?.id)" class="workspace-item">
    <img [src]="workspace.icon" class="w-5 h-5 object-contain mr-3" *ngIf="workspace?.icon" />
    <span [attr.data-test]="'side-bar-button-' + workspace?.name" class="line-ellipsis"> {{ workspace?.name }} </span>
  </div>
</div>
