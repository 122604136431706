import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { UserOrgsActions } from './user-orgs-store';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
})
export class MainComponent {
  constructor(
    private route: ActivatedRoute,
    private store: Store<RootStoreState.State>,
  ) {
    this.store.dispatch(UserOrgsActions.loadUserOrgs());
  }
}
