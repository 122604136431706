import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-copy-rename-entity-modal',
  template: `
    <app-modal [title]="title | titlecase" [dialogRef]="dialogRef">
      <app-copy-rename-entity-form
        [rename]="rename"
        [entityType]="entityType"
        (onFormSubmitted)="closeModal()"
      ></app-copy-rename-entity-form>
    </app-modal>
  `,
  styles: [],
})
export class CopyRenameEntityModalComponent implements OnInit {
  title: string;
  @Input() entityType: string; // workspace (folder) | board | plan | showroom | showcase
  @Input() rename: boolean = false; // copy / rename  => false / true;
  @Input() entityId: string;
  @Input() entityName: string;

  constructor(
    public dialogRef: MatDialogRef<CopyRenameEntityModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.entityId = data?.entityId;
    this.entityName = data?.entityName;
    this.rename = data?.rename;
    this.entityType = data?.entityType;
  }

  ngOnInit(): void {
    this.title = (this.rename ? `Rename ` : `Copy `) + this.entityType;
  }

  closeModal() {
    this.dialogRef.close();
  }
}
