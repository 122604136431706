import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { AssortmentsActions, AssortmentsSelectors } from '@common/assortments/assortments-store';
import { currentAssortment } from '@common/assortments/assortments-store/assortments.selectors';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom, mergeMap } from 'rxjs/operators';
import { Plan } from 'src/app/plans/plans-store/plans.state';
import { PlansActions, PlansSelectors, RootStoreState } from 'src/app/root-store';
import { DashboardsActions, DashboardsSelectors } from '.';
import { DashboardService } from '../dashboard-service';
import { Entities } from '@contrail/sdk';

@Injectable()
export class DashboardEffects {
  constructor(
    private actions$: Actions,
    private dashboardService: DashboardService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}
  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          this.store.dispatch(AssortmentsActions.setCurrentAssortment({ assortment: null }));
          this.store.dispatch(AssortmentsActions.loadAssortments());
        }),
      ),
    { dispatch: false },
  );
  currentAssortmentChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AssortmentsActions.AssortmentsActionTypes.SET_CURRENT_ASSORTMENT),
        withLatestFrom(this.store.select(PlansSelectors.plans)),
        mergeMap(async ([action, plans]: [any, Array<Plan>]) => {
          const assortment = action.assortment;
          if (!assortment) {
            this.store.dispatch(DashboardsActions.setCurrentAssortmentSummary({ currentAssortmentSummary: null }));
            this.store.dispatch(DashboardsActions.setCurrentAssortmentPlan({ currentAssortmentPlan: null }));
            this.store.dispatch(DashboardsActions.setLoading({ loading: false }));
            return;
          } else {
            this.store.dispatch(DashboardsActions.setCurrentAssortmentSummary({ currentAssortmentSummary: null }));
            this.store.dispatch(DashboardsActions.loadCurrentAssortmentHistory({ assortmentId: assortment.id }));
            if (plans) {
              const planWithoutSummary: Plan = plans.find((p) => p.targetAssortmentId === assortment.id);
              if (planWithoutSummary) {
                const plan = await new Entities().get({
                  entityName: 'plan',
                  id: planWithoutSummary.id,
                  relations: ['updatedBy', 'createdBy', 'targetAssortment', 'planSummary'],
                });
                this.store.dispatch(DashboardsActions.setCurrentAssortmentPlan({ currentAssortmentPlan: plan }));
              } else {
                this.store.dispatch(DashboardsActions.setCurrentAssortmentPlan({ currentAssortmentPlan: null }));
              }
            }
          }
        }),
      ),
    { dispatch: false },
  );
  plansLoad$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.LOAD_PLANS_SUCCESS),
        withLatestFrom(this.store.select(AssortmentsSelectors.currentAssortment)),
        mergeMap(async ([action, currentAssortment]: [any, any]) => {
          if (action.data && currentAssortment) {
            const plans = action.data;
            const planWithoutSummary: Plan = plans.find((p) => p.targetAssortmentId === currentAssortment.id);
            if (planWithoutSummary) {
              const plan = await new Entities().get({
                entityName: 'plan',
                id: planWithoutSummary.id,
                relations: ['updatedBy', 'createdBy', 'targetAssortment', 'planSummary'],
              });
              this.store.dispatch(DashboardsActions.setCurrentAssortmentPlan({ currentAssortmentPlan: plan }));
            }
          }
        }),
      ),
    { dispatch: false },
  );

  loadCurrentAssortmentHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(DashboardsActions.DashboardActionTypes.LOAD_CURRENT_ASSORTMENT_HISTORY),
      switchMap((action: any) => {
        this.store.dispatch(DashboardsActions.setLoading({ loading: true }));
        return from(this.dashboardService.getAssortmentHistory(action.assortmentId)).pipe(
          tap(() => this.store.dispatch(DashboardsActions.setLoading({ loading: false }))),
          tap((history) => {
            let summary;
            const sorted = history as Array<any>;
            sorted.sort((a, b) => (a.createdOn < b.createdOn ? 1 : -1));
            if (sorted.length) {
              const historyItem = sorted[0];
              summary = historyItem?.summary;
            }
            this.store.dispatch(DashboardsActions.setCurrentAssortmentSummary({ currentAssortmentSummary: summary }));
          }),
          map((data) => {
            return DashboardsActions.loadCurrentAssortmentHistorySuccess({ currentAssortmentHistory: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(DashboardsActions.loadCurrentAssortmentHistoryFailure({ error }));
          }),
        );
      }),
    ),
  );
}
