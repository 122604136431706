import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { WorkspacePrincipal } from './workspace-principal';

@Injectable({
  providedIn: 'root',
})
export class WorkspacePrincipalsService {
  constructor() {}

  public async getWorkspacePrincipals(workspaceId: string) {
    console.log('getWorkspacePrincipals: ', workspaceId);
    return new Entities().get({
      entityName: 'workspace-principal',
      criteria: { workspaceId },
      relations: ['principal'],
    });
  }
  public async getWorkspacePrincipalById(id: string) {
    return new Entities().get({ entityName: 'workspace-principal', id });
  }
  public async createWorkspacePrincipal(object: any) {
    return new Entities().create({ entityName: 'workspace-principal', object });
  }
  public async createWorkspacePrincipals(objects: any) {
    return new Entities().batchCreate({ entityName: 'workspace-principal', objects: objects });
  }
  public async deleteWorkspacePrincipal(workspacePrincipal: WorkspacePrincipal) {
    await new Entities().delete({ entityName: 'workspace-principal', id: workspacePrincipal.id });
    return workspacePrincipal;
  }
  public async deleteWorkspacePrincipals(ids: string[]) {
    return await new Entities().batchDelete({ entityName: 'workspace-principal', ids });
  }
  public async updateWorkspacePrincipal(id: string, changes: WorkspacePrincipal) {
    return new Entities().update({ entityName: 'workspace-principal', id, object: changes });
  }
}
