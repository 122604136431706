<div [attr.data-test]="propertyFormConfiguration?.typeProperty?.slug + '-form-field'">
  <app-type-property-form-field-text
    #formField
    *ngIf="stringPropertyTypes.includes(propertyType)"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-text>

  <app-type-property-form-field-select
    #formField
    *ngIf="propertyType === 'choice'"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-select>

  <app-type-property-form-field-color-chip
    #formField
    *ngIf="propertyType === 'multi_select' && propertyFormConfiguration.typeProperty.slug === 'colorHexCode'"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-color-chip>

  <app-type-property-form-field-multi-select
    #formField
    *ngIf="propertyType === 'multi_select' && propertyFormConfiguration.typeProperty.slug !== 'colorHexCode'"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-multi-select>

  <app-type-property-form-field-number
    #formField
    *ngIf="isNumberProperty()"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-number>

  <app-type-property-form-field-percent
    #formField
    *ngIf="isPercentProperty()"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-percent>

  <app-type-property-form-field-boolean
    #formField
    *ngIf="propertyType === 'boolean'"
    [value]="value"
    [entity]="entity"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-boolean>

  <app-type-property-form-field-date
    #formField
    *ngIf="propertyType === 'date'"
    [value]="value"
    [entity]="entity"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [hint]="hint"
    [errorMessage]="errorMessage"
  >
  </app-type-property-form-field-date>

  <app-type-property-form-field-type-reference
    #formField
    *ngIf="propertyType === 'type_reference'"
    [value]="value"
    [entity]="entity"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [hint]="hint"
    [errorMessage]="errorMessage"
  >
  </app-type-property-form-field-type-reference>

  <app-type-property-form-field-object-reference
    #formField
    *ngIf="propertyType === 'object_reference'"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-object-reference>

  <app-type-property-form-field-size-range
    #formField
    *ngIf="propertyType === 'size_range'"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-size-range>

  <app-type-property-form-field-user-list
    #formField
    *ngIf="propertyType === 'userList'"
    [entity]="entity"
    [value]="value"
    [propertyFormConfiguration]="propertyFormConfiguration"
    (valueChange)="handleChange($event)"
    [appearance]="appearance"
    [errorMessage]="errorMessage"
    [hint]="hint"
  >
  </app-type-property-form-field-user-list>
  <div class="hint">{{ hint }}</div>
</div>
