import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Board {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  name?: string;
  workspaceId?: string;
  previewFile?: any;
  isTrashed?: boolean;
}

interface BoardsState extends EntityState<Board> {}

export interface State {
  boards: BoardsState;
  currentBoard: Board;
  boardsLoaded: boolean;
}
export const boardsEntityAdapter: EntityAdapter<Board> = createEntityAdapter<Board>({});

export const boardsInitialState = {
  boards: boardsEntityAdapter.getInitialState({}),
  currentBoard: null,
  boardsLoaded: false,
};
