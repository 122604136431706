import { State } from '../collection-manager.state';
import {
  CollectionViewDefinition,
  collectionViewsEntityAdapter,
  localCollectionViewsEntityAdapter,
} from './collection-views.state';
import { on } from '@ngrx/store';
import * as CollectionViewsActions from './collection-views.actions';
import { ViewDefinition } from '@contrail/client-views';

const setData = (state: State, { data }) => {
  return {
    ...state,
    viewDefinitions: collectionViewsEntityAdapter.setAll(data, state.viewDefinitions),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    viewDefinitions: collectionViewsEntityAdapter.removeAll(state.viewDefinitions),
  };
};
const add = (state: State, { viewDefinition }) => {
  return {
    ...state,
    viewDefinitions: collectionViewsEntityAdapter.addOne(viewDefinition, state.viewDefinitions),
  };
};
const addMany = (state: State, { viewDefinitions }) => {
  return {
    ...state,
    viewDefinitions: collectionViewsEntityAdapter.addMany(viewDefinitions, state.viewDefinitions),
  };
};
const update = (state: State, { id, changes }) => {
  // refresh the current viewDefinition is current
  let currentViewDefinition = state.currentViewDefinition;
  if (state.currentViewDefinition?.id === id) {
    currentViewDefinition = { ...state.currentViewDefinition, ...changes };
  }
  return {
    ...state,
    viewDefinitions: collectionViewsEntityAdapter.updateOne({ id, changes }, state.viewDefinitions),
    currentViewDefinition,
  };
};
const remove = (state: State, { viewDefinition }) => {
  return {
    ...state,
    viewDefinitions: collectionViewsEntityAdapter.removeOne(viewDefinition.id, state.viewDefinitions),
  };
};
const setCurrentViewDefinition = (state: State, { viewDefinition }) => {
  return {
    ...state,
    currentViewDefinition: viewDefinition,
  };
};

const updateViewDefs = (state: State, { updatedViewDefs }) => {
  return {
    ...state,
    viewDefinitions: collectionViewsEntityAdapter.updateMany(
      updatedViewDefs.map((viewDef) => Object.assign({}, { id: viewDef.id, changes: viewDef })),
      state.viewDefinitions,
    ),
  };
};

const setViewDefinitionsWorking = (state: State, { working }) => {
  return {
    ...state,
    viewDefinitionsWorking: working,
  };
};

const setLocalViewDefinitions = (
  state: State,
  { viewDefinitions }: { viewDefinitions: CollectionViewDefinition[] },
) => {
  return {
    ...state,
    localViewDefinitions: localCollectionViewsEntityAdapter.setAll(viewDefinitions, state.localViewDefinitions),
  };
};

const updateLocalViewDefinition = (
  state: State,
  { id, changes }: { id: string; changes: CollectionViewDefinition },
) => {
  const updatedState = {
    ...state,
    localViewDefinitions: localCollectionViewsEntityAdapter.updateOne({ id, changes }, state.localViewDefinitions),
  };

  const currentViewDefinition = state.currentViewDefinition;
  const isUpdatingCurrentView = currentViewDefinition?.id === id;
  if (isUpdatingCurrentView) {
    updatedState.currentViewDefinition = { ...currentViewDefinition, ...changes };
  }

  return updatedState;
};

export const collectionViewsReducers = [
  on(CollectionViewsActions.setCurrentViewDefinitionSuccess, setCurrentViewDefinition),
  on(CollectionViewsActions.loadViewDefinitionsSuccess, setData),
  on(CollectionViewsActions.createViewDefinitionsSuccess, addMany),
  on(CollectionViewsActions.deleteViewDefinitionSuccess, remove),
  on(CollectionViewsActions.updateViewDefinitionSuccess, update),
  on(CollectionViewsActions.loadCurrentViewDefinitionSuccess, setCurrentViewDefinition),
  on(CollectionViewsActions.updateViewOrderSuccess, updateViewDefs),
  on(CollectionViewsActions.applyViewDefinitionChangesSuccess, update),
  on(CollectionViewsActions.setViewDefinitionsWorking, setViewDefinitionsWorking),
  on(CollectionViewsActions.setLocalViewDefinitionsSuccess, setLocalViewDefinitions),
  on(CollectionViewsActions.updateLocalViewDefinitionSuccess, updateLocalViewDefinition),
];
