import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface Showcase {
  id?: string;
  isTemplate?: boolean;
  isArchived?: boolean;
  createdOn?: Date;
  updatedOn?: Date;
  createdBy?: any;
  updatedBy?: any;
  name?: string;
  workspaceId?: string;
  previewFile?: any;
  coverPhoto?: string;
  sourceAssortment?: any;
  workspace?: any;
  isTrashed?: boolean;
}

interface ShowcasesState extends EntityState<Showcase> {}

export interface State {
  showcases: ShowcasesState;
  currentShowcase: Showcase;
  showcasesLoaded: boolean;
}
export const showcasesEntityAdapter: EntityAdapter<Showcase> = createEntityAdapter<Showcase>({});

export const showcasesInitialState = {
  showcases: showcasesEntityAdapter.getInitialState({}),
  currentShowcase: null,
  showcasesLoaded: false,
};
