import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import {
  ViewDefinition,
  ViewPropertyAggregateFunction,
  ViewPropertyConfiguration,
  ViewPropertyPivotType,
} from '@contrail/client-views';
import { PropertyType, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { AGGREGATE_VALUE_PROPERTY_TYPES, ELIGIBLE_GROUP_PROPERTY_TYPES } from '../pivot-grid.constants';
import { PivotProperty } from '../pivot-grid.interfaces';
import { PivotAggregateHelper } from '../pivot-aggregate-helper';
import { PivotConfiguratorHelper } from './pivot-configurator-helper';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { ChangeObject } from '@common/entities/entities.interfaces';

interface TypePropertyWithDefinedRoot extends TypeProperty {
  typeRootSlug?: string;
}

@Component({
  selector: 'app-pivot-view-configurator',
  templateUrl: './pivot-view-configurator.component.html',
  styleUrls: ['./pivot-view-configurator.component.scss'],
})
export class PivotViewConfiguratorComponent implements OnInit, OnDestroy, OnChanges {
  @Input() typeDefinition: Type;
  @Input() viewDefinition: ViewDefinition;
  @Output() close = new EventEmitter();
  @Output() viewDefinitionUpdated = new EventEmitter<ChangeObject<ViewDefinition>>();

  public allRowGroupingProperties: PivotProperty[] = [];
  public selectedRowGroupingProperties: PivotProperty[] = [];

  public allColumnGroupingProperties: PivotProperty[] = [];
  public selectedColumnGroupingProperties: PivotProperty[] = [];

  public allValueProperties: PivotProperty[];
  public selectedValueProperties: PivotProperty[] = [];
  public readonly PIVOT_TYPE = ViewPropertyPivotType;
  private readonly DEFAULT_TYPE_ROOT_SLUG = 'plan-placeholder';

  constructor(private store: Store<RootStoreState.State>) {}

  async ngOnInit(): Promise<void> {
    this.setProperties();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.viewDefinition) {
      this.setCurrentProperties(this.viewDefinition);
    }
  }

  ngOnDestroy() {}

  setCurrentProperties(viewDefinition: ViewDefinition): any {
    if (!viewDefinition.properties?.length) {
      this.selectedRowGroupingProperties = [];
      this.selectedColumnGroupingProperties = [];
      this.selectedValueProperties = [];
      return;
    }

    this.selectedRowGroupingProperties = viewDefinition.properties
      .filter((property) => this.isValidRowGroupingProperty(property))
      .map((property) => {
        return {
          value: property.slug,
          label: property.propertyDefinition.label,
          propertyType: property.propertyDefinition.propertyType,
          pivotType: ViewPropertyPivotType.ROW,
        };
      });

    this.selectedColumnGroupingProperties = viewDefinition.properties
      .filter((property) => this.isValidColumnGroupingProperty(property))
      .map((property) => {
        return {
          value: property.slug,
          label: property.propertyDefinition.label,
          propertyType: property.propertyDefinition.propertyType,
          pivotType: ViewPropertyPivotType.COLUMN,
        };
      });

    this.selectedValueProperties = viewDefinition.properties
      .filter((property) => this.isValidValueProperty(property))
      .map((property) => {
        const propertyType = property.propertyDefinition?.propertyType || PropertyType.Number;
        return {
          value: property.slug,
          label: property.propertyDefinition?.label || 'Count',
          propertyType,
          aggregateFunction:
            property.aggregateFunction || PivotAggregateHelper.getDefaultAggregateFunctionForPropertyType(propertyType),
          pivotType: ViewPropertyPivotType.VALUE,
        };
      });
  }

  private isValidRowGroupingProperty(property: ViewPropertyConfiguration) {
    return PivotConfiguratorHelper.isRowGroupingProperty(property) && property.enabled && property.propertyDefinition;
  }

  private isValidColumnGroupingProperty(property: ViewPropertyConfiguration) {
    return (
      PivotConfiguratorHelper.isColumnGroupingProperty(property) && property.enabled && property.propertyDefinition
    );
  }

  private isValidValueProperty(property: ViewPropertyConfiguration) {
    return PivotConfiguratorHelper.isValueProperty(property) && property.enabled && property.propertyDefinition;
  }

  private setProperties() {
    const allValueProperties = this.typeDefinition.typeProperties
      .filter((property) => AGGREGATE_VALUE_PROPERTY_TYPES.includes(property.propertyType))
      .filter((property) => property.slug !== 'createdOn' && property.slug !== 'updatedOn')
      .map((property) => {
        return {
          value: property.slug,
          label: property.label,
          propertyType: property.propertyType,
          pivotType: ViewPropertyPivotType.VALUE,
        };
      });

    const rowGroupProperties = this.typeDefinition.typeProperties
      .filter((property) => ELIGIBLE_GROUP_PROPERTY_TYPES.includes(property.propertyType))
      .filter((property) => property.slug !== 'createdOn' && property.slug !== 'updatedOn')
      .map((property) => {
        return {
          value: property.slug,
          label: property.label,
          propertyType: property.propertyType,
          pivotType: ViewPropertyPivotType.ROW,
        };
      });

    const columnGroupProperties = rowGroupProperties.map((property) => {
      const columnProperty = ObjectUtil.cloneDeep(property);
      columnProperty.pivotType = ViewPropertyPivotType.COLUMN;
      return columnProperty;
    });

    this.allRowGroupingProperties = rowGroupProperties;
    this.allColumnGroupingProperties = columnGroupProperties;
    this.allValueProperties = allValueProperties;
  }

  updateProperties(selectedProperties: PivotProperty[], type: ViewPropertyPivotType) {
    const newViewPropertyConfigurations: ViewPropertyConfiguration[] = [];
    const isUpdatingListOfGroupProperties = [ViewPropertyPivotType.ROW, ViewPropertyPivotType.COLUMN].includes(type);

    for (const property of selectedProperties) {
      const propertyDefinition = this.findOrBuildTypeProperty(property);
      const newPropertyConfig: ViewPropertyConfiguration = {
        enabled: true,
        frozen: isUpdatingListOfGroupProperties,
        propertyDefinition,
        slug: propertyDefinition.slug,
        typeRootSlug: propertyDefinition.typeRootSlug ?? this.DEFAULT_TYPE_ROOT_SLUG,
        width: 150,
        pivotType: type,
      };

      if (!isUpdatingListOfGroupProperties) {
        const aggregate =
          property.aggregateFunction ??
          PivotAggregateHelper.getDefaultAggregateFunctionForPropertyType(property.propertyType);

        newPropertyConfig.aggregateFunction = aggregate;
        property.aggregateFunction = aggregate;
      }

      newViewPropertyConfigurations.push(newPropertyConfig);
    }

    if (type === ViewPropertyPivotType.ROW) {
      this.selectedRowGroupingProperties = ObjectUtil.cloneDeep(selectedProperties);
    }

    if (type === ViewPropertyPivotType.COLUMN) {
      this.selectedColumnGroupingProperties = ObjectUtil.cloneDeep(selectedProperties);
    }

    if (type === ViewPropertyPivotType.VALUE) {
      this.selectedValueProperties = ObjectUtil.cloneDeep(selectedProperties);
    }

    const updatedViewProperties = this.buildUpdatedViewProperties(newViewPropertyConfigurations, type);
    this.updateViewDefinition(this.viewDefinition, { properties: updatedViewProperties });
  }

  updateSortProperties(event) {
    let updateView = false;
    const newSort = {
      direction: event.direction,
      propertyType: event.property.propertyType,
      propertyLabel: event.property.label,
      propertySlug: event.property.value,
    };
    const updatedViewDef = ObjectUtil.cloneDeep(this.viewDefinition);
    updatedViewDef.sorts = updatedViewDef.sorts || [];
    const index = updatedViewDef.sorts.findIndex((sort) => sort.propertySlug === newSort.propertySlug);
    if (index > -1) {
      if (updatedViewDef.sorts[index].direction !== newSort.direction) {
        updatedViewDef.sorts.splice(index, 1, newSort);
        updateView = true;
      }
    } else {
      updatedViewDef.sorts.push(newSort);
      updateView = true;
    }
    if (updateView) {
      this.updateViewDefinition(this.viewDefinition, { sorts: updatedViewDef.sorts });
    }
  }

  updatePropertyAggregateFunction(event: {
    property: PivotProperty;
    functionType: ViewPropertyAggregateFunction;
    index: number;
  }) {
    const { property, functionType, index } = event;

    if (property.pivotType !== ViewPropertyPivotType.VALUE) {
      return;
    }

    const currentValueProperty = this.selectedValueProperties[index];
    currentValueProperty.aggregateFunction = functionType;

    const viewProperties: ViewPropertyConfiguration[] = ObjectUtil.cloneDeep(this.viewDefinition.properties);
    const valueViewProperties = viewProperties.filter((viewProperty) => this.isValidValueProperty(viewProperty));
    valueViewProperties[index].aggregateFunction = functionType;

    this.updateViewDefinition(this.viewDefinition, { properties: viewProperties });
  }

  async updateViewDefinition(viewDefinition: ViewDefinition, changes: any) {
    this.viewDefinitionUpdated.emit({ id: viewDefinition.id, changes });
  }

  private findOrBuildTypeProperty(property: PivotProperty): TypePropertyWithDefinedRoot {
    const existingTypeProperty = this.typeDefinition.typeProperties.find(
      (typeProperty) => typeProperty.slug === property.value,
    );

    if (!existingTypeProperty) {
      return {
        slug: property.value,
        label: property.label,
        propertyType: property.propertyType,
        typeRootSlug: property.typeRootSlug,
      };
    }

    return existingTypeProperty;
  }

  private buildUpdatedViewProperties(
    newViewProperties: ViewPropertyConfiguration[],
    type: ViewPropertyPivotType,
  ): ViewPropertyConfiguration[] {
    const allExistingProperties: ViewPropertyConfiguration[] = ObjectUtil.cloneDeep(this.viewDefinition.properties);

    const allPropertiesNotOfMatchingType = allExistingProperties.filter((property) => {
      if (type === ViewPropertyPivotType.ROW) {
        return !PivotConfiguratorHelper.isRowGroupingProperty(property);
      }

      if (type === ViewPropertyPivotType.COLUMN) {
        return !PivotConfiguratorHelper.isColumnGroupingProperty(property);
      }

      if (type === ViewPropertyPivotType.VALUE) {
        return !PivotConfiguratorHelper.isValueProperty(property);
      }

      return false;
    });

    const updatedPropertiesList: ViewPropertyConfiguration[] = [];

    if (type === ViewPropertyPivotType.ROW) {
      updatedPropertiesList.push(...newViewProperties, ...allPropertiesNotOfMatchingType);
    }

    if (type === ViewPropertyPivotType.COLUMN) {
      const indexOfLastFrozenProperty = allExistingProperties.findIndex((prop) => prop.frozen) + 1;
      updatedPropertiesList.push(
        ...allPropertiesNotOfMatchingType.slice(0, indexOfLastFrozenProperty),
        ...newViewProperties,
        ...allPropertiesNotOfMatchingType.slice(indexOfLastFrozenProperty),
      );
    }

    if (type === ViewPropertyPivotType.VALUE) {
      updatedPropertiesList.push(...allPropertiesNotOfMatchingType, ...newViewProperties);
    }

    const isFirstPropertyFrozen = updatedPropertiesList.length > 0 && updatedPropertiesList[0].frozen;
    if (isFirstPropertyFrozen) {
      const currentWidth = allExistingProperties?.[0]?.width;
      const wasNewRowPropertyAdded = newViewProperties.some(
        (viewProperty) => viewProperty.pivotType === ViewPropertyPivotType.ROW,
      );
      const rowGroupProperties = wasNewRowPropertyAdded
        ? updatedPropertiesList.filter((viewProperty) => this.isValidRowGroupingProperty(viewProperty))
        : null;

      const widthOfFirstProperty = this.getWidthOfFirstProperty({
        currentWidth,
        rowGroupProperties,
      });

      updatedPropertiesList[0].width = widthOfFirstProperty;
    }

    return updatedPropertiesList;
  }

  private getWidthOfFirstProperty(context: { rowGroupProperties: ViewPropertyConfiguration[]; currentWidth?: number }) {
    const { rowGroupProperties, currentWidth } = context;

    if (rowGroupProperties) {
      return this.calculateWidthOfRowGroupingLabel(rowGroupProperties);
    }

    return currentWidth ?? 150;
  }

  private calculateWidthOfRowGroupingLabel(rowGroupProperties: ViewPropertyConfiguration[]): number {
    const numberOfRowProperties = rowGroupProperties.length;
    const totalLengthOfLabels = rowGroupProperties.reduce(
      (totalLength, property) => totalLength + (property?.propertyDefinition?.label?.length || 0),
      0,
    );

    const widthOfBreakBetweenLabelsInPx = 16.44;
    const widthOfCharacterInPx = 6.6;
    const widthOfLabelBreaks = (numberOfRowProperties - 1) * widthOfBreakBetweenLabelsInPx;
    const widthOfLabels = totalLengthOfLabels * widthOfCharacterInPx;
    const minLabelWidth = 100 + widthOfLabels + widthOfLabelBreaks;

    const defaultWidth = (numberOfRowProperties > 1 ? 100 : 150) * numberOfRowProperties;
    return Math.max(minLabelWidth, defaultWidth);
  }
}
