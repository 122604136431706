import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WorkspaceSelectorComponent } from './workspace-selector/workspace-selector.component';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { WorkspaceFolderNavigationComponent } from './workspace-folder-navigation/workspace-folder-navigation.component';
import { WorkspaceFolderNavigationFolderComponent } from './workspace-folder-navigation/workspace-folder-navigation-folder/workspace-folder-navigation-folder.component';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { PipesModule } from '@common/pipes/pipes.module';

@NgModule({
  declarations: [
    WorkspaceSelectorComponent,
    WorkspaceFolderNavigationComponent,
    WorkspaceFolderNavigationFolderComponent,
  ],
  imports: [CommonModule, PipesModule, MatMenuModule, MatIconModule, MatTooltipModule],
  exports: [WorkspaceFolderNavigationComponent],
})
export class WorkspacesModule {}
