import { State } from './user-orgs.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as UserOrgsActions from './user-orgs.actions';
import { userOrgsEntityAdapter } from './user-orgs.state';
import { userOrgsInitialState } from './user-orgs.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    userOrgs: userOrgsEntityAdapter.setAll(data, state.userOrgs),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    userOrgs: userOrgsEntityAdapter.removeAll(state.userOrgs),
  };
};
const add = (state: State, { userOrg }) => {
  return {
    ...state,
    userOrgs: userOrgsEntityAdapter.addOne(userOrg, state.userOrgs),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    userOrgs: userOrgsEntityAdapter.updateOne({ id, changes }, state.userOrgs),
  };
};
const remove = (state: State, { userOrg }) => {
  return {
    ...state,
    userOrgs: userOrgsEntityAdapter.removeOne(userOrg.id, state.userOrgs),
  };
};
const batchRemove = (state: State, { ids }) => {
  return {
    ...state,
    userOrgs: userOrgsEntityAdapter.removeMany(ids, state.userOrgs),
  };
};
const setCurrentUserOrg = (state: State, { userOrg }) => {
  return {
    ...state,
    currentUserOrg: userOrg,
  };
};

export const userOrgsReducers = createReducer(
  userOrgsInitialState,
  on(UserOrgsActions.loadUserOrgsSuccess, setData),
  on(UserOrgsActions.createUserOrgSuccess, add),
  on(UserOrgsActions.deleteUserOrgSuccess, remove),
  on(UserOrgsActions.deleteUserOrgsSuccess, batchRemove),
  on(UserOrgsActions.updateUserOrgSuccess, update),
  on(UserOrgsActions.loadCurrentUserOrgSuccess, setCurrentUserOrg),
);

export function reducer(state: State | undefined, action: Action): any {
  return userOrgsReducers(state, action);
}
