<div class="flex flex-wrap">
  <ng-container *ngFor="let itemData of listData; index as idx">
    <app-explorer-card
      [rootWorkspaceType]="rootWorkspaceType"
      [cardData]="itemData"
      [multiSelect]="multiSelect"
      [recents]="recents"
      [class.selected]="itemData?.selected"
      [class.droppable]="itemData?.isDropTarget"
      [class.dragging]="draggingSelected && itemData?.selected"
      [id]="itemData?.entity?.id"
      [draggable]="draggable"
      (dragstart)="handleDragStart($event, itemData, idx)"
      (dragend)="handleDragEnd($event, itemData)"
      (dragenter)="handleDragEnter($event, itemData)"
      (dragleave)="handleDragLeave($event, itemData)"
      (dragover)="handleDragOver($event)"
      (drop)="handleDrop($event, itemData)"
      (click)="handleClickRow($event, itemData, idx)"
      (checkClick)="checkBoxClick($event, itemData, idx)"
      (dblclick)="handleDoubleClickRow(itemData)"
      (contextmenu)="handleContextMenu($event, itemData)"
      (openMenu)="cardActionBtnClick($event, itemData)"
    ></app-explorer-card>
  </ng-container>
</div>
