import { createAction, props } from '@ngrx/store';
import { WorkspacePrincipal } from '@common/workspaces/workspace-principal';

export enum FolderPrincipalsActionTypes {
  LOAD_FOLDER_PRINCIPALS = '[FolderPrincipals] Load FolderPrincipals',
  LOAD_FOLDER_PRINCIPALS_SUCCESS = '[FolderPrincipals] Load FolderPrincipals Success',
  LOAD_FOLDER_PRINCIPALS_FAILURE = '[FolderPrincipals] Load FolderPrincipals Failure',

  CREATE_FOLDER_PRINCIPAL = '[FolderPrincipals] Create FolderPrincipal',
  CREATE_FOLDER_PRINCIPAL_SUCCESS = '[FolderPrincipals] Create FolderPrincipal Success',
  CREATE_FOLDER_PRINCIPAL_FAILURE = '[FolderPrincipals] Create FolderPrincipal Failure',

  CREATE_FOLDER_PRINCIPALS = '[FolderPrincipals] Create FolderPrincipals',
  CREATE_FOLDER_PRINCIPALS_SUCCESS = '[FolderPrincipals] Create FolderPrincipals Success',
  CREATE_FOLDER_PRINCIPALS_FAILURE = '[FolderPrincipals] Create FolderPrincipals Failure',

  DELETE_FOLDER_PRINCIPAL = '[FolderPrincipals] Delete FolderPrincipal',
  DELETE_FOLDER_PRINCIPAL_SUCCESS = '[FolderPrincipals] Delete FolderPrincipal Success',
  DELETE_FOLDER_PRINCIPAL_FAILURE = '[FolderPrincipals] Delete FolderPrincipal Failure',

  DELETE_FOLDER_PRINCIPALS = '[FolderPrincipals] Delete FolderPrincipals',
  DELETE_FOLDER_PRINCIPALS_SUCCESS = '[FolderPrincipals] Delete FolderPrincipals Success',
  DELETE_FOLDER_PRINCIPALS_FAILURE = '[FolderPrincipals] Delete FolderPrincipals Failure',

  UPDATE_FOLDER_PRINCIPAL = '[FolderPrincipals] Update FolderPrincipal',
  UPDATE_FOLDER_PRINCIPAL_SUCCESS = '[FolderPrincipals] Update FolderPrincipal Success',
  UPDATE_FOLDER_PRINCIPAL_FAILURE = '[FolderPrincipals] Update FolderPrincipal Failure',

  LOAD_CURRENT_FOLDER_PRINCIPAL = '[FolderPrincipals] Load Current FolderPrincipal',
  LOAD_CURRENT_FOLDER_PRINCIPAL_SUCCESS = '[FolderPrincipals] Load Current FolderPrincipal Success',
  LOAD_CURRENT_FOLDER_PRINCIPAL_FAILURE = '[FolderPrincipals] Load Current FolderPrincipal Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadFolderPrincipals = createAction(FolderPrincipalsActionTypes.LOAD_FOLDER_PRINCIPALS);

export const loadFolderPrincipalsSuccess = createAction(
  FolderPrincipalsActionTypes.LOAD_FOLDER_PRINCIPALS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadFolderPrincipalsFailure = createAction(
  FolderPrincipalsActionTypes.LOAD_FOLDER_PRINCIPALS_FAILURE,
  props<{ error: any }>(),
);

export const createFolderPrincipal = createAction(
  FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPAL,
  props<{ principalReference: string; role: string }>(),
);

export const createFolderPrincipalSuccess = createAction(
  FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPAL_SUCCESS,
  props<{ folderPrincipal: WorkspacePrincipal }>(),
);

export const createFolderPrincipalFailure = createAction(
  FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);

export const createFolderPrincipals = createAction(
  FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPALS,
  props<{ objects: any }>(),
);

export const createFolderPrincipalsSuccess = createAction(
  FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPALS_SUCCESS,
  props<{ data: any }>(),
);

export const createFolderPrincipalsFailure = createAction(
  FolderPrincipalsActionTypes.CREATE_FOLDER_PRINCIPALS_FAILURE,
  props<{ error: any }>(),
);

export const updateFolderPrincipal = createAction(
  FolderPrincipalsActionTypes.UPDATE_FOLDER_PRINCIPAL,
  props<{ id: string; changes: WorkspacePrincipal }>(),
);

export const updateFolderPrincipalSuccess = createAction(
  FolderPrincipalsActionTypes.UPDATE_FOLDER_PRINCIPAL_SUCCESS,
  props<{ id: string; changes: WorkspacePrincipal }>(),
);

export const updateFolderPrincipalFailure = createAction(
  FolderPrincipalsActionTypes.UPDATE_FOLDER_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);

export const deleteFolderPrincipal = createAction(
  FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPAL,
  props<{ folderPrincipal: WorkspacePrincipal }>(),
);

export const deleteFolderPrincipalSuccess = createAction(
  FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPAL_SUCCESS,
  props<{ folderPrincipal: WorkspacePrincipal }>(),
);

export const deleteFolderPrincipalFailure = createAction(
  FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);

export const deleteFolderPrincipals = createAction(
  FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPALS,
  props<{ ids: string[] }>(),
);

export const deleteFolderPrincipalsSuccess = createAction(
  FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPALS_SUCCESS,
  props<{ data: any }>(),
);

export const deleteFolderPrincipalsFailure = createAction(
  FolderPrincipalsActionTypes.DELETE_FOLDER_PRINCIPALS_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentFolderPrincipal = createAction(
  FolderPrincipalsActionTypes.LOAD_CURRENT_FOLDER_PRINCIPAL,
  props<{ id: string }>(),
);

export const loadCurrentFolderPrincipalSuccess = createAction(
  FolderPrincipalsActionTypes.LOAD_CURRENT_FOLDER_PRINCIPAL_SUCCESS,
  props<{ folderPrincipal: WorkspacePrincipal }>(),
);

export const loadCurrentFolderPrincipalFailure = createAction(
  FolderPrincipalsActionTypes.LOAD_CURRENT_FOLDER_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);
