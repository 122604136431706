import { Injectable } from '@angular/core';
import { AuthService } from '../auth/auth.service';

export interface AnalyticsEvent {
  eventName: string;
  eventCategory?: string;
  eventLabel?: string;
  eventValue?: number;
  eventTarget?: string;
}
export interface EventService {
  emitEvent: (event: AnalyticsEvent) => void;
  setActiveUser: () => void;
}

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  constructor(private authService: AuthService) {
    this.authService.authContext.subscribe((context) => {
      if (context && context.user) {
        this.setActiveUser();
      }
    });
  }

  public registerService(service: EventService) {
    this.services.push(service);
  }
  private services: Array<EventService> = [];

  public emitEvent(event: AnalyticsEvent) {
    this.services.map((service) => {
      service.emitEvent(event);
    });
  }

  public async setActiveUser() {
    this.services.map((service) => {
      service.setActiveUser();
    });
  }

  public init() {}
}
