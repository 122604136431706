import { ViewDefinitionApplicationViewSlug } from '@common/collection-manager/collection-manager-view.service';
import { ViewDefinition } from '@contrail/client-views';
import { createAction, props } from '@ngrx/store';
import { CollectionViewDefinition } from './collection-views.state';

export enum CollectionViewsActionTypes {
  LOAD_VIEW_DEFINITIONS = '[ViewDefinitions] Load ViewDefinitions',
  LOAD_VIEW_DEFINITIONS_SUCCESS = '[ViewDefinitions] Load ViewDefinitions Success',
  LOAD_VIEW_DEFINITIONS_FAILURE = '[ViewDefinitions] Load ViewDefinitions Failure',

  CREATE_VIEW_DEFINITIONS = '[ViewDefinitions] Create ViewDefinitions',
  CREATE_VIEW_DEFINITIONS_SUCCESS = '[ViewDefinitions] Create ViewDefinitions Success',
  CREATE_VIEW_DEFINITIONS_FAILURE = '[ViewDefinitions] Create ViewDefinitions Failure',

  DELETE_VIEW_DEFINITION = '[ViewDefinitions] Delete ViewDefinition',
  DELETE_VIEW_DEFINITION_SUCCESS = '[ViewDefinitions] Delete ViewDefinition Success',
  DELETE_VIEW_DEFINITION_FAILURE = '[ViewDefinitions] Delete ViewDefinition Failure',

  UPDATE_VIEW_DEFINITION = '[ViewDefinitions] Update ViewDefinition',
  UPDATE_VIEW_DEFINITION_SUCCESS = '[ViewDefinitions] Update ViewDefinition Success',
  UPDATE_VIEW_DEFINITION_FAILURE = '[ViewDefinitions] Update ViewDefinition Failure',

  LOAD_CURRENT_VIEW_DEFINITION = '[ViewDefinitions] Load Current ViewDefinition',
  LOAD_CURRENT_VIEW_DEFINITION_SUCCESS = '[ViewDefinitions] Load Current ViewDefinition Success',
  LOAD_CURRENT_VIEW_DEFINITION_FAILURE = '[ViewDefinitions] Load Current ViewDefinition Failure',

  CLEAR_CURRENT_VIEW_DEFINITION = '[ViewDefinitions] Clear Current ViewDefinition',
  SET_CURRENT_VIEW_DEFINITION = '[ViewDefinitions] Set Current ViewDefinition',
  SET_CURRENT_VIEW_DEFINITION_SUCCESS = '[ViewDefinitions] Set Current ViewDefinition Success',

  UPDATE_VIEW_ORDER = '[ViewDefinitions] Update View Order',
  UPDATE_VIEW_ORDER_SUCCESS = '[ViewDefinitions] Update View Order Success',
  UPDATE_VIEW_ORDER_FAILURE = '[ViewDefinitions] Update View Order Failure',

  APPLY_VIEW_DEFINITION_CHANGES = '[CollectionManager] Apply View Definition Changes',
  APPLY_VIEW_DEFINITION_CHANGES_SUCCESS = '[CollectionManager] Apply View Definition Changes Success',

  SET_VIEW_DEFINITIONS_WORKING = '[CollectionManager] Set View Defintions Working',

  SET_LOCAL_VIEW_DEFINITIONS = '[CollectionManager] Set Local View Definitions',
  SET_LOCAL_VIEW_DEFINITIONS_SUCCESS = '[CollectionManager] Set Local View Definitions Success',
  UPDATE_LOCAL_VIEW_DEFINITION = '[CollectionManager] Update Local View Definition',
  UPDATE_LOCAL_VIEW_DEFINITION_SUCCESS = '[CollectionManager] Update Local View Definition Success',
}

////////////////////////////////////////////////////////////////////////////////
export const applyViewDefinitionChanges = createAction(
  CollectionViewsActionTypes.APPLY_VIEW_DEFINITION_CHANGES,
  props<{ id: string; changes: ViewDefinition }>(),
);

export const applyViewDefinitionChangesSuccess = createAction(
  CollectionViewsActionTypes.APPLY_VIEW_DEFINITION_CHANGES_SUCCESS,
  props<{ id: string; changes: ViewDefinition }>(),
);

export const loadViewDefinitions = createAction(
  CollectionViewsActionTypes.LOAD_VIEW_DEFINITIONS,
  props<{ contextReference: string; applicationViewSlug: ViewDefinitionApplicationViewSlug }>(),
);

export const loadViewDefinitionsSuccess = createAction(
  CollectionViewsActionTypes.LOAD_VIEW_DEFINITIONS_SUCCESS,
  props<{ data: Array<any>; applicationViewSlug: ViewDefinitionApplicationViewSlug }>(),
);

export const loadViewDefinitionsFailure = createAction(
  CollectionViewsActionTypes.LOAD_VIEW_DEFINITIONS_FAILURE,
  props<{ error: any }>(),
);

export const createViewDefinitions = createAction(
  CollectionViewsActionTypes.CREATE_VIEW_DEFINITIONS,
  props<{ viewDefinitions: Array<ViewDefinition> }>(),
);

export const createViewDefinitionsSuccess = createAction(
  CollectionViewsActionTypes.CREATE_VIEW_DEFINITIONS_SUCCESS,
  props<{ viewDefinitions: Array<ViewDefinition> }>(),
);

export const createViewDefinitionsFailure = createAction(
  CollectionViewsActionTypes.CREATE_VIEW_DEFINITIONS_FAILURE,
  props<{ error: any }>(),
);

export const updateViewDefinition = createAction(
  CollectionViewsActionTypes.UPDATE_VIEW_DEFINITION,
  props<{ id: string; changes: ViewDefinition }>(),
);

export const updateViewDefinitionSuccess = createAction(
  CollectionViewsActionTypes.UPDATE_VIEW_DEFINITION_SUCCESS,
  props<{ id: string; changes: ViewDefinition }>(),
);

export const updateViewDefinitionFailure = createAction(
  CollectionViewsActionTypes.UPDATE_VIEW_DEFINITION_FAILURE,
  props<{ error: any }>(),
);

export const deleteViewDefinition = createAction(
  CollectionViewsActionTypes.DELETE_VIEW_DEFINITION,
  props<{ viewDefinition: ViewDefinition }>(),
);

export const deleteViewDefinitionSuccess = createAction(
  CollectionViewsActionTypes.DELETE_VIEW_DEFINITION_SUCCESS,
  props<{ viewDefinition: ViewDefinition }>(),
);

export const deleteViewDefinitionFailure = createAction(
  CollectionViewsActionTypes.DELETE_VIEW_DEFINITION_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentViewDefinition = createAction(
  CollectionViewsActionTypes.LOAD_CURRENT_VIEW_DEFINITION,
  props<{ id: string }>(),
);

export const loadCurrentViewDefinitionSuccess = createAction(
  CollectionViewsActionTypes.LOAD_CURRENT_VIEW_DEFINITION_SUCCESS,
  props<{ viewDefinition: ViewDefinition }>(),
);

export const loadCurrentViewDefinitionFailure = createAction(
  CollectionViewsActionTypes.LOAD_CURRENT_VIEW_DEFINITION_FAILURE,
  props<{ error: any }>(),
);

export const clearCurrentViewDefinition = createAction(CollectionViewsActionTypes.CLEAR_CURRENT_VIEW_DEFINITION);

export const setCurrentViewDefinition = createAction(
  CollectionViewsActionTypes.SET_CURRENT_VIEW_DEFINITION,
  props<{ viewDefinition: ViewDefinition }>(),
);

export const setCurrentViewDefinitionSuccess = createAction(
  CollectionViewsActionTypes.SET_CURRENT_VIEW_DEFINITION_SUCCESS,
  props<{ viewDefinition: ViewDefinition }>(),
);
export const updateViewOrder = createAction(
  CollectionViewsActionTypes.UPDATE_VIEW_ORDER,
  props<{ viewSortOrder: Array<any> }>(),
);

export const updateViewOrderSuccess = createAction(
  CollectionViewsActionTypes.UPDATE_VIEW_ORDER_SUCCESS,
  props<{ updatedViewDefs: Array<ViewDefinition> }>(),
);

export const updateViewOrderFailure = createAction(
  CollectionViewsActionTypes.UPDATE_VIEW_ORDER_FAILURE,
  props<{ error: any }>(),
);

export const setViewDefinitionsWorking = createAction(
  CollectionViewsActionTypes.SET_VIEW_DEFINITIONS_WORKING,
  props<{ working: boolean }>(),
);

export const setLocalViewDefinitions = createAction(
  CollectionViewsActionTypes.SET_LOCAL_VIEW_DEFINITIONS,
  props<{ viewDefinitions: Array<CollectionViewDefinition> }>(),
);

export const setLocalViewDefinitionsSuccess = createAction(
  CollectionViewsActionTypes.SET_LOCAL_VIEW_DEFINITIONS_SUCCESS,
  props<{ viewDefinitions: Array<CollectionViewDefinition> }>(),
);

export const updateLocalViewDefinition = createAction(
  CollectionViewsActionTypes.UPDATE_LOCAL_VIEW_DEFINITION,
  props<{ id: string; changes: CollectionViewDefinition }>(),
);

export const updateLocalViewDefinitionSuccess = createAction(
  CollectionViewsActionTypes.UPDATE_LOCAL_VIEW_DEFINITION_SUCCESS,
  props<{ id: string; changes: CollectionViewDefinition }>(),
);
