import { Injectable } from '@angular/core';
import { Entities, EntityReference, SortOrderOptions } from '@contrail/sdk';
import { BehaviorSubject } from 'rxjs';
import { WorkspaceEntityHelper } from './workspace-entity-helper';
import { Workspace, WorkspaceEntitiesFolderParameters, WorkspaceEntity } from './workspaces-store/workspaces.state';
import { cloneDeep } from '@contrail/util/lib/object-util/cloneDeep/cloneDeep';
import { SortObjects } from '@components/sort/sort-objects';
import { SortDefinition, SortDirection } from '@components/sort/sort-definition';
import { PropertyType } from '@contrail/types';
import { AuthService } from '@common/auth/auth.service';

export interface WorkspaceEntitiesFolderSummary {
  folderCount?: number;
  totalEntitiesCount?: number;
  loadedEntitiesCount?: number;
  nonFolderCount?: number;
  nextPageKey?: string;
}
export interface WorkspaceFolderResults {
  allEntities: Array<WorkspaceEntity>;
  summary: WorkspaceEntitiesFolderSummary;
}
export interface WorkspaceFolderResultsNextPageData {
  additionalEntities: Array<WorkspaceEntity>;
  nextPageKey?: string;
}

@Injectable({
  providedIn: 'root',
})
export class WorkspacesService {
  public handleDropSuccess = new BehaviorSubject<boolean>(false);

  constructor(private authService: AuthService) {}

  public async getWorkspaces() {
    // only hub : isArchived frontend filter
    let workspaces = await new Entities().get({
      entityName: 'workspace',
      relations: ['workspacePrincipals', 'updatedBy', 'createdBy'],
    });
    workspaces = this.sortWorkspaces(workspaces);
    return workspaces;
  }
  public async getWorkspaceById(id: string) {
    return new Entities().get({ entityName: 'workspace', id, relations: ['workspacePrincipals'] });
  }
  public async createWorkspace(workspace: Workspace) {
    return new Entities().create({ entityName: 'workspace', object: workspace });
  }
  public async deleteWorkspace(workspace: Workspace) {
    await new Entities().delete({ entityName: 'workspace', id: workspace.id });
    return workspace;
  }
  public async updateWorkspace(id: string, changes: Workspace) {
    return new Entities().update({ entityName: 'workspace', id, object: changes });
  }

  /** Fetches the next page of results for a workspace entities query */
  public async getNextWorkspaceEntitiesPage(
    workspace: Workspace,
    params: WorkspaceEntitiesFolderParameters = {},
    nextPageKey: string,
  ): Promise<WorkspaceFolderResultsNextPageData> {
    const options = this.buildWorkspaceEntityOptions(workspace, params, nextPageKey);
    const entityResults = await new Entities().get(options);

    const entities = WorkspaceEntityHelper.postProcessWorkspaceEntities(entityResults.results, workspace);
    let nextPageData: WorkspaceFolderResultsNextPageData = {
      nextPageKey: entityResults.nextPageKey,
      additionalEntities: entities,
    };
    console.log('getNextWorkspaceEntitiesPageL: ', nextPageData);
    return nextPageData;
  }

  private buildWorkspaceEntityOptions(
    workspace: Workspace,
    params: WorkspaceEntitiesFolderParameters = {},
    nextPageKey: string = null,
  ) {
    const options: any = {
      entityName: 'open-search',
      take: 60,
      paginate: true,
      criteria: {
        entityType: 'global',
        entityTypes: ['plan', 'board', 'showcase', 'showroom', 'color', 'asset'],
        filters: {
          workspaceId: workspace.id,
          isTrashed: false,
        },
      },
      relations: ['previewFile', 'updatedBy', 'createdBy', 'showroomLayouts', 'showroomLayouts.previewFile'],
    };
    if (params?.search) {
      options.search = params.search + '*';
    }
    if (params?.order) {
      options.order = params.order;
    } else {
      options.order = [{ order: SortOrderOptions.DESC, orderField: 'updatedOn' }];
    }
    if (params?.createdById) {
      options.criteria.filters.createdById = params.createdById;
    }
    if (nextPageKey) {
      options.nextPageKey = nextPageKey;
    }
    return options;
  }

  /** Fetches the first page of workspace entities for a folder, and includes
   * the folders subfolders in a composite results object.
   */
  public async getWorkspaceEntities(
    workspace: Workspace,
    params: WorkspaceEntitiesFolderParameters = {},
  ): Promise<WorkspaceFolderResults> {
    const options = this.buildWorkspaceEntityOptions(workspace, params);
    let [entityResults, folders] = await Promise.all([
      new Entities().get(options),
      this.getWorkspaceFolders(workspace, params?.order),
    ]);
    folders = WorkspaceEntityHelper.postProcessWorkspaceEntities(folders, workspace);
    const entities = WorkspaceEntityHelper.postProcessWorkspaceEntities(entityResults.results, workspace);
    const results: WorkspaceFolderResults = {
      allEntities: [...folders, ...entities],
      summary: {
        folderCount: folders.length,
        totalEntitiesCount: entityResults.count,
        loadedEntitiesCount: entityResults.count < options.take ? entityResults.count : options.take,
        nextPageKey: entityResults.nextPageKey,
      },
    };
    return results;
  }

  public async getWorkspaceFolders(workspace: Workspace, orderOpt?: any) {
    const order = orderOpt ?? [{ order: SortOrderOptions.DESC, orderField: 'updatedOn' }];
    let folders = await new Entities().get({
      entityName: 'open-search',
      take: 100,
      paginate: false,
      criteria: {
        entityType: 'global',
        entityTypes: ['workspace'],
        filters: {
          parentId: workspace.id,
        },
      },
      order,
      relations: ['updatedBy', 'createdBy'],
    });
    folders = WorkspaceEntityHelper.postProcessWorkspaceEntities(folders, workspace);
    console.log('getWorkspaceFolders : folders: ', folders);
    return folders;
  }

  /** Takes a set of entity references and updates the workspace on every entity.
   */
  public async moveFolderEntities(entityReferences: Array<string>, targetWorkspaceId: string) {
    console.log('moveFolderEntities: ', entityReferences, targetWorkspaceId);

    // This function groups the entity references based on entity type
    // and then makes one batch update call per entityType (plan, board, etc);
    // Note that changing the parent workspace of a folder is handled slightly differently.
    const entityGroups = this.groupReferences(entityReferences);
    console.log('entityGroups: ', entityGroups);
    const promises = [];
    for (const entityType of Object.keys(entityGroups)) {
      let ids = entityGroups[entityType];
      const objects = ids.map((id) => {
        if (entityType === 'workspace') {
          ids = ids.filter((id) => id !== targetWorkspaceId); // block move into itself
          return { id, changes: { parentId: targetWorkspaceId } };
        } else {
          return { id, changes: { workspaceId: targetWorkspaceId } };
        }
      });
      promises.push(new Entities().batchUpdate({ entityName: entityType, objects }));
    }
    await Promise.all(promises);
  }

  private groupReferences(entityReferences) {
    const grouping = {};
    const references: Array<EntityReference> = entityReferences.map((ref) => new EntityReference(ref));
    console.log('references: ', references);
    for (const entityReference of references) {
      const array = grouping[entityReference.entityType] || [];
      array.push(entityReference.id);
      grouping[entityReference.entityType] = array;
    }
    return grouping;
  }

  public addEntityToCurrentFolder(data) {
    const userData: any = cloneDeep(data.authContextUser);
    delete userData?.orgs;
    const source = { createdBy: userData, updatedBy: userData };
    let entity = cloneDeep(data?.entity);
    delete entity?.planRowOrder; // plan
    delete entity?.document; // board
    delete entity?.showroomLayouts; // showroom
    delete entity?.presentation; // showcase
    delete entity?.content; // asset
    let newEntity = [Object.assign(entity, source)];
    const currentFolderWorkspace = data.currentWorkspaceFolder;
    newEntity = WorkspaceEntityHelper.postProcessWorkspaceEntities(newEntity, currentFolderWorkspace);

    const orderField = data?.order[0]?.orderField;
    let newSummary = cloneDeep(data.workspaceEntitiesFolderSummary);
    const folderCount = newSummary.folderCount || 0;
    let entities = cloneDeep(data.workspaceFolderEntities);
    let folderEntities: any[] = entities.splice(0, folderCount);

    if (newEntity[0]?.entityType !== 'workspace') {
      entities.unshift(newEntity[0]); // entity
      newSummary.totalEntitiesCount++;
      newSummary.loadedEntitiesCount++;
      entities = this.sortEntities(entities, orderField);
    } else {
      folderEntities.unshift(newEntity[0]); // folder
      newSummary.folderCount++;
      folderEntities = this.sortEntities(folderEntities, orderField);
    }

    const allEntities = folderEntities.concat(entities);
    const res = { allEntities, newSummary };
    return res;
  }

  public deleteEntitiesFromCurrentFolder(data) {
    let newSummary = data.workspaceEntitiesFolderSummary;
    newSummary = {
      ...data.workspaceEntitiesFolderSummary,
      totalEntitiesCount: newSummary.totalEntitiesCount - data.entities.length,
      loadedEntitiesCount: newSummary.loadedEntitiesCount - data.entities.length,
    };
    const ids = data.entities.map((e) => e.id);
    const allEntities = data.workspaceFolderEntities.filter((e) => !ids.includes(e.id));
    const res = { allEntities, newSummary };
    return res;
  }

  private sortEntities(data, orderField) {
    switch (orderField) {
      case 'updatedOn': // desc
      case 'createOn': // desc
        break;

      case 'name': // asc
        data.sort((e1, e2) => (e1.name > e2.name ? 1 : -1));
        break;
      case 'createdById': // desc
        data.sort((e1, e2) => (e1.createdBy?.createdById < e2.createdBy?.createdById ? 1 : -1));
        break;
      default:
        break;
    }
    return data;
  }

  private sortWorkspaces(workspaces: any) {
    const currentOrgConfig = this.authService.getCurrentOrg().orgConfig;
    let sortDefinitions: SortDefinition[] = [
      {
        propertySlug: 'name',
        direction: SortDirection.ASCENDING,
        propertyType: PropertyType.String,
      },
    ];
    if (currentOrgConfig?.projectSortField) {
      sortDefinitions = [
        {
          propertySlug: currentOrgConfig.projectSortField,
          direction:
            currentOrgConfig.projectSortDirection === 'asc' ? SortDirection.ASCENDING : SortDirection.DESCENDING,
          propertyType: ['createdOn', 'modifiedOn'].includes(currentOrgConfig.projectSortField)
            ? PropertyType.Date
            : PropertyType.String,
        },
      ];
    }
    workspaces = SortObjects.sort(workspaces, sortDefinitions);
    return workspaces;
  }
}
