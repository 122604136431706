<div class="session-header" *ngIf="currentWorkspace?.id && !onList; else hubLogo">
  <div class="title-section">
    <ng-container *ngIf="!isProject">
      <img src="/assets/images/{{ name }}.svg" class="w-9 h-9 object-contain ml-2 mr-5 non-selectable" />
      <h6>{{ name | titlecase }}</h6>
    </ng-container>
    <ng-container *ngIf="isProject">
      <div *ngIf="name" class="initials ml-2 mr-5 non-selectable">{{ name.slice(0, 2) }}</div>
      <div class="title mr-3">
        <h6 *ngIf="!editName" class="line-ellipsis max-w-[400px]">{{ name }}</h6>
        <form [formGroup]="form">
          <mat-form-field *ngIf="editName">
            <input
              matInput
              #editNameInput
              type="text"
              formControlName="name"
              id="name_input"
              name="name"
              (blur)="endEditName()"
            />
          </mat-form-field>
        </form>
      </div>
    </ng-container>

    <button id="edit_button" mat-button class="btn-32" (click)="startEditName()" *ngIf="isAdmin && isProject">
      <mat-icon>edit</mat-icon>
    </button>

    <div
      class="flex items-center cursor-pointer ml-5"
      id="principal_admin_manage"
      (click)="principalAdmin()"
      *ngIf="isProject"
    >
      <div
        *ngFor="let principal of (workspacePrincipals$ | async)?.slice(0, 4); index as idx"
        class="flex items-center"
      >
        <div
          *ngIf="!principal?.principal?.email"
          class="w-8 h-8 rounded-2xl flex-center -ml-3"
          [ngStyle]="{ 'background-color': getBackgroundColor(principal?.principal?.id) }"
          [matTooltip]="principal?.principal?.name"
        >
          <span class="material-icons text-white text-xl"> people_alt </span>
        </div>
        <app-avatar
          *ngIf="principal?.principal?.email"
          class="-ml-3"
          [ngStyle]="{ 'z-index': 100 - idx }"
          [user]="principal?.principal"
        ></app-avatar>
      </div>
      <div
        *ngIf="(workspacePrincipals$ | async)?.length > 4"
        class="flex-center w-8 h-8 bg-blue-50 font-bold rounded-2xl -ml-3"
      >
        <span class="material-icons"> more_horiz </span>
      </div>
    </div>
  </div>
</div>

<ng-template #hubLogo>
  <div class="hub-logo flex items-center ml-4 non-selectable">
    <img src="assets/images/workspace_icon.svg" class="w-9 h-9 object-contain mr-4" alt="" />
    <h6>Hub</h6>
  </div>
</ng-template>
