import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserSessionsStoreModule } from './user-sessions-store';
import { UserSessionsAvatarsComponent } from './user-sessions-avatars/user-sessions-avatars.component';
import { WebSocketModule } from '../web-socket/web-socket.module';
import { UserSessionMessageHandler } from './user-session-message-handler';
import { ComponentsModule } from '../components/components.module';

@NgModule({
  declarations: [UserSessionsAvatarsComponent],
  imports: [CommonModule, UserSessionsStoreModule, WebSocketModule, ComponentsModule],
  exports: [UserSessionsAvatarsComponent],
})
export class UserSessionsModule {
  constructor(private userSessionMessageHandler: UserSessionMessageHandler) {}
}
