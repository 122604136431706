import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkspacePrincipal } from '@common/workspaces/workspace-principal';

interface WorkspacePrincipalsState extends EntityState<WorkspacePrincipal> {}

export interface State {
  workspacePrincipals: WorkspacePrincipalsState;
  currentWorkspacePrincipal: WorkspacePrincipal;
}
export const workspacePrincipalsEntityAdapter: EntityAdapter<WorkspacePrincipal> =
  createEntityAdapter<WorkspacePrincipal>({});

export const workspacePrincipalsInitialState = {
  workspacePrincipals: workspacePrincipalsEntityAdapter.getInitialState({}),
  currentWorkspacePrincipal: null,
};
