import { Workspace, WorkspaceType } from './workspaces-store/workspaces.state';
export enum WorkspacePrincipalRole {
  admin = 'ADMIN',
  member = 'MEMBER',
  viewer = 'VIEWER',
}
export interface WorkspacePrincipalRoleDisplay {
  value: WorkspacePrincipalRole;
  text: string;
}
export const WORKSPACE_PRINCIPLE_ROLES: WorkspacePrincipalRoleDisplay[] = [
  { value: WorkspacePrincipalRole.viewer, text: 'Viewer' },
  { value: WorkspacePrincipalRole.member, text: 'Owner' },
  { value: WorkspacePrincipalRole.admin, text: 'Admin' },
];

export interface WorkspacePrincipal {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;
  typeId?: string;
  orgId?: string;

  workspaceId?: string;
  workspace?: Workspace;
  workspaceType?: WorkspaceType;

  principalReference?: string;
  principal?: any;

  role?: WorkspacePrincipalRole;
}
