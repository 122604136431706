import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace, WorkspaceFolder } from '@common/workspaces/workspaces-store/workspaces.state';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { RoutingService } from '@common/routing/routing.service';
import { WorkspacesService } from '@common/workspaces/workspaces.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-workspace-folder-navigation-folder',
  templateUrl: './workspace-folder-navigation-folder.component.html',
  styleUrls: ['./workspace-folder-navigation-folder.component.scss'],
})
export class WorkspaceFolderNavigationFolderComponent implements OnInit {
  @Output() folderClicked = new EventEmitter();
  @Output() folderExpanded = new EventEmitter();
  @Input() folder: WorkspaceFolder;
  @Input() selectedWorkspace: Workspace;
  private dragEnterCounter = 0;
  constructor(
    private element: ElementRef,
    private routingService: RoutingService,
    private store: Store<RootStoreState.State>,
    private workspaceService: WorkspacesService,
  ) {}

  ngOnInit(): void {}

  handleSubFolderClicked($event) {
    this.folderClicked.emit($event);
  }
  handleSubFolderExpanded($event) {
    this.folderExpanded.emit($event);
  }

  toggleExpanded() {
    this.folderExpanded.emit(this.folder);
  }
  handleSelect() {
    this.goToFolders();
    console.log('Go to folder: ', this.folder);
    if (this.selectedWorkspace.id !== this.folder.workspace.id) {
      this.folderClicked.emit(this.folder);
    }
  }
  async goToFolders() {
    const root = await firstValueFrom(this.store.select(WorkspacesSelectors.currentWorkspace));
    const path = `/workspaces/workspace/${root.id}/explorer`;
    console.log('path: ', path);
    this.routingService.go(path);
  }

  handleDragEnter($event) {
    this.dragEnterCounter++;
    console.log('handleDragEnter: ', $event, this.dragEnterCounter);

    const folder = this.element.nativeElement.querySelector('.folder');
    if (folder) {
      folder.classList.add('dragOver');
    }
  }

  handleDragOver($event) {
    $event.preventDefault();
  }

  handleDragLeave($event) {
    this.dragEnterCounter--;
    console.log('handleDragLeave: ', $event, this.dragEnterCounter);

    const folder = this.element.nativeElement.querySelector('.folder');
    if (folder && this.dragEnterCounter === 0) {
      folder.classList.remove('dragOver');
    }
  }

  handleDrop($event) {
    $event.preventDefault();
    const droppedItemData = JSON.parse($event.dataTransfer.getData('text/plain'));
    this.handleFolderEntityMove(droppedItemData, this.folder.workspace);

    const folder = this.element.nativeElement.querySelector('.folder');
    if (folder) {
      folder.classList.remove('dragOver');
    }
    this.dragEnterCounter = 0;
  }

  getFolderDivFromEvent($event) {
    for (const el of $event.path) {
      if (el.className?.indexOf('folder') > -1) {
        return el;
      }
    }
  }

  handleFolderEntityMove(droppedItemData, targetWorkspace) {
    this.workspaceService.handleDropSuccess.next(true);
    console.log('handleFolderEntityMove: ', droppedItemData, targetWorkspace);
    const entityReferences = droppedItemData.map((i) => i.entityReference);
    this.store.dispatch(
      WorkspacesActions.moveFolderEntities({ entityReferences, targetWorkspaceId: targetWorkspace.id }),
    );
  }
}
