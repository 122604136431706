import { createAction, props } from '@ngrx/store';
import { WorkspacePrincipal } from '@common/workspaces/workspace-principal';

export enum WorkspacePrincipalsActionTypes {
  LOAD_WORKSPACE_PRINCIPALS = '[WorkspacePrincipals] Load WorkspacePrincipals',
  LOAD_WORKSPACE_PRINCIPALS_SUCCESS = '[WorkspacePrincipals] Load WorkspacePrincipals Success',
  LOAD_WORKSPACE_PRINCIPALS_FAILURE = '[WorkspacePrincipals] Load WorkspacePrincipals Failure',

  CREATE_WORKSPACE_PRINCIPAL = '[WorkspacePrincipals] Create WorkspacePrincipal',
  CREATE_WORKSPACE_PRINCIPAL_SUCCESS = '[WorkspacePrincipals] Create WorkspacePrincipal Success',
  CREATE_WORKSPACE_PRINCIPAL_FAILURE = '[WorkspacePrincipals] Create WorkspacePrincipal Failure',

  CREATE_WORKSPACE_PRINCIPALS = '[WorkspacePrincipals] Create WorkspacePrincipals',
  CREATE_WORKSPACE_PRINCIPALS_SUCCESS = '[WorkspacePrincipals] Create WorkspacePrincipals Success',
  CREATE_WORKSPACE_PRINCIPALS_FAILURE = '[WorkspacePrincipals] Create WorkspacePrincipals Failure',

  DELETE_WORKSPACE_PRINCIPAL = '[WorkspacePrincipals] Delete WorkspacePrincipal',
  DELETE_WORKSPACE_PRINCIPAL_SUCCESS = '[WorkspacePrincipals] Delete WorkspacePrincipal Success',
  DELETE_WORKSPACE_PRINCIPAL_FAILURE = '[WorkspacePrincipals] Delete WorkspacePrincipal Failure',

  DELETE_WORKSPACE_PRINCIPALS = '[WorkspacePrincipals] Delete WorkspacePrincipals',
  DELETE_WORKSPACE_PRINCIPALS_SUCCESS = '[WorkspacePrincipals] Delete WorkspacePrincipals Success',
  DELETE_WORKSPACE_PRINCIPALS_FAILURE = '[WorkspacePrincipals] Delete WorkspacePrincipals Failure',

  UPDATE_WORKSPACE_PRINCIPAL = '[WorkspacePrincipals] Update WorkspacePrincipal',
  UPDATE_WORKSPACE_PRINCIPAL_SUCCESS = '[WorkspacePrincipals] Update WorkspacePrincipal Success',
  UPDATE_WORKSPACE_PRINCIPAL_FAILURE = '[WorkspacePrincipals] Update WorkspacePrincipal Failure',

  LOAD_CURRENT_WORKSPACE_PRINCIPAL = '[WorkspacePrincipals] Load Current WorkspacePrincipal',
  LOAD_CURRENT_WORKSPACE_PRINCIPAL_SUCCESS = '[WorkspacePrincipals] Load Current WorkspacePrincipal Success',
  LOAD_CURRENT_WORKSPACE_PRINCIPAL_FAILURE = '[WorkspacePrincipals] Load Current WorkspacePrincipal Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const loadWorkspacePrincipals = createAction(WorkspacePrincipalsActionTypes.LOAD_WORKSPACE_PRINCIPALS);

export const loadWorkspacePrincipalsSuccess = createAction(
  WorkspacePrincipalsActionTypes.LOAD_WORKSPACE_PRINCIPALS_SUCCESS,
  props<{ data: Array<any> }>(),
);

export const loadWorkspacePrincipalsFailure = createAction(
  WorkspacePrincipalsActionTypes.LOAD_WORKSPACE_PRINCIPALS_FAILURE,
  props<{ error: any }>(),
);

export const createWorkspacePrincipal = createAction(
  WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPAL,
  props<{ principalReference: string; role: string }>(),
);

export const createWorkspacePrincipalSuccess = createAction(
  WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPAL_SUCCESS,
  props<{ workspacePrincipal: WorkspacePrincipal }>(),
);

export const createWorkspacePrincipalFailure = createAction(
  WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);

export const createWorkspacePrincipals = createAction(
  WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPALS,
  props<{ objects: any }>(),
);

export const createWorkspacePrincipalsSuccess = createAction(
  WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPALS_SUCCESS,
  props<{ data: any }>(),
);

export const createWorkspacePrincipalsFailure = createAction(
  WorkspacePrincipalsActionTypes.CREATE_WORKSPACE_PRINCIPALS_FAILURE,
  props<{ error: any }>(),
);

export const updateWorkspacePrincipal = createAction(
  WorkspacePrincipalsActionTypes.UPDATE_WORKSPACE_PRINCIPAL,
  props<{ id: string; changes: WorkspacePrincipal }>(),
);

export const updateWorkspacePrincipalSuccess = createAction(
  WorkspacePrincipalsActionTypes.UPDATE_WORKSPACE_PRINCIPAL_SUCCESS,
  props<{ id: string; changes: WorkspacePrincipal }>(),
);

export const updateWorkspacePrincipalFailure = createAction(
  WorkspacePrincipalsActionTypes.UPDATE_WORKSPACE_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);

export const deleteWorkspacePrincipal = createAction(
  WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPAL,
  props<{ workspacePrincipal: WorkspacePrincipal }>(),
);

export const deleteWorkspacePrincipalSuccess = createAction(
  WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPAL_SUCCESS,
  props<{ workspacePrincipal: WorkspacePrincipal }>(),
);

export const deleteWorkspacePrincipalFailure = createAction(
  WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);

export const deleteWorkspacePrincipals = createAction(
  WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPALS,
  props<{ ids: string[] }>(),
);

export const deleteWorkspacePrincipalsSuccess = createAction(
  WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPALS_SUCCESS,
  props<{ data: any }>(),
);

export const deleteWorkspacePrincipalsFailure = createAction(
  WorkspacePrincipalsActionTypes.DELETE_WORKSPACE_PRINCIPALS_FAILURE,
  props<{ error: any }>(),
);

export const loadCurrentWorkspacePrincipal = createAction(
  WorkspacePrincipalsActionTypes.LOAD_CURRENT_WORKSPACE_PRINCIPAL,
  props<{ id: string }>(),
);

export const loadCurrentWorkspacePrincipalSuccess = createAction(
  WorkspacePrincipalsActionTypes.LOAD_CURRENT_WORKSPACE_PRINCIPAL_SUCCESS,
  props<{ workspacePrincipal: WorkspacePrincipal }>(),
);

export const loadCurrentWorkspacePrincipalFailure = createAction(
  WorkspacePrincipalsActionTypes.LOAD_CURRENT_WORKSPACE_PRINCIPAL_FAILURE,
  props<{ error: any }>(),
);
