import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State {
  libraries: any;
  librariesLoaded: boolean;
}
export const librariesEntityAdapter: EntityAdapter<any> = createEntityAdapter<any>({});

export const librariesInitialState = {
  libraries: librariesEntityAdapter.getInitialState({}),
  librariesLoaded: false,
};
