import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { UserSessionsStoreModule } from '../common/user-sessions/user-sessions-store';
import { AuthStoreModule } from '../common/auth/auth-store';
import { CommentsStoreModule } from '../common/comments/comments-store';
import { LoadingIndicatorStoreModule } from '../common/loading-indicator/loading-indicator-store';
import { PlansStoreModule } from '../plans/plans-store';
import { WorkspacesStoreModule } from '../common/workspaces/workspaces-store';
import { BoardsStoreModule } from '../boards/boards-store';
import { ShowcasesStoreModule } from '../showcases/showcases-store';
import { AssortmentsStoreModule } from '../common/assortments/assortments-store';
import { WorkspacePrincipalsStoreModule } from '../workspaces/workspace-principals/workspace-principals-store';
import { ShowroomsStoreModule } from '../showrooms/showrooms-store/showrooms-store.module';
import { DashboardStoreModule } from '../dashboard/dashboard-store';
import { FolderPrincipalsStoreModule } from '@common/folders/folder-principals-store';
import { UserOrgsStoreModule } from '../main/user-orgs-store';
import { LibrariesStoreModule } from '../libraries/libraries-store';
import { FeatureFlagsStoreModule } from '@common/feature-flags';
import { CollectionManagerStoreModule } from '@common/collection-manager/collection-manager-store';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot([]),
    UserSessionsStoreModule,
    AuthStoreModule,
    CommentsStoreModule,
    LoadingIndicatorStoreModule,
    PlansStoreModule,
    WorkspacesStoreModule,
    LibrariesStoreModule,
    AssortmentsStoreModule,
    BoardsStoreModule,
    ShowroomsStoreModule,
    ShowcasesStoreModule,
    WorkspacePrincipalsStoreModule,
    FolderPrincipalsStoreModule,
    DashboardStoreModule,
    UserOrgsStoreModule,
    FeatureFlagsStoreModule,
    CollectionManagerStoreModule,
  ],
})
export class RootStoreModule {}
