import { ViewPropertyConfiguration, ViewPropertyPivotType } from '@contrail/client-views';

export class PivotConfiguratorHelper {
  public static isRowGroupingProperty(property: ViewPropertyConfiguration): boolean {
    const isRowProperty = property.pivotType !== ViewPropertyPivotType.COLUMN;

    return property.frozen && isRowProperty;
  }

  public static isColumnGroupingProperty(property: ViewPropertyConfiguration): boolean {
    return property.frozen && property.pivotType === ViewPropertyPivotType.COLUMN;
  }

  public static isValueProperty(property: ViewPropertyConfiguration): boolean {
    return !property.frozen;
  }
}
