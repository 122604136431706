import { BoardsStoreState } from '../boards/boards-store';
import { AssortmentsStoreState } from '../common/assortments/assortments-store';
import { AuthStoreState } from '../common/auth/auth-store';
import { CommentsStoreState } from '../common/comments/comments-store';
import { LoadingIndicatorStoreState } from '../common/loading-indicator/loading-indicator-store';
import { UserSessionStoreState } from '../common/user-sessions/user-sessions-store';
import { PlansStoreState } from '../plans/plans-store';
import { ShowcasesStoreState } from '../showcases/showcases-store';
import { ShowroomsStoreState } from '../showrooms/showrooms-store';
import { WorkspacePrincipalsStoreState } from '../workspaces/workspace-principals/workspace-principals-store';
import { WorkspacesStoreState } from '../common/workspaces/workspaces-store';
import { DashboardsStoreState } from '../dashboard/dashboard-store';
import { FolderPrincipalsStoreState } from '@common/folders/folder-principals-store';
import { UserOrgsStoreState } from '../main/user-orgs-store';
import { LibrariesStoreState } from '../libraries/libraries-store';
import { FeatureFlagsStoreState } from '@common/feature-flags';
import { CollectionManagerStoreState } from '@common/collection-manager/collection-manager-store';

export interface State {
  userSessions: UserSessionStoreState.State;
  auth: AuthStoreState.State;
  comments: CommentsStoreState.State;
  loadingIndicator: LoadingIndicatorStoreState.State;
  plans: PlansStoreState.State;
  workspaces: WorkspacesStoreState.State;
  libraries: LibrariesStoreState.State;
  assortments: AssortmentsStoreState.State;
  boards: BoardsStoreState.State;
  showcases: ShowcasesStoreState.State;
  showrooms: ShowroomsStoreState.State;
  workspacePrincipals: WorkspacePrincipalsStoreState.State;
  folderPrincipals: FolderPrincipalsStoreState.State;
  dashboard: DashboardsStoreState.State;
  userOrgs: UserOrgsStoreState.State;
  featureFlags: FeatureFlagsStoreState.State;
  collectionManager: CollectionManagerStoreState.State;
}
