<div *ngIf="(currentWorkspace$ | async) && (workspaceFolderStructure$ | async) && visible">
  <app-workspace-folder-navigation-folder
    *ngFor="let folder of workspaceFolderStructure$ | async"
    [folder]="folder"
    [selectedWorkspace]="currentWorkspaceFolder$ | async"
    (folderClicked)="handleFolderClicked($event)"
    (folderExpanded)="handleFolderExpanded($event)"
  >
  </app-workspace-folder-navigation-folder>
</div>
