import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { RootStoreState } from '@rootstore';
import { Store } from '@ngrx/store';
import { ShowroomsActions } from '../showrooms-store';

@Component({
  selector: 'app-create-showroom',
  templateUrl: './create-showroom.component.html',
  styleUrls: ['./create-showroom.component.scss'],
})
export class CreateShowroomComponent implements OnInit {
  public nameFormControl = new UntypedFormControl('', Validators.required);
  private sourceAssortmentId: string;
  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CreateShowroomComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit(): void {}
  setAssortment(assortmentId) {
    this.sourceAssortmentId = assortmentId;
  }
  cancel() {
    this.dialogRef.close();
  }
  async save() {
    const showroom: any = {
      name: this.nameFormControl.value,
    };

    if (this.sourceAssortmentId) {
      showroom.sourceAssortmentId = this.sourceAssortmentId;
    }
    this.store.dispatch(ShowroomsActions.createShowroom({ showroom }));
    this.dialogRef.close();
  }
  isValid() {
    return this.nameFormControl.valid;
  }
}
