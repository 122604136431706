import { Plan } from 'src/app/plans/plans-store/plans.state';

export interface State {
  loading: boolean;
  currentAssortmentSummary: any;
  currentAssortmentHistory: any;
  currentAssortmentPlan: Plan;
}

export const initialState: State = {
  loading: false,
  currentAssortmentSummary: null,
  currentAssortmentHistory: null,
  currentAssortmentPlan: null,
};
