import { createAction, props } from '@ngrx/store';
import { Plan } from './plans.state';

export enum PlansActionTypes {
  LOAD_PLANS = '[Plans] Load Plans',
  LOAD_PLANS_SUCCESS = '[Plans] Load Plans Success',
  LOAD_PLANS_FAILURE = '[Plans] Load Plans Failure',

  CLEAR_PLANS = '[Plans] Clear Plans',

  CREATE_PLAN = '[Plans] Create Plan',
  CREATE_PLAN_SUCCESS = '[Plans] Create Plan Success',
  CREATE_PLAN_FAILURE = '[Plans] Create Plan Failure',

  COPY_PLAN = '[Plans] Copy Plan',
  COPY_PLAN_SUCCESS = '[Plans] Copy Plan Success',
  COPY_PLAN_FAILURE = '[Plans] Copy Plan Failure',

  ASYNC_COPY_PLAN = '[Plan] Async Copy Plan',
  ASYNC_COPY_PLAN_SUCCESS = '[Plan] Async Copy Plan Success',

  DELETE_PLAN = '[Plans] Delete Plan',
  DELETE_PLAN_SUCCESS = '[Plans] Delete Plan Success',
  DELETE_PLAN_FAILURE = '[Plans] Delete Plan Failure',

  UPDATE_PLAN = '[Plans] Update Plan',
  UPDATE_PLAN_SUCCESS = '[Plans] Update Plan Success',
  UPDATE_PLAN_FAILURE = '[Plans] Update Plan Failure',

  LOAD_CURRENT_PLAN = '[Plans] Load Current Plan',
  LOAD_CURRENT_PLAN_SUCCESS = '[Plans] Load Current Plan Success',
  LOAD_CURRENT_PLAN_FAILURE = '[Plans] Load Current Plan Failure',
}

////////////////////////////////////////////////////////////////////////////////
export const clearPlans = createAction(PlansActionTypes.CLEAR_PLANS);

export const loadPlans = createAction(PlansActionTypes.LOAD_PLANS);

export const loadPlansSuccess = createAction(PlansActionTypes.LOAD_PLANS_SUCCESS, props<{ data: Array<any> }>());

export const loadPlansFailure = createAction(PlansActionTypes.LOAD_PLANS_FAILURE, props<{ error: any }>());

export const createPlan = createAction(PlansActionTypes.CREATE_PLAN, props<{ plan: Plan }>());

export const createPlanSuccess = createAction(PlansActionTypes.CREATE_PLAN_SUCCESS, props<{ plan: Plan }>());

export const createPlanFailure = createAction(PlansActionTypes.CREATE_PLAN_FAILURE, props<{ error: any }>());

export const copyPlan = createAction(PlansActionTypes.COPY_PLAN, props<{ name: string; sourceId: string }>());

export const copyPlanSuccess = createAction(PlansActionTypes.COPY_PLAN_SUCCESS, props<{ plan: Plan }>());

export const copyPlanFailure = createAction(PlansActionTypes.COPY_PLAN_FAILURE, props<{ error: any }>());

export const asyncCopyPlan = createAction(
  PlansActionTypes.ASYNC_COPY_PLAN,
  props<{ name: string; sourceId: string; newWorkspaceId?; navigateToFolder?: { id?; folder?; across?; rootId? } }>(),
);

export const asyncCopyPlanSuccess = createAction(
  PlansActionTypes.ASYNC_COPY_PLAN_SUCCESS,
  props<{ jobId: string; path: string; navigateToFolder?: { id?; folder?; across?; rootId? } }>(),
);

export const updatePlan = createAction(PlansActionTypes.UPDATE_PLAN, props<{ id: string; changes: Plan }>());

export const updatePlanSuccess = createAction(
  PlansActionTypes.UPDATE_PLAN_SUCCESS,
  props<{ id: string; changes: Plan }>(),
);

export const updatePlanFailure = createAction(PlansActionTypes.UPDATE_PLAN_FAILURE, props<{ error: any }>());

export const deletePlan = createAction(PlansActionTypes.DELETE_PLAN, props<{ planId: string }>());

export const deletePlanSuccess = createAction(PlansActionTypes.DELETE_PLAN_SUCCESS, props<{ planId: string }>());

export const deletePlanFailure = createAction(PlansActionTypes.DELETE_PLAN_FAILURE, props<{ error: any }>());

export const loadCurrentPlan = createAction(PlansActionTypes.LOAD_CURRENT_PLAN, props<{ id: string }>());

export const loadCurrentPlanSuccess = createAction(PlansActionTypes.LOAD_CURRENT_PLAN_SUCCESS, props<{ plan: Plan }>());

export const loadCurrentPlanFailure = createAction(PlansActionTypes.LOAD_CURRENT_PLAN_FAILURE, props<{ error: any }>());
