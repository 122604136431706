import { Component, OnInit, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { AppNotificationsService } from '@common/app-notifications/app-notifications.service';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace } from '@common/workspaces/workspaces-store/workspaces.state';
import { WorkspacesService } from '@common/workspaces/workspaces.service';
import { ObjectUtil } from '@contrail/util';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import { BehaviorSubject, firstValueFrom, from, Observable, of, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { WorkspaceEntitiesHelperService } from 'src/app/workspaces/workspace-entities-helper.service';

@Component({
  selector: 'app-copy-move-entity-modal',
  templateUrl: './copy-move-entity-modal.component.html',
  styleUrls: ['./copy-move-entity-modal.component.scss'],
})
export class CopyMoveEntityModalComponent implements OnInit {
  private destroy$ = new Subject();
  loading = false;
  public workspaces$: Observable<Array<Workspace>>;

  acrossWorkspace: boolean = false;
  inAllWorkspaces: boolean = false;
  selectedWorkspaceIdx: number = null;
  rootWorkspace: Workspace;
  currentWorkspaceId: string; // initial rootWorkspace.id to check acrossWorkspace
  selectedFolder: Workspace;
  folderId: string;
  folderEntities$: Observable<Array<any>>;
  private _folderEntities$ = new BehaviorSubject<any>(null);

  public currentFolder: Workspace;
  tempArr = [];
  tempLength = 0;

  copy = false;

  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CopyMoveEntityModalComponent>,
    private workspaceEntitiesHelper: WorkspaceEntitiesHelperService,
    private notificationService: AppNotificationsService,
    private workspaceService: WorkspacesService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.copy = data.copy;
    this.workspaces$ = this.store
      .select(WorkspacesSelectors.workspaces)
      .pipe(map((wsArray) => wsArray.filter((ws) => !ws.isArchived)));
  }

  async ngOnInit() {
    this.currentFolder = await firstValueFrom(this.workspaceEntitiesHelper.currentFolder$);
    this.folderId = '';
    this.setTempArr();
    this.rootWorkspace = await firstValueFrom(this.workspaceEntitiesHelper.rootWorkspace$);
    this.currentWorkspaceId = this.rootWorkspace.id;

    const entities = await firstValueFrom(this.store.select(WorkspacesSelectors.workspaceFolderEntities));
    const folders = [];
    entities
      .filter((e) => e.entityReference.includes('workspace'))
      .forEach((element) => {
        const listItem: any = ObjectUtil.cloneDeep(element);
        this.workspaceEntitiesHelper.setIcon(listItem);
        listItem.selected = false;
        folders.push(listItem);
      });
    this._folderEntities$.next(folders);
    this.folderEntities$ = this._folderEntities$.asObservable();
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  selectWorkspace(ws, idx) {
    this.acrossWorkspace = this.currentWorkspaceId !== ws.id ? true : false;
    this.selectedWorkspaceIdx = idx;
    this.rootWorkspace = ws;
  }
  async openWorkspace(ws) {
    this.loading = true;
    this.acrossWorkspace = this.currentWorkspaceId !== ws.id ? true : false;
    this.inAllWorkspaces = false;
    this.selectedWorkspaceIdx = null;

    this.rootWorkspace = ws;
    this.folderId = '';
    this.currentFolder = ws;
    this.setTempArr();

    const workspaceFolders = await this.getSubFolders(ws);
    this._folderEntities$.next(workspaceFolders);
    this.folderEntities$ = this._folderEntities$.asObservable();
    this.loading = false;
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    let id;
    let folder = null;
    const across = this.acrossWorkspace;
    const rootId = this.rootWorkspace.id;
    if (this.inAllWorkspaces) {
      id = rootId;
      folder = { id: rootId };
    } else {
      id = !this.folderId ? this.currentFolder.rootWorkspaceId : this.folderId;
      folder = !this.folderId ? this.currentFolder : this.selectedFolder;
    }
    this.dialogRef.close({ id, folder, across, rootId });
  }

  clickFolder(folder, folders) {
    folder.selected = !folder.selected;
    this.selectedFolder = folder;
    this.folderId = folder?.selected ? folder.id : '';
    folders.forEach((f) => {
      if (f.id !== folder.id) {
        f.selected = false;
      }
    });
  }
  selectDocumentLevel(folders) {
    this.folderId = '';
    folders.forEach((f) => (f.selected = false));
  }

  setTempArr() {
    let tempWS = this.currentFolder;
    let length = 0;
    this.tempArr = [];
    const arr = [...Array(20).keys()];
    for (let idx = 0; idx < arr.length; idx++) {
      if (tempWS?.parent) {
        this.tempArr.push(tempWS);
        tempWS = tempWS.parent;
      } else {
        length = idx;
        break;
      }
    }
    this.tempLength = length;
    this.tempArr.reverse();
  }

  openFolder(folderEntity) {
    const parentFolder = folderEntity.workspace;
    if (parentFolder.id === this.rootWorkspace.id) {
      parentFolder.name = this.rootWorkspace?.rootWorkspaceType === 'PROJECT' ? 'Documents' : 'Explorer';
    }
    const folder = { ...folderEntity, parentId: folderEntity.workspaceId, parent: parentFolder };
    this.goToFolder(folder);
  }

  async getSubFolders(workspace) {
    const folders = [];
    const workspaceFolders = await this.workspaceService.getWorkspaceFolders(workspace);
    workspaceFolders.forEach((ws) => {
      const folder: any = ObjectUtil.cloneDeep(ws);
      this.workspaceEntitiesHelper.setIcon(folder);
      //folder.selected = false;
      folders.push(folder);
    });
    return folders;
  }

  async goToFolder(folder) {
    this.loading = true;
    const workspaceFolders = await this.getSubFolders(folder);
    if (workspaceFolders.length) {
      this._folderEntities$.next(workspaceFolders);
      this.folderEntities$ = this._folderEntities$.asObservable();
      this.loading = false;
    } else {
      this.notificationService.showConfirmationMessage('No sub folders');
    }

    this.folderId = '';
    this.currentFolder = folder;
    this.setTempArr();
    this.loading = false;
  }

  get tooltip() {
    if (!this.currentFolder?.parent) {
      return this.copy ? `Copy to Documents` : `Move to Documents`;
    }
  }
  get tooltipDisabled() {
    return !this.currentFolder?.parent && !this.folderId ? false : true;
  }
  get btnDisabled() {
    if (this.inAllWorkspaces) {
      return this.selectedWorkspaceIdx !== null ? false : true;
    } else {
      return !this.currentFolder?.parent || (this.currentFolder?.parent && this.folderId) ? false : true;
    }
  }
}
