import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable } from 'rxjs';
import { RoutingService } from 'src/app/common/routing/routing.service';

export interface PageHeaderConfig {
  title: string;
}

@Injectable({
  providedIn: 'root',
})
export class PageHeaderService {
  private pageHeaderConfigSubject: Subject<PageHeaderConfig> = new BehaviorSubject({ title: '' });
  public pageHeaderConfig: Observable<PageHeaderConfig> = this.pageHeaderConfigSubject.asObservable();
  constructor() {}

  setPageConfig(config: PageHeaderConfig) {
    console.log('Setting page config: ', config);
    this.pageHeaderConfigSubject.next(config);
  }
}
