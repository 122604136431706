import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration } from '@contrail/client-views';
import { PivotGridService } from '../pivot-grid-service';
import { AggregateColumnEntity } from '../pivot-aggregate-helper';
import { DEFAULT_ROW_HEIGHT_PX, PivotHeaderColumnResizeHelper } from './pivot-header-column-resize-helper';

@Component({
  selector: 'app-pivot-header-row',
  templateUrl: './pivot-header-row.component.html',
  styleUrls: ['./pivot-header-row.component.scss'],
})
export class PivotHeaderRowComponent implements OnDestroy, OnChanges {
  @Input() viewDefinition: ViewDefinition;
  @Input() properties: Array<ViewPropertyConfiguration>;
  @Input() columnGroupingValues: AggregateColumnEntity;
  @Input() side: string;
  @Input() showGoals: boolean = false;
  @Output() resizeColumn: EventEmitter<any> = new EventEmitter();
  @Output() toggleRows: EventEmitter<any> = new EventEmitter();

  public totalWidth = 0;
  public groupingHeader = '';
  public groupingProperty: ViewPropertyConfiguration;
  public headerHeightPx: number = DEFAULT_ROW_HEIGHT_PX;

  constructor(
    private pivotGridService: PivotGridService,
    private columnResizeHelper: PivotHeaderColumnResizeHelper,
  ) {}

  ngOnDestroy(): void {}

  ngOnChanges(): void {
    const isRowGroupingHeader = this.side === 'left' && this.properties?.length > 0;
    if (isRowGroupingHeader) {
      this.groupingProperty = this.properties[0];
      this.totalWidth = this.properties[0].width || this.properties.length * 100;
      this.groupingHeader = '';
      this.properties.forEach((prop) => {
        this.groupingHeader += (this.groupingHeader !== '' ? ' / ' : '') + prop.propertyDefinition.label;
      });
    }

    this.headerHeightPx = this.columnResizeHelper.getHeaderRowHeightInPixels(this.columnGroupingValues, {
      includeGoalsRow: this.showGoals,
    });
  }

  performResizeColumn(columnChanges: any) {
    this.resizeColumn.emit(columnChanges);
  }

  performToggleRows(columnChanges: any) {
    this.toggleRows.emit(columnChanges);
  }
}
