import { TypeProperty } from '@contrail/types';
import { CommentOwnerInfo } from '../comments.service';

export interface State {
  contextReference: string;
  contextComments: Array<Comment>;
  currentComment: Comment;
  commentOwnerInfo: CommentOwnerInfo;
  showCommentOverlay: boolean;
  showCommentsSideBar: boolean;
  commentOverlayPosition: { x: number; y: number };
}
export const initialState: State = {
  contextReference: null,
  currentComment: null,
  contextComments: [],
  commentOwnerInfo: null,
  showCommentOverlay: false,
  showCommentsSideBar: false,
  commentOverlayPosition: null,
};
