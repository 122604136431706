import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { delay, filter, retryWhen, switchMap } from 'rxjs/operators';
import { SessionEvent, SessionMessage, SessionMessageHandler } from './session-message';
import { AuthContext, AuthService } from '../auth/auth.service';
import * as uuid from 'uuid';
import { environment } from 'src/environments/environment';
const WS_URL = environment.websocketService;

@Injectable({
  providedIn: 'root',
})
export class WebSocketService implements OnDestroy {
  public messages: Subject<SessionMessage> = new Subject<SessionMessage>();
  private connection$: WebSocketSubject<any>;
  private currentSessionId: string;
  authContext: AuthContext;
  private apiToken: string;
  private clientId = uuid.v4();
  RETRY_SECONDS = 10;
  constructor(public authService: AuthService) {
    //
    this.authService.authContext.subscribe(async (authContext) => {
      console.log('WebSocketService: authContext: ', authContext);
      this.authContext = authContext;
      if (this.authContext) {
        this.setupConnection();
      }
    });
  }

  async setupConnection() {
    console.log('WebSocketService: setupConnection');
    this.apiToken = await this.authService.loadToken();
    const con = this.connect();
    con.subscribe(
      (msg) => {
        this.messages.next(msg);
      },
      (error) => {
        console.log('WebSocketService: Error in web socket...');
      },
      () => {
        this.connection$ = null;
        console.log('WebSocketService: Websocket closing...');
        this.setupConnection();
      },
    );
    if (this.currentSessionId) {
      this.joinSession(this.currentSessionId);
    }
  }

  connect(): Observable<any> {
    return of(WS_URL).pipe(
      filter((apiUrl) => !!apiUrl),
      switchMap((wsUrl) => {
        if (this.connection$) {
          return this.connection$;
        } else {
          const connectString = `${WS_URL}?apiKey=${this.apiToken}&apiOrg=${this.authContext.currentOrg.orgSlug}&clientId=${this.clientId}`;
          this.connection$ = webSocket(connectString);
          return this.connection$;
        }
      }),
      retryWhen((errors) => errors.pipe(delay(this.RETRY_SECONDS))),
    );
  }
  sendSessionEvent(event: SessionEvent) {
    this.sendMessage({
      sessionId: this.currentSessionId,
      action: 'SESSION_EVENT',
      event,
    });
  }
  sendMessage(message: SessionMessage) {
    if (this.connection$) {
      this.connection$.next(message);
    } else {
      console.error('Did not send data, open a connection first');
    }
  }
  closeConnection() {
    console.log('WebSocketService: closeConnection');
    if (this.connection$) {
      this.connection$.complete();
      this.connection$ = null;
    }
  }
  async joinSession(sessionId: string) {
    console.log('WebSocketService: joinSession:', sessionId);
    this.currentSessionId = sessionId;
    this.sendMessage({
      action: 'JOIN_SESSION',
      sessionId,
    });
  }
  ngOnDestroy() {
    this.closeConnection();
  }

  public getCurrentSessionId() {
    return this.currentSessionId;
  }
  public getClientId() {
    return this.clientId;
  }
}
