<div [id]="'column_header_' + property.slug" class="w-full bg-gray-100">
  <div class="header-cell">
    <div *ngIf="fixedLabel" class="ml-[5px]">
      <mat-icon data-test="pivot-expand-all-button" (click)="expandRows()">add_box</mat-icon>
      <mat-icon (click)="collapseRows()">indeterminate_check_box</mat-icon>
    </div>

    <span class="label">
      {{ fixedLabel || property?.propertyDefinition?.label || 'Count' }}
    </span>
  </div>

  <div *ngIf="!fixedLabel && showGoals" class="flex box-border border-gray-300 border-t-[1px]">
    <div class="flex justify-end label" style="width: 50%">Current</div>
    <div *ngIf="!fixedLabel && showGoals" class="flex justify-end label sub-header-cell-right" style="width: 50%">
      Goal
    </div>
  </div>
</div>

<div cdkDropList>
  <div
    class="resize-handle"
    [attr.data-test]="'column_resize_' + property.slug + '_draggable'"
    cdkDrag
    cdkDragLockAxis="x"
    (cdkDragStarted)="columnResizeDragStart($event)"
    (cdkDragDropped)="columnResizeDrop($event)"
    (cdkDragMoved)="columnResizeDragMove($event)"
  >
    <div
      [attr.data-test]="'column_resize_' + property.slug + '_drag_preview'"
      [id]="'column_resize_' + property.slug"
      class="column-resize-preview"
      *cdkDragPreview
    ></div>
  </div>
</div>
