import { Types } from '@contrail/sdk';
import { FormulaProcessor, Type, TypeProperty } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { FormulaFunctionProcessor } from '@contrail/types';
import { OrgConfig } from 'src/app/common/auth/auth.service';
import { CollectionDataEntity, CollectionDataEntityType } from './collection-manager.service';

export class CollectionDataUtil {
  public static async buildHydratedCollectionDataEntities(
    collectionDataEntities: Array<CollectionDataEntity>,
    entityRootTypePath: CollectionDataEntityType,
    context: {
      typeMap: { [key: string]: Type };
      assortment?: any;
      orgConfig: OrgConfig;
    },
  ) {
    const { typeMap, assortment, orgConfig } = context;
    const mappedPropertySlugs = this.getMappedProperties(typeMap, entityRootTypePath).map((prop) => prop.slug);

    const entityType = await new Types().getType({ root: entityRootTypePath, path: entityRootTypePath });
    const projectItemType = await new Types().getType({ root: 'project-item', path: 'project-item' });
    const itemType = await new Types().getType({ root: 'item', path: 'item' });

    const itemPropertyMap = this.getPropertyMap(itemType);
    const projectItemPropertyMap = this.getPropertyMap(projectItemType);

    const promises = [];
    for (const entity of collectionDataEntities) {
      promises.push(
        CollectionDataUtil.buildHydratedCollectionDataEntity(ObjectUtil.cloneDeep(entity), {
          entityType,
          mappedPropertySlugs,
          itemPropertyMap,
          projectItemPropertyMap,
          assortment,
          orgConfig,
        }),
      );
    }

    const hydratedCollectionDataEntities = await Promise.all(promises);
    return hydratedCollectionDataEntities;
  }

  public static async buildHydratedCollectionDataEntity(
    collectionDataEntity: CollectionDataEntity,
    context: {
      entityType: Type;
      mappedPropertySlugs: string[];
      itemPropertyMap: { [key: string]: TypeProperty };
      projectItemPropertyMap: { [key: string]: TypeProperty };
      assortment: any;
      orgConfig: OrgConfig;
    },
  ) {
    const { entityType, mappedPropertySlugs, itemPropertyMap, projectItemPropertyMap, assortment, orgConfig } = context;
    const processed = CollectionDataUtil.buildCollectionDataEntityWithMappedProperties(
      ObjectUtil.cloneDeep(collectionDataEntity),
      mappedPropertySlugs,
      itemPropertyMap,
      projectItemPropertyMap,
      orgConfig,
    );

    /** Calculate fomulas.  */
    FormulaProcessor.processFormulasForEntity(processed, entityType.typeProperties);
    await FormulaFunctionProcessor.processFormulaFunctionsForEntity(processed, entityType.typeProperties, {
      assortment,
      previousObj: processed,
    });
    return processed;
  }

  public static getPropertyMap(type: Type): { [key: string]: TypeProperty } {
    const map = {};
    for (const prop of type.typeProperties) {
      map[prop.slug] = prop;
    }
    return map;
  }

  public static getMappedProperties(
    typeMap: { [key: string]: Type },
    entityRootTypePath?: CollectionDataEntityType,
  ): TypeProperty[] {
    const matchedProperties: TypeProperty[] = [];
    const shouldAddAllProperties = entityRootTypePath === CollectionDataEntityType.ASSORTMENT_ITEM;
    const entityType: Type = typeMap[entityRootTypePath];
    const itemType: Type = typeMap['item'];
    const projectItemType: Type = typeMap['project-item'];
    const propertiesToIgnore = ['createdOn', 'createdBy', 'updatedOn', 'updatedBy'];

    for (const itemProperty of itemType.typeProperties) {
      if (propertiesToIgnore.includes(itemProperty.slug)) {
        continue;
      }

      if (shouldAddAllProperties) {
        matchedProperties.push(itemProperty);
        continue;
      }

      const isMatch = entityType.typeProperties.some((entityProperty) => entityProperty.slug === itemProperty.slug);
      if (isMatch) {
        matchedProperties.push(itemProperty);
      }
    }

    for (const projectItemProperty of projectItemType.typeProperties) {
      if (propertiesToIgnore.includes(projectItemProperty.slug)) {
        continue;
      }

      if (shouldAddAllProperties) {
        matchedProperties.push(projectItemProperty);
        continue;
      }

      const isMatch = entityType.typeProperties.some(
        (entityProperty) => entityProperty.slug === projectItemProperty.slug,
      );
      if (isMatch) {
        matchedProperties.push(projectItemProperty);
      }
    }

    return matchedProperties;
  }

  /** This function is a little tired, and seems to be getting copied around. */
  public static buildCollectionDataEntityWithMappedProperties(
    data,
    mappedProperties: Array<string> = [],
    itemPropertyMap,
    projectItemPropertyMap,
    orgConfig: OrgConfig,
  ): CollectionDataEntity {
    this.setThumbnailOfCollectionData(data, orgConfig);
    if (data.itemOption || data.itemFamily) {
      const itemOption = data.itemOption;
      const itemFamily = data.itemFamily;

      // Handle itemType property, which is special handling for managing the item type.
      if (itemFamily) {
        data.itemTypeId = itemFamily.typeId;
      }

      for (const propSlug of mappedProperties) {
        const itemProperty = itemPropertyMap[propSlug];
        if (itemProperty) {
          if (itemOption) {
            data[propSlug] = itemOption[propSlug];
          } else if (itemFamily && propSlug !== 'optionName' && itemProperty?.propertyLevel !== 'option') {
            data[propSlug] = itemFamily[propSlug];
          }
        }

        const projectItemProperty = projectItemPropertyMap[propSlug];
        if (projectItemProperty) {
          if (itemOption?.projectItem && ['option', 'overridable', 'all'].includes(projectItemProperty.propertyLevel)) {
            data[propSlug] = itemOption.projectItem[propSlug];
          } else if (
            itemFamily?.projectItem &&
            ['family', 'overridable', 'all'].includes(projectItemProperty.propertyLevel)
          ) {
            data[propSlug] = itemFamily.projectItem[propSlug];
          }
        }
      }
    }
    return data;
  }

  static setThumbnailOfCollectionData(collectionData: CollectionDataEntity, orgConfig: OrgConfig): void {
    if (!collectionData.itemOption && !collectionData.itemFamily) return;
    const urlsToSet = this.getUrlsToSetForThumbnail(collectionData.itemOption, collectionData.itemFamily, orgConfig);

    collectionData.thumbnail = urlsToSet.thumbnail;
    collectionData.thumbnailPreview = urlsToSet.thumbnailPreview;
  }

  public static getUrlsToSetForThumbnail(
    itemOption,
    itemFamily,
    orgConfig: OrgConfig,
  ): { thumbnail: string; thumbnailPreview: string } {
    if (!itemOption && !itemFamily) return;

    const shouldShowFamilyThumbnail = Boolean(
      (itemOption &&
        orgConfig.showFamilyThumbnailInPlanWhenNoOptionThumbnail &&
        !itemOption.smallViewableDownloadUrl) ||
        (!itemOption && itemFamily),
    );

    let thumbnail = null;
    let thumbnailPreview = null;

    if (shouldShowFamilyThumbnail) {
      thumbnail = itemFamily?.smallViewableDownloadUrl;
      thumbnailPreview = itemFamily?.mediumViewableDownloadUrl;
    } else {
      thumbnail = itemOption?.smallViewableDownloadUrl;
      thumbnailPreview = itemOption?.mediumViewableDownloadUrl;
    }

    if (!thumbnail) {
      return { thumbnail: null, thumbnailPreview: null };
    }

    return { thumbnail, thumbnailPreview };
  }

  public static getPropertiesFromTypeMapByCollectionDataType(
    typeMap: { [key: string]: Type },
    collectionDataType: CollectionDataEntityType,
  ): TypeProperty[] {
    if (collectionDataType === CollectionDataEntityType.PLAN_PLACEHOLDER) {
      const planPlaceholderType = typeMap['plan-placeholder'];
      return planPlaceholderType?.typeProperties ?? [];
    }

    if (collectionDataType === CollectionDataEntityType.ASSORTMENT_ITEM) {
      const assortmentItemType = typeMap['assortment-item'];
      const itemType = typeMap['item'];
      const projectItemType = typeMap['project-item'];

      const assortmentItemTypeProperties =
        assortmentItemType?.typeProperties?.map((typeProperty) => {
          return Object.assign({}, typeProperty, { typeRootSlug: 'assortment-item' });
        }) || [];

      const itemTypeProperties =
        itemType?.typeProperties?.map((typeProperty) => {
          return Object.assign({}, typeProperty, { typeRootSlug: 'item' });
        }) || [];

      const projectItemTypeProperties =
        projectItemType?.typeProperties?.map((typeProperty) => {
          return Object.assign({}, typeProperty, { typeRootSlug: 'project-item' });
        }) || [];

      const allTypeProperties = [...assortmentItemTypeProperties, ...itemTypeProperties, ...projectItemTypeProperties];
      return allTypeProperties;
    }
  }
}
