<table>
  <th
    *ngFor="let column of columnDefinitions"
    [style.max-width.px]="column.width"
    [class.text-right]="column.alignment === 'right'"
    [class.text-left]="column.alignment === 'left'"
    [class.!pl-0]="column.label === 'Type'"
  >
    <ng-container *ngIf="!sortBy">{{ column.label }}</ng-container>
    <ng-container *ngIf="sortBy">
      <span class="label" (debounceClick)="sort(column)" appDebounceClick appHoverActive>
        {{ column.label }}
        <span *ngIf="column?.label && column.label === sortBy?.label" class="material-icons z-40">
          {{ sortBy?.direction === 'asc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </span>
        <span *ngIf="column?.label" class="material-icons z-50 hover-icon opacity-0">
          {{ sortBy?.direction === 'desc' ? 'arrow_drop_up' : 'arrow_drop_down' }}
        </span>
      </span>
    </ng-container>
  </th>
  <tr
    *ngFor="let itemData of listData; index as idx"
    [class.selected]="itemData?.selected"
    [class.droppable]="itemData?.isDropTarget"
    [class.dragging]="draggingSelected && itemData?.selected"
    [id]="itemData?.entity?.id"
    [draggable]="draggable"
    (dragstart)="handleDragStart($event, itemData, idx)"
    (dragend)="handleDragEnd($event, itemData)"
    (dragenter)="handleDragEnter($event, itemData)"
    (dragleave)="handleDragLeave($event, itemData)"
    (dragover)="handleDragOver($event)"
    (drop)="handleDrop($event, itemData)"
    (click)="handleClickRow($event, itemData, idx)"
    (dblclick)="handleDoubleClickRow(itemData)"
    (contextmenu)="handleContextMenu($event, itemData)"
  >
    <td
      *ngFor="let column of columnDefinitions"
      [style.width.px]="column.width"
      [class.text-right]="column.alignment === 'right'"
      [class.text-left]="column.alignment === 'left'"
    >
      <app-list-item-cell
        [listItemData]="itemData"
        [columnDefinition]="column"
        (checkClick)="checkBoxClick($event, itemData, idx)"
        (actionClick)="cellActionClick($event, itemData)"
      ></app-list-item-cell>
    </td>
  </tr>
</table>
