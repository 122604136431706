import { createSelector } from '@ngrx/store';
import { WorkspacePrincipal } from '@common/workspaces/workspace-principal';
import { workspacePrincipalsEntityAdapter } from './workspace-principals.state';

export const { selectAll, selectEntities, selectIds, selectTotal } = workspacePrincipalsEntityAdapter.getSelectors();

const workspacePrincipalEntities = (state: any) => state.workspacePrincipals.workspacePrincipals.entities;
export const workspacePrincipals = createSelector(
  workspacePrincipalEntities,
  (state) => Object.values(state) as Array<WorkspacePrincipal>,
);
export const currentWorkspacePrincipal = (state: any) => state.workspacePrincipals.currentWorkspacePrincipal;
