import { Action, createReducer, on } from '@ngrx/store';
import { collectionElementReducers } from './collection-elements/collection-element.reducers';
import { collectionElementInitialState } from './collection-elements/collection-elements.state';
import * as CollectionManagerActions from './collection-manager.actions';
import { initialState, State } from './collection-manager.state';
import { collectionStatusMessagesReducers } from './collection-status-messages/collection-status-messages.reducers';
import { collectionStatusMessagesInitialState } from './collection-status-messages/collection-status-messages.state';
import { collectionViewsReducers } from './collection-views/collection-views.reducers';

const clear = (state: State, {}) => {
  return {
    ...state,
    collectionElements: collectionElementInitialState,
    collectionStatusMessages: collectionStatusMessagesInitialState,
    currentViewDefinition: null,
    hoveredEntityId: null,
    selectedEntityIds: [],
    selectedProperty: null,
    remoteSelectedProperties: new Map(),
    selectedElementLocation: null,
    scrollHorizontalPercentage: 0,
    scrollVerticalPercentage: 0,
    filterDefinition: null,
    selectorActive: false,
    selectorKeyActive: false,
    rowSelectorActive: false,
    dragActiveRow: null,
    copiedRows: [],
    anchorRowSelectorId: null,
    focusedItemFamily: null,
    contextMenuActive: false,
  };
};
const setEditorMode = (state: State, { editorMode }) => {
  return {
    ...state,
    editorMode,
  };
};
const setHoveredEntityId = (state: State, { id }) => {
  return {
    ...state,
    hoveredEntityId: id,
  };
};
const addSelectedEntityId = (state: State, { id }) => {
  return {
    ...state,
    selectedEntityIds: [...state.selectedEntityIds, id],
    anchorRowSelectorId: id,
  };
};

const setSelectedEntityIds = (state: State, { ids }) => {
  return {
    ...state,
    selectedEntityIds: ids,
    anchorRowSelectorId: ids[ids.length - 1],
  };
};

const removeSelectedEntityId = (state: State, { id }) => {
  return {
    ...state,
    selectedEntityIds: [...state.selectedEntityIds.filter((i: any) => i !== id)],
  };
};
const removeSelectedEntityIds = (state: State, { ids }) => {
  return {
    ...state,
    selectedEntityIds: [...state.selectedEntityIds.filter((i: string) => !ids.includes(i))],
  };
};

const clearSelectedEntityIds = (state: State) => {
  return {
    ...state,
    selectedEntityIds: [],
  };
};

/** Updates which properties are selected by remote clients  */
const updateRemoteSelectedProperties = (state: State, { clientId, entityId, propertySlug, user }) => {
  const currentMap = state.remoteSelectedProperties;
  const newMap = new Map(currentMap);
  newMap.set(clientId, { clientId, entityId, propertySlug, user });
  return {
    ...state,
    remoteSelectedProperties: newMap,
  };
};
const setSelectedEntityProperty = (state: State, { entityId, propertySlug, propertyValue, propertyType }) => {
  let prop = null;
  if (entityId && propertySlug) {
    prop = { entityId, propertySlug, propertyValue, propertyType };
  }
  return {
    ...state,
    selectedProperty: prop,
  };
};

const setSelectedElementLocation = (state: State, { location }) => {
  return {
    ...state,
    selectedElementLocation: location,
  };
};
const setTypeDefinitions = (state: State, { typeDefinitions }) => {
  return {
    ...state,
    typeDefinitions,
  };
};
const setScrollHorizontalPercentage = (state: State, { percentage }) => {
  return {
    ...state,
    scrollHorizontalPercentage: percentage,
  };
};
const setScrollVerticalPercentage = (state: State, { percentage }) => {
  return {
    ...state,
    scrollVerticalPercentage: percentage,
  };
};
const setSorts = (state: State, { sorts }) => {
  return {
    ...state,
    sorts,
  };
};

const setFilterDefinition = (state: State, { filterDefinition }) => {
  return {
    ...state,
    filterDefinition,
  };
};

const setGridTotalWidth = (state: State, { gridTotalWidth }) => {
  return {
    ...state,
    gridTotalWidth,
    scrollVerticalPercentage: 0, // reset scroll upon resize
    scrollHorizontalPercentage: 0, // reset scroll upon resize
  };
};

const setGridTotalHeight = (state: State, { gridTotalHeight }) => {
  return {
    ...state,
    gridTotalHeight,
    scrollVerticalPercentage: 0, // reset scroll upon resize
    scrollHorizontalPercentage: 0, // reset scroll upon resize
  };
};

const setCollectionElementsLoaded = (state: State, { loaded }) => {
  return {
    ...state,
    collectionElementsLoaded: loaded,
  };
};

const setHideEmptyGroups = (state: State, { hideEmptyGroups }) => {
  return {
    ...state,
    hideEmptyGroups,
  };
};

const setSelectorActive = (state: State, { selectorActive }) => {
  return {
    ...state,
    selectorActive,
  };
};

const setSelectorKeyActive = (state: State, { selectorKeyActive }) => {
  return {
    ...state,
    selectorKeyActive,
  };
};

const setRowSelectorActive = (state: State, { rowSelectorActive }) => {
  return {
    ...state,
    rowSelectorActive,
  };
};

const setDragActiveRow = (state: State, { dragActiveRow }) => {
  return {
    ...state,
    dragActiveRow,
  };
};

const setCopiedRows = (state: State, { copiedRows }) => {
  return {
    ...state,
    copiedRows,
  };
};

const setAnchorRowSelectorId = (state: State, { anchorRowSelectorId }) => {
  return {
    ...state,
    anchorRowSelectorId,
  };
};

const resetSearchReplace = (state: State) => {
  return {
    ...state,
  };
};

const setSidePanelWidth = (state: State, { sidePanelWidth }) => {
  return {
    ...state,
    sidePanelWidth,
  };
};

const setStatusMessageElement = (state: State, { statusMessageElement }) => {
  return {
    ...state,
    statusMessageElement,
  };
};

const setFocusedItemFamily = (state: State, { itemFamily }) => {
  return {
    ...state,
    focusedItemFamily: itemFamily,
  };
};
const setSpreadValueInfo = (state: State, { spreadValueInfo }) => {
  return {
    ...state,
    spreadValueInfo,
  };
};

const setContextMenuActive = (state: State, { contextMenuActive }) => {
  return {
    ...state,
    contextMenuActive,
  };
};

export const collectionManagerReducer = createReducer(
  initialState,
  ...collectionElementReducers,
  ...collectionViewsReducers,
  ...collectionStatusMessagesReducers,

  on(CollectionManagerActions.setCollectionElementsLoaded, setCollectionElementsLoaded),

  // WHAT IS HOVERED
  on(CollectionManagerActions.clearCollectionManager, clear),

  // WHAT IS HOVERED
  on(CollectionManagerActions.setHoveredEntityId, setHoveredEntityId),

  // SELECTED ROWS/ENTITIIES
  on(CollectionManagerActions.addSelectedEntityId, addSelectedEntityId),
  on(CollectionManagerActions.setSelectedEntityIds, setSelectedEntityIds),
  on(CollectionManagerActions.removeSelectedEntityId, removeSelectedEntityId),
  on(CollectionManagerActions.removeSelectedEntityIds, removeSelectedEntityIds),
  on(CollectionManagerActions.clearSelectedEntityIds, clearSelectedEntityIds),

  // WHICH CELLS/PROPERTIES ARE HIGHLIGHTED/SELECTED
  // Sets which 'property' is selected (close to a seleted cell) in the local editor
  on(CollectionManagerActions.setSelectedEntityProperty, setSelectedEntityProperty),
  // Update which cells, etc are selected by remote clients
  on(CollectionManagerActions.updateRemoteSelectedEntityProperties, updateRemoteSelectedProperties),

  // COORDS OF LOCAL SELECTED ELEMENT
  // Update which cells, etc are selected by remote clients
  on(CollectionManagerActions.setSelectedElementLocation, setSelectedElementLocation),

  on(CollectionManagerActions.setTypeDefinitions, setTypeDefinitions),

  // HANDLES SCROLL PERCENTAGES
  on(CollectionManagerActions.setScrollHorizontalPercentage, setScrollHorizontalPercentage),
  on(CollectionManagerActions.setScrollVerticalPercentage, setScrollVerticalPercentage),

  // SORTS
  on(CollectionManagerActions.setSorts, setSorts),

  // FILTER DEFINITION
  on(CollectionManagerActions.setFilterDefinition, setFilterDefinition),

  // SET GRID TOTAL WIDTH
  on(CollectionManagerActions.setGridTotalWidth, setGridTotalWidth),

  // SET GRID TOTAL HEIGHT
  on(CollectionManagerActions.setGridTotalHeight, setGridTotalHeight),

  on(CollectionManagerActions.setHideEmptyGroups, setHideEmptyGroups),

  on(CollectionManagerActions.setEditorMode, setEditorMode),

  on(CollectionManagerActions.setSelectorActive, setSelectorActive),

  on(CollectionManagerActions.setSelectorKeyActive, setSelectorKeyActive),

  on(CollectionManagerActions.setRowSelectorActive, setRowSelectorActive),

  on(CollectionManagerActions.setRowDragActive, setDragActiveRow),

  on(CollectionManagerActions.setCopiedRows, setCopiedRows),

  on(CollectionManagerActions.setAnchorRowSelectorId, setAnchorRowSelectorId),

  on(CollectionManagerActions.resetSearchReplace, resetSearchReplace),

  on(CollectionManagerActions.setSidePanelWidth, setSidePanelWidth),

  on(CollectionManagerActions.setStatusMessageElement, setStatusMessageElement),

  on(CollectionManagerActions.setFocusedItemFamily, setFocusedItemFamily),
  on(CollectionManagerActions.setSpreadValueInfo, setSpreadValueInfo),

  on(CollectionManagerActions.setContextMenuActive, setContextMenuActive),
);

export function reducer(state: State | undefined, action: Action): any {
  return collectionManagerReducer(state, action);
}
