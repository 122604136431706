import { Injectable } from '@angular/core';
import { ViewDefinition } from '@contrail/client-views';
import { Entities } from '@contrail/sdk';
import { Type } from '@contrail/types';
import { Store } from '@ngrx/store';
import { filter, take, tap } from 'rxjs/operators';
import { ViewManagerService } from '@common/views/view-manager.service';
import { RootStoreState } from '@rootstore';
import { CollectionManagerSelectors } from './collection-manager-store';

export enum ViewDefinitionApplicationViewSlug {
  HUB_ASSORTMENT_EDITOR = 'hub:assortment_editor',
  PLAN_EDITOR = 'plan:plan_editor',
}

@Injectable({
  providedIn: 'root',
})
export class CollectionManagerViewService {
  private defaultViewSlugMapping = {
    kanban: ['itemFamily', 'itemOption', 'thumbnail'],
    matrix: ['itemFamily', 'itemOption', 'thumbnail'],
    grid: ['ALL'],
  };
  private removedViewAttributes: any = {};

  constructor(
    private store: Store<RootStoreState.State>,
    private viewsService: ViewManagerService,
  ) {}

  public async getViewDefinitionsByEntityIdAndViewSlug(entityId: string, viewSlug: ViewDefinitionApplicationViewSlug) {
    if (!entityId) {
      return [];
    }

    if (viewSlug === ViewDefinitionApplicationViewSlug.PLAN_EDITOR) {
      return this.viewsService.getViewDefinitions({
        applicationViewSlug: ViewDefinitionApplicationViewSlug.PLAN_EDITOR,
        contextReference: `plan:${entityId}`,
      });
    }

    if (viewSlug === ViewDefinitionApplicationViewSlug.HUB_ASSORTMENT_EDITOR) {
      return this.viewsService.getViewDefinitions({
        applicationViewSlug: ViewDefinitionApplicationViewSlug.HUB_ASSORTMENT_EDITOR,
        contextReference: `assortment:${entityId}`,
      });
    }

    return [];
  }

  public buildDefaultPlanViewDefinition(planId: string): ViewDefinition {
    return {
      applicationViewSlug: ViewDefinitionApplicationViewSlug.PLAN_EDITOR,
      contextReference: `plan:${planId}`,
      label: 'Default',
      viewType: 'grid',
      properties: [
        {
          enabled: true,
          slug: 'itemFamily',
          typeRootSlug: 'plan-placeholder',
        },
        {
          enabled: true,
          slug: 'itemOption',
          typeRootSlug: 'plan-placeholder',
        },
      ],
    };
  }

  public buildDefaultAssortmentViewDefinition(assortmentId: string): ViewDefinition {
    return {
      applicationViewSlug: ViewDefinitionApplicationViewSlug.HUB_ASSORTMENT_EDITOR,
      contextReference: `assortment:${assortmentId}`,
      label: 'Default',
      viewType: 'pivot',
      properties: [],
    };
  }

  /** Gets views from the API and binds the correct type-property definition to the views.
   * We do this here so that the view definition doesn't need to keep a local copy of the
   * type definition. We could move this processing to the server potentially.
   */
  async getViewDefinitionsAndBindProperties(
    contextReference: string,
    applicationViewSlug: ViewDefinitionApplicationViewSlug,
  ): Promise<ViewDefinition[]> {
    const viewDefinitions = await this.viewsService.getViewDefinitions({
      applicationViewSlug,
      contextReference,
    });

    for (const viewDef of viewDefinitions) {
      await this.bindPropertiesToView(viewDef);
    }

    return viewDefinitions;
  }

  public async getViewDefinitionById(id: string) {
    return new Entities().get({ entityName: 'view-definition', id });
  }
  public async createViewDefinitions(
    viewDefinitions: Array<ViewDefinition>,
    context: {
      currentPlanId?: string;
      currentAssortmentId?: string;
    },
  ): Promise<Array<ViewDefinition>> {
    const results = [];
    for (const viewDefinition of viewDefinitions) {
      const contextReference = this.getContextReferenceForViewDefinition(viewDefinition, context);
      results.push(await this.createViewDefinition(viewDefinition, contextReference));
    }
    return results;
  }

  private getContextReferenceForViewDefinition(
    viewDefinition: ViewDefinition,
    context: { currentPlanId?: string; currentAssortmentId?: string },
  ): string {
    if (viewDefinition.contextReference) {
      return viewDefinition.contextReference;
    }

    if (viewDefinition.applicationViewSlug === ViewDefinitionApplicationViewSlug.PLAN_EDITOR && context.currentPlanId) {
      return `plan:${context.currentPlanId}`;
    }

    if (
      viewDefinition.applicationViewSlug === ViewDefinitionApplicationViewSlug.HUB_ASSORTMENT_EDITOR &&
      context.currentAssortmentId
    ) {
      return `assortment:${context.currentAssortmentId}`;
    }

    return null;
  }

  public async createViewDefinition(
    viewDefinition: ViewDefinition,
    contextReference = null,
    initWithProperties = true,
  ) {
    let properties = viewDefinition.properties;
    if (!properties?.length) {
      properties = [];
    }
    const newViewDef: ViewDefinition = { ...viewDefinition, properties, contextReference };
    const newView = await new Entities().create({ entityName: 'view-definition', object: newViewDef });
    console.log('Created new view: ', newView);
    return newView;
  }
  public async deleteViewDefinition(viewDefinition: ViewDefinition) {
    await new Entities().delete({ entityName: 'view-definition', id: viewDefinition.id });
    return viewDefinition;
  }
  public async updateViewDefinition(id: string, changes: ViewDefinition) {
    const adjustedViewDefinition = this.getViewDefinitionWithRestrictedPropertiesAddedBack(id, changes);
    return new Entities().update({ entityName: 'view-definition', id, object: adjustedViewDefinition });
  }

  public async updateViewSortOrder(data) {
    const updatedViewDefs = [];
    for (const view of data) {
      updatedViewDefs.push(await this.updateViewDefinition(view.id, { sortOrder: parseInt(view.sortOrder) }));
    }
    return updatedViewDefs;
  }

  public async bindPropertiesToView(viewDefinition: ViewDefinition): Promise<ViewDefinition> {
    return new Promise((res) => {
      this.store
        .select(CollectionManagerSelectors.typeDefinitions)
        .pipe(
          filter((typeMap) => !!typeMap),
          take(1),
          tap((typeMap) => {
            if (viewDefinition?.properties) {
              const properties = this.buildViewDefinitionProperties(viewDefinition, typeMap);
              viewDefinition.properties = properties;
            }
            res(viewDefinition);
          }),
        )
        .subscribe();
    });
  }

  public buildViewDefinitionProperties(viewDefinition: ViewDefinition, typeMap: { [key: string]: Type }) {
    const properties = [];
    for (const property of viewDefinition.properties) {
      const type = typeMap[property.typeRootSlug] || typeMap[property['rootTypeSlug']] || typeMap['plan-placeholder'];
      const propertyDefinition = type?.typeProperties?.find((typeProp) => typeProp.slug === property.slug);
      if (!propertyDefinition) {
        continue;
      }

      properties.push({ ...property, propertyDefinition });
    }

    return properties;
  }

  public getViewDefinitionWithRestrictedPropertiesRemoved(viewId: string, viewDefinition: ViewDefinition) {
    const restrictedPropertySlugs = this.getRestrictedPropertySlugsForView(viewDefinition);
    if (!restrictedPropertySlugs?.length) {
      return viewDefinition;
    }

    if (!this.removedViewAttributes[viewId]) {
      this.removedViewAttributes[viewId] = {
        properties: [],
        filters: [],
        sorts: [],
      };
    }

    const viewWithRemovedProperties = this.removeRestrictedPropertiesFromView(
      viewId,
      viewDefinition,
      restrictedPropertySlugs,
    );
    const viewWithRemovedFilters = this.removeRestrictedFiltersFromView(
      viewId,
      viewWithRemovedProperties,
      restrictedPropertySlugs,
    );
    const viewWithRemovedSorts = this.removeRestrictedSortsFromView(
      viewId,
      viewWithRemovedFilters,
      restrictedPropertySlugs,
    );
    return viewWithRemovedSorts;
  }

  private getRestrictedPropertySlugsForView(viewDefinition: ViewDefinition): string[] {
    const typeMap = this.getTypeMap();
    if (!typeMap) {
      return [];
    }

    const isForAssortmentEditor =
      viewDefinition.applicationViewSlug === ViewDefinitionApplicationViewSlug.HUB_ASSORTMENT_EDITOR;

    if (isForAssortmentEditor) {
      const assortmentItemType = typeMap['assortment-item'];
      const itemType = typeMap['item'];
      const projectItemType = typeMap['project-item'];

      return [
        ...(assortmentItemType?.restrictedTypePropertySlugs ?? []),
        ...(itemType?.restrictedTypePropertySlugs ?? []),
        ...(projectItemType?.restrictedTypePropertySlugs ?? []),
      ];
    }

    const planPlaceholderType = typeMap['plan-placeholder'];
    return planPlaceholderType?.restrictedTypePropertySlugs;
  }

  private removeRestrictedPropertiesFromView(viewId, viewDefinition, restrictedPropertySlugs) {
    if (!viewDefinition?.properties?.length) {
      return viewDefinition;
    }

    this.removedViewAttributes[viewId].properties = [];
    const logOfRemovedProperties = this.removedViewAttributes[viewId].properties;
    const nonRestrictedProperties = [];
    viewDefinition.properties.forEach((property, index) => {
      if (!restrictedPropertySlugs.includes(property.slug)) {
        nonRestrictedProperties.push(property);
      } else {
        const alreadyRemovedProperty = logOfRemovedProperties.find((p) => p.property.slug === property.slug);
        if (!alreadyRemovedProperty) {
          logOfRemovedProperties.push({ property, index });
        } else {
          alreadyRemovedProperty.index = index;
        }
      }
    });

    return {
      ...viewDefinition,
      properties: nonRestrictedProperties,
    };
  }

  private removeRestrictedFiltersFromView(viewId, viewDefintion, restrictedPropertySlugs) {
    if (!viewDefintion?.filterCriteria?.propertyCriteria?.length) {
      return viewDefintion;
    }

    this.removedViewAttributes[viewId].filters = [];
    const logOfRemovedPropertyFilters = this.removedViewAttributes[viewId].filters;
    const nonRestrictedPropertyFilters = [];
    viewDefintion.filterCriteria.propertyCriteria.forEach((filter, index) => {
      const property = filter.filterPropertyDefinition;
      if (!restrictedPropertySlugs.includes(property.slug)) {
        nonRestrictedPropertyFilters.push(filter);
      } else {
        logOfRemovedPropertyFilters.push({ filter, index });
      }
    });

    return {
      ...viewDefintion,
      filterCriteria: {
        ...viewDefintion.filterCriteria,
        propertyCriteria: nonRestrictedPropertyFilters,
      },
    };
  }

  private removeRestrictedSortsFromView(viewId, viewDefintion, restrictedPropertySlugs) {
    if (!viewDefintion?.sorts?.length) {
      return viewDefintion;
    }

    this.removedViewAttributes[viewId].sorts = [];
    const logOfRemovedSorts = this.removedViewAttributes[viewId].sorts;
    const nonRestrictedSorts = [];
    viewDefintion.sorts.forEach((sort, index) => {
      if (!restrictedPropertySlugs.includes(sort.propertySlug)) {
        nonRestrictedSorts.push(sort);
      } else {
        logOfRemovedSorts.push({ sort, index });
      }
    });

    return {
      ...viewDefintion,
      sorts: nonRestrictedSorts,
    };
  }

  public getViewDefinitionWithRestrictedPropertiesAddedBack(viewId: string, viewDefinition: any) {
    const viewWithAddedProperties = this.addRemovedPropertiesBackToView(viewId, viewDefinition);
    const viewWithAddedFilters = this.addRemovedFiltersBackToView(viewId, viewWithAddedProperties);
    const viewWithAddedSorts = this.addRemovedSortsBackToView(viewId, viewWithAddedFilters);
    return viewWithAddedSorts;
  }

  private addRemovedPropertiesBackToView(viewId: string, viewDefinition: any) {
    const removedProperties = this.removedViewAttributes[viewId]?.properties;
    if (!removedProperties?.length || !viewDefinition?.properties) {
      return viewDefinition;
    }

    const updatedProperties = [...viewDefinition.properties];

    removedProperties.forEach(({ property, index }) => {
      const hasPropertyAlreadyBeenReadded = updatedProperties.find(
        (existingProperty) => existingProperty.slug === property.slug,
      );
      if (!hasPropertyAlreadyBeenReadded) {
        updatedProperties.splice(index, 0, property);
      }
    });

    return {
      ...viewDefinition,
      properties: updatedProperties,
    };
  }

  private addRemovedFiltersBackToView(viewId: string, viewDefinition: any) {
    const removedPropertyFilters = this.removedViewAttributes[viewId]?.filters;
    if (!removedPropertyFilters?.length || !viewDefinition?.filterCriteria?.propertyCriteria) {
      return viewDefinition;
    }

    const updatedPropertyFilters = [...viewDefinition.filterCriteria.propertyCriteria];

    removedPropertyFilters.forEach(({ filter, index }) => {
      updatedPropertyFilters.splice(index, 0, filter);
    });

    return {
      ...viewDefinition,
      filterCriteria: {
        ...viewDefinition.filterCriteria,
        propertyCriteria: updatedPropertyFilters,
      },
    };
  }

  private addRemovedSortsBackToView(viewId: string, viewDefinition: any) {
    const removedPropertySorts = this.removedViewAttributes[viewId]?.sorts;
    if (!removedPropertySorts?.length || !viewDefinition?.sorts) {
      return viewDefinition;
    }

    const updatedSorts = [...viewDefinition.sorts];

    removedPropertySorts.forEach(({ sort, index }) => {
      updatedSorts.splice(index, 0, sort);
    });

    return {
      ...viewDefinition,
      sorts: updatedSorts,
    };
  }

  public copyAndApplyPropertyDefinitionsFromPreviousView(newViewDef, previousViewDef) {
    if (!previousViewDef || !newViewDef?.properties) {
      return newViewDef;
    }

    const hydratedProperties = [];
    for (const property of newViewDef.properties) {
      const propertyInPreviousViewDef = previousViewDef?.properties.find((p) => p.slug === property.slug);
      if (!property.propertyDefinition && propertyInPreviousViewDef?.propertyDefinition) {
        hydratedProperties.push({ ...property, propertyDefinition: propertyInPreviousViewDef.propertyDefinition });
      } else {
        hydratedProperties.push(property);
      }
    }

    return {
      ...newViewDef,
      properties: hydratedProperties,
    };
  }

  private getTypeMap() {
    let typeMap;
    this.store
      .select(CollectionManagerSelectors.typeDefinitions)
      .pipe(
        take(1),
        tap((map) => {
          typeMap = map;
        }),
      )
      .subscribe();
    return typeMap;
  }
}
