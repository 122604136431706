import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { WebSocketService } from 'src/app/common/web-socket/web-socket.service';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { ShowroomsActions } from '.';
import { Showroom } from './showrooms.state';
import { ShowroomsService } from '../showrooms.service';
import { RoutingService } from '@common/routing/routing.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';

@Injectable()
export class ShowroomsEffects {
  constructor(
    private actions$: Actions,
    private showroomService: ShowroomsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private webSocketService: WebSocketService,
    private routingService: RoutingService,
  ) {}
  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          // this.store.dispatch(ShowroomsActions.clearShowrooms());
          // this.store.dispatch(ShowroomsActions.loadShowrooms());
        }),
      ),
    { dispatch: false },
  );
  loadShowrooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowroomsActions.ShowroomsActionTypes.LOAD_SHOWROOMS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.showroomService.getShowrooms(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => ShowroomsActions.loadShowroomsSuccess({ data })),
          catchError((error) => observableOf(ShowroomsActions.loadShowroomsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentShowrooms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShowroomsActions.ShowroomsActionTypes.LOAD_CURRENT_SHOWROOM),
      switchMap((action: any) => {
        return from(this.showroomService.getShowroomById(action.id)).pipe(
          map((data) => ShowroomsActions.loadCurrentShowroomSuccess({ showroom: data })),
          catchError((error) => observableOf(ShowroomsActions.loadCurrentShowroomFailure({ error }))),
        );
      }),
    ),
  );
  createShowroom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowroomsActions.ShowroomsActionTypes.CREATE_SHOWROOM),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating showroom.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          const showroom: Showroom = { ...action.showroom };
          showroom.workspaceId = store.workspaces.currentWorkspaceFolder.id;
          return from(this.showroomService.createShowroom(showroom)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showroom Created.', '', { duration: 2000 });

              // Could be we only want this in hub....
              this.routingService.openApp('showroom', `showrooms/view/${data.id}`);
              this.store.dispatch(WorkspacesActions.addEntity({ entity: data }));
              return ShowroomsActions.createShowroomSuccess({ showroom: data });
            }),
            catchError((error) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowroomsActions.createShowroomFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  deleteShowroom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowroomsActions.ShowroomsActionTypes.DELETE_SHOWROOM),
        withLatestFrom(this.store),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Deleting showroom.' }));
        }),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.showroomService.deleteShowroomById(action.showroomId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showroom Deleted.', '', { duration: 2000 });
              this.store.dispatch(
                WorkspacesActions.removeFolderEntities({ entityReferences: [`showroom:${action.showroomId}`] }),
              );
              return ShowroomsActions.deleteShowroomSuccess({ showroomId: action.showroomId });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowroomsActions.deleteShowroomFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  updateShowroom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowroomsActions.ShowroomsActionTypes.UPDATE_SHOWROOM),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Updating showroom.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.showroomService.updateShowroom(action.id, action.changes)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showroom Updated.', '', { duration: 2000 });
              if (store.workspaces.currentWorkspaceFolder?.id) {
                this.store.dispatch(
                  WorkspacesActions.loadWorkspaceFolderEntities({
                    workspaceId: store.workspaces.currentWorkspaceFolder.id,
                  }),
                );
              }

              this.store.dispatch(ShowroomsActions.updateShowroomSuccess({ id: data.id, changes: data }));
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowroomsActions.updateShowroomFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  copyShowroom$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ShowroomsActions.ShowroomsActionTypes.COPY_SHOWROOM),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Copying Showroom.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.showroomService.copy(action.name, action.sourceId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Showroom Copied.', '', { duration: 2000 });
              this.routingService.openApp('showroom', `showrooms/view/${data.id}`);
              this.store.dispatch(
                WorkspacesActions.loadWorkspaceFolderEntities({
                  workspaceId: store.workspaces.currentWorkspaceFolder.id,
                }),
              );
              return ShowroomsActions.copyShowroomSuccess({ showroom: data });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(ShowroomsActions.copyShowroomFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
}
