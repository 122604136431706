<app-search-bar class="w-60" [placeholder]="'Search'"></app-search-bar>

<div
  *ngIf="workspaces$"
  [ngClass]="{ 'max-h-[420px]': more }"
  class="narrow-scroll w-[250px] overflow-y-auto max-h-72 mt-3"
>
  <div *ngFor="let workspace of (workspaces$ | async)?.slice(0, slice)" class="narrow-scroll w-60">
    <app-workspace-item [workspace]="workspace"></app-workspace-item>
  </div>
</div>

<p class="text-blue-600 font-bold mt-2 cursor-pointer" *ngIf="(workspaces$ | async)?.length > 4">
  <span (click)="slice = more ? 4 : undefined; more = !more">
    {{ more ? 'Show less' : 'Show more' }}
  </span>
</p>
