<div
  class="flex items-center min-h-[25px]"
  [class.justify-start]="columnDefinition.alignment === 'left'"
  [class.justify-end]="columnDefinition.alignment === 'right'"
>
  <div [ngSwitch]="columnDefinition?.propertyType" class="flex items-center">
    <span *ngSwitchCase="'check'" (click)="trPrevent($event)" class="w-8 h-6 flex-center">
      <mat-checkbox
        [checked]="listItemData?.selected"
        (change)="selectRow($event)"
        (dblclick)="quickChangePrevent($event)"
      ></mat-checkbox>
    </span>
    <span *ngSwitchCase="'thumbnail'" class="flex-center img-wrap">
      <img [attr.src]="display | secureImage | async" />
    </span>
    <!-- [ngStyle]="{'width.px': columnDefinition?.width}" -->
    <span *ngSwitchCase="'icon'" class="flex-center img-wrap">
      <img [src]="display" />
    </span>
    <ng-container *ngSwitchCase="'color'">
      <span class="flex-center img-wrap" *ngIf="listItemData?.icon">
        <img [src]="listItemData.icon" />
      </span>
      <span
        *ngIf="!listItemData?.icon"
        class="flex-center min-w-[24px] min-h-[24px]"
        [ngStyle]="{ 'background-color': '#' + listItemData?.entity.hex }"
      ></span>
    </ng-container>

    <span
      *ngSwitchCase="'matIcon'"
      class="material-icons"
      (click)="rowAction($event, display)"
      (dblclick)="rowAction($event, display)"
      [matTooltip]="columnDefinition?.tooltip"
      matTooltipPosition="before"
      [attr.data-test]="'trash-actions-' + display"
    >
      {{ display }}
    </span>

    <span *ngSwitchDefault>
      <ng-container *ngIf="!columnDefinition?.dateFormat; else dateFormat">
        <ng-container *ngIf="columnDefinition.shortenLength">
          <span matTooltipShowDelay="1000">{{ display | shorten: columnDefinition.shortenLength }}</span>
        </ng-container>
        <ng-container *ngIf="!columnDefinition.shortenLength">
          {{ display }}
        </ng-container>
      </ng-container>
      <ng-template #dateFormat>
        {{ display | date: columnDefinition?.dateFormat }}
      </ng-template>
    </span>
  </div>
</div>
