import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
@Component({
  selector: 'app-empty-placeholder',
  templateUrl: './empty-placeholder.component.html',
  styleUrls: ['./empty-placeholder.component.scss'],
})
export class EmptyPlaceholderComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() footer: string;
  @Input() actionLabel: string;

  @Output() private triggerAction = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}

  triggerEvent() {
    this.triggerAction.emit(true);
  }
}
