import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { CommentsActions } from '.';
import { WebSocketService } from '../../web-socket/web-socket.service';
import { CommentsService } from '../comments.service';

@Injectable()
export class CommentsEffects {
  constructor(
    private actions$: Actions,
    private store: Store<RootStoreState.State>,
    private commentsService: CommentsService,
    private webSocketService: WebSocketService,
  ) {}

  loadComments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.LOAD_COMMENTS),
      switchMap((action: any) => {
        return from(this.commentsService.loadComments(action.contextReference)).pipe(
          map((data) => CommentsActions.loadCommentsSuccess({ data })),
          catchError((error) => observableOf(CommentsActions.loadCommentsFailure({ error }))),
        );
      }),
    ),
  );

  createComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.CREATE_COMMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.commentsService.addComment(action.text, action.ownerInfo, action.changeSuggestion)).pipe(
          map((comment) => {
            // DONT LOVE WHERE THIS IS...
            this.webSocketService.sendMessage({
              sessionId: store.userSessions.currentSessionId,
              action: 'SESSION_EVENT',
              event: {
                eventType: 'ADD_COMMENT',
                changes: comment,
              },
            });
            return CommentsActions.createCommentSuccess({ comment });
          }),
          catchError((error) => observableOf(CommentsActions.createCommentFailure({ error }))),
        );
      }),
    ),
  );

  updateComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.UPDATE_COMMENT),
      switchMap((action: any) => {
        return from(this.commentsService.updateComment(action.comment)).pipe(
          map((comment) => CommentsActions.updateCommentSuccess({ comment })),
          catchError((error) => observableOf(CommentsActions.updateCommentFailure({ error }))),
        );
      }),
    ),
  );
  deleteComment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommentsActions.CommentsActionTypes.DELETE_COMMENT),
      switchMap((action: any) => {
        return from(this.commentsService.deleteComment(action.comment)).pipe(
          map((comment) => CommentsActions.deleteCommentSuccess({ comment })),
          catchError((error) => observableOf(CommentsActions.deleteCommentFailure({ error }))),
        );
      }),
    ),
  );
}
