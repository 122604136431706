import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-global-search-result-item',
  templateUrl: './global-search-result-item.component.html',
  styleUrls: ['./global-search-result-item.component.scss'],
})
export class GlobalSearchResultItemComponent implements OnInit {
  @Input() entity: any;
  constructor() {}

  ngOnInit(): void {}
}
