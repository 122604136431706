<ng-container>
  <ng-container *ngIf="side === 'left'">
    <div
      class="view"
      [style.marginLeft.px]="marginLeft"
      [ngClass]="{ 'expanded-row': data.expanded }"
      *ngIf="display && display !== ''"
    >
      <mat-icon *ngIf="data.children" (click)="toggleRow()">{{ icon }}</mat-icon>
      <div [style.width]="cellWidth">{{ display }}</div>
    </div>
  </ng-container>
  <ng-container *ngIf="side === 'right'">
    <div *ngIf="!showGoals" [ngClass]="{ 'expanded-row': data.expanded }">
      {{ display }}
    </div>
    <div *ngIf="showGoals" [ngClass]="{ 'expanded-row': data.expanded }" class="flex w-full relative h-full">
      <div style="width: 50%" class="flex justify-center text-ellipsis overflow-hidden whitespace-nowrap">
        {{ display }}
      </div>
      <div style="width: 50%" class="flex justify-center text-ellipsis overflow-hidden whitespace-nowrap split-cell">
        {{ goalDisplay }}
        <div style="font-size: 10px; width: 38px" [ngClass]="goalDifDisplayClass">{{ goalDifDisplay }}</div>
      </div>
    </div>
  </ng-container>
</ng-container>
