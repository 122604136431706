import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { FolderPrincipalsActions } from '@common/folders/folder-principals-store';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of as observableOf, from } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { AuthActions } from 'src/app/common/auth/auth-store';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '.';
import { WorkspaceFolderResults, WorkspaceFolderResultsNextPageData, WorkspacesService } from '../workspaces.service';

@Injectable()
export class WorkspacesEffects {
  constructor(
    private actions$: Actions,
    private workspaceService: WorkspacesService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {
    const sortByOption = JSON.parse(localStorage.getItem('entitySort'));
    if (sortByOption) {
      let parameters: any = {};
      parameters.order = [{ order: sortByOption.direction, orderField: sortByOption.property }];
      this.store.dispatch(WorkspacesActions.setWorkspaceEntitiesFolderParameters({ parameters }));
    }
  }

  authContextChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AuthActions.AuthActionTypes.SET_AUTH_CONTEXT),
        tap((action: any) => {
          this.store.dispatch(WorkspacesActions.clearWorkspaces());
          this.store.dispatch(WorkspacesActions.loadWorkspaces());
        }),
      ),
    { dispatch: false },
  );

  loadWorkspaces$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_WORKSPACES),
      switchMap((action: any) => {
        return from(this.workspaceService.getWorkspaces()).pipe(
          map((data) => WorkspacesActions.loadWorkspacesSuccess({ data })),
          catchError((error) => observableOf(WorkspacesActions.loadWorkspacesFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE),
      tap(() => {
        this.store.dispatch(WorkspacesActions.setCurrentWorkspaceLoading({ loading: true }));
      }),
      switchMap((action: any) => {
        return from(this.workspaceService.getWorkspaceById(action.id)).pipe(
          map((workspace) => WorkspacesActions.loadCurrentWorkspaceSuccess({ workspace })),
          tap(() => {
            this.store.dispatch(WorkspacesActions.setCurrentWorkspaceLoading({ loading: false }));
          }),
          catchError((error) => observableOf(WorkspacesActions.loadCurrentWorkspaceFailure({ error }))),
        );
      }),
    ),
  );

  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          console.log('WorksSpace: workspaceChange: ', action);
          this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace: action.workspace }));
        }),
      ),
    { dispatch: false },
  );

  loadCurrentFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.SET_CURRENT_WORKSPACE_FOLDER),
        tap((action: any) => {
          this.store.dispatch(FolderPrincipalsActions.loadFolderPrincipals()); //TODO: PERFORMANCE _ SKIP dispatch if rootWorkspaceLevel _ HUB only
          this.store.dispatch(WorkspacesActions.setWorkspaceEntitiesFolderParameters({ parameters: { search: null } }));
          //this.store.dispatch(WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: action.workspace?.id }));
        }),
      ),
    { dispatch: false },
  );

  setFolderEntitiesParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.SET_WORKSPACE_ENTITIES_FOLDER_PARAMETERS),
        withLatestFrom(this.store),
        tap(([action, store]: [any, RootStoreState.State]) => {
          this.store.dispatch(
            WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: store.workspaces.currentWorkspaceFolder.id }),
          );
        }),
      ),
    { dispatch: false },
  );

  loadFolderEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_WORKSPACE_FOLDER_ENTITIES),
      withLatestFrom(this.store),
      tap(() => {
        this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolderLoading({ loading: true }));
      }),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const currentFolderWorkspace = store.workspaces.currentWorkspaceFolder;
        const params = store.workspaces.workspaceEntitiesFolderParameters;
        return from(this.workspaceService.getWorkspaceEntities(currentFolderWorkspace, params)).pipe(
          map((workspaceEntityResults: WorkspaceFolderResults) => {
            this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolderLoading({ loading: false }));
            this.store.dispatch(
              WorkspacesActions.setWorkspaceEntitiesFolderSummary({
                workspaceEntitiesFolderSummary: workspaceEntityResults.summary,
              }),
            );
            return WorkspacesActions.loadWorkspaceFolderEntitiesSuccess({
              workspaceEntities: workspaceEntityResults.allEntities,
            });
          }),
          catchError((error) => observableOf(WorkspacesActions.loadCurrentWorkspaceFailure({ error }))),
        );
      }),
    ),
  );

  loadNextFolderPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_NEXT_FOLDER_PAGE),
      withLatestFrom(this.store),
      tap(() => {
        this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolderNextPageLoading({ loading: true }));
      }),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const currentFolderWorkspace = store.workspaces.currentWorkspaceFolder;
        const params = store.workspaces.workspaceEntitiesFolderParameters;
        const nextPageKey = store.workspaces.workspaceEntitiesFolderSummary.nextPageKey;
        return from(
          this.workspaceService.getNextWorkspaceEntitiesPage(currentFolderWorkspace, params, nextPageKey),
        ).pipe(
          map((nextPageData: WorkspaceFolderResultsNextPageData) => {
            this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolderNextPageLoading({ loading: false }));
            return WorkspacesActions.loadNextFolderPageSuccess({ nextPageData: nextPageData });
          }),
          catchError((error) => observableOf(WorkspacesActions.workspaceAPIError({ error }))),
        );
      }),
    ),
  );

  createWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.CREATE_WORKSPACE),
      tap(() => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating workspace.' }));
      }),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.createWorkspace(action.workspace)).pipe(
          map((data) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open('Project Created.', '', { duration: 2000 });
            // this.store.dispatch(WorkspacesActions.setCurrentWorkspace({ currentWorkspace: data }));
            return WorkspacesActions.createWorkspaceSuccess({ workspace: data });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacesActions.createWorkspaceFailure({ error }));
          }),
        );
      }),
    ),
  );

  createWorkspaceFolder$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.CREATE_WORKSPACE_FOLDER),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating folder.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          const folderObj = Object.assign({}, action.workspace);
          folderObj.parentId = store.workspaces.currentWorkspaceFolder.id;
          folderObj.workspaceType = 'FOLDER';

          return from(this.workspaceService.createWorkspace(folderObj)).pipe(
            map((data) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open('Folder Created.', '', { duration: 2000 });
              this.store.dispatch(WorkspacesActions.addEntity({ entity: data }));
              return WorkspacesActions.createWorkspaceFolderSuccess({ workspace: data });
            }),
            catchError((error) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(WorkspacesActions.createWorkspaceFolderFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  addEntityToCurrentFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.ADD_ENTITY_TO_CURRENT_FOLDER),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const data = {
          entity: action.entity,
          authContextUser: store.auth.authContext.user,
          currentWorkspaceFolder: store.workspaces.currentWorkspaceFolder,
          workspaceFolderEntities: store.workspaces.workspaceFolderEntities,
          workspaceEntitiesFolderSummary: store.workspaces.workspaceEntitiesFolderSummary,
          order: store.workspaces.workspaceEntitiesFolderParameters?.order || [
            { order: 'desc', orderField: 'updatedOn' },
          ],
        };
        const res = this.workspaceService.addEntityToCurrentFolder(data);
        this.store.dispatch(
          WorkspacesActions.setWorkspaceEntitiesFolderSummary({ workspaceEntitiesFolderSummary: res.newSummary }),
        );
        return observableOf(
          WorkspacesActions.loadWorkspaceFolderEntitiesSuccess({ workspaceEntities: res.allEntities }),
        );
      }),
    ),
  );

  deleteEntityFromCurrentFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.DELETE_ENTITIES_FROM_CURRENT_FOLDER),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const data = {
          entities: action.entities,
          workspaceFolderEntities: store.workspaces.workspaceFolderEntities,
          workspaceEntitiesFolderSummary: store.workspaces.workspaceEntitiesFolderSummary,
        };
        const res = this.workspaceService.deleteEntitiesFromCurrentFolder(data);
        this.store.dispatch(
          WorkspacesActions.setWorkspaceEntitiesFolderSummary({ workspaceEntitiesFolderSummary: res.newSummary }),
        );
        return observableOf(
          WorkspacesActions.loadWorkspaceFolderEntitiesSuccess({ workspaceEntities: res.allEntities }),
        );
      }),
    ),
  );

  deleteWorkspaceFolder$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.DELETE_WORKSPACE_FOLDER),
      tap(() => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Deleting folder.' }));
      }),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.deleteWorkspace({ id: action.workspaceId })).pipe(
          map((data) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open('Folder Deleted.', '', { duration: 2000 });
            this.store.dispatch(WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: data.id }));
            return WorkspacesActions.deleteWorkspaceFolderSuccess({ workspace: data });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            const errMsg = `Error: The folder cannot be deleted. Remove documents from the folder and try again.`;
            this.snackBar.open(errMsg, '', { duration: 2000 });
            // this.snackBar.open(error?.error?.message ?? errMsg, '', { duration: 2000, });
            return observableOf(WorkspacesActions.deleteWorkspaceFolderFailure({ error }));
          }),
        );
      }),
    ),
  );

  deleteWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.DELETE_WORKSPACE),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.deleteWorkspace(action.workspace)).pipe(
          map((data) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open('Project Deleted.', '', { duration: 2000 });
            // this.store.dispatch(WorkspacesActions.setCurrentWorkspace({ currentWorkspace: null }));
            return WorkspacesActions.deleteWorkspaceSuccess({ workspace: data });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacesActions.deleteWorkspaceFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateWorkspace$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.UPDATE_WORKSPACE),
      tap(() => {
        this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Updating Workspace.' }));
      }),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.updateWorkspace(action.id, action.changes)).pipe(
          tap(() => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
          }),
          map((data) => {
            this.snackBar.open('Project Updated.', '', { duration: 2000 });
            this.store.dispatch(
              WorkspacesActions.loadWorkspaceFolderEntities({
                workspaceId: store?.workspaces?.currentWorkspaceFolder?.id,
              }),
            );
            return WorkspacesActions.updateWorkspaceSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacesActions.updateWorkspaceFailure({ error }));
          }),
        );
      }),
    ),
  );

  moveFolderEntities$ = createEffect(() =>
    this.actions$.pipe(
      ofType(WorkspacesActions.WorkspacesActionTypes.MOVE_FOLDER_ENTITIES),
      tap(() => {
        this.store.dispatch(
          LoadingIndicatorActions.setLoading({ loading: true, message: 'Moving items to new folder.' }),
        );
      }),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.workspaceService.moveFolderEntities(action.entityReferences, action.targetWorkspaceId)).pipe(
          map((data) => {
            // this.workspaceService.handleDropSuccess.next(true);            // TODO: Error handling here, but should consider UX. ?????
            console.log('move complete: ', data);
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            // this.store.dispatch(WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: store.workspaces.currentWorkspaceFolder.id }));
            this.snackBar.open('Move complete.', '', { duration: 2000 });
            const removedIds = store.workspaces.workspaceFolderEntities
              .filter((ent) => action.entityReferences.includes(ent.entityReference))
              .map((we) => we.id);
            return WorkspacesActions.moveFolderEntitiesSuccess({ workspaceEntityIds: removedIds });
          }),
          catchError((error) => {
            this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(WorkspacesActions.updateWorkspaceFailure({ error }));
          }),
        );
      }),
    ),
  );
}
