import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { collectionManagerReducer } from './collection-manager.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CollectionManagerEffects } from './collection-manager-effects';
import { CollectionElementEffects } from './collection-elements/collection-element.effects';
import { CollectionViewsEffects } from './collection-views/collection-views.effects';
import { CollectionStatusMessagesEffects } from './collection-status-messages/collection-status-messages.effects';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature('collectionManager', collectionManagerReducer),
    EffectsModule.forFeature([
      CollectionManagerEffects,
      CollectionElementEffects,
      CollectionViewsEffects,
      CollectionStatusMessagesEffects,
    ]),
  ],
})
export class CollectionManagerStoreModule {}
