import { Injectable } from '@angular/core';
import { API_VERSION, Entities } from '@contrail/sdk';
import { Assortment } from './assortments-store/assortments.state';

@Injectable({
  providedIn: 'root',
})
export class AssortmentsService {
  constructor() {}

  public async getAllAssortmentsBatched(workspaceId: string) {
    const initialPaginatedAssortments = await this.getAssortmentsPaginated(workspaceId);
    const assortments = [...initialPaginatedAssortments.results];
    let assortmentsNextPageKey = initialPaginatedAssortments.nextPageKey;
    while (assortmentsNextPageKey) {
      const morePaginatedAssortments = await this.getAssortmentsPaginated(workspaceId, assortmentsNextPageKey);
      assortmentsNextPageKey = morePaginatedAssortments.nextPageKey;
      assortments.push(...morePaginatedAssortments.results);
    }

    const sortedAssortments = assortments.sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
    return sortedAssortments;
  }

  public async getAssortmentsPaginated(workspaceId: string, nextPageKey?: string) {
    const numberOfResultsPerPage = 75;
    const criteria = { rootWorkspaceId: workspaceId, isArchived: false };
    const data = await new Entities().get({
      entityName: 'assortment',
      criteria,
      relations: ['sourceAssortments'],
      take: numberOfResultsPerPage,
      apiVersion: API_VERSION.V2,
      paginate: true,
      nextPageKey,
    });

    const newNextPageKey = data.results.length < numberOfResultsPerPage ? undefined : data.nextPageKey;

    const filteredResults = data.results?.length
      ? data.results
          .filter((a) => a.name?.indexOf('Backing') < 0)
          .filter((a) => a?.assortmentType !== 'BACKING' && a?.assortmentType !== 'SHOWCASE')
          .filter((a) => a.isArchived !== true && a.isTrashed !== true)
      : [];

    return { results: filteredResults, nextPageKey: newNextPageKey };
  }

  public async getAssortmentById(id: string) {
    return new Entities().get({ entityName: 'assortment', id });
  }

  public async getSourceAssortment(id: string) {
    let assortment: any;
    const params: any = {
      entityName: 'assortment',
      id,
      relations: [
        'assortmentItems',
        'assortmentItems.item',
        'assortmentItems.item.primaryViewable',
        'assortmentItems.item.primaryViewable.primaryFile,',
        'assortmentItems.item.primaryViewable.smallViewable',
      ],
    };
    // params.cacheMode = 'USE';
    assortment = await new Entities().get(params);
    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      assortment.assortmentItems = assortmentItems;
    }
    return assortment;
  }
  public async createAssortment(assortment: Assortment) {
    return new Entities().create({ entityName: 'assortment', object: assortment });
  }
  public async deleteAssortment(assortment: Assortment) {
    await new Entities().delete({ entityName: 'assortment', id: assortment.id });
    return assortment;
  }
  public async updateAssortment(id: string, changes: Assortment) {
    return new Entities().update({ entityName: 'assortment', id, object: changes });
  }
}
