import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { WorkspacePrincipal } from '@common/workspaces/workspace-principal';

interface FolderPrincipalsState extends EntityState<WorkspacePrincipal> {}

export interface State {
  folderPrincipals: FolderPrincipalsState;
  currentFolderPrincipal: WorkspacePrincipal;
}
export const folderPrincipalsEntityAdapter: EntityAdapter<WorkspacePrincipal> = createEntityAdapter<WorkspacePrincipal>(
  {},
);

export const folderPrincipalsInitialState = {
  folderPrincipals: folderPrincipalsEntityAdapter.getInitialState({}),
  currentFolderPrincipal: null,
};
