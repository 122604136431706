import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { showcasesReducers } from './showcases.reducers';
import { EffectsModule } from '@ngrx/effects';
import { ShowcasesEffects } from './showcases.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('showcases', showcasesReducers),
    EffectsModule.forFeature([ShowcasesEffects]),
  ],
})
export class ShowcasesStoreModule {}
