import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyValueComponent } from './property-value/property-value.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TypePropertyFormFieldComponent } from './forms/type-property-form-field/type-property-form-field.component';
import { TypePropertyFormFieldTextComponent } from './forms/type-property-form-field/property-types/type-property-form-field-text.component';
import { TypePropertyFormFieldSelectComponent } from './forms/type-property-form-field/property-types/type-property-form-field-select.component';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatLegacyMenuModule as MatMenuModule } from '@angular/material/legacy-menu';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacySelectModule as MatSelectModule } from '@angular/material/legacy-select';
import { MatLegacyChipsModule as MatChipsModule } from '@angular/material/legacy-chips';
import { TypePropertyFormFieldNumberComponent } from './forms/type-property-form-field/property-types/type-property-form-field-number.component';
import { TypePropertyFormFieldBooleanComponent } from './forms/type-property-form-field/property-types/type-property-form-field-boolean.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { TypePropertyFormFieldDateComponent } from './forms/type-property-form-field/property-types/type-property-form-field-date.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { TypePropertyFormComponent } from './forms/type-property-form/type-property-form.component';
import { MatLegacySlideToggleModule as MatSlideToggleModule } from '@angular/material/legacy-slide-toggle';
import { TypePropertyFormFieldMultiSelectComponent } from './forms/type-property-form-field/property-types/type-property-form-field-multi-select.component';
import { TypePropertyFormValidationErrorComponent } from './forms/type-property-form-validation-error/type-property-form-validation-error.component';
import { TypePropertyFormFieldSizeRangeComponent } from './forms/type-property-form-field/property-types/type-property-form-field-size-range.component';
import { ComponentsModule } from '@components/components.module';
import { TypePropertyFormFieldObjectReferenceComponent } from './forms/type-property-form-field/property-types/type-property-form-field-object-reference.component';
import { SizeRangeEditorModule } from '@components/size-range-editor/size-range-editor.module';
import { TypePropertyFormFieldColorChipComponent } from './forms/type-property-form-field/property-types/type-property-form-field-color-chip.component';
import { TypePropertyFormFieldTextAreaComponent } from './forms/type-property-form-field/property-types/type-property-form-field-text-area.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { TypePropertyFormFieldUserListComponent } from './forms/type-property-form-field/property-types/type-property-form-field-user-list.component';
import { TypePropertyFormFieldPercentComponent } from './forms/type-property-form-field/property-types/type-property-form-field-percent.component';
import { DirectivesModule } from '@directives/directives.module';

@NgModule({
  declarations: [
    PropertyValueComponent,
    TypePropertyFormFieldComponent,
    TypePropertyFormFieldTextComponent,
    TypePropertyFormFieldNumberComponent,
    TypePropertyFormFieldBooleanComponent,
    TypePropertyFormFieldDateComponent,
    TypePropertyFormFieldSelectComponent,
    TypePropertyFormFieldMultiSelectComponent,
    TypePropertyFormFieldSizeRangeComponent,
    TypePropertyFormFieldObjectReferenceComponent,
    TypePropertyFormFieldColorChipComponent,
    TypePropertyFormComponent,
    TypePropertyFormValidationErrorComponent,
    TypePropertyFormFieldTextAreaComponent,
    TypePropertyFormFieldUserListComponent,
    TypePropertyFormFieldPercentComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatMenuModule,
    MatIconModule,
    MatCheckboxModule,
    MatSelectModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    SizeRangeEditorModule,
    MatChipsModule,
    MatButtonModule,
    ComponentsModule,
    DirectivesModule,
  ],
  exports: [
    PropertyValueComponent,
    TypePropertyFormFieldComponent,
    TypePropertyFormComponent,
    TypePropertyFormFieldTextComponent,
    TypePropertyFormFieldNumberComponent,
    TypePropertyFormFieldBooleanComponent,
    TypePropertyFormFieldDateComponent,
    TypePropertyFormFieldSelectComponent,
    TypePropertyFormFieldMultiSelectComponent,
    TypePropertyFormFieldObjectReferenceComponent,
    TypePropertyFormFieldSizeRangeComponent,
    TypePropertyFormFieldColorChipComponent,
    TypePropertyFormValidationErrorComponent,
    TypePropertyFormFieldTextAreaComponent,
    TypePropertyFormFieldUserListComponent,
    TypePropertyFormFieldPercentComponent,
  ],
})
export class TypesModule {}
