import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { Entities } from '@contrail/sdk';
import { Store } from '@ngrx/store';
import { RootStoreState, PlansActions } from '@rootstore';
import { BoardsActions } from 'src/app/boards/boards-store';
import { ShowcasesActions } from 'src/app/showcases/showcases-store';
import { ShowroomsActions } from 'src/app/showrooms/showrooms-store';

@Component({
  selector: 'app-copy-rename-entity-form',
  template: `
    <app-mat-spinner [overlay]="true" [loading]="isLoading" *ngIf="isLoading"></app-mat-spinner>
    <form [formGroup]="form" (ngSubmit)="onSubmit(form.value, entityId)">
      <mat-form-field class="w-full" appearance="fill">
        <input matInput type="text" [placeholder]="placeholder" formControlName="name" />
        <mat-error *ngIf="hasError('name', 'required')">Name is required</mat-error>
        <mat-error *ngIf="hasError('name', 'maxlength')">You have more than 60 characters</mat-error>
      </mat-form-field>

      <div class="actions flex justify-end w-full mt-3">
        <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">OK</button>
      </div>
    </form>
  `,
  styles: [],
})
export class CopyRenameEntityFormComponent implements OnInit {
  public isLoading = false;
  placeholder: string;
  @Input() entityType: string; // workspace (folder) | board | plan | showroom | showcase
  @Input() rename: boolean = false; // copy / rename  => false / true;

  @Input() entityId: string;
  @Input() entityName: string;
  @Output() onFormSubmitted = new EventEmitter();
  form = new UntypedFormGroup({
    name: new UntypedFormControl('', [Validators.required, Validators.maxLength(60)]),
  });

  constructor(
    private store: Store<RootStoreState.State>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    this.entityId = data?.entityId;
    this.entityName = data?.entityName;
  }

  ngOnInit(): void {
    this.placeholder = this.entityType + ` Name`;
    this.setEntityFormName();
  }

  setEntityFormName() {
    if (this.rename) {
      this.form.controls.name.setValue(this.entityName);
    } else {
      this.form.controls.name.setValue('Copy of ' + this.entityName);
    }
  }

  hasError(controlName: string, errorName: string) {
    return this.form.controls[controlName].hasError(errorName);
  }

  async onSubmit(data, id) {
    switch (this.entityType) {
      case 'workspace':
      case 'folder':
        if (this.rename) {
          this.store.dispatch(WorkspacesActions.updateWorkspace({ id: id, changes: { name: data.name } }));
        } else {
        }
        break;
      case 'plan':
        if (this.rename) {
          this.store.dispatch(PlansActions.updatePlan({ id: id, changes: { name: data.name } }));
        } else {
          this.store.dispatch(PlansActions.asyncCopyPlan({ name: data.name, sourceId: id }));
        }
        break;
      case 'showcase':
        if (this.rename) {
          this.store.dispatch(ShowcasesActions.updateShowcase({ id: id, changes: { name: data.name } }));
        } else {
          this.store.dispatch(ShowcasesActions.asyncCopyShowcase({ name: data.name, sourceId: id }));
        }
        break;
      case 'board':
        if (this.rename) {
          this.store.dispatch(BoardsActions.updateBoard({ id: id, changes: { name: data.name } }));
        } else {
          this.store.dispatch(BoardsActions.asyncCopyBoard({ name: data.name, sourceId: id }));
        }
        break;
      case 'showroom':
        if (this.rename) {
          this.store.dispatch(ShowroomsActions.updateShowroom({ id: id, changes: { name: data.name } }));
        } else {
          // this.store.dispatch(ShowroomsActions.copyShowroom({ name: data.name, sourceId: id }));
        }
        break;
      default:
        if (this.rename) {
          new Entities().update({ entityName: this.entityType, id: this.entityId, object: { name: data.name } });
        } else {
        }
        break;
    }

    this.onFormSubmitted.emit(data);
  }
}
