import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';

@Injectable({
  providedIn: 'root',
})
export class LibrariesService {
  constructor() {}

  public async getLibraries() {
    const libraries = await new Entities().get({
      entityName: 'workspace',
      criteria: { workspaceType: 'LIBRARY' },
      relations: ['updatedBy', 'createdBy'],
    });
    libraries.sort((ws1, ws2) => (ws1.name > ws2.name ? 1 : -1));
    return libraries.filter((lib) => lib.name !== 'item');
  }

  public async createLibraryEntity(data: any) {
    return new Entities().create({ entityName: data.entityName, object: data.object });
  }
}
