import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ViewPropertyAggregateFunction } from '@contrail/client-views';
import { NUMERIC_AGGREGATE_VALUE_PROPERTY_TYPES } from '@components/pivot-grid/pivot-grid.constants';
import { PivotProperty } from '@components/pivot-grid/pivot-grid.interfaces';

@Component({
  selector: 'app-pivot-view-property',
  templateUrl: './pivot-view-property.component.html',
  styleUrls: ['./pivot-view-property.component.scss'],
})
export class PivotViewPropertyComponent implements OnInit {
  @Input() property: PivotProperty;
  @Input() sortable = false;
  @Input() sortMenu;

  @Output() propertyRemoved = new EventEmitter();
  @Output() propertyAggregateFunctionChanged = new EventEmitter();

  public functionTypes: ViewPropertyAggregateFunction[];
  private readonly ALL_AGGREGATE_FUNCTION_TYPES = [
    ViewPropertyAggregateFunction.MAX,
    ViewPropertyAggregateFunction.MIN,
    ViewPropertyAggregateFunction.AVG,
    ViewPropertyAggregateFunction.SUM,
    ViewPropertyAggregateFunction.COUNT,
  ];

  constructor() {}

  ngOnInit(): void {
    if (NUMERIC_AGGREGATE_VALUE_PROPERTY_TYPES.includes(this.property?.propertyType)) {
      this.functionTypes = this.ALL_AGGREGATE_FUNCTION_TYPES;
    } else {
      this.functionTypes = [ViewPropertyAggregateFunction.COUNT];
    }
  }

  removeProperty(property) {
    this.propertyRemoved.emit(property);
  }

  selectAggregateFunction(event, property) {
    const aggregateFunctionType = event.value;
    this.propertyAggregateFunctionChanged.emit({ property, aggregateFunctionType });
  }
}
