import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AccessManagedType, Entities, EntityReference, Types } from '@contrail/sdk';
import { PlansActions, RootStoreState } from '@rootstore';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
} from '@angular/material/legacy-dialog';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { RoutingService } from '@common/routing/routing.service';
import { WorkspacesActions, WorkspacesSelectors } from '@common/workspaces/workspaces-store';
import { Workspace, WorkspaceEntity } from '@common/workspaces/workspaces-store/workspaces.state';
import { ContextMenuActionDefinition } from '@common/components/context-menu/context-menu.component';
import { ConfirmationBoxService } from '@common/components/confirmation-box/confirmation-box';
import { CreateFolderComponent } from '@common/folders/create-folder/create-folder.component';
import { CopyRenameEntityModalComponent } from '@common/components/copy-rename-entity/copy-rename-entity-modal.component';
import { CopyMoveEntityModalComponent } from '@common/components/copy-move-entity-modal/copy-move-entity-modal.component';
import { WorkspacesService } from '@common/workspaces/workspaces.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { EntityModalComponent } from '@common/entity-details/entity-modal/entity-modal.component';

import { ShowroomsActions } from 'src/app/showrooms/showrooms-store';
import { ShowcasesActions } from 'src/app/showcases/showcases-store';
import { BoardsActions } from 'src/app/boards/boards-store';
import { CreateWorkspaceComponent } from 'src/app/workspaces/create-workspace/create-workspace.component';
import { CreateBoardComponent } from 'src/app/boards/create-board/create-board.component';
import { CreatePlanComponent } from 'src/app/plans/create-plan/create-plan.component';
import { CreateShowcaseModalComponent } from 'src/app/showcases/create-showcase/create-showcase-modal/create-showcase-modal.component';
import { CreateShowroomComponent } from 'src/app/showrooms/create-showroom/create-showroom.component';

import { Observable, firstValueFrom } from 'rxjs';
import { CreateColorComponent } from '../libraries/colors/create-color/create-color.component';
import { ObjectUtil } from '@contrail/util';

@Injectable({
  providedIn: 'root',
})
export class WorkspaceEntitiesHelperService {
  public rootWorkspace$: Observable<Workspace>;
  public currentFolder$: Observable<Workspace>;
  public callerRole: 'VIEWER' | 'MEMBER' | 'ADMIN' = 'VIEWER'; //

  constructor(
    private routingService: RoutingService,
    private confirmationBoxService: ConfirmationBoxService,
    private workspaceService: WorkspacesService,
    private matDialog: MatDialog,
    private snackBar: MatSnackBar,
    private store: Store<RootStoreState.State>,
  ) {
    this.currentFolder$ = this.store.select(WorkspacesSelectors.currentWorkspaceFolder);
    this.rootWorkspace$ = this.store.select(WorkspacesSelectors.currentWorkspace);
  }

  public async getActionsList(item?: any): Promise<Array<ContextMenuActionDefinition>> {
    let type: AccessManagedType;
    if (item?.typeId) {
      type = await new Types().getType({ id: item.typeId });
    }
    //Only return false if type is found and has a typeProperty name set to editable === false
    const isNameEditable = !type?.typeProperties?.some(
      (property) => property.slug === 'name' && property.editable === false,
    );

    return [
      { actionName: 'open', icon: 'open_in_new', label: 'Open' },
      {
        actionName: 'share',
        icon: 'share',
        label: 'Share',
        isApplicable: (target) => {
          return [].includes(target?.entityType); // no use case
        },
      },
      {
        actionName: 'rename',
        icon: 'drive_file_rename_outline',
        label: 'Rename',
        isApplicable: (target) => {
          console.log('Target', target);
          return (
            ['workspace', 'plan', 'board', 'showcase', 'showroom', 'color', 'asset'].includes(target?.entityType) &&
            isNameEditable
          );
        },
      },
      {
        actionName: 'copy',
        icon: 'content_copy',
        label: 'Copy',
        isApplicable: (target) => {
          return ['showcase', 'plan', 'board'].includes(target?.entityType);
        },
      },
      {
        actionName: 'move',
        icon: 'drive_file_move',
        label: 'Move',
        isApplicable: (target) => {
          return ['workspace', 'plan', 'showroom', 'board', 'showcase', 'color', 'asset'].includes(target?.entityType);
        },
      },
      {
        actionName: 'copyToFolder',
        icon: 'snippet_folder',
        label: 'Copy to folder',
        isApplicable: (target) => {
          return ['plan', 'board', 'showcase'].includes(target?.entityType);
        },
      },
      {
        actionName: 'delete',
        icon: 'delete',
        label: 'Delete',
        isApplicable: (target) => {
          if (type && !type.canDelete) {
            return false;
          }
          return ['workspace'].includes(target?.entityType);
        },
      },
      {
        actionName: 'trash',
        icon: 'delete',
        label: 'Move to trash',
        isApplicable: (target) => {
          if (type && !type.canDelete) {
            return false;
          }
          return ['plan', 'showroom', 'board', 'showcase', 'color', 'asset'].includes(target?.entityType);
        },
      },
    ];
  }
  public getMultiActionsList(): Array<ContextMenuActionDefinition> {
    return [
      {
        actionName: 'move',
        icon: 'drive_file_move',
        label: 'Move',
        isApplicable: (target) => {
          return ['workspace', 'plan', 'showroom', 'board', 'showcase', 'color', 'asset'].includes(target?.entityType);
        },
      },
      {
        actionName: 'trash',
        icon: 'delete',
        label: 'Move to trash',
        isApplicable: (target) => {
          return ['plan', 'showroom', 'board', 'showcase', 'color', 'asset'].includes(target?.entityType);
        },
      },
    ];
  }

  public handleAction() {}

  async goToEntity(entity: WorkspaceEntity) {
    const rootWorkspace = await firstValueFrom(this.rootWorkspace$);
    switch (entity?.entityType) {
      case 'workspace':
        const parentFolder = ObjectUtil.cloneDeep(entity.workspace);
        if (parentFolder.id === rootWorkspace.id) {
          parentFolder.name = rootWorkspace?.rootWorkspaceType === 'PROJECT' ? 'Documents' : 'Explorer';
        }
        this.goToFolder({ id: entity.id, parentId: entity.workspaceId, name: entity.name, parent: parentFolder });
        break;
      case 'board':
        this.routingService.openApp('boards', `board/${entity.id}`);
        break;
      case 'showcase':
        this.routingService.openApp('showcase-manager', `showcase-details/${entity.id}`);
        break;
      case 'plan':
        this.routingService.openApp('plan', `plan/${entity.id}`);
        break;
      case 'showroom':
        this.routingService.openApp('showroom', `showrooms/view/${entity.id}`);
        break;
      case 'asset':
      case 'color':
        const config = {
          data: { entityReference: entity.entityReference, entity: entity, accessLevel: 'EDIT' },
          panelClass: [`no-padding`, `entity-details-modal`],
          maxWidth: '95vw',
          width: '1350px',
          height: '800px',
          autoFocus: true,
        };
        const dialogRef = this.matDialog.open(EntityModalComponent, config);
        // const entityModalComponent: EntityModalComponent = dialogRef.componentInstance;
        // const sub: Subscription = entityModalComponent.updated.subscribe((result) => {
        //   entity = {...result.entity, ...result.changes }
        // });
        // entityModalComponent.dialogRef.afterClosed().subscribe(() => { sub.unsubscribe() });
        break;
      default:
        break;
    }
  }

  setIcon(entity, type?: string) {
    if (!entity) {
      return;
    }
    switch (entity.entityType) {
      case 'color':
        entity.matIcon = 'palette';
        break;
      case 'asset':
        entity.matIcon = 'photo_library';
        break;
      case 'board':
        entity.icon = 'assets/images/board_icon.svg';
        break;
      case 'showcase':
        entity.icon = 'assets/images/showcase_icon.svg';
        break;
      case 'plan':
        entity.icon = 'assets/images/plan_icon.svg';
        break;
      case 'showroom':
        entity.icon = 'assets/images/showroom_icon.svg';
        break;
      case 'workspace':
        entity.icon = 'assets/images/folder_icon.svg';
        if (type === 'asset') {
          let ent = entity.entity;
          ent = { ...ent, mediumViewableDownloadUrl: 'assets/images/folder_icon.svg' };
          entity.entity = ent;
        }
        // else if (type === 'project' || type === 'color') { }
        break;
      case 'item':
        entity.icon = 'assets/images/item.svg';
        break;
      default:
        break;
    }
  }

  handleMenuAction(action, target, targetItems?: any[]) {
    switch (action) {
      case 'delete':
        this.delete(target?.entityReference);
        break;
      case 'copy':
        this.copy(target);
        break;
      case 'open':
        this.goToEntity(target);
        break;
      case 'share':
        this.share(target);
        break;
      case 'rename':
        this.rename(target);
        break;
      case 'move':
        this.move(target, targetItems);
        break;
      case 'copyToFolder':
        this.copyToFolder(target);
        break;
      case 'trash':
        this.batchTrashOrRestore([target], true);
        break;
      case 'restore':
        this.batchTrashOrRestore([target], false);
        break;
    }
  }

  share(entity) {}

  rename(entity) {
    let entityType = entity.entityType;
    if (entityType === 'workspace') {
      entityType = 'folder';
    }
    this.launchModal(CopyRenameEntityModalComponent, {
      entityId: entity.id,
      entityName: entity.name,
      rename: true,
      entityType: entityType,
    });
  }

  move(entity, targetItems?) {
    const moveItems = targetItems ? targetItems : [entity];
    const modal = this.launchModal(CopyMoveEntityModalComponent, { copy: false });
    modal.afterClosed().subscribe((res) => {
      if (res?.id && res.id !== entity.workspace.id) {
        this.workspaceService.handleDropSuccess.next(true);
        const entityReferences = moveItems.map((i) => i.entityReference);
        this.store.dispatch(WorkspacesActions.moveFolderEntities({ entityReferences, targetWorkspaceId: res.id }));
      }
    });
  }

  copyToFolder(entity) {
    const modal = this.launchModal(CopyMoveEntityModalComponent, { copy: true });
    modal.afterClosed().subscribe(async (res) => {
      if (res?.id) {
        const ref = new EntityReference(entity.entityReference);
        let entityType = ref.entityType;
        const newWorkspaceId = res.folder.id;
        const name = 'Copy of ' + entity.name;
        const sourceId = ref.id;
        switch (entityType) {
          case 'plan':
            this.store.dispatch(PlansActions.asyncCopyPlan({ name, sourceId, newWorkspaceId, navigateToFolder: res }));
            break;
          case 'showcase':
            this.store.dispatch(
              ShowcasesActions.asyncCopyShowcase({ name, sourceId, newWorkspaceId, navigateToFolder: res }),
            );
            break;
          case 'board':
            this.store.dispatch(
              BoardsActions.asyncCopyBoard({ name, sourceId, newWorkspaceId, navigateToFolder: res }),
            );
            break;
        }
      }
    });
  }

  copy(entity) {
    const ref = new EntityReference(entity.entityReference);
    let entityType = ref.entityType;
    if (ref.entityType === 'workspace') {
      entityType = 'folder';
    }
    this.launchModal(CopyRenameEntityModalComponent, {
      entityId: ref.id,
      entityName: entity.name,
      entityType: entityType,
    });
  }

  async delete(entityReference) {
    const ref = new EntityReference(entityReference);
    if (ref.entityType === 'workspace') {
      this.store.dispatch(WorkspacesActions.deleteWorkspaceFolder({ workspaceId: ref.id }));
    } else {
      const confirm = await this.confirmationBoxService.open(
        `Delete ${ref.entityType}`,
        `Are you sure you want to delete this ${ref.entityType}?`,
      );
      if (confirm) {
        switch (ref.entityType) {
          case 'plan':
            this.store.dispatch(PlansActions.deletePlan({ planId: ref.id }));
            break;
          case 'showcase':
            this.store.dispatch(ShowcasesActions.deleteShowcase({ showcaseId: ref.id }));
            break;
          case 'board':
            this.store.dispatch(BoardsActions.deleteBoard({ boardId: ref.id }));
            break;
          case 'showroom':
            this.store.dispatch(ShowroomsActions.deleteShowroom({ showroomId: ref.id }));
            break;

          default: // color | asset
            await new Entities().delete({ entityName: ref.entityType, id: ref.id });
            break;
        }
      } else {
        return 'deleteCancel';
      }
    }
  }

  async batchDelete(items) {
    const confirm = await this.confirmationBoxService.open(
      `Delete Forever`,
      'Are you sure you want to delete all selected items?',
    );
    if (confirm) {
      const sortByTypes = this.sortEntities(items);
      const deletePromises = [];
      Object.keys(sortByTypes).forEach((type) => {
        if (type !== 'workspace' && sortByTypes[type]?.length) {
          const ids = sortByTypes[type];
          const promise = new Entities().batchDelete({ entityName: type, ids });
          deletePromises.push(promise);
        }
      });
      const batchDeleteRes = await Promise.all(deletePromises);
      this.snackBar.open('Deleted.', '', { duration: 2000 });
      // this.store.dispatch(WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: this.currentFolder.id }));
    } else {
      return 'deleteCancel';
    }
  }

  async batchTrashOrRestore(items, isTrashed: boolean) {
    if (this.callerRole === 'VIEWER') {
      const errorMessage = `You do not have the necessary permissions to complete this action. Please reach out to your administrator for assistance.`;
      this.snackBar.open(errorMessage, '', { duration: 4000 });
      return;
    }

    const currentFolder = await firstValueFrom(this.currentFolder$);
    const sortByTypes = this.sortEntities(items);
    const updatePromises = [];
    Object.keys(sortByTypes).forEach((type) => {
      if (type !== 'workspace' && sortByTypes[type]?.length) {
        const objects = sortByTypes[type].map((id) => {
          return { id, changes: { isTrashed } };
        });
        const promise = new Entities().batchUpdate({ entityName: type, objects });
        updatePromises.push(promise);
      }
    });
    const batchUpdateRes = await Promise.all(updatePromises);
    let msg = items.length > 1 ? `${items.length} items` : `${items.length} item`;
    msg = isTrashed ? msg + ' moved to trash' : msg + ' restored';
    this.snackBar.open(msg, '', { duration: 2000 });

    if (isTrashed) {
      // move to trash
      this.store.dispatch(WorkspacesActions.deleteEntity({ entities: items }));
    } else {
      // restore
      const entities = items.filter((i) => i.workspaceId === currentFolder.id).map((i) => i.entity);
      entities.forEach((entity) => {
        this.store.dispatch(WorkspacesActions.addEntity({ entity }));
      });
    }
    // this.store.dispatch(WorkspacesActions.loadWorkspaceFolderEntities({ workspaceId: currentFolder.id })); // we can't use this because it takes 3-5 seconds to get the latest data
  }

  sortEntities(entities) {
    let sortByTypes = { plan: [], showroom: [], showcase: [], board: [], workspace: [], asset: [], color: [] };
    entities.forEach((e) => {
      sortByTypes[e?.entity?.entityType].push(e?.entity?.id);
    });
    return sortByTypes;
  }

  goToFolder(workspace: Workspace) {
    this.store.dispatch(WorkspacesActions.setCurrentWorkspaceFolder({ workspace }));
  }
  async goToRootFolder() {
    const rootWorkspace = await firstValueFrom(this.rootWorkspace$);
    this.goToFolder(rootWorkspace);
  }

  createNew(objectType) {
    let component;
    switch (objectType) {
      case 'plan': {
        component = CreatePlanComponent;
        break;
      }
      case 'workspace': {
        component = CreateWorkspaceComponent;
        break;
      }
      case 'board': {
        component = CreateBoardComponent;
        break;
      }
      case 'showroom': {
        component = CreateShowroomComponent;
        break;
      }
      case 'showcase': {
        component = CreateShowcaseModalComponent;
        break;
      }
      case 'folder': {
        component = CreateFolderComponent;
        break;
      }
      case 'color': {
        component = CreateColorComponent;
        break;
      }
      case 'asset': {
        return 'asset';
      }
    }
    this.launchModal(component, {});
  }

  launchModal(component, data) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.width = '700px';
    dialogConfig.data = data;
    const dialogRef = this.matDialog.open(component, dialogConfig);
    return dialogRef;
  }
}
