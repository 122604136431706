import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { Entities } from '@contrail/sdk';
import { UserNamePipe } from '@common/pipes/user-name.pipe';
import { ColorUtil } from '@common/util/color-util';
import { Store } from '@ngrx/store';
import { combineLatest, firstValueFrom, from, Observable, Subject } from 'rxjs';
import { distinctUntilChanged, map, takeUntil, tap } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import {
  WorkspacePrincipal,
  WorkspacePrincipalRole,
  WORKSPACE_PRINCIPLE_ROLES,
} from '@common/workspaces/workspace-principal';
import { WorkspacePrincipalsActions, WorkspacePrincipalsSelectors } from './workspace-principals-store';
import { MatLegacySelect as MatSelect } from '@angular/material/legacy-select';
import { MatLegacyOption as MatOption } from '@angular/material/legacy-core';
import { UserOrgsSelectors } from 'src/app/main/user-orgs-store';

@Component({
  selector: 'app-workspace-principals',
  templateUrl: './workspace-principals.component.html',
  styleUrls: ['./workspace-principals.component.scss'],
})
export class WorkspacePrincipalsComponent implements OnInit, AfterViewInit, OnDestroy {
  loading = false;
  private destroy$ = new Subject();

  principals = [];
  roles = WORKSPACE_PRINCIPLE_ROLES;

  searchKey = '';
  public addList = [];
  public addControl = new UntypedFormControl({ value: [], disabled: false });

  displayedColumns: string[] = ['select', 'name', 'type', 'email', 'role', 'actions'];
  dataSource = new MatTableDataSource<any>([]);
  selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort) sort: MatSort;

  @ViewChild('addSelect') addSelect: MatSelect;
  addSelectClear() {
    this.addSelect?.options?.forEach((data: MatOption) => data.deselect());
  }

  public workspacePrincipals$: Observable<Array<WorkspacePrincipal>>;
  public newRoleControl = new UntypedFormControl({ value: WorkspacePrincipalRole.member, disabled: false });

  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<WorkspacePrincipalsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public userNamePipe: UserNamePipe,
  ) {}

  async ngOnInit() {
    this.store.dispatch(WorkspacePrincipalsActions.loadWorkspacePrincipals());
    const userOrgs = await firstValueFrom(this.store.select(UserOrgsSelectors.userOrgs));
    const orgAdmins = [];
    const orgMembers = [];
    userOrgs.forEach((i) => {
      i.role === 'ADMIN' ? orgAdmins.push(i) : orgMembers.push(i);
    });
    const orgAdminIds = orgAdmins.map((a) => a.user.id);

    let orgAdminPrincipals = [];
    orgAdmins.forEach((orgAdmin) => {
      let principal: any = {};
      principal.role = 'ORG_ADMIN';
      principal.id = orgAdmin.user.id; // different from workspacePrincipals, but disable row
      principal.type = 'User';
      principal.user = {
        firstName: orgAdmin?.user?.firstName,
        lastName: orgAdmin?.user?.lastName,
        email: orgAdmin?.userEmail,
      };
      orgAdminPrincipals.push(principal);
    });

    combineLatest([
      this.store.select(WorkspacePrincipalsSelectors.workspacePrincipals),
      from(new Entities().get({ entityName: 'group', relations: ['groupPrincipals'] })),
    ])
      .pipe(
        distinctUntilChanged(),
        map(([workspacePrincipals, groups]) => {
          const wps = [];
          wps.push(...orgAdminPrincipals);
          workspacePrincipals.forEach((p) => {
            if (orgAdminIds.includes(p?.principal?.id)) return;
            let principal: any = {};
            principal.role = p.role;
            if (p?.principal?.email) {
              principal.id = p?.id;
              principal.type = 'User';
              principal.user = {
                firstName: p?.principal?.firstName,
                lastName: p?.principal?.lastName,
                email: p?.principal?.email,
              };
            } else {
              principal.id = p?.id;
              principal.type = 'Group';
              principal.user = {
                email: p?.principal?.name,
              };
            }
            wps.push(principal);
          });
          this.dataSource.data = [...wps];

          // Org Members
          let users = [];
          const wpIds = workspacePrincipals.map((p) => p?.principal?.id);
          orgMembers.forEach((res) => {
            if (!wpIds.includes(res.user.id)) {
              let user: any;
              user = {
                type: 'user:',
                id: res.user.id,
                value: res.user.email,
              };
              users.push(user);
            }
          });

          // Groups
          let subGroups = groups.map((res) => {
            let group: any;
            group = {
              type: 'group:',
              id: res.id,
              value: res.name,
            };
            return group;
          });
          return [...users, ...subGroups];
        }),
        takeUntil(this.destroy$),
      )
      .subscribe((res) => {
        this.addList = [...res];
        this.loading = false;
      });
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'name':
          return this.userNamePipe.transform(item?.user).toLowerCase();
        case 'email':
          return item?.user?.email;

        default:
          return item[property];
      }
    };
  }
  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  selectAll(list) {
    const val = [...new Set([...this.addControl.value, ...list])];
    this.addControl.setValue(val);
  }
  clearAll(list) {
    const ids = list.map((l) => l.id);
    const val = this.addControl.value.filter((i) => !ids.includes(i.id));
    this.addControl.setValue(val);
  }
  selectPanelOpen(evt) {
    if (!evt) this.searchKey = '';
  }

  handleRoleChange(workspacePrincipal, evt) {
    const role = evt.value;
    this.store.dispatch(
      WorkspacePrincipalsActions.updateWorkspacePrincipal({ id: workspacePrincipal.id, changes: { role } }),
    );
    this.selection.clear();
  }

  async addPrincipal() {
    this.loading = true;
    let principalReferences: string[] = [];

    if (this.addControl.value) {
      const selected = this.addControl.value;
      selected.forEach((item) => {
        const ref = item.type + item.id;
        principalReferences.push(ref);
      });
    }
    const objects: any = {
      principalReferences: principalReferences,
      workspaceIds: [this.data?.workspaceId],
      role: this.newRoleControl.value,
    };
    this.store.dispatch(WorkspacePrincipalsActions.createWorkspacePrincipals({ objects }));
    this.loading = false;
    this.selection.clear();
    this.addSelectClear();
  }

  async removeFromWorkspace(row, idx, evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.store.dispatch(WorkspacePrincipalsActions.deleteWorkspacePrincipal({ workspacePrincipal: row }));
    this.selection.clear();
  }

  async removeMembers() {
    let ids = [];
    this.selection.selected.map((row) => {
      if (row.role !== 'ORG_ADMIN') {
        ids.push(row.id);
      }
    });
    this.store.dispatch(WorkspacePrincipalsActions.deleteWorkspacePrincipals({ ids }));
    this.selection.clear();
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }
  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row?.id}`;
  }

  getBackgroundColor(id) {
    if (id) {
      return ColorUtil.stringToHslaColor(id);
    }
  }
}
