import { State } from './plans.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as PlansActions from './plans.actions';
import { plansEntityAdapter } from './plans.state';
import { plansInitialState } from './plans.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    plans: plansEntityAdapter.setAll(data, state.plans),
    plansLoaded: true,
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    plans: plansEntityAdapter.removeAll(state.plans),
    plansLoaded: false,
  };
};
const add = (state: State, { plan }) => {
  return {
    ...state,
    plans: plansEntityAdapter.addOne(plan, state.plans),
  };
};
const update = (state: State, { id, changes }) => {
  let currentPlan = state.currentPlan;
  if (state.currentPlan?.id === id) {
    currentPlan = { ...state.currentPlan, ...changes };
  }
  return {
    ...state,
    plans: plansEntityAdapter.updateOne({ id, changes }, state.plans),
    currentPlan,
  };
};
const remove = (state: State, { plan }) => {
  return {
    ...state,
    plans: plansEntityAdapter.removeOne(plan.id, state.plans),
  };
};
const setCurrentPlan = (state: State, { plan }) => {
  return {
    ...state,
    currentPlan: plan,
  };
};

export const plansReducers = createReducer(
  plansInitialState,
  on(PlansActions.loadPlansSuccess, setData),
  on(PlansActions.clearPlans, clearData),
  on(PlansActions.createPlanSuccess, add),
  on(PlansActions.copyPlanSuccess, add),
  //  on(PlansActions.deletePlanSuccess, remove),
  on(PlansActions.updatePlanSuccess, update),
  on(PlansActions.loadCurrentPlanSuccess, setCurrentPlan),
);

export function reducer(state: State | undefined, action: Action): any {
  return plansReducers(state, action);
}
