import { Injectable } from '@angular/core';
import { Entities } from '@contrail/sdk';
import { AnalyticsService } from '../analytics/analytics.service';
import { AuthService, User } from '../auth/auth.service';
import { Store } from '@ngrx/store';
import { RootStoreState, UserSessionSelectors } from 'src/app/root-store';
import { CommentsActions } from './comments-store';
import { AuthSelectors } from '../auth/auth-store';

export interface CommentOwnerInfo {
  id?: string;
  name?: string;
  entityType?: string;
  modalTitle?: string;
  value?: string;
  property?: {
    slug?: string;
    label?: string;
    propertyType?: string;
  };
}
export interface Comment {
  id?: string;
  text: string;
  ownedByReference?: string;
  contextReference: string;
  ownedByPropertySlug?: string; // comment is about a particular property.
  changeSuggestion?: ChangeSuggestion; // change sugguestion
  ownedBy?: any;
  context?: any;
  updatedOn?: Date;
  createdOn?: Date;
  createdBy?: User;
  updatedBy?: User;
  updatedById?: string;
  createdById?: string;
  orgId?: string;
}
export interface ChangeSuggestion {
  changeType?: string;
  changeDetails: {
    propertySlug?: string;
    newValue?: string;
    oldValue?: string;
  };
}

@Injectable({
  providedIn: 'root',
})
export class CommentsService {
  public contextReference;
  private user;

  constructor(
    private store: Store<RootStoreState.State>,
    private authService: AuthService,
    private analyticsService: AnalyticsService,
  ) {
    this.store.select(UserSessionSelectors.currentSessionId).subscribe((sessionId) => {
      if (sessionId) {
        this.contextReference = sessionId;
        this.store.dispatch(CommentsActions.loadComments({ contextReference: this.contextReference }));
      }
    });
    this.store.select(AuthSelectors.selectAuthContext).subscribe((authContext) => {
      if (authContext) {
        this.user = authContext.user;
      }
    });
  }

  async loadComments(contextReference: string) {
    const option = {
      entityName: 'comment',
      criteria: { contextReference },
      relations: ['ownedBy', 'ownedBy.item', 'createdBy'],
    };
    return await new Entities().get(option);
  }

  async addComment(text, ownerInfo: CommentOwnerInfo, changeSuggestion: ChangeSuggestion): Promise<Comment> {
    let ownedByReference;
    this.analyticsService.emitEvent({ eventName: 'ADD_COMMENT', eventTarget: ownerInfo?.id });

    if (!ownerInfo) {
      ownedByReference = this.contextReference;
    } else {
      ownedByReference = ownerInfo.entityType + ':' + ownerInfo.id;
    }
    let comment: Comment = {
      text,
      ownedByReference,
      contextReference: this.contextReference,
      changeSuggestion,
      ownedByPropertySlug: ownerInfo?.property?.slug,
    };
    console.log(`newComment ${JSON.stringify(comment)}`);

    const option = {
      entityName: 'comment',
      object: comment,
    };
    comment = await new Entities().create(option);
    comment.createdBy = this.user;
    return comment;
  }

  async updateComment(comment: Comment): Promise<Comment> {
    this.analyticsService.emitEvent({ eventName: 'UPDATE_COMMENT' });
    const option = {
      entityName: 'comment',
      id: comment.id,
      object: comment,
    };
    const updated = await new Entities().update(option);
    updated.updatedBy = this.user;
    updated.createdBy = comment.createdBy;

    console.log(`The updated comment is ${JSON.stringify(updated)}`);
    return updated;
  }

  async deleteComment(comment: Comment) {
    this.analyticsService.emitEvent({ eventName: 'DELETE_COMMENT' });
    const option = {
      entityName: 'comment',
      id: comment.id,
    };
    await new Entities().delete(option);
    return comment;
  }

  canUpdateComment(comment: Comment): boolean {
    return this.authService.isAdmin() || this.isCommentOwner(comment);
  }
  canDeleteComment(comment: Comment): boolean {
    return this.authService.isAdmin() || this.isCommentOwner(comment);
  }
  isCommentOwner(comment: Comment): boolean {
    return this.user && comment.createdBy?.id === this.user?.id;
  }
}
