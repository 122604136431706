<div class="flex items-center my-1 w-full">
  <!-- Visual -->
  <div>
    <img
      *ngIf="entity.smallViewableDownloadUrl; else img"
      [src]="entity.smallViewableDownloadUrl | secureImage | async"
      class="icon object-contain"
    />
    <ng-template #img>
      <ng-container [ngSwitch]="entity.entityType">
        <img *ngSwitchCase="'plan'" src="assets/images/plan_icon.svg" class="icon" />
        <img *ngSwitchCase="'board'" src="assets/images/board_icon.svg" class="icon" />
        <img *ngSwitchCase="'showcase'" src="assets/images/showcase_icon.svg" class="icon" />
        <img *ngSwitchCase="'showroom'" src="assets/images/showroom_icon.svg" class="icon" />
        <div *ngSwitchCase="'color'" [style.background]="'#' + entity.hex" class="icon rounded"></div>
      </ng-container>
    </ng-template>
  </div>

  <!-- Name -->
  <div class="ml-3 name">
    <div class="w-full text-sm line-ellipsis">
      <span>{{ entity.name }}</span>
      <span class="ml-1" *ngIf="entity?.optionName">({{ entity.optionName }})</span>
    </div>
    <div class="text-slate-600 text-[11px] leading-[11px]">
      {{ entity.updatedOn | date: 'mediumDate' }} by {{ entity?.updatedBy | userName: 'fullEmail' }}
    </div>
  </div>
</div>
