<!-- color & asset have cardData.matIcon, the others have icon value -->

<ng-container *ngIf="rootWorkspaceType === 'project'">
  <ng-container *ngTemplateOutlet="thumbnailCard"></ng-container>
</ng-container>

<ng-container *ngIf="rootWorkspaceType === 'color'">
  <!-- //TODO: FOLDER CASE _ FROM MATICON VALUE -->
  <ng-container *ngTemplateOutlet="colorCard"></ng-container>
</ng-container>

<ng-container *ngIf="rootWorkspaceType === 'asset'">
  <!-- //TODO: FOLDER CASE _ FROM MATICON VALUE -->
  <ng-container *ngTemplateOutlet="assetCard"></ng-container>
</ng-container>

<ng-template #thumbnailCard>
  <div class="thumbnail-card relative flex-center flex-col">
    <mat-checkbox
      *ngIf="multiSelect"
      class="check-icon !absolute top-2 left-0 py-2 px-3"
      [checked]="cardData?.selected"
      (change)="selectRow($event)"
      (click)="onCheckClick($event)"
      (dblclick)="quickChangePrevent($event)"
    ></mat-checkbox>
    <mat-checkbox
      *ngIf="!multiSelect"
      class="!absolute !hidden"
      (click)="onCheckClick($event)"
      (dblclick)="quickChangePrevent($event)"
    ></mat-checkbox>
    <div>
      <img
        *ngIf="cardData?.thumbnailUrl"
        class="preview pointer-events-none"
        [attr.src]="cardData?.thumbnailUrl | secureImage | async"
      />
      <div class="placeholder flex-center" *ngIf="!cardData?.thumbnailUrl">
        <img
          *ngIf="cardData?.entity?.entityType === 'plan'"
          class="thumb-img scale-50"
          src="assets/images/plan_icon.svg"
        />
        <img
          *ngIf="cardData?.entity?.entityType === 'showroom'"
          class="thumb-img scale-50"
          src="assets/images/showroom_icon.svg"
        />
        <img *ngIf="cardData?.entity?.entityType === 'workspace'" class="thumb-img scale-75" [src]="cardData?.icon" />
      </div>
    </div>
    <div class="thumbnail-card-footer" [ngClass]="{ selected: cardData?.selected }">
      <div class="meta">
        <div class="name text-title-2 !text-black60">
          <img class="h-4 w-4 mr-[6px] max-w-none" *ngIf="cardData?.icon" [src]="cardData?.icon" />
          <span> {{ cardData?.name | shorten: 20 }} </span>
        </div>
        <div class="details">
          <ng-container *ngIf="!recents; else accessed"> Edited {{ cardData?.updatedOn | dateAgo }} </ng-container>
          <ng-template #accessed> Accessed {{ cardData?.lastAccess | dateAgo }} </ng-template>
        </div>
      </div>
      <div class="menu-toggle" (click)="showMenu($event)">
        <mat-icon>more_vert</mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #colorCard>
  <div class="color-card">
    <div class="flex items-center p-2">
      <mat-checkbox
        *ngIf="multiSelect"
        class="check-icon"
        [checked]="cardData?.selected"
        (change)="selectRow($event)"
        (click)="onCheckClick($event)"
        (dblclick)="quickChangePrevent($event)"
      ></mat-checkbox>
      <!-- <span class="indicator min-w-[12px] h-3 rounded-2xl mr-1 bg-primary"></span> -->
      <span class="text-primary text-caption mr-auto opacity-0">COLOR</span>
      <span (click)="showMenu($event)" class="material-icons cursor-pointer text-black54"> more_vert </span>
    </div>

    <ng-container *ngIf="cardData?.matIcon; else folderIcon">
      <div
        class="flex-center min-w-[160px] min-h-[140px] mx-2 cursor-pointer"
        [ngStyle]="{ 'background-color': '#' + cardData?.entity.hex }"
        [matTooltip]="rgb"
        [matTooltipShowDelay]="1000"
        matTooltipPosition="above"
      ></div>
    </ng-container>

    <div class="color-card-footer flex flex-col p-2" [ngClass]="{ selected: cardData?.selected }">
      <span class="text-sm font-medium line-ellipsis">{{ cardData.name }}</span>
      <span
        class="text-sm font-medium text-black60 w-fit line-ellipsis"
        [matTooltip]="cardData?.matIcon ? rgb : ''"
        [matTooltipShowDelay]="500"
        matTooltipPosition="after"
        [class.opacity-0]="cardData?.icon"
      >
        #{{ cardData?.entity.hex | uppercase }}
        <!-- // @RORY folder ? -->
      </span>
      <span class="text-xs pt-1 text-black60">{{ cardData?.updatedOn | date: 'shortDate' }}</span>
    </div>
  </div>
</ng-template>

<ng-template #assetCard>
  <div class="asset-card">
    <div class="flex items-center p-2">
      <mat-checkbox
        *ngIf="multiSelect"
        class="check-icon"
        [checked]="cardData?.selected"
        (change)="selectRow($event)"
        (click)="onCheckClick($event)"
        (dblclick)="quickChangePrevent($event)"
      ></mat-checkbox>
      <!-- <span class="indicator min-w-[12px] h-3 rounded-2xl mr-1 bg-primary"></span> -->
      <span class="text-primary text-caption mr-auto opacity-0">ASSET</span>
      <span (click)="showMenu($event)" class="material-icons cursor-pointer text-black54"> more_vert </span>
    </div>

    <ng-container *ngIf="cardData?.matIcon; else folderIcon">
      <img
        class="w-[160px] h-[140px] mx-2 object-contain"
        [attr.src]="cardData?.entity?.mediumViewableDownloadUrl | secureImage | async"
      />
    </ng-container>

    <div class="asset-card-footer flex flex-col p-2" [ngClass]="{ selected: cardData?.selected }">
      <span class="text-sm font-medium line-ellipsis">{{ cardData.name }}</span>
      <span class="text-sm font-medium text-black60 line-ellipsis" [class.opacity-0]="cardData?.icon">
        {{ cardData?.entity?.fileName || 'folder' }}
        <!-- // TODO: @RORY folder ? -->
      </span>
      <span class="text-xs pt-1 text-black60">{{ cardData?.updatedOn | date: 'shortDate' }}</span>
    </div>
  </div>
</ng-template>

<ng-template #folderIcon>
  <img class="thumb-img scale-75" [src]="cardData?.icon" />
</ng-template>
