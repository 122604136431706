import { State } from './folder-principals.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as FolderPrincipalsActions from './folder-principals.actions';
import { folderPrincipalsEntityAdapter } from './folder-principals.state';
import { folderPrincipalsInitialState } from './folder-principals.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    folderPrincipals: folderPrincipalsEntityAdapter.setAll(data, state.folderPrincipals),
  };
};
const clearData = (state: State, {}) => {
  return {
    ...state,
    folderPrincipals: folderPrincipalsEntityAdapter.removeAll(state.folderPrincipals),
  };
};
const add = (state: State, { folderPrincipal }) => {
  return {
    ...state,
    folderPrincipals: folderPrincipalsEntityAdapter.addOne(folderPrincipal, state.folderPrincipals),
  };
};
const batchAdd = (state: State, { data }) => {
  return {
    ...state,
    folderPrincipals: folderPrincipalsEntityAdapter.addMany(data, state.folderPrincipals),
  };
};
const update = (state: State, { id, changes }) => {
  return {
    ...state,
    folderPrincipals: folderPrincipalsEntityAdapter.updateOne({ id, changes }, state.folderPrincipals),
  };
};
const remove = (state: State, { folderPrincipal }) => {
  return {
    ...state,
    folderPrincipals: folderPrincipalsEntityAdapter.removeOne(folderPrincipal.id, state.folderPrincipals),
  };
};
const batchRemove = (state: State, { data }) => {
  const ids = data.map((i) => i.id);
  return {
    ...state,
    folderPrincipals: folderPrincipalsEntityAdapter.removeMany(ids, state.folderPrincipals),
  };
};
const setCurrentFolderPrincipal = (state: State, { folderPrincipal }) => {
  return {
    ...state,
    currentFolderPrincipal: folderPrincipal,
  };
};

export const folderPrincipalsReducers = createReducer(
  folderPrincipalsInitialState,
  on(FolderPrincipalsActions.loadFolderPrincipalsSuccess, setData),
  on(FolderPrincipalsActions.createFolderPrincipalSuccess, add),
  on(FolderPrincipalsActions.deleteFolderPrincipalSuccess, remove),
  on(FolderPrincipalsActions.createFolderPrincipalsSuccess, batchAdd),
  on(FolderPrincipalsActions.deleteFolderPrincipalsSuccess, batchRemove),
  on(FolderPrincipalsActions.updateFolderPrincipalSuccess, update),
  on(FolderPrincipalsActions.loadCurrentFolderPrincipalSuccess, setCurrentFolderPrincipal),
);

export function reducer(state: State | undefined, action: Action): any {
  return folderPrincipalsReducers(state, action);
}
