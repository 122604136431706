import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ComponentsModule } from '@components/components.module';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { Store } from '@ngrx/store';
import { RootStoreState } from '@rootstore';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef,
} from '@angular/material/legacy-dialog';
import { ColorPickerModule, ColorPickerService } from 'ngx-color-picker';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { ColorUtil } from '@util/color-util';
import { Subject, firstValueFrom } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LibrariesActions } from '../../libraries-store';
import { WorkspacesSelectors } from '@common/workspaces/workspaces-store';

@Component({
  selector: 'app-create-color',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    ComponentsModule,
    ColorPickerModule,
    MatInputModule,
    MatButtonModule,
  ],
  templateUrl: './create-color.component.html',
  styleUrls: ['./create-color.component.scss'],
  providers: [ColorPickerService],
})
export class CreateColorComponent implements OnInit {
  private destroy$ = new Subject();

  public colorForm = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    rgb: new UntypedFormGroup({
      red: new UntypedFormControl(0, Validators.required),
      green: new UntypedFormControl(0, Validators.required),
      blue: new UntypedFormControl(0, Validators.required),
    }),
    hex: new UntypedFormControl('#000000', Validators.required),
  });

  color = '#000000';

  constructor(
    private store: Store<RootStoreState.State>,
    public dialogRef: MatDialogRef<CreateColorComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.colorForm.controls['rgb'].valueChanges.pipe(takeUntil(this.destroy$)).subscribe((val) => {
      this.color = ColorUtil.rgbToHex(val.red, val.green, val.blue);
      this.colorForm.controls['hex'].setValue(this.color);
    });
  }

  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  colorChange(evt) {
    const rgb = ColorUtil.hexToRgb(evt);
    if (rgb) {
      this.color = evt;
      this.colorForm.controls['hex'].setValue(evt);
      this.colorForm.controls['rgb'].setValue({ red: rgb.r, green: rgb.g, blue: rgb.b });
    }
  }

  cancel() {
    this.dialogRef.close();
  }
  async save() {
    const name = this.colorForm.controls['name'].value;
    const rgb = this.colorForm.controls['rgb'].value;
    const hex = this.color.slice(1);
    const currentFolder = await firstValueFrom(this.store.select(WorkspacesSelectors.currentWorkspaceFolder));
    this.dialogRef.close();
    const color = {
      typePath: 'color',
      entityType: 'color',
      workspaceId: currentFolder?.id,
      name,
      hex,
      red: rgb.red,
      green: rgb.green,
      blue: rgb.blue,
    };
    const data: any = {
      entityName: 'color',
      object: color,
    };
    this.store.dispatch(LibrariesActions.createLibraryEntity(data));
  }

  isValid() {
    return this.colorForm.valid;
  }
}
