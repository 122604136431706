import { Injectable } from '@angular/core';
import { API_VERSION, Entities } from '@contrail/sdk';
import { Assortment, AssortmentType } from './assortments-store/assortments.state';
import { PaginatedSearchResult } from '@common/entities/entities.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AssortmentsService {
  constructor() {}

  public async getAllNonBackingAssortmentsInWorkspace(
    workspaceId: string,
    options: {
      relations?: string[];
    } = {},
  ): Promise<Assortment[]> {
    return this.getAllAssortmentsInWorkspace(workspaceId, options, {
      assortmentType: [AssortmentType.PLAN, AssortmentType.ASSORTMENT, AssortmentType.INTEGRATION],
    });
  }

  public async getAllAssortmentsInWorkspace(
    workspaceId: string,
    options: {
      relations?: string[];
    },
    additionalCriteria: { [key: string]: any } = {},
  ): Promise<Assortment[]> {
    const { relations } = options;
    const criteria = {
      rootWorkspaceId: workspaceId,
      isArchived: false,
      isTrashed: false,
      ...additionalCriteria,
    };

    const assortments = await this.fetchAllPaginatedResults<Assortment>((nextPageKey) =>
      this.getNonBackingAssortmentsPaginated({ criteria, relations, nextPageKey }),
    );

    return this.sortAssortmentsByName(assortments);
  }

  private async getNonBackingAssortmentsPaginated(options: {
    criteria: { [key: string]: any };
    relations?: string[];
    nextPageKey?: string;
  }): Promise<PaginatedSearchResult<Assortment>> {
    const { criteria, relations, nextPageKey } = options;

    const numberOfResultsPerPage = relations?.length ? 100 : 500;
    const data: PaginatedSearchResult<Assortment> = await new Entities().get({
      entityName: 'assortment',
      criteria,
      relations,
      take: numberOfResultsPerPage,
      apiVersion: API_VERSION.V2,
      paginate: true,
      nextPageKey,
    });

    const filteredResults = data.results?.length
      ? data.results
          .filter((a) => a.name?.indexOf('Backing') < 0)
          .filter((a) => a?.assortmentType !== 'BACKING' && a?.assortmentType !== 'SHOWCASE')
          .filter((a) => a.isArchived !== true && a.isTrashed !== true)
      : [];

    return { results: filteredResults, nextPageKey: data.nextPageKey };
  }

  public async getAssortmentById(id: string) {
    return new Entities().get({ entityName: 'assortment', id });
  }

  public async getSourceAssortment(id: string) {
    let assortment: any;
    const params: any = {
      entityName: 'assortment',
      id,
      relations: [
        'assortmentItems',
        'assortmentItems.item',
        'assortmentItems.item.primaryViewable',
        'assortmentItems.item.primaryViewable.primaryFile,',
        'assortmentItems.item.primaryViewable.smallViewable',
      ],
    };
    // params.cacheMode = 'USE';
    assortment = await new Entities().get(params);
    if (assortment.itemsDownloadURL) {
      const response = await fetch(assortment.itemsDownloadURL);
      const assortmentItems = await response.json();
      assortment.assortmentItems = assortmentItems;
    }
    return assortment;
  }
  public async createAssortment(assortment: Assortment) {
    return new Entities().create({ entityName: 'assortment', object: assortment });
  }
  public async deleteAssortment(assortment: Assortment) {
    await new Entities().delete({ entityName: 'assortment', id: assortment.id });
    return assortment;
  }
  public async updateAssortment(id: string, changes: Assortment) {
    return new Entities().update({ entityName: 'assortment', id, object: changes });
  }

  private sortAssortmentsByName(assortments: Assortment[]): Assortment[] {
    return assortments.sort((a1, a2) => (a1.name?.toUpperCase() > a2.name?.toUpperCase() ? 1 : -1));
  }

  private async fetchAllPaginatedResults<T>(
    fetchPage: (nextPageKey?: string) => Promise<{ results: T[]; nextPageKey?: string }>,
  ): Promise<T[]> {
    const results: T[] = [];
    let nextPageKey: string | undefined;

    do {
      const { results: pageResults, nextPageKey: newNextPageKey } = await fetchPage(nextPageKey);
      results.push(...pageResults);
      nextPageKey = newNextPageKey;
    } while (nextPageKey);

    return results;
  }
}
