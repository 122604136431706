import { Injectable } from '@angular/core';
import { ViewDefinition, ViewPropertyConfiguration, ViewPropertyPivotType } from '@contrail/client-views';
import { PropertyType } from '@contrail/types';
import { ObjectUtil } from '@contrail/util';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { SortObjects } from '../sort/sort-objects';
import { PivotHeaderColumnResizeHelper } from './header-row/pivot-header-column-resize-helper';
import { AggregateColumnEntity, PivotAggregateHelper } from './pivot-aggregate-helper';
import { PivotConfiguratorHelper } from './pivot-view-configurator/pivot-configurator-helper';
import { ChangeObject } from '@common/entities/entities.interfaces';

@Injectable({
  providedIn: 'root',
})
export class PivotGridManager {
  rawData: Array<any> = []; // stores filteredData from CollectionManagerSelectors
  pivotData: Array<any>; // calculated pivotData for display
  originalPivotData: Array<any>; // original calculated pivotData for reference
  viewDefinition: ViewDefinition; // currently selected view definition
  expandedRows: Array<any> = []; // currently expanded rows so that expanded rows can be maintained after sorting
  private rowGroupingProperties: ViewPropertyConfiguration[];
  private columnGroupingProperties: ViewPropertyConfiguration[];
  private pivotDataSubject: Subject<Array<any>> = new BehaviorSubject(null);
  public pivotData$: Observable<Array<any>> = this.pivotDataSubject.asObservable();
  private viewDefinitionUpdateSubject: Subject<ChangeObject<ViewDefinition>> = new BehaviorSubject(null); // used to emit updated viewDefinition
  public viewDefinitionUpdate$: Observable<ChangeObject<ViewDefinition>> =
    this.viewDefinitionUpdateSubject.asObservable();

  constructor(private columnResizeHelper: PivotHeaderColumnResizeHelper) {}

  setGridView(viewDefinition: ViewDefinition): void {
    this.viewDefinition = viewDefinition;
  }

  calculatePivotData(data, viewDefinition: ViewDefinition, keepExpandedRows = false) {
    this.rawData = data;
    this.setCurrentGridView(viewDefinition);
    if (!viewDefinition.properties?.length) {
      this.pivotData = [];
      this.pivotDataSubject.next([]);
      return;
    }

    this.rowGroupingProperties = this.viewDefinition.properties.filter(
      (property) => PivotConfiguratorHelper.isRowGroupingProperty(property) && property.propertyDefinition,
    );

    this.columnGroupingProperties = this.viewDefinition.properties.filter(
      (property) => PivotConfiguratorHelper.isColumnGroupingProperty(property) && property.propertyDefinition,
    );

    const enabledProps = viewDefinition.properties.filter(
      (property) => property.enabled && property.propertyDefinition,
    );

    const aggregateProperties = enabledProps
      .filter((property) => PivotConfiguratorHelper.isValueProperty(property))
      .map((property) => {
        const propertyType = property.propertyDefinition?.propertyType || PropertyType.Number;
        return {
          typeProperty: {
            slug: property.propertyDefinition?.slug || 'count',
            label: property.propertyDefinition?.label || 'Count',
            propertyType,
            aggregateFunction:
              property.aggregateFunction ||
              PivotAggregateHelper.getDefaultAggregateFunctionForPropertyType(propertyType),
            pivotType: ViewPropertyPivotType.VALUE,
          },
          indexPrefix: null,
        };
      });

    const rowGroupProperties = enabledProps
      .filter((property) => PivotConfiguratorHelper.isRowGroupingProperty(property))
      .filter((prop) => prop.propertyDefinition)
      .map((property) => {
        return {
          typeProperty: {
            slug: property.propertyDefinition.slug,
            label: property.propertyDefinition.label,
            propertyType: property.propertyDefinition.propertyType,
            options: property.propertyDefinition.options,
          },
          indexPrefix: null,
        };
      });

    const columnGroupProperties = enabledProps
      .filter((property) => PivotConfiguratorHelper.isColumnGroupingProperty(property))
      .filter((prop) => prop.propertyDefinition)
      .map((property) => {
        return {
          typeProperty: {
            slug: property.propertyDefinition.slug,
            label: property.propertyDefinition.label,
            propertyType: property.propertyDefinition.propertyType,
            options: property.propertyDefinition.options,
          },
          indexPrefix: null,
        };
      });

    const aggregateData = PivotAggregateHelper.computeAggregates({
      data,
      rowGroupProperties,
      columnGroupProperties,
      aggregateProperties,
    });

    if (!aggregateData.children) {
      this.pivotData = [];
      this.pivotDataSubject.next([]);
      return;
    }

    this.pivotData = aggregateData.children;
    this.pivotData.forEach((row) => {
      row.topRow = true;
    });
    if (viewDefinition?.sorts?.length > 0) {
      SortObjects.sort(this.pivotData, ObjectUtil.cloneDeep(viewDefinition.sorts));
    }
    this.originalPivotData = ObjectUtil.cloneDeep(this.pivotData);
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    const clonedExpandedRows = ObjectUtil.cloneDeep(this.expandedRows);
    this.expandedRows = [];
    // keep expanded rows expanded
    if (keepExpandedRows) {
      clonedExpandedRows.forEach((expandedRow) => {
        const row = updatedPivotData.find((pivotrow) => pivotrow.id === expandedRow.id);
        if (row) {
          this.expandRow(row, updatedPivotData);
        }
      });
    }
    this.pivotData = ObjectUtil.cloneDeep(updatedPivotData);
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  public clearPivotData() {
    this.pivotData = [];
    this.pivotDataSubject.next([]);
  }

  public expandSingleRow(rowData: any) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    this.expandRow(rowData, updatedPivotData);
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  public collapseSingleRow(rowData: any) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    this.collapseRow(rowData, updatedPivotData);
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  public toggleAllRows(changes: any) {
    let updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    if (changes.eventType === 'expand') {
      this.expandRows(updatedPivotData, this.pivotData, this.rowGroupingProperties.length - 2);
    } else {
      updatedPivotData = ObjectUtil.cloneDeep(this.originalPivotData);
      this.expandedRows = [];
    }
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  private expandRows(updatedPivotData, rows, level) {
    if (!rows) {
      return;
    }

    rows.forEach((rowData) => {
      if (rowData.level <= level) {
        this.expandRow(rowData, updatedPivotData);
        if (rowData.children) {
          this.expandRows(updatedPivotData, rowData.children, level);
        }
      }
    });
  }

  public expandRowsAtLevel(level) {
    let updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    this.expandRows(updatedPivotData, this.pivotData, level);
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  public collapseRowsAtLevel(level) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    const clonedExpandedRows = ObjectUtil.cloneDeep(this.expandedRows);
    clonedExpandedRows.forEach((expandedRow, i) => {
      if (expandedRow.level === level) {
        this.collapseRow(expandedRow, updatedPivotData);
      }
    });
    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(updatedPivotData));
  }

  private expandRow(rowData, updatedPivotData) {
    const groupIndex = this.rowGroupingProperties.findIndex(
      (groupProperty) => groupProperty.slug === rowData.propertySlug,
    );
    const rowIndex = updatedPivotData.findIndex((row) => row.id === rowData.id);
    if (!updatedPivotData[rowIndex].expanded && this.rowGroupingProperties[groupIndex + 1]) {
      this.expandedRows.push(ObjectUtil.cloneDeep(rowData));
      updatedPivotData[rowIndex].expanded = true;
      const sortIndex = this.viewDefinition.sorts?.findIndex(
        (sort) => sort.propertySlug === this.rowGroupingProperties[groupIndex + 1].slug,
      );
      const children = ObjectUtil.cloneDeep(rowData.children);
      if (sortIndex > -1) {
        SortObjects.sort(children, ObjectUtil.cloneDeep(this.viewDefinition.sorts));
      }
      updatedPivotData.splice(rowIndex + 1, 0, ...children);
    }
  }

  private collapseRow(rowData, updatedPivotData) {
    this.expandedRows.splice(
      this.expandedRows.findIndex((expandedRow) => expandedRow.id === rowData.id),
      1,
    );
    const rowIndex = updatedPivotData.findIndex((row) => row.id === rowData.id);
    updatedPivotData[rowIndex].expanded = false;
    rowData.children.forEach((child) => {
      this.removeChildRows(updatedPivotData, child);
    });
  }

  private removeChildRows(updatedPivotData: any, row: any) {
    const clonedRow = ObjectUtil.cloneDeep(row);
    const rowIndex = updatedPivotData.findIndex((data) => data.id === row.id);
    if (rowIndex > -1) {
      updatedPivotData.splice(rowIndex, 1);
      clonedRow.children?.forEach((child) => {
        this.removeChildRows(updatedPivotData, child);
      });
    }
  }

  public sortPivotData(viewDefinition: ViewDefinition) {
    const updatedPivotData = ObjectUtil.cloneDeep(this.pivotData);
    updatedPivotData
      .filter((rowData) => rowData.propertySlug === this.rowGroupingProperties[0].slug)
      .forEach((rowData) => {
        const rowIndex = updatedPivotData.findIndex((row) => row.id === rowData.id);
        updatedPivotData[rowIndex].expanded = false;
        rowData.children?.forEach((child) => {
          this.removeChildRows(updatedPivotData, child);
        });
      });
    SortObjects.sort(updatedPivotData, ObjectUtil.cloneDeep(viewDefinition.sorts));
    this.rowGroupingProperties.forEach((groupProperty, i) => {
      this.expandedRows
        .filter((expandedRow) => expandedRow.propertySlug === groupProperty.slug)
        .forEach((expandedRow) => {
          const rowIndex = updatedPivotData.findIndex((row) => row.id === expandedRow.id);
          updatedPivotData[rowIndex].expanded = true;
          const children = ObjectUtil.cloneDeep(updatedPivotData[rowIndex].children);
          const sortIndex = viewDefinition.sorts.findIndex(
            (sort) => sort.propertySlug === this.rowGroupingProperties[i + 1].slug,
          );
          if (sortIndex > -1) {
            SortObjects.sort(children, [ObjectUtil.cloneDeep(viewDefinition.sorts)[sortIndex]]);
          }
          updatedPivotData.splice(rowIndex + 1, 0, ...children);
        });
    });

    this.pivotData = updatedPivotData;
    this.pivotDataSubject.next(ObjectUtil.cloneDeep(this.pivotData));
  }

  public arePropertiesEqual(previousViewDefinition: ViewDefinition, viewDefinition: ViewDefinition): boolean {
    if (!previousViewDefinition || !viewDefinition) {
      return false;
    }

    if (this.haveRowGroupingPropertiesChanged(viewDefinition)) {
      return false;
    }

    if (this.haveColumnGroupingPropertiesChanged(viewDefinition)) {
      return false;
    }

    if (this.haveValuePropertiesChanged(previousViewDefinition, viewDefinition)) {
      return false;
    }

    return true;
  }

  private haveRowGroupingPropertiesChanged(viewDefinition: ViewDefinition) {
    const selectedRowGroupingProperties = viewDefinition.properties.filter(
      (property) => PivotConfiguratorHelper.isRowGroupingProperty(property) && property.propertyDefinition,
    );

    if ((this.rowGroupingProperties?.length || 0) !== selectedRowGroupingProperties.length) {
      return true;
    }

    const haveRowPropertiesBeenReordered = selectedRowGroupingProperties.some((property, i) => {
      const hasDifference = this.rowGroupingProperties[i].slug !== property.slug;
      return hasDifference;
    });

    return haveRowPropertiesBeenReordered;
  }

  private haveColumnGroupingPropertiesChanged(viewDefinition: ViewDefinition) {
    const selectedColumnGroupingProperties = viewDefinition.properties.filter(
      (property) => PivotConfiguratorHelper.isColumnGroupingProperty(property) && property.propertyDefinition,
    );

    if ((this.columnGroupingProperties?.length || 0) !== selectedColumnGroupingProperties.length) {
      return true;
    }

    const haveColumnPropertiesBeenReordered = selectedColumnGroupingProperties.some((property, i) => {
      const hasDifference = this.columnGroupingProperties[i].slug !== property.slug;
      return hasDifference;
    });

    return haveColumnPropertiesBeenReordered;
  }

  private haveValuePropertiesChanged(previousViewDefinition: ViewDefinition, viewDefinition: ViewDefinition) {
    const selectedValueProperties = viewDefinition.properties.filter(
      (property) => PivotConfiguratorHelper.isValueProperty(property) && property.propertyDefinition,
    );

    const previousValueProperties = previousViewDefinition.properties.filter(
      (property) => PivotConfiguratorHelper.isValueProperty(property) && property.propertyDefinition,
    );

    const haveValuePropertiesChanged = selectedValueProperties.some((property) => {
      const isNewProperty =
        previousValueProperties.findIndex((previousProperty) => previousProperty.slug === property.slug) === -1;
      return isNewProperty;
    });

    return haveValuePropertiesChanged;
  }

  public areSortsEqual(selectedViewDefinition: ViewDefinition, viewDefinition: ViewDefinition): boolean {
    if (!selectedViewDefinition || selectedViewDefinition.sorts?.length !== viewDefinition.sorts?.length) {
      return false;
    }
    let sameSorts = true;
    selectedViewDefinition.sorts?.forEach((sort, i) => {
      if (ObjectUtil.compareDeep(sort, viewDefinition.sorts[i], '').length > 0) {
        sameSorts = false;
      }
    });
    return sameSorts;
  }

  public areAggregateFunctionsEqual(selectedViewDefinition: ViewDefinition, viewDefinition: ViewDefinition): boolean {
    let sameProperties = true;
    selectedViewDefinition.properties.forEach((property, i) => {
      if (property.aggregateFunction !== viewDefinition.properties[i].aggregateFunction) {
        sameProperties = false;
      }
    });
    return sameProperties;
  }

  public isDataEqual(data: Array<any>, data2: Array<any>) {
    return (
      data
        ?.map((row) => row.id)
        .sort()
        .toString() ===
      data2
        ?.map((row) => row.id)
        .sort()
        .toString()
    );
  }

  calculateWidth(viewDefinition: ViewDefinition, columnEntity: AggregateColumnEntity) {
    let leftSideColumnWidth = 0;
    const leftSideColumns = viewDefinition.properties.filter(
      (property) =>
        PivotConfiguratorHelper.isRowGroupingProperty(property) && property.enabled && property.propertyDefinition,
    );

    if (leftSideColumns.length > 0) {
      leftSideColumnWidth = leftSideColumns[0].width || leftSideColumns.length * 100; // use the first prop's width if exists
    }

    const rightSideColumnWidth = this.columnResizeHelper.getHeaderColumnWidthInPixels(columnEntity);

    return { leftSideColumnWidth, rightSideColumnWidth };
  }

  public setCurrentGridView(viewDefinition: ViewDefinition): void {
    this.viewDefinition = viewDefinition;
  }

  public setGridViewUpdates(viewUpdates: ChangeObject<ViewDefinition>): void {
    this.viewDefinitionUpdateSubject.next(viewUpdates);
  }

  async handleResizeColumn(columnChanges: any) {
    switch (columnChanges.eventType) {
      case 'dragStart':
        this.columnResizeHelper.handleColumnResizeDragStart(columnChanges.property);
        break;
      case 'dragMove':
        this.columnResizeHelper.handleColumnResizeDragMove(columnChanges.event);
        break;
      case 'dragEnd':
        const viewUpdates = this.columnResizeHelper.buildUpdatedViewForColumnResizeDragDrop(this.viewDefinition);
        if (viewUpdates) {
          this.setGridViewUpdates(viewUpdates);
        }
        break;
    }
  }
}
