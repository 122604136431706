<div #pivotGrid class="grid-body" id="grid-body" [style.width.px]="gridTotalWidth" cdkDrop (click)="closeContextMenu()">
  <div class="left-side" *ngIf="leftProperties.length > 0">
    <app-pivot-header-row
      [viewDefinition]="viewDefinition"
      [properties]="leftProperties"
      [columnGroupingValues]="columnGroupingValues"
      side="left"
      [showGoals]="showGoals"
      (resizeColumn)="performResizeColumn($event)"
      (toggleRows)="toggleRows($event)"
    >
    </app-pivot-header-row>
    <app-pivot-data-rows
      [allData]="pivotData"
      [data]="pivotData"
      side="left"
      [goals]="goals"
      [sideWidth]="leftSideColumnWidth"
      [properties]="leftProperties"
      [rowGroupingProperties]="rowGroupingProperties"
      [rowsToRender]="rowsToRender"
      [rowContainerHeight]="rowContainerHeight + TOTALS_ROW_HEIGHT"
      [rowStartIndex]="rowStartIndex"
      (rowAction)="onRowAction($event)"
      (hoverRow)="onHoverRow($event)"
      [hoveredRowId]="hoveredRowId"
      (onContextMenu)="handleContextMenu($event)"
    >
    </app-pivot-data-rows>
  </div>

  <div
    class="right-side"
    [style.minWidth.px]="rightColumnMinWidth"
    *ngIf="rightProperties?.length || columnGroupingValues?.values?.length"
  >
    <div>
      <app-pivot-header-row
        [viewDefinition]="viewDefinition"
        [properties]="rightProperties"
        [columnGroupingValues]="columnGroupingValues"
        side="right"
        [showGoals]="showGoals"
        (resizeColumn)="performResizeColumn($event)"
      ></app-pivot-header-row>
      <app-pivot-data-rows
        [allData]="pivotData"
        [data]="pivotData"
        side="right"
        [goals]="goals"
        [showGoals]="showGoals"
        [properties]="rightProperties"
        [rowGroupingProperties]="rowGroupingProperties"
        [columnGroupingKeys]="columnGroupingKeys"
        [rowsToRender]="rowsToRender"
        [rowContainerHeight]="rowContainerHeight"
        [rowStartIndex]="rowStartIndex"
        (hoverRow)="onHoverRow($event)"
        [hoveredRowId]="hoveredRowId"
      >
      </app-pivot-data-rows>
    </div>

    <div class="agg-row">
      <app-aggregate-row
        [data]="pivotData"
        [properties]="rightProperties"
        [columnGroupingKeys]="columnGroupingKeys"
        [showGoals]="showGoals"
        class="flex"
      ></app-aggregate-row>
    </div>
  </div>
  <div
    #verticalScroll
    (scroll)="onScroll($event)"
    class="vertical-scroller"
    [style.height.px]="rowContainerHeight + TOTALS_ROW_HEIGHT + 1"
  >
    <div class="w-[1px]" [style.height.px]="rowViewPortHeight + 30"></div>
  </div>
</div>
<app-pivot-context-menu (handleRowAction)="handleRowAction($event)"></app-pivot-context-menu>
