<div class="card">
  <div>
    <img class="preview pointer-events-none" *ngIf="imageUrl && !secureImage" [src]="imageUrl" />
    <img
      class="preview pointer-events-none"
      *ngIf="imageUrl && secureImage"
      [attr.src]="imageUrl | secureImage | async"
    />
    <div class="placeholder" *ngIf="!imageUrl">
      <mat-icon *ngIf="!matIcon">image</mat-icon>
      <mat-icon *ngIf="matIcon">folder_outline</mat-icon>
    </div>
    <div *ngIf="badge" class="badge">
      <span>{{ badge.text }}</span>
    </div>
  </div>
  <div class="card-footer" [ngClass]="{ selected: selected }">
    <div class="meta">
      <div class="name">
        <img class="icon max-w-none" *ngIf="iconUrl" [src]="iconUrl" />
        <mat-icon *ngIf="matIcon">{{ matIcon }}</mat-icon>
        <span> {{ title | shorten: 20 }} </span>
      </div>
      <div class="details">Edited {{ updatedOn | dateAgo }}</div>
    </div>
    <div class="menu-toggle" (click)="showMenu($event)">
      <mat-icon>more_vert</mat-icon>
    </div>
  </div>
</div>
