import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { PlansActions } from '.';
import { PlansService } from '../plans.service';
import { Plan } from './plans.state';
import { RoutingService } from '@common/routing/routing.service';
import { LoadingIndicatorActions } from '@common/loading-indicator/loading-indicator-store';
import { DownloadService } from '@common/exports/download/download.service';
import { WorkspaceEntitiesHelperService } from 'src/app/workspaces/workspace-entities-helper.service';

@Injectable()
export class PlansEffects {
  constructor(
    private actions$: Actions,
    private planService: PlansService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
    private routingService: RoutingService,
    private downloadService: DownloadService,
    private workspaceEntitiesHelperService: WorkspaceEntitiesHelperService,
  ) {}

  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          this.store.dispatch(PlansActions.clearPlans());
          this.store.dispatch(PlansActions.loadPlans());
        }),
      ),
    { dispatch: false },
  );

  loadPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.LOAD_PLANS),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.planService.getPlans(store.workspaces.currentWorkspace.id)).pipe(
          map((data) => PlansActions.loadPlansSuccess({ data })),
          catchError((error) => observableOf(PlansActions.loadPlansFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentPlans$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PlansActions.PlansActionTypes.LOAD_CURRENT_PLAN),
      switchMap((action: any) => {
        return from(this.planService.getPlanById(action.id)).pipe(
          map((data) => PlansActions.loadCurrentPlanSuccess({ plan: data })),
          catchError((error) => observableOf(PlansActions.loadCurrentPlanFailure({ error }))),
        );
      }),
    ),
  );
  createPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.CREATE_PLAN),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Creating plan.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          const plan: Plan = { ...action.plan };
          plan.workspaceId = store.workspaces.currentWorkspaceFolder.id;
          return from(this.planService.createPlan(plan)).pipe(
            map((data) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open('Plan Created.', '', { duration: 2000 });
              // Could be we only want this in hub....
              this.routingService.openApp('plan', `plan/${data.id}`);
              this.store.dispatch(WorkspacesActions.addEntity({ entity: data }));
              return PlansActions.createPlanSuccess({ plan: data });
            }),
            catchError((error) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(PlansActions.createPlanFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  deletePlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.DELETE_PLAN),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Deleting plan.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.planService.deletePlanById(action.planId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Plan Deleted.', '', { duration: 2000 });
              // this.store.dispatch(PlansActions.setCurrentPlan({ currentPlan: null }));
              this.store.dispatch(
                WorkspacesActions.removeFolderEntities({ entityReferences: [`plan:${action.planId}`] }),
              );
              return PlansActions.deletePlanSuccess({ planId: action.planId });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(PlansActions.deletePlanFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  updatePlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.UPDATE_PLAN),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Updating plan.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.planService.updatePlan(action.id, action.changes)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Plan Updated.', '', { duration: 2000 });
              if (store.workspaces.currentWorkspaceFolder?.id) {
                this.store.dispatch(
                  WorkspacesActions.loadWorkspaceFolderEntities({
                    workspaceId: store.workspaces.currentWorkspaceFolder.id,
                  }),
                );
              }

              this.store.dispatch(PlansActions.updatePlanSuccess({ id: data.id, changes: data }));
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(PlansActions.updatePlanFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  copyPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.COPY_PLAN),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Copying plan.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.planService.copy(action.name, action.sourceId)).pipe(
            tap(() => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
            }),
            map((data) => {
              this.snackBar.open('Plan Copied.', '', { duration: 2000 });
              this.routingService.openApp('plan', `plan/${data.id}`);
              this.store.dispatch(
                WorkspacesActions.loadWorkspaceFolderEntities({
                  workspaceId: store.workspaces.currentWorkspaceFolder.id,
                }),
              );
              return PlansActions.copyPlanSuccess({ plan: data });
            }),
            catchError((error) => {
              this.snackBar.open(error, '', { duration: 2000 });
              return observableOf(PlansActions.copyPlanFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );
  asyncCopyPlan$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.ASYNC_COPY_PLAN),
        tap(() => {
          this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: true, message: 'Copying plan.' }));
        }),
        withLatestFrom(this.store),
        switchMap(([action, store]: [any, RootStoreState.State]) => {
          return from(this.planService.asyncCopyPlan(action.name, action.sourceId, action.newWorkspaceId)).pipe(
            map((data) => {
              if (!data?.jobId) {
                throw new Error();
              }
              this.downloadService.initDownloadPolling(data.jobId, `/plans/copy/${data.jobId}`);
              const combletedJobSubscription = this.downloadService.completedJobsObservable$.subscribe(
                async (completedJob) => {
                  if (completedJob?.jobId === data.jobId && completedJob?.path) {
                    this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
                    this.store.dispatch(
                      PlansActions.asyncCopyPlanSuccess({
                        jobId: completedJob.jobId,
                        path: completedJob.path as string,
                        navigateToFolder: action.navigateToFolder,
                      }),
                    );
                    combletedJobSubscription.unsubscribe();
                  }
                },
              );
              return data;
            }),
            catchError((error) => {
              this.store.dispatch(LoadingIndicatorActions.setLoading({ loading: false }));
              this.snackBar.open(error, 'An error occurred while attempting to copy this document. Please try again.', {
                duration: 2000,
              });
              return observableOf(PlansActions.copyPlanFailure({ error }));
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  asyncCopyPlanSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(PlansActions.PlansActionTypes.ASYNC_COPY_PLAN_SUCCESS),
        withLatestFrom(this.store),
        tap(async ([action, store]: [any, RootStoreState.State]) => {
          this.snackBar.open('Plan Copied.', '', { duration: 2000 });
          const response = await fetch(action.path);
          const plan = await response.json();
          this.routingService.openApp('plan', `plan/${plan.id}`);
          this.store.dispatch(
            WorkspacesActions.loadWorkspaceFolderEntities({
              workspaceId: store.workspaces.currentWorkspaceFolder.id,
            }),
          );
          this.store.dispatch(PlansActions.copyPlanSuccess({ plan }));

          if (action.navigateToFolder) {
            if (action.navigateToFolder?.across) {
              this.routingService.go('/workspaces/workspace/' + action.navigateToFolder.rootId + '/explorer');
              if (action.navigateToFolder?.folder?.entity) {
                setTimeout(() => this.workspaceEntitiesHelperService.goToEntity(action.navigateToFolder.folder), 1500);
              }
            } else {
              action.navigateToFolder?.folder?.entity
                ? this.workspaceEntitiesHelperService.goToEntity(action.navigateToFolder.folder)
                : this.workspaceEntitiesHelperService.goToRootFolder();
            }
          }
        }),
      ),
    { dispatch: false },
  );
}
