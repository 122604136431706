import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export interface ListCardBadge {
  text: string;
  color?: string;
}
@Component({
  selector: 'app-list-card',
  templateUrl: './list-card.component.html',
  styleUrls: ['./list-card.component.scss'],
})
export class ListCardComponent implements OnInit {
  @Input() entity: any;
  @Input() imageUrl: string;
  @Input() title: string;
  @Input() secureImage = false;
  @Input() updatedOn: any;
  @Input() iconUrl: string;
  @Input() matIcon: string;
  @Input() badge: ListCardBadge;
  @Output() openMenu = new EventEmitter();
  selected = false;
  constructor() {}

  ngOnInit(): void {
    if (this.entity) {
      this.title = this.entity?.name;
      this.imageUrl = this.entity?.thumbnailUrl;
      this.iconUrl = this.entity?.icon;
      this.matIcon = this.entity?.matIcon;
      this.updatedOn = this.entity?.updatedOn;
    }
  }

  showMenu($event) {
    $event.stopPropagation();
    this.openMenu.emit($event);
  }
}
