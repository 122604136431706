<ng-container>
  <span>{{ property.label }}</span>
  <div class="flex items-center justify-end grow">
    <div>
      <mat-select
        data-test="aggregate-function-select"
        *ngIf="!sortable"
        [(value)]="property.aggregateFunction"
        (selectionChange)="selectAggregateFunction($event, property)"
      >
        <mat-option *ngFor="let functionType of functionTypes" [value]="functionType">
          {{ functionType }}
        </mat-option>
      </mat-select>
    </div>
    <mat-icon
      class="cursor-pointer !flex items-center justify-end !h-[15px] !w-[15px] !text-[15px] mr-0"
      *ngIf="sortable"
      svgIcon="sort"
      [matMenuTriggerFor]="sortMenu"
      [matMenuTriggerData]="{ property }"
    ></mat-icon>
    <mat-icon
      data-test="remove-property-button"
      class="cursor-pointer !flex items-center justify-end !text-[15px] mr-0"
      (click)="removeProperty(property)"
      >delete</mat-icon
    >
  </div>
</ng-container>
