import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface ShowroomLayout {
  id: string;
  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;
  typeId?: string;
  orgId?: string;

  showroomId?: string;
  showroom?: Showroom;
  layout?: any;

  previewFileId?: string;
  previewFile?: string;
}

export interface Showroom {
  id?: string;
  createdOn?: Date;
  updatedOn?: Date;
  createdById?: string;
  updatedById?: string;
  createdBy?: any;
  updatedBy?: any;
  typeId?: string;
  orgId?: string;

  name?: string;
  workspaceId?: string;
  showroomLayouts?: ShowroomLayout[];
  sourceAssortmentId?: string;
  sourceAssortment?: any;
  isTrashed?: boolean;
}

interface ShowroomsState extends EntityState<Showroom> {}

export interface State {
  showrooms: ShowroomsState;
  currentShowroom: Showroom;
  showroomsLoaded: boolean;
}
export const showroomsEntityAdapter: EntityAdapter<Showroom> = createEntityAdapter<Showroom>({});

export const showroomsInitialState = {
  showrooms: showroomsEntityAdapter.getInitialState({}),
  currentShowroom: null,
  showroomsLoaded: false,
};
