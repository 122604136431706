import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from, of as observableOf } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';
import { RootStoreState } from 'src/app/root-store';
import { WorkspacesActions } from '@common/workspaces/workspaces-store';
import { AssortmentsActions } from '.';
import { AssortmentsService } from '../assortments.service';
import { Assortment } from './assortments.state';

@Injectable()
export class AssortmentsEffects {
  constructor(
    private actions$: Actions,
    private assortmentService: AssortmentsService,
    private store: Store<RootStoreState.State>,
    private snackBar: MatSnackBar,
  ) {}

  workspaceChange$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(WorkspacesActions.WorkspacesActionTypes.LOAD_CURRENT_WORKSPACE_SUCCESS),
        tap((action: any) => {
          this.store.dispatch(AssortmentsActions.setCurrentAssortment({ assortment: null }));
        }),
      ),
    { dispatch: false },
  );

  loadAssortments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.LOAD_ASSORTMENTS),
      tap(() => {
        this.store.dispatch(AssortmentsActions.setAssortmentsLoaded({ loaded: false }));
      }),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(
          this.assortmentService.getAllAssortmentsInWorkspace(store.workspaces.currentWorkspace.id, {
            relations: ['sourceAssortments'],
          }),
        ).pipe(
          map((data) => {
            this.store.dispatch(AssortmentsActions.setAssortmentsLoaded({ loaded: true }));
            return AssortmentsActions.loadAssortmentsSuccess({ data });
          }),
          catchError((error) => observableOf(AssortmentsActions.loadAssortmentsFailure({ error }))),
        );
      }),
    ),
  );

  loadSourceAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.LOAD_SOURCE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        console.log('loadSourceAssortment!!!!!');
        return from(this.assortmentService.getSourceAssortment(action.sourceAssortmentId)).pipe(
          map((sourceAssortment) => AssortmentsActions.loadSourceAssortmentSuccess({ sourceAssortment })),
          catchError((error) => observableOf(AssortmentsActions.loadAssortmentsFailure({ error }))),
        );
      }),
    ),
  );
  loadCurrentAssortments$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.LOAD_CURRENT_ASSORTMENT),
      switchMap((action: any) => {
        return from(this.assortmentService.getAssortmentById(action.id)).pipe(
          map((data) => AssortmentsActions.loadCurrentAssortmentSuccess({ assortment: data })),
          catchError((error) => observableOf(AssortmentsActions.loadCurrentAssortmentFailure({ error }))),
        );
      }),
    ),
  );
  createAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.CREATE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        const assortment: Assortment = { ...action.assortment };
        assortment.workspaceId = store.workspaces.currentWorkspaceFolder.id;
        return from(this.assortmentService.createAssortment(assortment)).pipe(
          map((data) => {
            this.snackBar.open('Assortment Created.', '', { duration: 2000 });
            // this.store.dispatch(AssortmentsActions.setCurrentAssortment({ currentAssortment: data }));
            return AssortmentsActions.createAssortmentSuccess({ assortment: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(AssortmentsActions.createAssortmentFailure({ error }));
          }),
        );
      }),
    ),
  );
  deleteAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.DELETE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.assortmentService.deleteAssortment(action.assortment)).pipe(
          map((data) => {
            this.snackBar.open('Assortment Deleted.', '', { duration: 2000 });
            // this.store.dispatch(AssortmentsActions.setCurrentAssortment({ currentAssortment: null }));
            return AssortmentsActions.deleteAssortmentSuccess({ assortment: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(AssortmentsActions.deleteAssortmentFailure({ error }));
          }),
        );
      }),
    ),
  );
  updateAssortment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AssortmentsActions.AssortmentsActionTypes.UPDATE_ASSORTMENT),
      withLatestFrom(this.store),
      switchMap(([action, store]: [any, RootStoreState.State]) => {
        return from(this.assortmentService.updateAssortment(action.id, action.changes)).pipe(
          map((data) => {
            this.snackBar.open('Assortment Updated.', '', { duration: 2000 });
            return AssortmentsActions.updateAssortmentSuccess({ id: data.id, changes: data });
          }),
          catchError((error) => {
            this.snackBar.open(error, '', { duration: 2000 });
            return observableOf(AssortmentsActions.updateAssortmentFailure({ error }));
          }),
        );
      }),
    ),
  );
}
