import { State } from './assortments.state';
import { Action, createReducer, on } from '@ngrx/store';
import * as AssortmentsActions from './assortments.actions';
import { assortmentsEntityAdapter } from './assortments.state';
import { assortmentsInitialState } from './assortments.state';

const setData = (state: State, { data }) => {
  return {
    ...state,
    assortments: assortmentsEntityAdapter.setAll(data, state.assortments),
  };
};

const setSourceAssortment = (state: State, { sourceAssortment }) => {
  return {
    ...state,
    sourceAssortment,
  };

  const clearData = (state: State, {}) => {
    return {
      ...state,
      assortments: assortmentsEntityAdapter.removeAll(state.assortments),
    };
  };
};

const add = (state: State, { assortment }) => {
  return {
    ...state,
    assortments: assortmentsEntityAdapter.addOne(assortment, state.assortments),
  };
};

const update = (state: State, { id, changes }) => {
  let currentAssortment = state.currentAssortment;
  if (state.currentAssortment?.id === id) {
    currentAssortment = { ...state.currentAssortment, ...changes };
  }
  return {
    ...state,
    assortments: assortmentsEntityAdapter.updateOne({ id, changes }, state.assortments),
    currentAssortment,
  };
};
const remove = (state: State, { assortment }) => {
  return {
    ...state,
    assortments: assortmentsEntityAdapter.removeOne(assortment.id, state.assortments),
  };
};
const setCurrentAssortment = (state: State, { assortment }) => {
  return {
    ...state,
    currentAssortment: assortment,
  };
};
const clearSourceAssortment = (state: State) => {
  return {
    ...state,
    sourceAssortment: null,
  };
};
const setAssortmentsLoaded = (state: State, { loaded }) => {
  return {
    ...state,
    assortmentsLoaded: loaded,
  };
};

export const assortmentsReducers = createReducer(
  assortmentsInitialState,
  on(AssortmentsActions.loadAssortmentsSuccess, setData),
  on(AssortmentsActions.loadSourceAssortmentSuccess, setSourceAssortment),
  on(AssortmentsActions.createAssortmentSuccess, add),
  on(AssortmentsActions.deleteAssortmentSuccess, remove),
  on(AssortmentsActions.updateAssortmentSuccess, update),
  on(AssortmentsActions.loadCurrentAssortmentSuccess, setCurrentAssortment),
  on(AssortmentsActions.setCurrentAssortment, setCurrentAssortment),
  on(AssortmentsActions.clearSourceAssortment, clearSourceAssortment),
  on(AssortmentsActions.setAssortmentsLoaded, setAssortmentsLoaded),
);

export function reducer(state: State | undefined, action: Action): any {
  return assortmentsReducers(state, action);
}
