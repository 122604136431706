import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatLegacyAutocompleteTrigger as MatAutocompleteTrigger } from '@angular/material/legacy-autocomplete';
import { _MatLegacyOptionBase as _MatOptionBase } from '@angular/material/legacy-core';
import { RoutingService } from '@common/routing/routing.service';
import { Entities, SortOrderOptions } from '@contrail/sdk';
import { firstValueFrom, from, Observable, of, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs/operators';

@Component({
  selector: 'app-global-search',
  templateUrl: './global-search.component.html',
  styleUrls: ['./global-search.component.scss'],
  animations: [
    trigger('focusInOut', [
      state(
        'in',
        style({
          width: '400px',
        }),
      ),
      state(
        'out',
        style({
          width: '200px',
        }),
      ),
      transition('in => out', animate('300ms ease-in-out')),
      transition('out => in', animate('200ms ease-in-out')),
    ]),
  ],
})
export class GlobalSearchComponent implements OnInit, AfterViewInit {
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;
  private destroy$ = new Subject();
  focus: 'in' | 'out' = 'out';
  focusing = true; // 200ms animation delay
  loading = true;
  moreLoading = false;

  searchKey: string = '';
  prevSearchTerm: string = '';
  count = 10;
  globalSearch = new FormControl<any>('');
  public results$: Observable<any[]> = from([]);

  constructor(private routingService: RoutingService) {}

  ngOnInit() {}
  ngOnDestroy() {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  ngAfterViewInit(): void {
    this.globalSearch.valueChanges
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.destroy$))
      .subscribe(async (inputValue) => {
        if (typeof inputValue === 'string') {
          this.loading = true;
          this.searchKey = inputValue;
          await this.getResults(inputValue);
        }
      });
  }

  async getResults(key) {
    let searchTerm = key?.trim();
    if (!searchTerm) {
      this.loading = false;
      this.autocomplete.closePanel();
      return;
    }
    if (!searchTerm?.endsWith('*')) {
      searchTerm += '*';
    }

    if (this.prevSearchTerm !== searchTerm) {
      this.prevSearchTerm = searchTerm;
      const res = await firstValueFrom(this.getData(searchTerm, this.count));
      if (res.count > this.count) {
        this.results$ = of(res.results);
        this.loading = false;
        this.moreLoading = true;
        const more = await firstValueFrom(this.getData(searchTerm, res.count - this.count, res.nextPageKey));
        this.results$ = of([...res.results, ...more.results]);
        this.moreLoading = false;
      } else {
        this.results$ = of(res.results);
        this.loading = false;
      }
    } else {
      this.loading = false;
    }
  }

  getData(searchTerm, limit, next?) {
    return of(
      new Entities().get({
        entityName: 'open-search',
        search: searchTerm,
        take: limit,
        order: [
          {
            order: SortOrderOptions.DESC,
            orderField: 'updatedOn',
          },
        ],
        paginate: true,
        criteria: { entityType: 'global', entityTypes: ['board', 'plan', 'showcase', 'showroom'] },
        relations: ['updatedBy'],
        nextPageKey: next,
      }),
    );
  }

  onBlur() {
    setTimeout(() => {
      this.focusing = true;
    }, 250);
    this.focus = 'out';
  }
  onFocus() {
    this.focus = 'in';
    setTimeout(() => {
      this.focusing = false;
    }, 250);
  }

  clear() {
    this.globalSearch.setValue('');
  }

  handleClick(evt) {
    this.globalSearch.setValue(this.searchKey);
    const entity = evt.option.value;
    switch (entity.entityType) {
      case 'board':
        this.routingService.openApp('boards', `board/${entity.id}`);
        break;
      case 'showcase':
        this.routingService.openApp('showcase-manager', `showcase-details/${entity.id}`);
        break;
      case 'plan':
        this.routingService.openApp('plan', `plan/${entity.id}`);
        break;
      case 'showroom':
        this.routingService.openApp('showroom', `showrooms/view/${entity.id}`);
        break;

      default:
        break;
    }
  }

  displayFn(ent: any): string {
    return ent;
    // should be full object to display input element value by form.setValue() | @Rory
    // return ent?.name || '';
  }
}
