import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { librariesReducers } from './libraries.reducers';
import { EffectsModule } from '@ngrx/effects';
import { LibrariesEffects } from './libraries.effects';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('libraries', librariesReducers),
    EffectsModule.forFeature([LibrariesEffects]),
  ],
})
export class LibrariesStoreModule {}
