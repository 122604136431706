import { PropertyType } from '@contrail/types';

export const ELIGIBLE_GROUP_PROPERTY_TYPES = [
  PropertyType.Date,
  PropertyType.SingleSelect,
  PropertyType.MultiSelect,
  PropertyType.String,
  PropertyType.Number,
  PropertyType.Currency,
  PropertyType.Formula,
  PropertyType.ObjectReference,
  PropertyType.Percent,
  PropertyType.Sequence,
  PropertyType.Boolean,
];

export const NUMERIC_AGGREGATE_VALUE_PROPERTY_TYPES = [
  PropertyType.Formula,
  PropertyType.Number,
  PropertyType.Currency,
  PropertyType.Percent,
];

export const AGGREGATE_VALUE_PROPERTY_TYPES = [
  ...NUMERIC_AGGREGATE_VALUE_PROPERTY_TYPES,
  PropertyType.String,
  PropertyType.SingleSelect,
  PropertyType.MultiSelect,
  PropertyType.Date,
  PropertyType.ObjectReference,
  PropertyType.Boolean,
];
